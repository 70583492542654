export const mbrs = [
"POLYGON ((-179.995260 -85.003694, -99.651695 -85.003694, -99.651695 19.624166, -179.995260 19.624166, -179.995260 -85.003694))",
"POLYGON ((-156.910540 19.624170, -100.359931 19.624170, -100.359931 20.930536, -156.910540 20.930536, -156.910540 19.624170))",
"POLYGON ((-159.457281 20.930584, -100.701356 20.930584, -100.701356 23.222648, -159.457281 23.222648, -159.457281 20.930584))",
"POLYGON ((-118.278228 23.222655, -100.134100 23.222655, -100.134100 31.345194, -118.278228 31.345194, -118.278228 23.222655))",
"POLYGON ((-111.237326 31.345290, -99.945725 31.345290, -99.945725 31.962257, -111.237326 31.962257, -111.237326 31.345290))",
"POLYGON ((-110.870158 31.962280, -106.685494 31.962280, -106.685494 32.184609, -110.870158 32.184609, -110.870158 31.962280))",
"POLYGON ((-117.241021 32.184674, -104.256387 32.184674, -104.256387 32.721015, -117.241021 32.721015, -117.241021 32.184674))",
"POLYGON ((-117.175345 32.721016, -107.282665 32.721016, -107.282665 32.934661, -117.175345 32.934661, -117.175345 32.721016))",
"POLYGON ((-116.973395 32.934674, -103.348168 32.934674, -103.348168 32.980580, -116.973395 32.980580, -116.973395 32.934674))",
"POLYGON ((-117.081897 32.980657, -106.182491 32.980657, -106.182491 33.305824, -117.081897 33.305824, -117.081897 32.980657))",
"POLYGON ((-118.326142 33.330371, -106.861884 33.330371, -106.861884 33.444924, -118.326142 33.444924, -118.326142 33.330371))",
"POLYGON ((-117.147131 33.444936, -111.971668 33.444936, -111.971668 33.448819, -117.147131 33.448819, -117.147131 33.444936))",
"POLYGON ((-117.706205 33.448821, -112.041466 33.448821, -112.041466 33.462981, -117.706205 33.462981, -117.706205 33.448821))",
"POLYGON ((-112.047387 33.462981, -112.039630 33.462981, -112.039630 33.463583, -112.047387 33.463583, -112.047387 33.462981))",
"POLYGON ((-112.047379 33.463585, -112.039433 33.463585, -112.039433 33.464423, -112.047379 33.464423, -112.047379 33.463585))",
"POLYGON ((-117.149582 33.464424, -112.039303 33.464424, -112.039303 33.493077, -117.149582 33.493077, -117.149582 33.464424))",
"POLYGON ((-117.947182 33.493077, -112.026419 33.493077, -112.026419 33.626909, -117.947182 33.626909, -117.947182 33.493077))",
"POLYGON ((-118.293782 33.628669, -112.375028 33.628669, -112.375028 33.842711, -118.293782 33.842711, -118.293782 33.628669))",
"POLYGON ((-120.166055 33.842711, -111.946693 33.842711, -111.946693 33.979239, -120.166055 33.979239, -120.166055 33.842711))",
"POLYGON ((-120.051792 33.979244, -116.146305 33.979244, -116.146305 34.050819, -120.051792 34.050819, -120.051792 33.979244))",
"POLYGON ((-119.101116 34.050826, -106.091069 34.050826, -106.091069 34.259798, -119.101116 34.259798, -119.101116 34.050826))",
"POLYGON ((-120.456491 34.259830, -103.205508 34.259830, -103.205508 34.759019, -120.456491 34.759019, -120.456491 34.259830))",
"POLYGON ((-119.760306 34.759029, -106.556672 34.759029, -106.556672 35.061876, -119.760306 35.061876, -119.760306 34.759029))",
"POLYGON ((-119.862236 35.062824, -106.534170 35.062824, -106.534170 35.146435, -119.862236 35.146435, -119.862236 35.062824))",
"POLYGON ((-120.731681 35.146441, -101.864933 35.146441, -101.864933 35.275995, -120.731681 35.275995, -120.731681 35.146441))",
"POLYGON ((-121.183854 35.276035, -99.695564 35.276035, -99.695564 35.746702, -121.183854 35.746702, -121.183854 35.276035))",
"POLYGON ((-117.135143 35.747753, -105.013327 35.747753, -105.013327 36.060225, -117.135143 36.060225, -117.135143 35.747753))",
"POLYGON ((-121.487202 36.060225, -107.953258 36.060225, -107.953258 36.246976, -121.487202 36.246976, -121.487202 36.060225))",
"POLYGON ((-121.919206 36.246986, -102.193829 36.246986, -102.193829 36.744101, -121.919206 36.744101, -121.919206 36.246986))",
"POLYGON ((-121.404281 36.744369, -102.468410 36.744369, -102.468410 36.883671, -121.404281 36.883671, -121.404281 36.744369))",
"POLYGON ((-122.083741 36.884347, -102.255979 36.884347, -102.255979 36.992594, -122.083741 36.992594, -122.083741 36.884347))",
"POLYGON ((-122.200892 36.993036, -102.254992 36.993036, -102.254992 37.318454, -122.200892 37.318454, -122.200892 36.993036))",
"POLYGON ((-121.951725 37.318454, -120.577624 37.318454, -120.577624 37.367427, -121.951725 37.367427, -121.951725 37.318454))",
"POLYGON ((-122.337882 37.367430, -113.643490 37.367430, -113.643490 37.508026, -122.337882 37.508026, -122.337882 37.367430))",
"POLYGON ((-122.514100 37.508026, -103.512861 37.508026, -103.512861 37.780494, -122.514100 37.780494, -122.514100 37.508026))",
"POLYGON ((-122.535175 37.780495, -120.663571 37.780495, -120.663571 37.824302, -122.535175 37.824302, -122.535175 37.780495))",
"POLYGON ((-122.482281 37.824302, -121.803113 37.824302, -121.803113 37.867592, -122.482281 37.867592, -122.482281 37.824302))",
"POLYGON ((-123.244226 37.867600, -102.329436 37.867600, -102.329436 38.572591, -123.244226 38.572591, -123.244226 37.867600))",
"POLYGON ((-121.506882 38.580283, -104.700815 38.580283, -104.700815 38.823099, -121.506882 38.823099, -121.506882 38.580283))",
"POLYGON ((-104.824661 38.823107, -104.701268 38.823107, -104.701268 38.836441, -104.824661 38.836441, -104.824661 38.823107))",
"POLYGON ((-104.824787 38.836465, -104.818057 38.836465, -104.818057 38.843042, -104.824787 38.843042, -104.824787 38.836465))",
"POLYGON ((-123.797932 38.843051, -104.820494 38.843051, -104.820494 39.316168, -123.797932 39.316168, -123.797932 38.843051))",
"POLYGON ((-123.806624 39.316197, -104.895216 39.316197, -104.895216 39.780179, -123.806624 39.780179, -123.806624 39.316197))",
"POLYGON ((-121.303495 39.780183, -104.735825 39.780183, -104.735825 40.566069, -121.303495 40.566069, -121.303495 39.780183))",
"POLYGON ((-122.491973 40.566070, -102.263412 40.566070, -102.263412 40.989090, -122.491973 40.989090, -122.491973 40.566070))",
"POLYGON ((-122.714590 40.989101, -102.264287 40.989101, -102.264287 42.195869, -122.714590 42.195869, -122.714590 40.989101))",
"POLYGON ((-123.329996 42.195874, -103.231673 42.195874, -103.231673 44.398137, -123.329996 44.398137, -123.329996 42.195874))",
"POLYGON ((-123.304285 44.398137, -103.759259 44.398137, -103.759259 45.528386, -123.304285 45.528386, -123.304285 44.398137))",
"POLYGON ((-123.971797 45.538903, -107.414190 45.538903, -107.414190 46.207289, -123.971797 46.207289, -123.971797 45.538903))",
"POLYGON ((-124.002171 46.207291, -100.843682 46.207291, -100.843682 46.870277, -124.002171 46.870277, -124.002171 46.207291))",
"POLYGON ((-121.644045 46.870322, -114.475950 46.870322, -114.475950 47.047755, -121.644045 47.047755, -121.644045 46.870322))",
"POLYGON ((-122.483548 47.048004, -120.645414 47.048004, -120.645414 47.133210, -122.483548 47.133210, -122.483548 47.048004))",
"POLYGON ((-122.663256 47.133232, -117.461141 47.133232, -117.461141 47.245581, -122.663256 47.245581, -122.663256 47.133232))",
"POLYGON ((-122.533044 47.245751, -101.380736 47.245751, -101.380736 47.386599, -122.533044 47.386599, -122.533044 47.245751))",
"POLYGON ((-122.296377 47.386632, -111.308308 47.386632, -111.308308 47.518632, -122.296377 47.518632, -122.296377 47.386632))",
"POLYGON ((-122.526029 47.518633, -115.807768 47.518633, -115.807768 47.578465, -122.526029 47.578465, -122.526029 47.518633))",
"POLYGON ((-122.526251 47.578467, -110.265483 47.578467, -110.265483 47.671638, -122.526251 47.671638, -122.526251 47.578467))",
"POLYGON ((-122.728475 47.672426, -117.332969 47.672426, -117.332969 48.403589, -122.728475 48.403589, -122.728475 47.672426))",
"POLYGON ((-123.534548 48.405180, -115.053590 48.405180, -115.053590 49.051168, -123.534548 49.051168, -123.534548 48.405180))",
"POLYGON ((-124.909002 49.051243, -100.844486 49.051243, -100.844486 49.707404, -124.909002 49.707404, -124.909002 49.051243))",
"POLYGON ((-124.908949 49.707404, -120.626996 49.707404, -120.626996 50.502172, -124.908949 50.502172, -124.908949 49.707404))",
"POLYGON ((-131.334422 50.502619, -101.342847 50.502619, -101.342847 53.905287, -131.334422 53.905287, -131.334422 50.502619))",
"POLYGON ((-179.125980 54.142881, -108.033334 54.142881, -108.033334 66.838860, -179.125980 66.838860, -179.125980 54.142881))",
"POLYGON ((-90.291826 -5.295051, -80.662433 -5.295051, -80.662433 13.802492, -90.291826 13.802492, -90.291826 -5.295051))",
"POLYGON ((-92.180864 13.802584, -87.683304 13.802584, -87.683304 15.333261, -92.180864 15.333261, -92.180864 13.802584))",
"POLYGON ((-93.749279 15.333271, -85.952198 15.333271, -85.952198 16.703640, -93.749279 16.703640, -93.749279 15.333271))",
"POLYGON ((-96.362561 16.703645, -89.114892 16.703645, -89.114892 16.928520, -96.362561 16.928520, -96.362561 16.703645))",
"POLYGON ((-96.769744 16.928528, -89.891455 16.928528, -89.891455 17.044851, -96.769744 17.044851, -96.769744 16.928528))",
"POLYGON ((-96.815628 17.044854, -88.850215 17.044854, -88.850215 17.219905, -96.815628 17.219905, -96.815628 17.044854))",
"POLYGON ((-99.498722 17.219911, -88.346608 17.219911, -88.346608 18.108566, -99.498722 18.108566, -99.498722 17.219911))",
"POLYGON ((-99.210164 18.108566, -88.350058 18.108566, -88.350058 18.518588, -99.210164 18.518588, -99.210164 18.108566))",
"POLYGON ((-99.326702 18.518591, -88.234799 18.518591, -88.234799 18.930601, -99.326702 18.930601, -99.326702 18.518591))",
"POLYGON ((-99.651482 18.930619, -88.110050 18.930619, -88.110050 19.301089, -99.651482 19.301089, -99.651482 18.930619))",
"POLYGON ((-99.455755 19.301113, -89.768952 19.301113, -89.768952 19.485169, -99.455755 19.485169, -99.455755 19.301113))",
"POLYGON ((-99.526707 19.485492, -89.592656 19.485492, -89.592656 19.687992, -99.526707 19.687992, -99.526707 19.485492))",
"POLYGON ((-99.142618 19.688000, -89.181859 19.688000, -89.181859 19.840432, -99.142618 19.840432, -99.142618 19.688000))",
"POLYGON ((-99.342694 19.840510, -87.427329 19.840510, -87.427329 20.248658, -99.342694 20.248658, -99.342694 19.840510))",
"POLYGON ((-97.379703 20.248661, -86.979095 20.248661, -86.979095 20.450049, -97.379703 20.450049, -97.379703 20.248661))",
"POLYGON ((-97.378395 20.450049, -86.842932 20.450049, -86.842932 20.684085, -97.378395 20.684085, -97.378395 20.450049))",
"POLYGON ((-99.564473 20.684086, -86.781504 20.684086, -86.781504 21.060080, -99.564473 21.060080, -99.564473 20.684086))",
"POLYGON ((-99.397248 21.060084, -86.777620 21.060084, -86.777620 21.163892, -99.397248 21.163892, -99.397248 21.060084))",
"POLYGON ((-99.636845 21.163924, -81.502835 21.163924, -81.502835 23.145921, -99.636845 23.145921, -99.636845 21.163924))",
"POLYGON ((-97.661336 23.145923, -80.671961 23.145923, -80.671961 28.375991, -97.661336 28.375991, -97.661336 23.145923))",
"POLYGON ((-98.450961 28.375997, -80.682856 28.375997, -80.682856 29.317411, -98.450961 29.317411, -98.450961 28.375997))",
"POLYGON ((-98.633759 29.317411, -94.778373 29.317411, -94.778373 29.428205, -98.633759 29.428205, -98.633759 29.317411))",
"POLYGON ((-98.638956 29.428434, -81.238909 29.428434, -81.238909 30.212049, -98.638956 30.212049, -98.638956 29.428434))",
"POLYGON ((-98.230498 30.212050, -82.668549 30.212050, -82.668549 30.321309, -98.230498 30.321309, -98.230498 30.212050))",
"POLYGON ((-91.191569 30.321343, -81.655475 30.321343, -81.655475 30.467058, -91.191569 30.467058, -91.191569 30.321343))",
"POLYGON ((-98.979551 30.467066, -81.470020 30.467066, -81.470020 31.439472, -98.979551 31.439472, -98.979551 30.467066))",
"POLYGON ((-93.094048 31.441498, -80.677500 31.441498, -80.677500 32.387947, -93.094048 32.387947, -93.094048 31.441498))",
"POLYGON ((-93.683030 32.388451, -80.679055 32.388451, -80.679055 32.514149, -93.683030 32.514149, -93.683030 32.388451))",
"POLYGON ((-93.685529 32.514150, -81.106822 32.514150, -81.106822 33.208854, -93.685529 33.208854, -93.685529 32.514150))",
"POLYGON ((-97.133228 33.208854, -81.028112 33.208854, -81.028112 33.988390, -97.133228 33.988390, -97.133228 33.208854))",
"POLYGON ((-89.534145 33.988421, -80.777648 33.988421, -80.777648 34.389477, -89.534145 34.389477, -89.534145 33.988421))",
"POLYGON ((-94.649229 34.389488, -81.178082 34.389488, -81.178082 34.851156, -94.649229 34.851156, -94.649229 34.389488))",
"POLYGON ((-97.243254 34.851200, -81.537415 34.851200, -81.537415 35.029910, -97.243254 35.029910, -97.243254 34.851200))",
"POLYGON ((-97.538167 35.030448, -80.579600 35.030448, -80.579600 35.462351, -97.538167 35.462351, -97.538167 35.030448))",
"POLYGON ((-97.538440 35.462352, -83.303460 35.462352, -83.303460 35.512520, -97.538440 35.512520, -97.538440 35.462352))",
"POLYGON ((-87.038892 35.512520, -82.179762 35.512520, -82.179762 35.649836, -87.038892 35.649836, -87.038892 35.512520))",
"POLYGON ((-83.589345 35.649852, -83.101950 35.649852, -83.101950 35.663331, -83.589345 35.663331, -83.589345 35.649852))",
"POLYGON ((-97.066042 35.663349, -80.895547 35.663349, -80.895547 37.339948, -97.066042 37.339948, -97.066042 35.663349))",
"POLYGON ((-97.272860 37.339948, -83.852764 37.339948, -83.852764 38.597516, -97.272860 38.597516, -97.272860 37.339948))",
"POLYGON ((-90.214327 38.597535, -84.266145 38.597535, -84.266145 38.654040, -90.214327 38.654040, -90.214327 38.597535))",
"POLYGON ((-97.608925 38.654040, -84.549783 38.654040, -84.549783 39.062800, -97.608925 39.062800, -97.608925 38.654040))",
"POLYGON ((-96.289413 39.062806, -84.498591 39.062806, -84.498591 39.101761, -96.289413 39.101761, -96.289413 39.062806))",
"POLYGON ((-96.580160 39.101765, -82.872548 39.101765, -82.872548 39.258582, -96.580160 39.258582, -96.580160 39.101765))",
"POLYGON ((-95.127253 39.258582, -81.541889 39.258582, -81.541889 39.591272, -95.127253 39.591272, -95.127253 39.258582))",
"POLYGON ((-93.078457 39.592639, -81.685553 39.592639, -81.685553 39.784966, -93.078457 39.784966, -93.078457 39.592639))",
"POLYGON ((-95.651080 39.784968, -81.456543 39.784968, -81.456543 40.634399, -95.651080 40.634399, -95.651080 39.784968))",
"POLYGON ((-96.644013 40.634897, -81.342912 40.634897, -81.342912 41.150170, -96.644013 41.150170, -96.644013 40.634897))",
"POLYGON ((-95.901779 41.150170, -81.342441 41.150170, -81.342441 41.510223, -95.901779 41.510223, -95.901779 41.150170))",
"POLYGON ((-93.737128 41.510322, -81.080007 41.510322, -81.080007 41.694156, -93.737128 41.694156, -93.737128 41.510322))",
"POLYGON ((-93.612011 41.694156, -87.524466 41.694156, -87.524466 41.876418, -93.612011 41.876418, -93.612011 41.694156))",
"POLYGON ((-89.803318 41.876420, -87.611317 41.876420, -87.611317 41.923847, -89.803318 41.923847, -89.803318 41.876420))",
"POLYGON ((-91.684358 41.928290, -83.045713 41.928290, -83.045713 42.329357, -91.684358 42.329357, -91.684358 41.928290))",
"POLYGON ((-94.595230 42.329481, -82.986716 42.329481, -82.986716 42.495796, -94.595230 42.495796, -94.595230 42.329481))",
"POLYGON ((-96.402882 42.495796, -81.220835 42.495796, -81.220835 43.185876, -96.402882 43.185876, -96.402882 42.495796))",
"POLYGON ((-95.655166 43.185876, -85.703773 43.185876, -85.703773 43.426820, -95.655166 43.426820, -95.655166 43.185876))",
"POLYGON ((-89.715228 43.426853, -84.253217 43.426853, -84.253217 43.756177, -89.715228 43.756177, -89.715228 43.426853))",
"POLYGON ((-97.171376 43.770749, -85.848468 43.770749, -85.848468 44.209864, -97.171376 44.209864, -97.171376 43.770749))",
"POLYGON ((-96.542651 44.209864, -80.876812 44.209864, -80.876812 44.892208, -96.542651 44.892208, -96.542651 44.209864))",
"POLYGON ((-98.486452 44.892221, -81.299620 44.892221, -81.299620 45.851913, -98.486452 45.851913, -98.486452 44.892221))",
"POLYGON ((-98.100727 45.851915, -80.760891 45.851915, -80.760891 46.656209, -98.100727 46.656209, -98.100727 45.851915))",
"POLYGON ((-80.770271 46.656209, -80.749325 46.656209, -80.749325 46.670949, -80.770271 46.670949, -80.770271 46.656209))",
"POLYGON ((-92.096801 46.670985, -80.748381 46.670985, -80.748381 46.782042, -92.096801 46.782042, -92.096801 46.670985))",
"POLYGON ((-99.468044 46.782059, -88.308149 46.782059, -88.308149 75.425018, -99.468044 75.425018, -99.468044 46.782059))",
"POLYGON ((-76.309651 -14.820008, -75.104896 -14.820008, -75.104896 -13.705391, -76.309651 -13.705391, -76.309651 -14.820008))",
"POLYGON ((-77.041192 -13.705137, -75.354214 -13.705137, -75.354214 -12.086668, -77.041192 -12.086668, -77.041192 -13.705137))",
"POLYGON ((-77.150790 -12.086666, -75.280421 -12.086666, -75.280421 -11.890364, -77.150790 -11.890364, -77.150790 -12.086666))",
"POLYGON ((-75.809464 -11.889994, -75.362544 -11.889994, -75.362544 -11.404785, -75.809464 -11.404785, -75.809464 -11.889994))",
"POLYGON ((-77.842591 -11.404476, -75.810131 -11.404476, -75.810131 -9.784918, -77.842591 -9.784918, -77.842591 -11.404476))",
"POLYGON ((-77.178415 -9.784194, -76.887253 -9.784194, -76.887253 -9.594750, -77.178415 -9.594750, -77.178415 -9.784194))",
"POLYGON ((-78.266606 -9.594675, -76.958694 -9.594675, -76.958694 -9.457752, -78.266606 -9.457752, -78.266606 -9.594675))",
"POLYGON ((-79.310427 -9.457258, -77.239103 -9.457258, -77.239103 -7.776798, -79.310427 -7.776798, -79.310427 -9.457258))",
"POLYGON ((-80.109465 -7.776760, -77.923071 -7.776760, -77.923071 -2.884095, -80.109465 -2.884095, -80.109465 -7.776760))",
"POLYGON ((-79.965366 -2.884095, -75.278993 -2.884095, -75.278993 3.467437, -79.965366 3.467437, -79.965366 -2.884095))",
"POLYGON ((-80.502621 3.467437, -75.197988 3.467437, -75.197988 10.422884, -80.502621 10.422884, -80.502621 3.467437))",
"POLYGON ((-80.517480 10.422889, -75.528959 10.422889, -75.528959 22.402752, -80.517480 22.402752, -80.517480 10.422889))",
"POLYGON ((-80.573702 22.402792, -77.306464 22.402792, -77.306464 33.965292, -80.573702 33.965292, -80.573702 22.402792))",
"POLYGON ((-78.893384 33.965873, -76.678342 33.965873, -76.678342 35.103449, -78.893384 35.103449, -78.893384 33.965873))",
"POLYGON ((-77.045798 35.103450, -77.042202 35.103450, -77.042202 35.105013, -77.045798 35.105013, -77.045798 35.103450))",
"POLYGON ((-79.932019 35.105020, -77.039682 35.105020, -77.039682 35.267270, -79.932019 35.267270, -79.932019 35.105020))",
"POLYGON ((-80.579569 35.267276, -77.619468 35.267276, -77.619468 35.468738, -80.579569 35.468738, -80.579569 35.267276))",
"POLYGON ((-80.381621 35.468935, -75.663740 35.468935, -75.663740 35.910938, -80.381621 35.910938, -80.381621 35.468935))",
"POLYGON ((-79.052188 35.910947, -75.662994 35.910947, -75.662994 35.928181, -79.052188 35.928181, -79.052188 35.910947))",
"POLYGON ((-79.523789 35.928192, -75.667704 35.928192, -75.667704 36.016776, -79.523789 36.016776, -79.523789 35.928192))",
"POLYGON ((-80.444937 36.016782, -75.668312 36.016782, -75.668312 36.330071, -80.444937 36.330071, -80.444937 36.016782))",
"POLYGON ((-78.238272 36.330120, -76.007780 36.330120, -76.007780 37.059097, -78.238272 37.059097, -78.238272 36.330120))",
"POLYGON ((-77.565082 37.059120, -76.482825 37.059120, -76.482825 37.176366, -77.565082 37.176366, -77.565082 37.059120))",
"POLYGON ((-77.470654 37.176401, -76.566528 37.176401, -76.566528 37.222322, -77.470654 37.222322, -77.470654 37.176401))",
"POLYGON ((-80.420675 37.222340, -76.690928 37.222340, -76.690928 37.270473, -80.420675 37.270473, -80.420675 37.222340))",
"POLYGON ((-76.704893 37.270474, -76.692051 37.270474, -76.692051 37.271087, -76.704893 37.271087, -76.704893 37.270474))",
"POLYGON ((-76.705072 37.271091, -76.689942 37.271091, -76.689942 37.271860, -76.705072 37.271860, -76.705072 37.271091))",
"POLYGON ((-76.703481 37.271863, -76.690670 37.271863, -76.690670 37.272698, -76.703481 37.272698, -76.703481 37.271863))",
"POLYGON ((-76.703104 37.272701, -76.691853 37.272701, -76.691853 37.274727, -76.703104 37.274727, -76.703104 37.272701))",
"POLYGON ((-79.536985 37.274728, -76.333346 37.274728, -76.333346 37.517164, -79.536985 37.517164, -79.536985 37.274728))",
"POLYGON ((-77.483714 37.517168, -77.333742 37.517168, -77.333742 37.553784, -77.483714 37.553784, -77.483714 37.517168))",
"POLYGON ((-80.309125 37.553791, -75.178423 37.553791, -75.178423 38.222550, -80.309125 38.222550, -80.309125 37.553791))",
"POLYGON ((-79.911225 38.222591, -75.140954 38.222591, -75.140954 38.880979, -79.911225 38.880979, -79.911225 38.222591))",
"POLYGON ((-77.173598 38.880982, -76.992392 38.880982, -76.992392 38.905578, -77.173598 38.905578, -77.173598 38.880982))",
"POLYGON ((-80.473272 38.905582, -76.486972 38.905582, -76.486972 39.039612, -80.473272 39.039612, -80.473272 38.905582))",
"POLYGON ((-80.471165 39.039624, -75.536510 39.039624, -75.536510 39.385865, -80.471165 39.385865, -80.471165 39.039624))",
"POLYGON ((-77.746866 39.385870, -75.547846 39.385870, -75.547846 39.457334, -77.746866 39.457334, -77.746866 39.385870))",
"POLYGON ((-77.757154 39.457348, -75.591737 39.457348, -75.591737 39.490003, -77.757154 39.490003, -77.757154 39.457348))",
"POLYGON ((-77.753163 39.490004, -75.572152 39.490004, -75.572152 39.535350, -77.753163 39.535350, -77.753163 39.490004))",
"POLYGON ((-76.644552 39.535425, -75.574298 39.535425, -75.574298 39.575203, -76.644552 39.575203, -76.644552 39.535425))",
"POLYGON ((-78.788406 39.575234, -75.564592 39.575234, -75.564592 39.640687, -78.788406 39.640687, -78.788406 39.575234))",
"POLYGON ((-75.761565 39.640709, -75.557775 39.640709, -75.557775 39.670405, -75.761565 39.670405, -75.761565 39.640709))",
"POLYGON ((-77.161702 39.670419, -75.745235 39.670419, -75.745235 39.684790, -77.161702 39.684790, -77.161702 39.670419))",
"POLYGON ((-75.786475 39.684818, -75.121078 39.684818, -75.121078 39.735063, -75.786475 39.735063, -75.786475 39.684818))",
"POLYGON ((-75.731704 39.735069, -75.537783 39.735069, -75.537783 39.744144, -75.731704 39.744144, -75.731704 39.735069))",
"POLYGON ((-75.736622 39.744144, -75.539069 39.744144, -75.539069 39.749994, -75.736622 39.749994, -75.736622 39.744144))",
"POLYGON ((-75.740607 39.750005, -75.523699 39.750005, -75.523699 39.755195, -75.740607 39.755195, -75.740607 39.750005))",
"POLYGON ((-75.749024 39.755309, -75.502991 39.755309, -75.502991 39.765476, -75.749024 39.765476, -75.749024 39.755309))",
"POLYGON ((-77.266823 39.765550, -75.490621 39.765550, -75.490621 39.778822, -77.266823 39.778822, -77.266823 39.765550))",
"POLYGON ((-77.926597 39.778836, -75.544345 39.778836, -75.544345 39.795462, -77.926597 39.795462, -77.926597 39.778836))",
"POLYGON ((-77.257928 39.795504, -75.453374 39.795504, -75.453374 39.809440, -77.257928 39.809440, -77.257928 39.795504))",
"POLYGON ((-77.260885 39.809482, -75.441719 39.809482, -75.441719 39.815489, -77.260885 39.815489, -77.260885 39.809482))",
"POLYGON ((-77.264979 39.815496, -75.444971 39.815496, -75.444971 39.854296, -77.264979 39.854296, -77.264979 39.815496))",
"POLYGON ((-79.468479 39.854741, -75.118830 39.854741, -75.118830 39.948828, -79.468479 39.948828, -79.468479 39.854741))",
"POLYGON ((-78.908069 39.948831, -75.124445 39.948831, -75.124445 40.090502, -78.908069 40.090502, -78.908069 39.948831))",
"POLYGON ((-80.021169 40.090503, -75.124931 40.090503, -75.124931 40.401775, -80.021169 40.401775, -80.021169 40.090503))",
"POLYGON ((-80.020967 40.401855, -75.144538 40.401855, -75.144538 40.735769, -80.020967 40.735769, -80.020967 40.401855))",
"POLYGON ((-80.140376 40.735775, -75.515684 40.735775, -75.515684 42.862949, -80.140376 42.862949, -80.140376 40.735775))",
"POLYGON ((-78.924904 42.862968, -75.848776 42.862968, -75.848776 42.936068, -78.924904 42.936068, -78.924904 42.862968))",
"POLYGON ((-79.999135 42.937363, -75.120725 42.937363, -75.120725 43.251295, -79.999135 43.251295, -79.999135 42.937363))",
"POLYGON ((-80.144448 43.251407, -76.516055 43.251407, -76.516055 43.650800, -80.144448 43.650800, -80.144448 43.251407))",
"POLYGON ((-80.094068 43.650800, -75.487109 43.650800, -75.487109 45.395333, -80.094068 45.395333, -80.094068 43.650800))",
"POLYGON ((-79.460537 45.395405, -75.608108 45.395405, -75.608108 46.312544, -79.460537 46.312544, -79.460537 45.395405))",
"POLYGON ((-74.428935 -55.048038, -57.810082 -55.048038, -57.810082 -38.712322, -74.428935 -38.712322, -74.428935 -55.048038))",
"POLYGON ((-73.400983 -38.711285, -57.545470 -38.711285, -57.545470 -37.131573, -73.400983 -37.131573, -73.400983 -38.711285))",
"POLYGON ((-73.160938 -37.131551, -56.675396 -37.131551, -56.675396 -35.462699, -73.160938 -35.462699, -73.160938 -37.131551))",
"POLYGON ((-72.041795 -35.462699, -53.538435 -35.462699, -53.538435 -33.689129, -72.041795 -33.689129, -72.041795 -35.462699))",
"POLYGON ((-71.633320 -33.688982, -53.538896 -33.688982, -53.538896 -33.019037, -71.633320 -33.019037, -71.633320 -33.688982))",
"POLYGON ((-71.564777 -33.019024, -52.341117 -33.019024, -52.341117 -31.655565, -71.564777 -31.655565, -71.564777 -33.019024))",
"POLYGON ((-68.704773 -31.655556, -51.189718 -31.655556, -51.189718 -30.107814, -68.704773 -30.107814, -68.704773 -31.655556))",
"POLYGON ((-71.336331 -30.107636, -50.275604 -30.107636, -50.275604 -29.167345, -71.336331 -29.167345, -71.336331 -30.107636))",
"POLYGON ((-67.780272 -29.167328, -49.064233 -29.167328, -49.064233 -26.466462, -67.780272 -26.466462, -67.780272 -29.167328))",
"POLYGON ((-67.297949 -26.466459, -48.807327 -26.466459, -48.807327 -25.447609, -67.297949 -25.447609, -67.297949 -26.466459))",
"POLYGON ((-66.496734 -25.447608, -48.971550 -25.447608, -48.971550 -24.153427, -66.496734 -24.153427, -66.496734 -25.447608))",
"POLYGON ((-70.422224 -24.153363, -48.973278 -24.153363, -48.973278 -21.329963, -70.422224 -21.329963, -70.422224 -24.153363))",
"POLYGON ((-70.333553 -21.329962, -50.319340 -21.329962, -50.319340 -18.480145, -70.333553 -18.480145, -70.333553 -21.329962))",
"POLYGON ((-70.333533 -18.480096, -60.740508 -18.480096, -60.740508 -17.701051, -70.333533 -17.701051, -70.333533 -18.480096))",
"POLYGON ((-70.978563 -17.700897, -51.802047 -17.700897, -51.802047 -16.922244, -70.978563 -16.922244, -70.978563 -17.700897))",
"POLYGON ((-74.310692 -16.922154, -49.083522 -16.922154, -49.083522 -15.639620, -74.310692 -15.639620, -74.310692 -16.922154))",
"POLYGON ((-75.104810 -15.639556, -56.076962 -15.639556, -56.076962 -13.496123, -75.104810 -13.496123, -75.104810 -15.639556))",
"POLYGON ((-74.231878 -13.496102, -71.540790 -13.496102, -71.540790 -13.178446, -74.231878 -13.178446, -74.231878 -13.496102))",
"POLYGON ((-74.231966 -13.178411, -72.542890 -13.178411, -72.542890 -13.000291, -74.231966 -13.000291, -74.231966 -13.178411))",
"POLYGON ((-74.449992 -13.000193, -73.190139 -13.000193, -73.190139 -12.874419, -74.449992 -12.874419, -74.449992 -13.000193))",
"POLYGON ((-74.872855 -12.874414, -74.447058 -12.874414, -74.447058 -12.556849, -74.872855 -12.556849, -74.872855 -12.874414))",
"POLYGON ((-75.086968 -12.556800, -64.421885 -12.556800, -64.421885 -10.191639, -75.086968 -10.191639, -75.086968 -12.556800))",
"POLYGON ((-67.902884 -10.191568, -65.730000 -10.191568, -65.730000 -9.321694, -67.902884 -9.321694, -67.902884 -10.191568))",
"POLYGON ((-74.556180 -9.321102, -49.901969 -9.321102, -49.901969 1.082970, -74.556180 1.082970, -74.556180 -9.321102))",
"POLYGON ((-74.895619 1.082998, -52.247329 1.082998, -52.247329 4.941096, -74.895619 4.941096, -74.895619 1.082998))",
"POLYGON ((-74.911479 4.941151, -52.332611 4.941151, -52.332611 5.040223, -74.911479 5.040223, -74.911479 4.941151))",
"POLYGON ((-74.629722 5.040337, -74.545979 5.040337, -74.545979 5.095527, -74.629722 5.095527, -74.629722 5.040337))",
"POLYGON ((-74.945786 5.095659, -52.582429 5.095659, -52.582429 5.281545, -74.945786 5.281545, -74.945786 5.095659))",
"POLYGON ((-73.820503 5.281599, -52.582633 5.281599, -52.582633 7.891208, -73.820503 7.891208, -73.820503 5.281599))",
"POLYGON ((-74.423467 7.891212, -61.623903 7.891212, -61.623903 10.591881, -74.423467 10.591881, -74.423467 7.891212))",
"POLYGON ((-74.970291 10.591902, -60.726510 10.591902, -60.726510 12.109707, -74.970291 12.109707, -74.970291 10.591902))",
"POLYGON ((-70.058421 12.109753, -59.594846 12.109753, -59.594846 16.020082, -70.058421 16.020082, -70.058421 12.109753))",
"POLYGON ((-73.754917 16.020086, -61.075106 16.020086, -61.075106 18.193297, -73.754917 18.193297, -73.754917 16.020086))",
"POLYGON ((-73.771882 18.193306, -73.736669 18.193306, -73.736669 18.198360, -73.771882 18.198360, -73.771882 18.193306))",
"POLYGON ((-73.759206 18.198365, -63.053549 18.198365, -63.053549 18.277036, -73.759206 18.277036, -73.759206 18.198365))",
"POLYGON ((-73.671186 18.277041, -73.391812 18.277041, -73.391812 18.300971, -73.671186 18.300971, -73.671186 18.277041))",
"POLYGON ((-73.660503 18.300971, -64.929470 18.300971, -64.929470 18.469521, -73.660503 18.469521, -73.660503 18.300971))",
"POLYGON ((-72.544162 18.469611, -66.120220 18.469611, -66.120220 18.927825, -72.544162 18.927825, -72.544162 18.469611))",
"POLYGON ((-72.716575 18.927975, -72.542265 18.927975, -72.542265 19.107743, -72.716575 19.107743, -72.716575 18.927975))",
"POLYGON ((-72.702497 19.107747, -72.691038 19.107747, -72.691038 19.111495, -72.702497 19.111495, -72.702497 19.107747))",
"POLYGON ((-72.701426 19.111503, -69.325809 19.111503, -69.325809 19.666530, -72.701426 19.666530, -72.701426 19.111503))",
"POLYGON ((-74.502114 19.666530, -64.651628 19.666530, -64.651628 32.364048, -74.502114 32.364048, -74.502114 19.666530))",
"POLYGON ((-75.073565 32.364048, -64.648213 32.364048, -64.648213 40.536064, -75.073565 40.536064, -75.073565 32.364048))",
"POLYGON ((-74.341275 40.536108, -73.508022 40.536108, -73.508022 40.604322, -74.341275 40.604322, -74.341275 40.536108))",
"POLYGON ((-74.390915 40.604344, -73.601327 40.604344, -73.601327 40.704620, -74.390915 40.704620, -74.390915 40.604344))",
"POLYGON ((-74.026621 40.704668, -73.576151 40.704668, -73.576151 40.743373, -74.026621 40.743373, -74.026621 40.704668))",
"POLYGON ((-74.010016 40.743380, -73.843866 40.743380, -73.843866 40.764253, -74.010016 40.764253, -74.010016 40.743380))",
"POLYGON ((-74.001969 40.764253, -73.965899 40.764253, -73.965899 40.765049, -74.001969 40.765049, -74.001969 40.764253))",
"POLYGON ((-74.480191 40.765049, -73.779320 40.765049, -73.779320 40.796402, -74.480191 40.796402, -74.480191 40.765049))",
"POLYGON ((-74.674638 40.796455, -72.896956 40.796455, -72.896956 41.003156, -74.674638 41.003156, -74.674638 40.796455))",
"POLYGON ((-74.759384 41.003162, -72.354770 41.003162, -72.354770 41.298265, -74.759384 41.298265, -74.759384 41.003162))",
"POLYGON ((-74.695373 41.298297, -71.336227 41.298297, -71.336227 41.478470, -74.695373 41.478470, -74.695373 41.298297))",
"POLYGON ((-74.880648 41.478470, -70.188633 41.478470, -70.188633 42.078058, -74.880648 42.078058, -74.880648 41.478470))",
"POLYGON ((-74.308446 42.078061, -70.716241 42.078061, -70.716241 42.363756, -74.308446 42.363756, -74.308446 42.078061))",
"POLYGON ((-73.820123 42.363756, -71.009972 42.363756, -71.009972 42.541788, -73.820123 42.541788, -73.820123 42.363756))",
"POLYGON ((-73.947860 42.541810, -70.638716 42.541810, -70.638716 42.984780, -73.947860 42.984780, -73.947860 42.541810))",
"POLYGON ((-73.784413 42.985601, -70.708735 42.985601, -70.708735 43.420565, -73.784413 43.420565, -73.784413 42.985601))",
"POLYGON ((-74.057242 43.420589, -64.068245 43.420589, -64.068245 44.499859, -74.057242 44.499859, -74.057242 43.420589))",
"POLYGON ((-73.349755 44.499859, -63.468585 44.499859, -63.468585 45.278900, -73.349755 45.278900, -73.349755 44.499859))",
"POLYGON ((-74.176773 45.278927, -66.069643 45.278927, -66.069643 45.504119, -74.176773 45.504119, -74.176773 45.278927))",
"POLYGON ((-73.604161 45.504189, -59.978641 45.504189, -59.978641 46.423289, -73.604161 46.423289, -73.604161 45.504189))",
"POLYGON ((-73.881645 46.423312, -52.681450 46.423312, -52.681450 50.676591, -73.881645 50.676591, -73.881645 46.423312))",
"POLYGON ((-73.887508 50.676591, -51.663359 50.676591, -51.663359 74.578141, -73.887508 74.578141, -73.887508 50.676591))",
"POLYGON ((-48.807310 -27.842019, -46.299535 -27.842019, -46.299535 -23.602598, -48.807310 -23.602598, -48.807310 -27.842019))",
"POLYGON ((-46.842043 -23.596717, -41.985115 -23.596717, -41.985115 -22.936541, -46.842043 -22.936541, -46.842043 -23.596717))",
"POLYGON ((-48.547962 -22.936541, -42.007957 -22.936541, -42.007957 -22.360079, -48.547962 -22.360079, -48.547962 -22.936541))",
"POLYGON ((-47.811772 -22.359722, -40.293281 -22.359722, -40.293281 -20.315225, -47.811772 -20.315225, -47.811772 -22.359722))",
"POLYGON ((-40.361264 -20.315220, -40.278396 -20.315220, -40.278396 -20.292319, -40.361264 -20.292319, -40.361264 -20.315220))",
"POLYGON ((-46.767474 -20.292319, -40.068071 -20.292319, -40.068071 -18.899624, -46.767474 -18.899624, -46.767474 -20.292319))",
"POLYGON ((-48.335350 -18.899567, -34.822667 -18.899567, -34.822667 -7.179733, -48.335350 -7.179733, -48.335350 -18.899567))",
"POLYGON ((-48.505610 -7.179688, -15.745799 -7.179688, -15.745799 14.662724, -48.505610 14.662724, -48.505610 -7.179688))",
"POLYGON ((-24.998292 14.662724, -15.689014 14.662724, -15.689014 20.830836, -24.998292 20.830836, -24.998292 14.662724))",
"POLYGON ((-17.104821 20.830845, -15.598812 20.830845, -15.598812 27.757163, -17.104821 27.757163, -17.104821 20.830845))",
"POLYGON ((-17.999148 27.757167, -15.586977 27.757167, -15.586977 27.818541, -17.999148 27.818541, -17.999148 27.757167))",
"POLYGON ((-17.931632 27.818541, -15.571937 27.818541, -15.571937 28.002588, -17.931632 28.002588, -17.931632 27.818541))",
"POLYGON ((-17.234956 28.002597, -15.750639 28.002597, -15.750639 28.036128, -17.234956 28.036128, -17.234956 28.002597))",
"POLYGON ((-17.256793 28.036135, -16.603792 28.036135, -16.603792 28.059847, -17.256793 28.059847, -17.256793 28.036135))",
"POLYGON ((-17.274599 28.059857, -15.647444 28.059857, -15.647444 28.078144, -17.274599 28.078144, -17.274599 28.059857))",
"POLYGON ((-17.312593 28.078147, -16.518627 28.078147, -16.518627 28.087013, -17.312593 28.087013, -17.312593 28.078147))",
"POLYGON ((-17.112381 28.087019, -16.578269 28.087019, -16.578269 28.093323, -17.112381 28.093323, -17.112381 28.087019))",
"POLYGON ((-17.335458 28.093323, -16.526675 28.093323, -16.526675 28.101248, -17.335458 28.101248, -17.335458 28.093323))",
"POLYGON ((-17.336378 28.101254, -15.710513 28.101254, -15.710513 28.110492, -17.336378 28.110492, -17.336378 28.101254))",
"POLYGON ((-17.282135 28.110519, -15.635196 28.110519, -15.635196 28.114454, -17.282135 28.114454, -17.282135 28.110519))",
"POLYGON ((-16.714863 28.114466, -16.470658 28.114466, -16.470658 28.120172, -16.714863 28.120172, -16.714863 28.114466))",
"POLYGON ((-17.292188 28.120197, -15.571807 28.120197, -15.571807 28.125470, -17.292188 28.125470, -17.292188 28.120197))",
"POLYGON ((-16.692559 28.125470, -15.701403 28.125470, -15.701403 28.133449, -16.692559 28.133449, -16.692559 28.125470))",
"POLYGON ((-16.745838 28.133554, -15.604328 28.133554, -15.604328 28.141242, -16.745838 28.141242, -16.745838 28.133554))",
"POLYGON ((-16.772096 28.141298, -15.649564 28.141298, -15.649564 28.145336, -16.772096 28.145336, -16.772096 28.141298))",
"POLYGON ((-16.762833 28.145338, -15.655020 28.145338, -15.655020 28.148993, -16.762833 28.148993, -16.762833 28.145338))",
"POLYGON ((-16.762490 28.148997, -16.441755 28.148997, -16.441755 28.153153, -16.762490 28.153153, -16.762490 28.148997))",
"POLYGON ((-16.743919 28.153153, -16.499378 28.153153, -16.499378 28.158561, -16.743919 28.158561, -16.743919 28.153153))",
"POLYGON ((-16.746349 28.158564, -15.662347 28.158564, -15.662347 28.159816, -16.746349 28.159816, -16.746349 28.158564))",
"POLYGON ((-16.755098 28.159816, -15.658573 28.159816, -15.658573 28.162360, -16.755098 28.162360, -16.755098 28.159816))",
"POLYGON ((-16.763110 28.162365, -15.658613 28.162365, -15.658613 28.166248, -16.763110 28.166248, -16.763110 28.162365))",
"POLYGON ((-16.771077 28.166250, -16.488522 28.166250, -16.488522 28.170132, -16.771077 28.170132, -16.771077 28.166250))",
"POLYGON ((-17.156263 28.170138, -16.499188 28.170138, -16.499188 28.175043, -17.156263 28.175043, -17.156263 28.170138))",
"POLYGON ((-16.760563 28.175057, -16.462761 28.175057, -16.462761 28.180823, -16.760563 28.180823, -16.760563 28.175057))",
"POLYGON ((-16.787937 28.180824, -16.430004 28.180824, -16.430004 28.186974, -16.787937 28.186974, -16.787937 28.180824))",
"POLYGON ((-17.230112 28.186976, -16.463814 28.186976, -16.463814 28.193124, -17.230112 28.193124, -17.230112 28.186976))",
"POLYGON ((-16.770534 28.193134, -16.466334 28.193134, -16.466334 28.200059, -16.770534 28.200059, -16.770534 28.193134))",
"POLYGON ((-16.771584 28.200063, -16.453908 28.200063, -16.453908 28.209134, -16.771584 28.209134, -16.771584 28.200063))",
"POLYGON ((-16.770566 28.209157, -16.458505 28.209157, -16.458505 28.216850, -16.770566 28.216850, -16.770566 28.209157))",
"POLYGON ((-16.769072 28.216857, -16.435575 28.216857, -16.435575 28.220332, -16.769072 28.220332, -16.769072 28.216857))",
"POLYGON ((-16.805986 28.220346, -16.443740 28.220346, -16.443740 28.236795, -16.805986 28.236795, -16.805986 28.220346))",
"POLYGON ((-16.800913 28.236801, -16.439312 28.236801, -16.439312 28.252092, -16.800913 28.252092, -16.800913 28.236801))",
"POLYGON ((-16.808577 28.252094, -16.429457 28.252094, -16.429457 28.269767, -16.808577 28.269767, -16.808577 28.252094))",
"POLYGON ((-16.849764 28.269773, -16.413639 28.269773, -16.413639 28.294250, -16.849764 28.294250, -16.849764 28.269773))",
"POLYGON ((-16.874411 28.294250, -16.363838 28.294250, -16.363838 28.318514, -16.874411 28.318514, -16.874411 28.294250))",
"POLYGON ((-16.844360 28.318516, -16.411143 28.318516, -16.411143 28.327570, -16.844360 28.327570, -16.844360 28.318516))",
"POLYGON ((-16.890753 28.327571, -16.363943 28.327571, -16.363943 28.338846, -16.890753 28.338846, -16.890753 28.327571))",
"POLYGON ((-16.892847 28.338847, -16.421972 28.338847, -16.421972 28.343236, -16.892847 28.343236, -16.892847 28.338847))",
"POLYGON ((-16.901875 28.343241, -16.414415 28.343241, -16.414415 28.348827, -16.901875 28.348827, -16.901875 28.343241))",
"POLYGON ((-16.900546 28.348827, -16.420037 28.348827, -16.420037 28.353101, -16.900546 28.353101, -16.900546 28.348827))",
"POLYGON ((-16.888655 28.353101, -16.552203 28.353101, -16.552203 28.355934, -16.888655 28.355934, -16.888655 28.353101))",
"POLYGON ((-16.891741 28.355939, -16.424537 28.355939, -16.424537 28.361032, -16.891741 28.361032, -16.891741 28.355939))",
"POLYGON ((-16.841626 28.361038, -16.420912 28.361038, -16.420912 28.367527, -16.841626 28.367527, -16.841626 28.361038))",
"POLYGON ((-16.849842 28.367529, -16.382288 28.367529, -16.382288 28.388403, -16.849842 28.388403, -16.849842 28.367529))",
"POLYGON ((-16.627185 28.388426, -16.321717 28.388426, -16.321717 28.416694, -16.627185 28.416694, -16.627185 28.388426))",
"POLYGON ((-16.548603 28.416694, -16.311396 28.416694, -16.311396 28.437053, -16.548603 28.437053, -16.548603 28.416694))",
"POLYGON ((-17.842683 28.437053, -16.247069 28.437053, -16.247069 28.467041, -17.842683 28.467041, -17.842683 28.437053))",
"POLYGON ((-16.418179 28.467055, -16.245791 28.467055, -16.245791 28.492994, -16.418179 28.492994, -16.418179 28.467055))",
"POLYGON ((-16.421735 28.492995, -16.145307 28.492995, -16.145307 28.526198, -16.421735 28.526198, -16.421735 28.492995))",
"POLYGON ((-17.817395 28.526212, -16.148046 28.526212, -16.148046 28.560477, -17.817395 28.560477, -17.817395 28.526212))",
"POLYGON ((-17.978718 28.560482, -16.151186 28.560482, -16.151186 28.785932, -17.978718 28.785932, -17.978718 28.560482))",
"POLYGON ((-45.385303 28.785986, -16.351488 28.785986, -16.351488 60.431153, -45.385303 60.431153, -45.385303 28.785986))",
"POLYGON ((-46.117411 60.431173, -16.980138 60.431173, -16.980138 66.344742, -46.117411 66.344742, -46.117411 60.431173))",
"POLYGON ((-10.766928 4.994306, -8.866471 4.994306, -8.866471 7.229497, -10.766928 7.229497, -10.766928 4.994306))",
"POLYGON ((-9.348675 7.229497, -8.969347 7.229497, -8.969347 7.257954, -9.348675 7.257954, -9.348675 7.229497))",
"POLYGON ((-15.536418 7.257965, -8.820144 7.257965, -8.820144 16.656804, -15.536418 16.656804, -15.536418 7.257965))",
"POLYGON ((-15.571783 16.656804, -10.398029 16.656804, -10.398029 27.947290, -15.571783 27.947290, -15.571783 16.656804))",
"POLYGON ((-15.485725 27.947290, -15.378319 27.947290, -15.378319 28.004294, -15.485725 28.004294, -15.485725 27.947290))",
"POLYGON ((-15.522691 28.004471, -15.388373 28.004471, -15.388373 28.058694, -15.522691 28.058694, -15.522691 28.004471))",
"POLYGON ((-15.571397 28.058694, -14.249193 28.058694, -14.249193 28.163336, -15.571397 28.163336, -15.571397 28.058694))",
"POLYGON ((-15.435786 28.163373, -13.863689 28.163373, -13.863689 28.384992, -15.435786 28.384992, -15.435786 28.163373))",
"POLYGON ((-14.057914 28.384992, -13.838738 28.384992, -13.838738 28.604976, -14.057914 28.604976, -14.057914 28.384992))",
"POLYGON ((-14.010965 28.605070, -13.513772 28.605070, -13.513772 28.997934, -14.010965 28.997934, -14.010965 28.605070))",
"POLYGON ((-13.681922 28.997936, -9.108235 28.997936, -9.108235 29.734859, -13.681922 29.734859, -13.681922 28.997936))",
"POLYGON ((-9.789316 29.734892, -8.693981 29.734892, -8.693981 32.414919, -9.789316 32.414919, -9.789316 29.734892))",
"POLYGON ((-9.019934 32.414939, -8.647115 32.414939, -8.647115 37.215880, -9.019934 37.215880, -9.019934 32.414939))",
"POLYGON ((-9.220339 37.215922, -8.782674 37.215922, -8.782674 38.566466, -9.220339 38.566466, -9.220339 37.215922))",
"POLYGON ((-9.420767 38.566481, -8.670229 38.566481, -8.670229 38.697261, -9.420767 38.697261, -9.420767 38.566481))",
"POLYGON ((-9.468386 38.697271, -8.957311 38.697271, -8.957311 38.720354, -9.468386 38.720354, -9.468386 38.697271))",
"POLYGON ((-9.478680 38.720361, -8.951884 38.720361, -8.951884 38.793047, -9.478680 38.793047, -9.478680 38.720361))",
"POLYGON ((-9.468380 38.793050, -9.022759 38.793050, -9.022759 38.935578, -9.468380 38.935578, -9.468380 38.793050))",
"POLYGON ((-9.413731 38.935595, -8.813247 38.935595, -8.813247 39.020152, -9.413731 39.020152, -9.413731 38.935595))",
"POLYGON ((-9.394099 39.020156, -9.058886 39.020156, -9.058886 39.174748, -9.394099 39.174748, -9.394099 39.020156))",
"POLYGON ((-9.510385 39.174854, -8.818493 39.174854, -8.818493 39.658880, -9.510385 39.658880, -9.510385 39.174854))",
"POLYGON ((-8.930464 39.658881, -8.652990 39.658881, -8.652990 41.558336, -8.930464 41.558336, -8.930464 39.658881))",
"POLYGON ((-8.884465 41.558400, -8.649009 41.558400, -8.649009 41.939963, -8.884465 41.939963, -8.884465 41.558400))",
"POLYGON ((-9.813033 41.940112, -8.645353 41.940112, -8.645353 51.473667, -9.813033 51.473667, -9.813033 41.940112))",
"POLYGON ((-10.234394 51.473667, -8.713685 51.473667, -8.713685 51.647720, -10.234394 51.647720, -10.234394 51.473667))",
"POLYGON ((-10.539088 51.647727, -8.704608 51.647727, -8.704608 52.078030, -10.539088 52.078030, -10.539088 51.647727))",
"POLYGON ((-10.513569 52.100574, -8.871553 52.100574, -8.871553 52.549660, -10.513569 52.549660, -10.513569 52.100574))",
"POLYGON ((-9.664156 52.549660, -9.469743 52.549660, -9.469743 52.570039, -9.664156 52.570039, -9.664156 52.549660))",
"POLYGON ((-9.904867 52.570041, -9.608636 52.570041, -9.608636 52.587567, -9.904867 52.587567, -9.904867 52.570041))",
"POLYGON ((-9.840780 52.587584, -8.870783 52.587584, -8.870783 52.598689, -9.840780 52.598689, -9.840780 52.587584))",
"POLYGON ((-9.769683 52.598722, -9.109423 52.598722, -9.109423 52.614167, -9.769683 52.614167, -9.769683 52.598722))",
"POLYGON ((-9.787715 52.614167, -9.368395 52.614167, -9.368395 52.619133, -9.787715 52.619133, -9.787715 52.614167))",
"POLYGON ((-9.759517 52.619136, -9.155101 52.619136, -9.155101 52.625892, -9.759517 52.625892, -9.759517 52.619136))",
"POLYGON ((-9.759085 52.625914, -9.186116 52.625914, -9.186116 52.633976, -9.759085 52.633976, -9.759085 52.625914))",
"POLYGON ((-9.740842 52.634000, -9.171572 52.634000, -9.171572 52.645123, -9.740842 52.645123, -9.740842 52.634000))",
"POLYGON ((-9.721767 52.645131, -9.116260 52.645131, -9.116260 52.653189, -9.721767 52.653189, -9.721767 52.645131))",
"POLYGON ((-9.675236 52.653197, -9.107683 52.653197, -9.107683 52.666014, -9.675236 52.666014, -9.675236 52.653197))",
"POLYGON ((-9.672329 52.666049, -9.092079 52.666049, -9.092079 52.677652, -9.672329 52.677652, -9.672329 52.666049))",
"POLYGON ((-9.638621 52.677664, -8.721177 52.677664, -8.721177 52.686304, -9.638621 52.686304, -9.638621 52.677664))",
"POLYGON ((-9.644253 52.686373, -8.732076 52.686373, -8.732076 52.705063, -9.644253 52.705063, -9.644253 52.686373))",
"POLYGON ((-9.623071 52.705064, -8.944693 52.705064, -8.944693 52.719282, -9.623071 52.719282, -9.623071 52.705064))",
"POLYGON ((-9.601507 52.719290, -8.933629 52.719290, -8.933629 52.729702, -9.601507 52.729702, -9.601507 52.719290))",
"POLYGON ((-9.559423 52.729766, -8.939619 52.729766, -8.939619 52.745353, -9.559423 52.745353, -9.559423 52.729766))",
"POLYGON ((-9.488627 52.745358, -9.005442 52.745358, -9.005442 52.773293, -9.488627 52.773293, -9.488627 52.745358))",
"POLYGON ((-9.473013 52.773297, -9.016529 52.773297, -9.016529 52.794076, -9.473013 52.794076, -9.473013 52.773297))",
"POLYGON ((-9.479558 52.794079, -8.903305 52.794079, -8.903305 52.809159, -9.479558 52.809159, -9.479558 52.794079))",
"POLYGON ((-9.532449 52.809192, -8.950050 52.809192, -8.950050 52.843868, -9.532449 52.843868, -9.532449 52.809192))",
"POLYGON ((-9.424743 52.843874, -8.981052 52.843874, -8.981052 52.871455, -9.424743 52.871455, -9.424743 52.843874))",
"POLYGON ((-9.435997 52.871482, -8.994084 52.871482, -8.994084 52.895791, -9.435997 52.895791, -9.435997 52.871482))",
"POLYGON ((-9.378276 52.895795, -8.980974 52.895795, -8.980974 52.929499, -9.378276 52.929499, -9.378276 52.895795))",
"POLYGON ((-9.470383 52.929548, -8.985912 52.929548, -8.985912 52.945315, -9.470383 52.945315, -9.470383 52.929548))",
"POLYGON ((-9.431172 52.945315, -8.967278 52.945315, -8.967278 52.954075, -9.431172 52.954075, -9.431172 52.945315))",
"POLYGON ((-9.414575 52.954084, -8.965913 52.954084, -8.965913 52.961520, -9.414575 52.961520, -9.414575 52.954084))",
"POLYGON ((-9.414494 52.961520, -8.915633 52.961520, -8.915633 52.976011, -9.414494 52.976011, -9.414494 52.961520))",
"POLYGON ((-9.351484 52.976018, -8.901613 52.976018, -8.901613 53.000392, -9.351484 53.000392, -9.351484 52.976018))",
"POLYGON ((-9.401069 53.000392, -8.879349 53.000392, -8.879349 53.023006, -9.401069 53.023006, -9.401069 53.000392))",
"POLYGON ((-9.392362 53.023023, -8.887163 53.023023, -8.887163 53.047773, -9.392362 53.047773, -9.392362 53.023023))",
"POLYGON ((-9.595114 53.047781, -8.838556 53.047781, -8.838556 53.095231, -9.595114 53.095231, -9.595114 53.047781))",
"POLYGON ((-9.767707 53.095233, -8.774832 53.095233, -8.774832 53.125448, -9.767707 53.125448, -9.767707 53.095233))",
"POLYGON ((-9.778171 53.125457, -8.786783 53.125457, -8.786783 53.170902, -9.778171 53.170902, -9.778171 53.125457))",
"POLYGON ((-10.167978 53.170916, -8.645311 53.170916, -8.645311 53.763567, -10.167978 53.763567, -10.167978 53.170916))",
"POLYGON ((-10.200657 53.763575, -8.746768 53.763575, -8.746768 54.172029, -10.200657 54.172029, -10.200657 53.763575))",
"POLYGON ((-14.803181 54.172033, -8.655496 54.172033, -8.655496 66.330431, -14.803181 66.330431, -14.803181 54.172033))",
"POLYGON ((-8.561638 7.572885, -6.396340 7.572885, -6.396340 33.256900, -8.561638 33.256900, -8.561638 7.572885))",
"POLYGON ((-8.530071 33.256901, -6.391139 33.256901, -6.391139 37.119358, -8.530071 37.119358, -8.530071 33.256901))",
"POLYGON ((-8.617230 37.119455, -6.892216 37.119455, -6.892216 37.254095, -8.617230 37.254095, -8.617230 37.119455))",
"POLYGON ((-8.563853 37.254101, -6.677900 37.254101, -6.677900 37.647642, -8.563853 37.647642, -8.563853 37.254101))",
"POLYGON ((-8.224848 37.648273, -7.495684 37.648273, -7.495684 37.708915, -8.224848 37.708915, -8.224848 37.648273))",
"POLYGON ((-8.513270 37.708916, -6.389840 37.708916, -6.389840 38.516181, -8.513270 38.516181, -8.513270 37.708916))",
"POLYGON ((-8.249298 38.516253, -7.067718 38.516253, -7.067718 38.557405, -8.249298 38.557405, -8.249298 38.516253))",
"POLYGON ((-8.061575 38.557406, -8.060781 38.557406, -8.060781 38.557560, -8.061575 38.557560, -8.061575 38.557406))",
"POLYGON ((-8.202522 38.557561, -7.907204 38.557561, -7.907204 38.581375, -8.202522 38.581375, -8.202522 38.557561))",
"POLYGON ((-8.156158 38.581401, -6.600859 38.581401, -6.600859 38.878804, -8.156158 38.878804, -8.156158 38.581401))",
"POLYGON ((-8.173691 38.878847, -6.881889 38.878847, -6.881889 39.370439, -8.173691 39.370439, -8.173691 38.878847))",
"POLYGON ((-8.578945 39.370449, -6.388587 39.370449, -6.388587 39.684708, -8.578945 39.684708, -8.578945 39.370449))",
"POLYGON ((-8.127208 39.684708, -6.474749 39.684708, -6.474749 39.904625, -8.127208 39.904625, -8.127208 39.684708))",
"POLYGON ((-8.625281 39.904625, -6.534500 39.904625, -6.534500 40.040088, -8.625281 40.040088, -8.625281 39.904625))",
"POLYGON ((-8.496026 40.040088, -7.458165 40.040088, -7.458165 40.138765, -8.496026 40.138765, -8.496026 40.040088))",
"POLYGON ((-8.528739 40.138765, -6.458881 40.138765, -6.458881 40.328504, -8.528739 40.328504, -8.528739 40.138765))",
"POLYGON ((-8.467686 40.328506, -6.889334 40.328506, -6.889334 40.568515, -8.467686 40.568515, -8.467686 40.328506))",
"POLYGON ((-8.603903 40.568556, -6.535534 40.568556, -6.535534 40.938536, -8.603903 40.938536, -8.603903 40.568556))",
"POLYGON ((-8.608978 40.938837, -6.486665 40.938837, -6.486665 41.142047, -8.608978 41.142047, -8.608978 40.938837))",
"POLYGON ((-8.628713 41.142072, -8.309871 41.142072, -8.309871 41.149966, -8.628713 41.149966, -8.628713 41.142072))",
"POLYGON ((-8.644879 41.149975, -6.457238 41.149975, -6.457238 41.323590, -8.644879 41.323590, -8.644879 41.149975))",
"POLYGON ((-8.542983 41.323609, -6.719689 41.323609, -6.719689 41.401176, -8.542983 41.401176, -8.542983 41.323609))",
"POLYGON ((-8.521194 41.401176, -7.381024 41.401176, -7.381024 41.428301, -8.521194 41.428301, -8.521194 41.401176))",
"POLYGON ((-8.458681 41.428313, -6.579261 41.428313, -6.579261 41.471908, -8.458681 41.471908, -8.458681 41.428313))",
"POLYGON ((-8.622370 41.471908, -6.415435 41.471908, -6.415435 41.580875, -8.622370 41.580875, -8.622370 41.471908))",
"POLYGON ((-8.535951 41.580879, -6.748873 41.580879, -6.748873 41.803986, -8.535951 41.803986, -8.535951 41.580879))",
"POLYGON ((-8.468496 41.803987, -6.748703 41.803987, -6.748703 41.871472, -8.468496 41.871472, -8.468496 41.803987))",
"POLYGON ((-8.475261 41.871474, -7.448377 41.871474, -7.448377 41.960232, -8.475261 41.960232, -8.475261 41.871474))",
"POLYGON ((-8.645202 41.960254, -6.633719 41.960254, -6.633719 42.089608, -8.645202 42.089608, -8.645202 41.960254))",
"POLYGON ((-8.621767 42.089608, -7.116037 42.089608, -7.116037 42.226588, -8.621767 42.226588, -8.621767 42.089608))",
"POLYGON ((-8.635275 42.226590, -6.570056 42.226590, -6.570056 42.541141, -8.635275 42.541141, -8.635275 42.226590))",
"POLYGON ((-8.552772 42.541254, -6.518244 42.541254, -6.518244 42.723943, -8.552772 42.723943, -8.552772 42.541254))",
"POLYGON ((-8.632494 42.723969, -6.984770 42.723969, -6.984770 42.882160, -8.632494 42.882160, -8.632494 42.723969))",
"POLYGON ((-8.641906 42.882284, -7.116686 42.882284, -7.116686 43.030767, -8.641906 43.030767, -8.641906 42.882284))",
"POLYGON ((-8.550497 43.031129, -7.143842 43.031129, -7.143842 43.111946, -8.550497 43.111946, -8.550497 43.031129))",
"POLYGON ((-8.457726 43.111987, -7.388130 43.111987, -7.388130 43.166293, -8.457726 43.166293, -8.457726 43.111987))",
"POLYGON ((-8.631117 43.166293, -6.595787 43.166293, -6.595787 43.251409, -8.631117 43.251409, -8.631117 43.166293))",
"POLYGON ((-8.630988 43.252112, -6.963607 43.252112, -6.963607 43.348633, -8.630988 43.348633, -8.630988 43.252112))",
"POLYGON ((-8.490213 43.348646, -6.771916 43.348646, -6.771916 43.452883, -8.490213 43.452883, -8.490213 43.348646))",
"POLYGON ((-8.338194 43.452889, -6.748900 43.452889, -6.748900 43.515448, -8.338194 43.515448, -8.338194 43.452889))",
"POLYGON ((-8.316958 43.515448, -6.547333 43.515448, -6.547333 43.643265, -8.316958 43.643265, -8.316958 43.515448))",
"POLYGON ((-8.605541 43.643564, -7.592943 43.643564, -7.592943 51.836314, -8.605541 51.836314, -8.605541 43.643564))",
"POLYGON ((-8.621927 51.836364, -6.929413 51.836364, -6.929413 52.127195, -8.621927 52.127195, -8.621927 51.836364))",
"POLYGON ((-8.642793 52.133746, -6.401671 52.133746, -6.401671 52.221319, -8.642793 52.221319, -8.642793 52.133746))",
"POLYGON ((-8.314566 52.221339, -6.388640 52.221339, -6.388640 52.288353, -8.314566 52.288353, -8.314566 52.221339))",
"POLYGON ((-8.532313 52.289999, -6.447893 52.289999, -6.447893 52.359924, -8.532313 52.359924, -8.532313 52.289999))",
"POLYGON ((-8.594834 52.359934, -6.409334 52.359934, -6.409334 52.521140, -8.594834 52.521140, -8.594834 52.359934))",
"POLYGON ((-8.625822 52.521149, -6.483975 52.521149, -6.483975 52.712309, -8.625822 52.712309, -8.625822 52.521149))",
"POLYGON ((-8.503977 52.712309, -6.574875 52.712309, -6.574875 53.049260, -8.503977 53.049260, -8.503977 52.712309))",
"POLYGON ((-8.244773 53.049292, -6.527364 53.049292, -6.527364 53.240584, -8.244773 53.240584, -8.244773 53.049292))",
"POLYGON ((-8.295374 53.240590, -6.426126 53.240590, -6.426126 53.325497, -8.295374 53.325497, -8.295374 53.240590))",
"POLYGON ((-8.629146 53.325608, -6.421447 53.325608, -6.421447 53.494476, -8.629146 53.494476, -8.629146 53.325608))",
"POLYGON ((-8.193685 53.494552, -6.461210 53.494552, -6.461210 53.578773, -8.193685 53.578773, -8.193685 53.494552))",
"POLYGON ((-8.158544 53.578867, -6.440966 53.578867, -6.440966 53.622112, -8.158544 53.622112, -8.158544 53.578867))",
"POLYGON ((-8.319231 53.623002, -6.475707 53.623002, -6.475707 53.694348, -8.319231 53.694348, -8.319231 53.623002))",
"POLYGON ((-8.107506 53.694350, -6.415309 53.694350, -6.415309 53.744682, -8.107506 53.744682, -8.107506 53.694350))",
"POLYGON ((-8.531831 53.744684, -6.384021 53.744684, -6.384021 54.055530, -8.531831 54.055530, -8.531831 53.744684))",
"POLYGON ((-8.495171 54.055536, -6.471639 54.055536, -6.471639 54.084229, -8.495171 54.084229, -8.495171 54.055536))",
"POLYGON ((-8.608827 54.084253, -6.433243 54.084253, -6.433243 54.271143, -8.608827 54.271143, -8.608827 54.084253))",
"POLYGON ((-8.603020 54.271153, -6.387805 54.271153, -6.387805 54.428520, -8.603020 54.428520, -8.603020 54.271153))",
"POLYGON ((-8.549330 54.428551, -6.388379 54.428551, -6.388379 55.021013, -8.549330 55.021013, -8.549330 54.428551))",
"POLYGON ((-8.292088 55.022115, -6.414715 55.022115, -6.414715 55.707894, -8.292088 55.707894, -8.292088 55.022115))",
"POLYGON ((-7.487818 55.707894, -6.392694 55.707894, -6.392694 62.187537, -7.487818 62.187537, -7.487818 55.707894))",
"POLYGON ((-6.337792 -15.959244, -4.990753 -15.959244, -4.990753 34.070338, -6.337792 34.070338, -6.337792 -15.959244))",
"POLYGON ((-6.150268 34.070412, -5.261077 34.070412, -5.261077 36.011665, -6.150268 36.011665, -6.150268 34.070412))",
"POLYGON ((-6.161336 36.011683, -5.232351 36.011683, -5.232351 36.352300, -6.161336 36.352300, -6.161336 36.011683))",
"POLYGON ((-6.272135 36.352426, -4.678658 36.352426, -4.678658 36.519224, -6.272135 36.519224, -6.272135 36.352426))",
"POLYGON ((-6.317461 36.519230, -4.415407 36.519230, -4.415407 36.720996, -6.317461 36.720996, -6.317461 36.519230))",
"POLYGON ((-6.351134 36.721007, -4.362562 36.721007, -4.362562 36.845152, -6.351134 36.845152, -6.351134 36.721007))",
"POLYGON ((-6.305118 36.845209, -4.245804 36.845209, -4.245804 37.380433, -6.305118 37.380433, -6.305118 36.845209))",
"POLYGON ((-6.006643 37.380983, -4.483083 37.380983, -4.483083 37.407846, -6.006643 37.407846, -6.006643 37.380983))",
"POLYGON ((-6.167158 37.407904, -4.269829 37.407904, -4.269829 37.559203, -6.167158 37.559203, -6.167158 37.407904))",
"POLYGON ((-6.312243 37.559203, -4.275463 37.559203, -4.275463 37.831584, -6.312243 37.831584, -6.312243 37.559203))",
"POLYGON ((-5.623236 37.831608, -4.767335 37.831608, -4.767335 37.882511, -5.623236 37.882511, -5.623236 37.831608))",
"POLYGON ((-4.875933 37.882512, -4.768938 37.882512, -4.768938 37.887450, -4.875933 37.887450, -4.875933 37.882512))",
"POLYGON ((-6.236090 37.887468, -4.271357 37.887468, -4.271357 38.384798, -6.236090 38.384798, -6.236090 37.887468))",
"POLYGON ((-6.355030 38.385011, -4.436765 38.385011, -4.436765 38.924376, -6.355030 38.924376, -6.355030 38.385011))",
"POLYGON ((-6.381279 38.924640, -4.287962 38.924640, -4.287962 39.463084, -6.381279 39.463084, -6.381279 38.924640))",
"POLYGON ((-6.379368 39.463109, -4.289986 39.463109, -4.289986 39.792601, -6.379368 39.792601, -6.379368 39.463109))",
"POLYGON ((-6.095637 39.792620, -4.299950 39.792620, -4.299950 40.028561, -6.095637 40.028561, -6.095637 39.792620))",
"POLYGON ((-6.380887 40.028669, -4.401592 40.028669, -4.401592 40.300590, -6.380887 40.300590, -6.380887 40.028669))",
"POLYGON ((-6.168442 40.300703, -4.401141 40.300703, -4.401141 40.534541, -6.168442 40.534541, -6.168442 40.300703))",
"POLYGON ((-6.021429 40.534545, -4.247400 40.534545, -4.247400 41.126105, -6.021429 41.126105, -6.021429 40.534545))",
"POLYGON ((-5.932075 41.126105, -4.293313 41.126105, -4.293313 41.311581, -5.932075 41.311581, -5.932075 41.126105))",
"POLYGON ((-6.357546 41.364786, -4.264435 41.364786, -4.264435 41.898202, -6.357546 41.898202, -6.357546 41.364786))",
"POLYGON ((-5.704912 41.898225, -4.273127 41.898225, -4.273127 42.000699, -5.704912 42.000699, -5.704912 41.898225))",
"POLYGON ((-5.683995 42.000737, -4.524405 42.000737, -4.524405 42.014254, -5.683995 42.014254, -5.683995 42.000737))",
"POLYGON ((-6.021740 42.014257, -4.244360 42.014257, -4.244360 42.322345, -6.021740 42.322345, -6.021740 42.014257))",
"POLYGON ((-6.349494 42.322346, -4.257747 42.322346, -4.257747 42.997614, -6.349494 42.997614, -6.349494 42.322346))",
"POLYGON ((-5.997852 42.999849, -4.245936 42.999849, -4.245936 43.379033, -5.997852 43.379033, -5.997852 42.999849))",
"POLYGON ((-6.261622 43.379093, -4.292515 43.379093, -4.292515 43.555207, -6.261622 43.555207, -6.261622 43.379093))",
"POLYGON ((-5.923867 43.555254, -4.241642 43.555254, -4.241642 48.042939, -5.923867 48.042939, -5.923867 43.555254))",
"POLYGON ((-4.706639 48.042946, -4.247742 48.042946, -4.247742 48.293988, -4.706639 48.293988, -4.706639 48.042946))",
"POLYGON ((-4.773278 48.294065, -4.246770 48.294065, -4.246770 48.339075, -4.773278 48.339075, -4.773278 48.294065))",
"POLYGON ((-4.781044 48.339088, -4.264862 48.339088, -4.264862 48.362529, -4.781044 48.362529, -4.781044 48.339088))",
"POLYGON ((-4.767918 48.362677, -4.300139 48.362677, -4.300139 48.393248, -4.767918 48.393248, -4.767918 48.362677))",
"POLYGON ((-5.101915 48.393258, -4.256863 48.393258, -4.256863 48.445196, -5.101915 48.445196, -5.101915 48.393258))",
"POLYGON ((-5.139894 48.445196, -4.253082 48.445196, -4.253082 48.593687, -5.139894 48.593687, -5.139894 48.445196))",
"POLYGON ((-6.321191 48.593695, -4.274295 48.593695, -4.274295 50.128003, -6.321191 50.128003, -6.321191 48.593695))",
"POLYGON ((-5.696168 50.128003, -5.013718 50.128003, -5.013718 50.216399, -5.696168 50.216399, -5.696168 50.128003))",
"POLYGON ((-5.283634 50.216430, -4.335539 50.216430, -4.335539 50.515714, -5.283634 50.515714, -5.283634 50.216430))",
"POLYGON ((-4.946187 50.515768, -4.275281 50.515768, -4.275281 50.636654, -4.946187 50.636654, -4.946187 50.515768))",
"POLYGON ((-4.760796 50.636700, -4.347132 50.636700, -4.347132 50.784352, -4.760796 50.784352, -4.760796 50.636700))",
"POLYGON ((-4.530091 50.784709, -4.329623 50.784709, -4.329623 50.831165, -4.530091 50.831165, -4.530091 50.784709))",
"POLYGON ((-4.935160 50.831400, -4.308623 50.831400, -4.308623 51.645693, -4.935160 51.645693, -4.935160 50.831400))",
"POLYGON ((-5.268918 51.645720, -4.295903 51.645720, -4.295903 51.872150, -5.268918 51.872150, -5.268918 51.645720))",
"POLYGON ((-5.312144 51.872154, -4.462919 51.872154, -4.462919 52.080743, -5.312144 52.080743, -5.312144 51.872154))",
"POLYGON ((-6.382409 52.080760, -4.275697 52.080760, -4.275697 53.141278, -6.382409 53.141278, -6.382409 52.080760))",
"POLYGON ((-6.351951 53.141278, -4.297360 53.141278, -4.297360 53.304785, -6.351951 53.304785, -6.351951 53.141278))",
"POLYGON ((-6.330635 53.304790, -4.342321 53.304790, -4.342321 53.408204, -6.330635 53.408204, -6.330635 53.304790))",
"POLYGON ((-6.383968 53.408219, -4.620462 53.408219, -4.620462 54.060564, -6.383968 54.060564, -6.383968 53.408219))",
"POLYGON ((-6.279750 54.060567, -4.458168 54.060567, -4.458168 54.203679, -6.279750 54.203679, -6.279750 54.060567))",
"POLYGON ((-6.382679 54.203681, -4.453055 54.203681, -4.453055 54.486888, -6.382679 54.486888, -6.382679 54.203681))",
"POLYGON ((-6.293556 54.486893, -5.667284 54.486893, -5.667284 54.715827, -6.293556 54.715827, -6.293556 54.486893))",
"POLYGON ((-6.360069 54.715881, -4.305541 54.715881, -4.305541 55.354541, -6.360069 55.354541, -6.360069 54.715881))",
"POLYGON ((-5.713926 55.354544, -4.552062 55.354544, -4.552062 55.576859, -5.713926 55.576859, -5.713926 55.354544))",
"POLYGON ((-6.220588 55.576911, -4.372233 55.576911, -4.372233 55.707497, -6.220588 55.707497, -6.220588 55.576911))",
"POLYGON ((-6.374546 55.707497, -4.847882 55.707497, -4.847882 55.768163, -6.374546 55.768163, -6.374546 55.707497))",
"POLYGON ((-6.358070 55.768168, -4.242697 55.768168, -4.242697 55.923525, -6.358070 55.923525, -6.358070 55.768168))",
"POLYGON ((-6.270819 55.923600, -4.278049 55.923600, -4.278049 56.085381, -6.270819 56.085381, -6.270819 55.923600))",
"POLYGON ((-6.157516 56.085423, -4.297706 56.085423, -4.297706 56.176438, -6.157516 56.176438, -6.157516 56.085423))",
"POLYGON ((-6.338971 56.178793, -4.257015 56.178793, -4.257015 56.454895, -6.338971 56.454895, -6.338971 56.178793))",
"POLYGON ((-6.179432 56.454895, -4.314835 56.454895, -4.314835 56.879636, -6.179432 56.879636, -6.179432 56.454895))",
"POLYGON ((-6.191654 56.879658, -4.283370 56.879658, -4.283370 57.177908, -6.191654 57.177908, -6.191654 56.879658))",
"POLYGON ((-6.113914 57.177912, -4.328629 57.177912, -4.328629 57.459465, -6.113914 57.459465, -6.113914 57.177912))",
"POLYGON ((-6.347275 57.459470, -4.261051 57.459470, -4.261051 58.550004, -6.347275 58.550004, -6.347275 57.459470))",
"POLYGON ((-4.240143 5.295009, -3.488816 5.295009, -3.488816 36.914749, -4.240143 36.914749, -4.240143 5.295009))",
"POLYGON ((-4.151107 36.914777, -3.289977 36.914777, -3.289977 37.176995, -4.151107 37.176995, -4.151107 36.914777))",
"POLYGON ((-4.217875 37.177025, -3.584921 37.177025, -3.584921 37.329480, -4.217875 37.329480, -4.217875 37.177025))",
"POLYGON ((-4.193205 37.329510, -3.530471 37.329510, -3.530471 37.460573, -4.193205 37.460573, -4.193205 37.329510))",
"POLYGON ((-4.171028 37.460580, -3.285753 37.460580, -3.285753 37.761591, -4.171028 37.761591, -4.171028 37.460580))",
"POLYGON ((-4.215209 37.761656, -3.353409 37.761656, -3.353409 37.984162, -4.215209 37.984162, -4.215209 37.761656))",
"POLYGON ((-3.881730 37.984168, -3.365633 37.984168, -3.365633 38.008260, -3.881730 38.008260, -3.881730 37.984168))",
"POLYGON ((-3.862496 38.008267, -3.364389 38.008267, -3.364389 38.038905, -3.862496 38.038905, -3.862496 38.008267))",
"POLYGON ((-4.196470 38.038930, -3.302298 38.038930, -3.302298 39.074299, -4.196470 39.074299, -4.196470 38.038930))",
"POLYGON ((-4.220613 39.074401, -3.285388 39.074401, -3.285388 39.859258, -4.220613 39.859258, -4.220613 39.074401))",
"POLYGON ((-4.037738 39.859264, -4.004914 39.859264, -4.004914 39.865395, -4.037738 39.865395, -4.037738 39.859264))",
"POLYGON ((-4.240787 39.865502, -3.331082 39.865502, -3.331082 40.413779, -4.240787 40.413779, -4.240787 39.865502))",
"POLYGON ((-4.182876 40.413784, -3.366654 40.413784, -3.366654 40.473391, -4.182876 40.473391, -4.182876 40.413784))",
"POLYGON ((-4.214255 40.473528, -3.336877 40.473528, -3.336877 40.509361, -4.214255 40.509361, -4.214255 40.473528))",
"POLYGON ((-4.214378 40.509362, -3.543759 40.509362, -3.543759 40.616239, -4.214378 40.616239, -4.214378 40.509362))",
"POLYGON ((-4.233950 40.616243, -3.368456 40.616243, -3.368456 41.782784, -4.233950 41.782784, -4.233950 40.616243))",
"POLYGON ((-4.203753 41.782809, -3.283066 41.782809, -3.283066 42.338967, -4.203753 42.338967, -4.203753 41.782809))",
"POLYGON ((-4.046506 42.338978, -3.299959 42.338978, -3.299959 42.472480, -4.046506 42.472480, -4.046506 42.338978))",
"POLYGON ((-4.227898 42.472505, -3.294828 42.472505, -3.294828 42.831233, -4.227898 42.831233, -4.227898 42.472505))",
"POLYGON ((-4.218674 42.831233, -3.368071 42.831233, -3.368071 42.999017, -4.218674 42.999017, -4.218674 42.831233))",
"POLYGON ((-4.237421 42.999032, -3.284335 42.999032, -3.284335 43.335179, -4.237421 43.335179, -4.237421 42.999032))",
"POLYGON ((-4.237685 43.335198, -3.705445 43.335198, -3.705445 43.372604, -4.237685 43.372604, -4.237685 43.335198))",
"POLYGON ((-3.829034 43.372606, -3.734738 43.372606, -3.734738 43.375800, -3.829034 43.375800, -3.829034 43.372606))",
"POLYGON ((-4.097353 43.375800, -3.366635 43.375800, -3.366635 43.439399, -4.097353 43.439399, -4.097353 43.375800))",
"POLYGON ((-3.835889 43.439406, -3.339589 43.439406, -3.339589 47.728421, -3.835889 47.728421, -3.835889 43.439406))",
"POLYGON ((-3.539465 47.728423, -3.287023 47.728423, -3.287023 47.769905, -3.539465 47.769905, -3.539465 47.728423))",
"POLYGON ((-4.241614 47.771227, -3.278436 47.771227, -3.278436 47.886481, -4.241614 47.886481, -4.241614 47.771227))",
"POLYGON ((-4.215451 47.886482, -3.340647 47.886482, -3.340647 48.101370, -4.215451 48.101370, -4.215451 47.886482))",
"POLYGON ((-4.229403 48.101375, -3.516449 48.101375, -3.516449 48.416577, -4.229403 48.416577, -4.229403 48.101375))",
"POLYGON ((-4.219160 48.416593, -3.392373 48.416593, -3.392373 48.543234, -4.219160 48.543234, -4.219160 48.416593))",
"POLYGON ((-4.168834 48.543236, -3.279949 48.543236, -3.279949 48.632148, -4.168834 48.632148, -4.168834 48.543236))",
"POLYGON ((-4.215963 48.632169, -3.434639 48.632169, -3.434639 48.672955, -4.215963 48.672955, -4.215963 48.632169))",
"POLYGON ((-4.121146 48.672957, -3.290231 48.672957, -3.290231 48.731686, -4.121146 48.731686, -4.121146 48.672957))",
"POLYGON ((-4.064941 48.731698, -3.286282 48.731698, -3.286282 50.336112, -4.064941 50.336112, -4.064941 48.731698))",
"POLYGON ((-4.238914 50.336155, -3.488265 50.336155, -3.488265 50.435395, -4.238914 50.435395, -4.238914 50.336155))",
"POLYGON ((-4.209530 50.435406, -3.539979 50.435406, -3.539979 50.504559, -4.209530 50.504559, -4.209530 50.435406))",
"POLYGON ((-4.043952 50.504723, -3.596107 50.504723, -3.596107 50.574118, -4.043952 50.574118, -4.043952 50.504723))",
"POLYGON ((-4.040804 50.574437, -3.587355 50.574437, -3.587355 50.586383, -4.040804 50.586383, -4.040804 50.574437))",
"POLYGON ((-4.061433 50.586397, -3.446922 50.586397, -3.446922 50.670715, -4.061433 50.670715, -4.061433 50.586397))",
"POLYGON ((-3.937105 50.670718, -3.276997 50.670718, -3.276997 50.715790, -3.937105 50.715790, -3.937105 50.670718))",
"POLYGON ((-4.009090 50.715817, -3.415683 50.715817, -3.415683 50.744075, -4.009090 50.744075, -4.009090 50.715817))",
"POLYGON ((-3.947857 50.744075, -3.276397 50.744075, -3.276397 50.863319, -3.947857 50.863319, -3.947857 50.744075))",
"POLYGON ((-4.204783 50.863521, -3.296035 50.863521, -3.296035 51.390248, -4.204783 51.390248, -4.204783 50.863521))",
"POLYGON ((-4.176827 51.390420, -3.279740 51.390420, -3.279740 51.828109, -4.176827 51.828109, -4.176827 51.390420))",
"POLYGON ((-4.173995 51.828109, -3.289522 51.828109, -3.289522 52.523450, -4.173995 52.523450, -4.173995 51.828109))",
"POLYGON ((-4.119896 52.523777, -3.283814 52.523777, -3.283814 52.893559, -4.119896 52.893559, -4.119896 52.523777))",
"POLYGON ((-4.233153 52.893563, -3.541218 52.893563, -3.541218 52.932125, -4.233153 52.932125, -4.233153 52.893563))",
"POLYGON ((-4.069432 52.932126, -3.487920 52.932126, -3.487920 53.025658, -4.069432 53.025658, -4.069432 52.932126))",
"POLYGON ((-4.166134 53.025672, -3.765516 53.025672, -3.765516 53.080479, -4.166134 53.080479, -4.166134 53.025672))",
"POLYGON ((-4.171936 53.081194, -3.301460 53.081194, -3.301460 53.200072, -4.171936 53.200072, -4.171936 53.081194))",
"POLYGON ((-4.095574 53.200297, -3.304085 53.200297, -3.304085 53.265329, -4.095574 53.265329, -4.095574 53.200297))",
"POLYGON ((-4.104038 53.265332, -3.282123 53.265332, -3.282123 53.282167, -4.104038 53.282167, -4.104038 53.265332))",
"POLYGON ((-4.125792 53.282173, -3.372065 53.282173, -3.372065 53.335670, -4.125792 53.335670, -4.125792 53.282173))",
"POLYGON ((-4.198293 53.335673, -3.276361 53.335673, -3.276361 55.362014, -4.198293 55.362014, -4.198293 53.335673))",
"POLYGON ((-4.063518 55.362015, -3.290537 55.362015, -3.290537 55.761340, -4.063518 55.761340, -4.063518 55.362015))",
"POLYGON ((-4.189381 55.761353, -3.358428 55.761353, -3.358428 55.810038, -4.189381 55.810038, -4.189381 55.761353))",
"POLYGON ((-4.240848 55.810038, -3.280698 55.810038, -3.280698 55.952394, -4.240848 55.952394, -4.240848 55.810038))",
"POLYGON ((-4.236113 55.952634, -3.290887 55.952634, -3.290887 55.999090, -4.236113 55.999090, -4.236113 55.952634))",
"POLYGON ((-4.129535 55.999223, -3.297732 55.999223, -3.297732 56.123247, -4.129535 56.123247, -4.129535 55.999223))",
"POLYGON ((-4.117100 56.123247, -3.311478 56.123247, -3.311478 56.373452, -4.117100 56.373452, -4.117100 56.123247))",
"POLYGON ((-4.174663 56.373488, -3.280149 56.373488, -3.280149 57.356299, -4.174663 57.356299, -4.174663 56.373488))",
"POLYGON ((-4.231436 57.356304, -3.357176 57.356304, -3.357176 57.518714, -4.231436 57.518714, -4.231436 57.356304))",
"POLYGON ((-4.208088 57.518832, -3.299701 57.518832, -3.299701 59.106361, -4.208088 59.106361, -4.208088 57.518832))",
"POLYGON ((-3.234454 10.954527, -2.389610 10.954527, -2.389610 35.314705, -3.234454 35.314705, -3.234454 10.954527))",
"POLYGON ((-3.014519 35.314730, -2.467190 35.314730, -2.467190 36.964650, -3.014519 36.964650, -3.014519 35.314730))",
"POLYGON ((-3.252520 36.964651, -2.386237 36.964651, -2.386237 38.240971, -3.252520 38.240971, -3.252520 36.964651))",
"POLYGON ((-3.213480 38.240971, -2.489192 38.240971, -2.489192 39.885685, -3.213480 39.885685, -3.213480 38.240971))",
"POLYGON ((-3.235635 39.885695, -2.639452 39.885695, -2.639452 41.013977, -3.235635 41.013977, -3.235635 39.885695))",
"POLYGON ((-3.244479 41.013984, -2.397581 41.013984, -2.397581 41.601931, -3.244479 41.601931, -3.244479 41.013984))",
"POLYGON ((-3.123593 41.601954, -2.435740 41.601954, -2.435740 41.964556, -3.123593 41.964556, -3.123593 41.601954))",
"POLYGON ((-3.267329 41.964556, -2.405283 41.964556, -2.405283 42.655810, -3.267329 42.655810, -3.267329 41.964556))",
"POLYGON ((-3.274615 42.655858, -2.400148 42.655858, -2.400148 42.990015, -3.274615 42.990015, -3.274615 42.655858))",
"POLYGON ((-3.274871 42.990015, -2.404168 42.990015, -2.404168 43.290825, -3.274871 43.290825, -3.274871 42.990015))",
"POLYGON ((-3.109822 43.290837, -2.382616 43.290837, -2.382616 43.362396, -3.109822 43.362396, -3.109822 43.290837))",
"POLYGON ((-3.219786 43.362405, -2.379654 43.362405, -2.379654 47.294271, -3.219786 47.294271, -3.219786 43.362405))",
"POLYGON ((-3.164488 47.294276, -2.511092 47.294276, -2.511092 47.518070, -3.164488 47.518070, -3.164488 47.294276))",
"POLYGON ((-3.159358 47.518070, -2.494088 47.518070, -2.494088 47.593550, -3.159358 47.593550, -3.159358 47.518070))",
"POLYGON ((-3.147084 47.593555, -2.623985 47.593555, -2.623985 47.609263, -3.147084 47.609263, -3.147084 47.593555))",
"POLYGON ((-3.252165 47.609292, -2.590429 47.609292, -2.590429 47.732484, -3.252165 47.732484, -3.252165 47.609292))",
"POLYGON ((-3.267907 47.732484, -2.396949 47.732484, -2.396949 47.951578, -3.267907 47.951578, -3.267907 47.732484))",
"POLYGON ((-3.262770 47.951630, -2.514170 47.951630, -2.514170 48.182565, -3.262770 48.182565, -3.262770 47.951630))",
"POLYGON ((-3.135023 48.182593, -2.659658 48.182593, -2.659658 48.276736, -3.135023 48.276736, -3.135023 48.182593))",
"POLYGON ((-3.252779 48.276946, -2.490276 48.276946, -2.490276 48.367475, -3.252779 48.367475, -3.252779 48.276946))",
"POLYGON ((-3.153033 48.367493, -2.395930 48.367493, -2.395930 48.562784, -3.153033 48.562784, -3.153033 48.367493))",
"POLYGON ((-3.261458 48.562855, -2.529053 48.562855, -2.529053 48.779913, -3.261458 48.779913, -3.261458 48.562855))",
"POLYGON ((-3.253087 48.779914, -2.531032 48.779914, -2.531032 49.427035, -3.253087 49.427035, -3.253087 48.779914))",
"POLYGON ((-2.671546 49.427036, -2.427056 49.427036, -2.427056 50.675915, -2.671546 50.675915, -2.671546 49.427036))",
"POLYGON ((-3.276061 50.676014, -2.441925 50.676014, -2.441925 50.707517, -3.276061 50.707517, -3.276061 50.676014))",
"POLYGON ((-3.239849 50.707562, -2.380903 50.707562, -2.380903 50.728935, -3.239849 50.728935, -3.239849 50.707562))",
"POLYGON ((-3.276218 50.728958, -2.379828 50.728958, -2.379828 50.779999, -3.276218 50.779999, -3.276218 50.728958))",
"POLYGON ((-3.255310 50.780038, -2.540495 50.780038, -2.540495 50.813445, -3.255310 50.813445, -3.255310 50.780038))",
"POLYGON ((-3.134955 50.813499, -2.404690 50.813499, -2.404690 50.912975, -3.134955 50.912975, -3.134955 50.813499))",
"POLYGON ((-3.178235 50.913830, -2.494681 50.913830, -2.494681 51.080828, -3.178235 51.080828, -3.178235 50.913830))",
"POLYGON ((-3.262782 51.081423, -2.495588 51.081423, -2.495588 51.249340, -3.262782 51.249340, -3.262782 51.081423))",
"POLYGON ((-3.115021 51.249340, -2.400176 51.249340, -2.400176 51.343342, -3.115021 51.343342, -3.115021 51.249340))",
"POLYGON ((-3.122632 51.343361, -2.379825 51.343361, -2.379825 51.448849, -3.122632 51.448849, -3.122632 51.343361))",
"POLYGON ((-3.269211 51.448914, -2.467018 51.448914, -2.467018 51.570044, -3.269211 51.570044, -3.269211 51.448914))",
"POLYGON ((-3.221189 51.570069, -2.532373 51.570069, -2.532373 51.611497, -3.221189 51.611497, -3.221189 51.570069))",
"POLYGON ((-3.223328 51.611510, -2.529728 51.611510, -2.529728 51.732500, -3.223328 51.732500, -3.223328 51.611510))",
"POLYGON ((-3.090338 51.732697, -2.380364 51.732697, -2.380364 52.022903, -3.090338 52.022903, -3.090338 51.732697))",
"POLYGON ((-3.126413 52.023056, -2.386985 52.023056, -2.386985 52.284546, -3.126413 52.284546, -3.126413 52.023056))",
"POLYGON ((-3.089776 52.284551, -2.471987 52.284551, -2.471987 52.360637, -3.089776 52.360637, -3.089776 52.284551))",
"POLYGON ((-3.252522 52.367000, -2.418359 52.367000, -2.418359 52.650087, -3.252522 52.650087, -3.252522 52.367000))",
"POLYGON ((-3.160823 52.650107, -2.388475 52.650107, -2.388475 52.731242, -3.160823 52.731242, -3.160823 52.650107))",
"POLYGON ((-3.187127 52.731433, -2.395583 52.731433, -2.395583 53.095355, -3.187127 53.095355, -3.187127 52.731433))",
"POLYGON ((-3.020013 53.097194, -2.426279 53.097194, -2.426279 53.227494, -3.020013 53.227494, -3.020013 53.097194))",
"POLYGON ((-3.248860 53.227513, -2.420618 53.227513, -2.420618 53.442554, -3.248860 53.442554, -3.248860 53.227513))",
"POLYGON ((-3.103074 53.442576, -2.379892 53.442576, -2.379892 54.045156, -3.103074 54.045156, -3.103074 53.442576))",
"POLYGON ((-3.272035 54.045159, -2.381321 54.045159, -2.381321 54.251863, -3.272035 54.251863, -3.272035 54.045159))",
"POLYGON ((-3.276305 54.257585, -2.486421 54.257585, -2.486421 54.422421, -3.276305 54.422421, -3.276305 54.257585))",
"POLYGON ((-3.221632 54.422422, -2.496383 54.422422, -2.496383 54.732095, -3.221632 54.732095, -3.221632 54.422422))",
"POLYGON ((-3.150264 54.732095, -2.397230 54.732095, -2.397230 54.906831, -3.150264 54.906831, -3.150264 54.732095))",
"POLYGON ((-3.186519 54.907310, -2.508720 54.907310, -2.508720 54.955759, -3.186519 54.955759, -3.186519 54.907310))",
"POLYGON ((-2.782793 54.955765, -2.384564 54.955765, -2.384564 54.995124, -2.782793 54.995124, -2.782793 54.955765))",
"POLYGON ((-3.268748 54.995129, -2.379535 54.995129, -2.379535 55.375032, -3.268748 55.375032, -3.268748 54.995129))",
"POLYGON ((-2.919895 55.375040, -2.382832 55.375040, -2.382832 55.575252, -2.919895 55.575252, -2.919895 55.375040))",
"POLYGON ((-3.115671 55.575254, -2.417756 55.575254, -2.417756 55.605820, -3.115671 55.605820, -3.115671 55.575254))",
"POLYGON ((-3.244301 55.605820, -2.405578 55.605820, -2.405578 55.779229, -3.244301 55.779229, -3.244301 55.605820))",
"POLYGON ((-3.265361 55.779236, -2.536380 55.779236, -2.536380 55.925705, -3.265361 55.925705, -3.265361 55.779236))",
"POLYGON ((-3.272397 55.926265, -2.997271 55.926265, -2.997271 55.951695, -3.272397 55.951695, -3.272397 55.926265))",
"POLYGON ((-3.226778 55.951697, -2.671624 55.951697, -2.671624 55.995130, -3.226778 55.995130, -3.226778 55.951697))",
"POLYGON ((-2.835678 55.995130, -2.512591 55.995130, -2.512591 56.077175, -2.835678 56.077175, -2.835678 55.995130))",
"POLYGON ((-3.271221 56.077241, -2.637934 56.077241, -2.637934 56.409761, -3.271221 56.409761, -3.271221 56.077241))",
"POLYGON ((-3.272015 56.409786, -2.468812 56.409786, -2.468812 56.911487, -3.272015 56.911487, -3.272015 56.409786))",
"POLYGON ((-3.251724 56.911493, -2.394396 56.911493, -2.394396 57.443477, -3.251724 57.443477, -3.251724 56.911493))",
"POLYGON ((-3.267262 57.443493, -2.394998 57.443493, -2.394998 59.320834, -3.267262 59.320834, -3.267262 57.443493))",
"POLYGON ((-2.363527 4.867727, -1.989965 4.867727, -1.989965 42.133956, -2.363527 42.133956, -2.363527 4.867727))",
"POLYGON ((-2.373921 42.133989, -1.980643 42.133989, -1.980643 43.085637, -2.373921 43.085637, -2.373921 42.133989))",
"POLYGON ((-2.333725 43.085639, -1.969200 43.085639, -1.969200 43.276767, -2.333725 43.276767, -2.333725 43.085639))",
"POLYGON ((-2.379330 43.276803, -1.970395 43.276803, -1.970395 46.985963, -2.379330 46.985963, -2.379330 43.276803))",
"POLYGON ((-2.306796 46.985965, -2.105453 46.985965, -2.105453 47.134017, -2.306796 47.134017, -2.306796 46.985965))",
"POLYGON ((-2.321822 47.134023, -2.138488 47.134023, -2.138488 47.251855, -2.321822 47.251855, -2.321822 47.134023))",
"POLYGON ((-2.279730 47.251864, -2.084487 47.251864, -2.084487 47.296673, -2.279730 47.296673, -2.279730 47.251864))",
"POLYGON ((-2.269094 47.297112, -1.969583 47.297112, -1.969583 47.385975, -2.269094 47.385975, -2.269094 47.297112))",
"POLYGON ((-2.350309 47.385975, -2.002265 47.385975, -2.002265 47.850804, -2.350309 47.850804, -2.350309 47.385975))",
"POLYGON ((-2.347452 47.850805, -1.989891 47.850805, -1.989891 48.453739, -2.347452 48.453739, -2.347452 47.850805))",
"POLYGON ((-2.339444 48.453741, -1.979149 48.453741, -1.979149 48.515585, -2.339444 48.515585, -2.339444 48.453741))",
"POLYGON ((-2.327054 48.515586, -1.973097 48.515586, -1.973097 48.646151, -2.327054 48.646151, -2.327054 48.515586))",
"POLYGON ((-2.330491 48.646152, -2.021016 48.646152, -2.021016 48.662466, -2.330491 48.662466, -2.330491 48.646152))",
"POLYGON ((-2.330465 48.662468, -2.068367 48.662468, -2.068367 48.678587, -2.330465 48.678587, -2.330465 48.662468))",
"POLYGON ((-2.319277 48.678652, -1.987551 48.678652, -1.987551 49.181686, -2.319277 49.181686, -2.319277 48.678652))",
"POLYGON ((-2.242925 49.181686, -1.972819 49.181686, -1.972819 50.633607, -2.242925 50.633607, -2.242925 49.181686))",
"POLYGON ((-2.378668 50.633615, -1.977628 50.633615, -1.977628 50.802428, -2.378668 50.802428, -2.378668 50.633615))",
"POLYGON ((-2.293884 50.802527, -1.969672 50.802527, -1.969672 50.937238, -2.293884 50.937238, -2.293884 50.802527))",
"POLYGON ((-2.198956 50.937248, -1.970164 50.937248, -1.970164 51.053955, -2.198956 51.053955, -2.198956 50.937248))",
"POLYGON ((-2.342165 51.053979, -1.975924 51.053979, -1.975924 51.320951, -2.342165 51.320951, -2.342165 51.053979))",
"POLYGON ((-2.379384 51.322162, -1.981190 51.322162, -1.981190 51.453598, -2.379384 51.453598, -2.379384 51.322162))",
"POLYGON ((-2.373901 51.454343, -2.028004 51.454343, -2.028004 51.658864, -2.373901 51.658864, -2.373901 51.454343))",
"POLYGON ((-2.372713 51.658866, -1.970375 51.658866, -1.970375 51.793180, -2.372713 51.793180, -2.372713 51.658866))",
"POLYGON ((-2.338230 51.793206, -2.013899 51.793206, -2.013899 51.851910, -2.338230 51.851910, -2.338230 51.793206))",
"POLYGON ((-2.378710 51.851942, -1.970762 51.851942, -1.970762 51.991048, -2.378710 51.991048, -2.378710 51.851942))",
"POLYGON ((-2.367067 51.991095, -1.969532 51.991095, -1.969532 52.266396, -2.367067 52.266396, -2.367067 51.991095))",
"POLYGON ((-2.350977 52.266475, -1.969445 52.266475, -1.969445 52.502403, -2.350977 52.502403, -2.350977 52.266475))",
"POLYGON ((-2.147203 52.502556, -2.010954 52.502556, -2.010954 52.529746, -2.147203 52.529746, -2.147203 52.502556))",
"POLYGON ((-2.094718 52.529746, -1.995535 52.529746, -1.995535 52.557733, -2.094718 52.557733, -2.094718 52.529746))",
"POLYGON ((-2.319696 52.557736, -1.971272 52.557736, -1.971272 52.694409, -2.319696 52.694409, -2.319696 52.557736))",
"POLYGON ((-2.374501 52.694411, -1.970843 52.694411, -1.970843 52.982741, -2.374501 52.982741, -2.374501 52.694411))",
"POLYGON ((-2.341127 52.982760, -1.972902 52.982760, -1.972902 53.154515, -2.341127 53.154515, -2.341127 52.982760))",
"POLYGON ((-2.282901 53.156442, -1.969486 53.156442, -1.969486 53.484545, -2.282901 53.484545, -2.282901 53.156442))",
"POLYGON ((-2.378755 53.484568, -1.980919 53.484568, -1.980919 53.742780, -2.378755 53.742780, -2.378755 53.484568))",
"POLYGON ((-2.379217 53.742780, -1.980924 53.742780, -1.980924 53.944784, -2.379217 53.944784, -2.379217 53.742780))",
"POLYGON ((-2.205078 53.944980, -1.970822 53.944980, -1.970822 54.051717, -2.205078 54.051717, -2.205078 53.944980))",
"POLYGON ((-2.161366 54.051719, -1.975045 54.051719, -1.975045 54.064030, -2.161366 54.064030, -2.161366 54.051719))",
"POLYGON ((-2.244768 54.064030, -1.969718 54.064030, -1.969718 54.084039, -2.244768 54.084039, -2.244768 54.064030))",
"POLYGON ((-2.232105 54.084039, -1.969353 54.084039, -1.969353 54.089358, -2.232105 54.089358, -2.232105 54.084039))",
"POLYGON ((-2.271783 54.089382, -1.975841 54.089382, -1.975841 54.094770, -2.271783 54.094770, -2.271783 54.089382))",
"POLYGON ((-2.121638 54.094772, -1.969992 54.094772, -1.969992 54.102212, -2.121638 54.102212, -2.121638 54.094772))",
"POLYGON ((-2.136089 54.102244, -1.969378 54.102244, -1.969378 54.109471, -2.136089 54.109471, -2.136089 54.102244))",
"POLYGON ((-2.198145 54.109471, -1.976386 54.109471, -1.976386 54.132190, -2.198145 54.132190, -2.198145 54.109471))",
"POLYGON ((-2.211556 54.132202, -1.990041 54.132202, -1.990041 54.145288, -2.211556 54.145288, -2.211556 54.132202))",
"POLYGON ((-2.169821 54.145345, -2.008720 54.145345, -2.008720 54.166218, -2.169821 54.166218, -2.169821 54.145345))",
"POLYGON ((-2.346933 54.166250, -2.014064 54.166250, -2.014064 54.179262, -2.346933 54.179262, -2.346933 54.166250))",
"POLYGON ((-2.346477 54.179278, -2.019554 54.179278, -2.019554 54.185916, -2.346477 54.185916, -2.346477 54.179278))",
"POLYGON ((-2.234634 54.185916, -2.029104 54.185916, -2.029104 54.194062, -2.234634 54.194062, -2.234634 54.185916))",
"POLYGON ((-2.375616 54.194223, -2.053726 54.194223, -2.053726 54.213820, -2.375616 54.213820, -2.375616 54.194223))",
"POLYGON ((-2.331460 54.213839, -2.028083 54.213839, -2.028083 54.234480, -2.331460 54.234480, -2.331460 54.213839))",
"POLYGON ((-2.283313 54.235016, -2.007269 54.235016, -2.007269 54.347731, -2.283313 54.347731, -2.283313 54.235016))",
"POLYGON ((-2.337966 54.347737, -2.012558 54.347737, -2.012558 54.622632, -2.337966 54.622632, -2.337966 54.347737))",
"POLYGON ((-2.314425 54.622641, -1.979128 54.622641, -1.979128 54.679414, -2.314425 54.679414, -2.314425 54.622641))",
"POLYGON ((-2.286789 54.679538, -1.970112 54.679538, -1.970112 54.974693, -2.286789 54.974693, -2.286789 54.679538))",
"POLYGON ((-2.379296 54.977361, -1.977953 54.977361, -1.977953 55.020624, -2.379296 55.020624, -2.379296 54.977361))",
"POLYGON ((-2.353936 55.020664, -2.025126 55.020664, -2.025126 55.096500, -2.353936 55.096500, -2.353936 55.020664))",
"POLYGON ((-2.355119 55.097707, -1.988812 55.097707, -1.988812 55.336939, -2.355119 55.336939, -2.355119 55.097707))",
"POLYGON ((-2.370198 55.337002, -1.980026 55.337002, -1.980026 55.437032, -2.370198 55.437032, -2.370198 55.337002))",
"POLYGON ((-2.373616 55.437045, -1.986352 55.437045, -1.986352 55.507640, -2.373616 55.507640, -2.373616 55.437045))",
"POLYGON ((-2.371890 55.507659, -2.016566 55.507659, -2.016566 55.547762, -2.371890 55.547762, -2.371890 55.507659))",
"POLYGON ((-2.363901 55.547787, -2.052047 55.547787, -2.052047 55.641420, -2.363901 55.641420, -2.363901 55.547787))",
"POLYGON ((-2.377586 55.641519, -1.998610 55.641519, -1.998610 56.946092, -2.377586 56.946092, -2.377586 55.641519))",
"POLYGON ((-2.367608 56.946101, -2.006026 56.946101, -2.006026 60.117605, -2.367608 60.117605, -2.367608 56.946101))",
"POLYGON ((-1.967486 4.793208, -1.484324 4.793208, -1.484324 37.508079, -1.967486 37.508079, -1.967486 4.793208))",
"POLYGON ((-1.899326 37.508079, -1.491557 37.508079, -1.491557 38.560476, -1.899326 38.560476, -1.899326 37.508079))",
"POLYGON ((-1.965753 38.560627, -1.472960 38.560627, -1.472960 41.359545, -1.965753 41.359545, -1.965753 38.560627))",
"POLYGON ((-1.922785 41.359546, -1.468308 41.359546, -1.468308 42.810759, -1.922785 42.810759, -1.922785 41.359546))",
"POLYGON ((-1.956251 42.810761, -1.482666 42.810761, -1.482666 43.322872, -1.956251 43.322872, -1.956251 42.810761))",
"POLYGON ((-1.933948 43.322874, -1.531347 43.322874, -1.531347 43.389579, -1.933948 43.389579, -1.933948 43.322874))",
"POLYGON ((-1.789645 43.389619, -1.459970 43.389619, -1.459970 43.501653, -1.789645 43.501653, -1.789645 43.389619))",
"POLYGON ((-1.549421 46.200976, -1.483894 46.200976, -1.483894 46.243212, -1.549421 46.243212, -1.549421 46.200976))",
"POLYGON ((-1.795923 46.243217, -1.471676 46.243217, -1.471676 46.748641, -1.795923 46.748641, -1.795923 46.243217))",
"POLYGON ((-1.910683 46.748648, -1.479477 46.748648, -1.479477 47.186603, -1.910683 47.186603, -1.910683 46.748648))",
"POLYGON ((-1.731753 47.186614, -1.535834 47.186614, -1.535834 47.215910, -1.731753 47.215910, -1.731753 47.186614))",
"POLYGON ((-1.737443 47.215912, -1.538239 47.215912, -1.538239 47.224562, -1.737443 47.224562, -1.737443 47.215912))",
"POLYGON ((-1.797576 47.224565, -1.465531 47.224565, -1.465531 47.493111, -1.797576 47.493111, -1.797576 47.224565))",
"POLYGON ((-1.895826 47.493153, -1.515116 47.493153, -1.515116 48.011040, -1.895826 48.011040, -1.895826 47.493153))",
"POLYGON ((-1.855974 48.011047, -1.495455 48.011047, -1.495455 48.111220, -1.855974 48.111220, -1.855974 48.011047))",
"POLYGON ((-1.964072 48.111249, -1.459433 48.111249, -1.459433 48.382998, -1.964072 48.382998, -1.964072 48.111249))",
"POLYGON ((-1.964091 48.382999, -1.490236 48.382999, -1.490236 48.491283, -1.964091 48.491283, -1.964091 48.382999))",
"POLYGON ((-1.968150 48.491284, -1.463364 48.491284, -1.463364 48.833849, -1.968150 48.833849, -1.968150 48.491284))",
"POLYGON ((-1.828473 48.833849, -1.458845 48.833849, -1.458845 49.489414, -1.828473 49.489414, -1.828473 48.833849))",
"POLYGON ((-1.882345 49.489415, -1.496709 49.489415, -1.496709 49.612209, -1.882345 49.612209, -1.882345 49.489415))",
"POLYGON ((-1.845249 49.612252, -1.466840 49.612252, -1.466840 49.640680, -1.845249 49.640680, -1.845249 49.612252))",
"POLYGON ((-1.799017 49.640680, -1.465301 49.640680, -1.465301 49.653879, -1.799017 49.653879, -1.799017 49.640680))",
"POLYGON ((-1.840850 49.653883, -1.468611 49.653883, -1.468611 49.666206, -1.840850 49.666206, -1.840850 49.653883))",
"POLYGON ((-1.740298 49.666208, -1.458968 49.666208, -1.458968 49.669989, -1.740298 49.669989, -1.740298 49.666208))",
"POLYGON ((-1.854814 49.669991, -1.463512 49.669991, -1.463512 49.673626, -1.854814 49.673626, -1.854814 49.669991))",
"POLYGON ((-1.796039 49.673634, -1.462792 49.673634, -1.462792 49.679292, -1.796039 49.679292, -1.796039 49.673634))",
"POLYGON ((-1.942897 49.679297, -1.469595 49.679297, -1.469595 49.709995, -1.942897 49.709995, -1.942897 49.679297))",
"POLYGON ((-1.959374 49.710317, -1.487299 49.710317, -1.487299 50.843637, -1.959374 50.843637, -1.959374 49.710317))",
"POLYGON ((-1.969117 50.843863, -1.469168 50.843863, -1.469168 51.069412, -1.969117 51.069412, -1.969117 50.843863))",
"POLYGON ((-1.968141 51.069733, -1.534247 51.069733, -1.534247 51.169538, -1.968141 51.169538, -1.968141 51.069733))",
"POLYGON ((-1.859094 51.169543, -1.623906 51.169543, -1.623906 51.175702, -1.859094 51.175702, -1.859094 51.169543))",
"POLYGON ((-1.859061 51.175708, -1.612207 51.175708, -1.612207 51.178879, -1.859061 51.178879, -1.859061 51.175708))",
"POLYGON ((-1.884894 51.178880, -1.527604 51.178880, -1.527604 51.213525, -1.884894 51.213525, -1.884894 51.178880))",
"POLYGON ((-1.908018 51.213566, -1.460177 51.213566, -1.460177 51.372446, -1.908018 51.372446, -1.908018 51.213566))",
"POLYGON ((-1.930008 51.372450, -1.642089 51.372450, -1.642089 51.427898, -1.930008 51.427898, -1.930008 51.372450))",
"POLYGON ((-1.859304 51.427899, -1.535138 51.427899, -1.535138 51.562803, -1.859304 51.562803, -1.859304 51.427899))",
"POLYGON ((-1.956575 51.566558, -1.458857 51.566558, -1.458857 51.951655, -1.956575 51.951655, -1.956575 51.566558))",
"POLYGON ((-1.957597 51.951715, -1.458856 51.951715, -1.458856 52.113386, -1.957597 52.113386, -1.957597 51.951715))",
"POLYGON ((-1.968460 52.113388, -1.533113 52.113388, -1.533113 52.348692, -1.968460 52.348692, -1.968460 52.113388))",
"POLYGON ((-1.968847 52.348694, -1.493924 52.348694, -1.493924 52.408017, -1.968847 52.408017, -1.968847 52.348694))",
"POLYGON ((-1.969105 52.408021, -1.459185 52.408021, -1.459185 52.488852, -1.969105 52.488852, -1.969105 52.408021))",
"POLYGON ((-1.967262 52.490053, -1.462879 52.490053, -1.462879 52.657183, -1.967262 52.657183, -1.967262 52.490053))",
"POLYGON ((-1.926244 52.657183, -1.469219 52.657183, -1.469219 52.913349, -1.926244 52.913349, -1.926244 52.657183))",
"POLYGON ((-1.963137 52.913372, -1.459072 52.913372, -1.459072 53.069763, -1.963137 53.069763, -1.963137 52.913372))",
"POLYGON ((-1.968362 53.069787, -1.465571 53.069787, -1.465571 53.368980, -1.968362 53.368980, -1.968362 53.069787))",
"POLYGON ((-1.963475 53.369039, -1.459163 53.369039, -1.459163 53.658496, -1.963475 53.658496, -1.963475 53.369039))",
"POLYGON ((-1.966158 53.658583, -1.462438 53.658583, -1.462438 53.874746, -1.966158 53.874746, -1.966158 53.658583))",
"POLYGON ((-1.921593 53.874746, -1.511358 53.874746, -1.511358 53.953447, -1.921593 53.953447, -1.921593 53.874746))",
"POLYGON ((-1.954284 53.953447, -1.538157 53.953447, -1.538157 54.003359, -1.954284 54.003359, -1.954284 53.953447))",
"POLYGON ((-1.969036 54.003391, -1.469009 54.003391, -1.469009 54.050805, -1.969036 54.050805, -1.969036 54.003391))",
"POLYGON ((-1.963002 54.050807, -1.740110 54.050807, -1.740110 54.061982, -1.963002 54.061982, -1.963002 54.050807))",
"POLYGON ((-1.960424 54.061988, -1.740153 54.061988, -1.740153 54.069086, -1.960424 54.069086, -1.960424 54.061988))",
"POLYGON ((-1.969195 54.069097, -1.717674 54.069097, -1.717674 54.088323, -1.969195 54.088323, -1.969195 54.069097))",
"POLYGON ((-1.969171 54.088324, -1.719416 54.088324, -1.719416 54.098560, -1.969171 54.098560, -1.969171 54.088324))",
"POLYGON ((-1.969137 54.098585, -1.828344 54.098585, -1.828344 54.107277, -1.969137 54.107277, -1.969137 54.098585))",
"POLYGON ((-1.968264 54.107279, -1.553451 54.107279, -1.553451 54.214421, -1.968264 54.214421, -1.968264 54.107279))",
"POLYGON ((-1.968689 54.214421, -1.567742 54.214421, -1.567742 54.514742, -1.968689 54.514742, -1.968689 54.214421))",
"POLYGON ((-1.969043 54.530896, -1.486328 54.530896, -1.486328 54.789336, -1.969043 54.789336, -1.969043 54.530896))",
"POLYGON ((-1.966960 54.789341, -1.467831 54.789341, -1.467831 54.957905, -1.966960 54.957905, -1.966960 54.789341))",
"POLYGON ((-1.966091 54.957969, -1.496056 54.957969, -1.496056 55.290696, -1.966091 55.290696, -1.966091 54.957969))",
"POLYGON ((-1.965999 55.290817, -1.611223 55.290817, -1.611223 55.416349, -1.965999 55.416349, -1.965999 55.290817))",
"POLYGON ((-1.967125 55.416352, -1.592565 55.416352, -1.592565 55.526094, -1.967125 55.526094, -1.967125 55.416352))",
"POLYGON ((-1.961805 55.526101, -1.625207 55.526101, -1.625207 60.298580, -1.961805 60.298580, -1.961805 55.526101))",
"POLYGON ((-1.454342 5.082053, -0.946565 5.082053, -0.946565 37.576412, -1.454342 37.576412, -1.454342 5.082053))",
"POLYGON ((-1.302242 37.576456, -0.822309 37.576456, -0.822309 37.595047, -1.302242 37.595047, -1.302242 37.576456))",
"POLYGON ((-1.332233 37.595055, -0.929630 37.595055, -0.929630 37.605339, -1.332233 37.605339, -1.332233 37.595055))",
"POLYGON ((-1.425012 37.605339, -0.797443 37.605339, -0.797443 37.853383, -1.425012 37.853383, -1.425012 37.605339))",
"POLYGON ((-1.433752 37.853390, -0.764999 37.853390, -0.764999 38.520976, -1.433752 38.520976, -1.433752 37.853390))",
"POLYGON ((-1.454032 38.520976, -0.764413 38.520976, -0.764413 40.213699, -1.454032 40.213699, -1.454032 38.520976))",
"POLYGON ((-1.428552 40.332749, -0.777469 40.332749, -0.777469 41.657504, -1.428552 41.657504, -1.428552 40.332749))",
"POLYGON ((-1.451781 41.657552, -0.881294 41.657552, -0.881294 42.762315, -1.451781 42.762315, -1.451781 41.657552))",
"POLYGON ((-1.452768 42.762350, -0.877565 42.762350, -0.877565 43.162772, -1.452768 43.162772, -1.452768 42.762350))",
"POLYGON ((-1.453782 43.162773, -0.769317 43.162773, -0.769317 43.380068, -1.453782 43.380068, -1.453782 43.162773))",
"POLYGON ((-1.430053 43.380068, -0.765865 43.380068, -0.765865 44.740755, -1.430053 44.740755, -1.430053 43.380068))",
"POLYGON ((-1.123093 44.740763, -0.765240 44.740763, -0.765240 45.561847, -1.123093 45.561847, -1.123093 44.740763))",
"POLYGON ((-1.255070 45.561870, -0.780679 45.561870, -0.780679 45.926531, -1.255070 45.926531, -1.255070 45.561870))",
"POLYGON ((-1.360607 45.926534, -0.804456 45.926534, -0.804456 46.023573, -1.360607 46.023573, -1.360607 45.926534))",
"POLYGON ((-1.324450 46.023580, -0.901736 46.023580, -0.901736 46.155725, -1.324450 46.155725, -1.324450 46.023580))",
"POLYGON ((-1.361190 46.155727, -0.971983 46.155727, -0.971983 46.178693, -1.361190 46.178693, -1.361190 46.155727))",
"POLYGON ((-1.440636 46.178700, -1.169898 46.178700, -1.169898 46.204470, -1.440636 46.204470, -1.440636 46.178700))",
"POLYGON ((-1.449822 46.204635, -0.769585 46.204635, -0.769585 46.594519, -1.449822 46.594519, -1.449822 46.204635))",
"POLYGON ((-1.446450 46.594523, -0.772264 46.594523, -0.772264 46.798326, -1.446450 46.798326, -1.446450 46.594523))",
"POLYGON ((-1.432138 46.798329, -0.826531 46.798329, -0.826531 47.060488, -1.432138 47.060488, -1.432138 46.798329))",
"POLYGON ((-1.443717 47.060497, -0.769802 47.060497, -0.769802 47.290756, -1.443717 47.290756, -1.443717 47.060497))",
"POLYGON ((-1.454722 47.290756, -0.811746 47.290756, -0.811746 47.427306, -1.454722 47.427306, -1.454722 47.290756))",
"POLYGON ((-1.436804 47.427307, -0.828716 47.427307, -0.828716 47.569402, -1.436804 47.569402, -1.436804 47.427307))",
"POLYGON ((-1.274359 47.569429, -0.881158 47.569429, -0.881158 47.675122, -1.274359 47.675122, -1.274359 47.569429))",
"POLYGON ((-1.374231 47.675125, -0.823854 47.675125, -0.823854 47.811256, -1.374231 47.811256, -1.374231 47.675125))",
"POLYGON ((-1.363484 47.811256, -0.762597 47.811256, -0.762597 48.067625, -1.363484 48.067625, -1.363484 47.811256))",
"POLYGON ((-0.775749 48.067628, -0.770003 48.067628, -0.770003 48.069862, -0.775749 48.069862, -0.775749 48.067628))",
"POLYGON ((-1.421683 48.069873, -0.773471 48.069873, -0.773471 48.353969, -1.421683 48.353969, -1.421683 48.069873))",
"POLYGON ((-1.455060 48.353969, -0.834699 48.353969, -0.834699 48.687066, -1.455060 48.687066, -1.455060 48.353969))",
"POLYGON ((-1.445745 48.687073, -0.812050 48.687073, -0.812050 49.049079, -1.445745 49.049079, -1.445745 48.687073))",
"POLYGON ((-1.458842 49.049080, -0.840512 49.049080, -0.840512 49.179450, -1.458842 49.179450, -1.458842 49.049080))",
"POLYGON ((-1.431317 49.179451, -0.785554 49.179451, -0.785554 49.360202, -1.431317 49.360202, -1.431317 49.179451))",
"POLYGON ((-1.359602 49.360208, -0.847079 49.360208, -0.847079 49.396549, -1.359602 49.396549, -1.359602 49.360208))",
"POLYGON ((-1.450818 49.396558, -0.988100 49.396558, -0.988100 49.481047, -1.450818 49.481047, -1.450818 49.396558))",
"POLYGON ((-1.345911 49.481058, -1.145416 49.481058, -1.145416 49.575892, -1.345911 49.575892, -1.345911 49.481058))",
"POLYGON ((-1.458743 49.575897, -1.239740 49.575897, -1.239740 49.689678, -1.458743 49.689678, -1.458743 49.575897))",
"POLYGON ((-1.426947 49.689678, -1.083640 49.689678, -1.083640 50.724518, -1.426947 50.724518, -1.426947 49.689678))",
"POLYGON ((-1.301497 50.724554, -0.765065 50.724554, -0.765065 50.814682, -1.301497 50.814682, -1.301497 50.724554))",
"POLYGON ((-1.450796 50.814684, -0.768606 50.814684, -0.768606 50.856786, -1.450796 50.856786, -1.450796 50.814684))",
"POLYGON ((-1.407302 50.856786, -1.013062 50.856786, -1.013062 50.902651, -1.407302 50.902651, -1.407302 50.856786))",
"POLYGON ((-1.418655 50.902652, -0.801137 50.902652, -0.801137 51.061287, -1.418655 51.061287, -1.418655 50.902652))",
"POLYGON ((-1.458015 51.061289, -0.775248 51.061289, -0.775248 51.269067, -1.458015 51.269067, -1.458015 51.061289))",
"POLYGON ((-1.361315 51.269075, -0.968080 51.269075, -0.968080 51.358889, -1.361315 51.358889, -1.361315 51.269075))",
"POLYGON ((-1.338658 51.358894, -0.762759 51.358894, -0.762759 51.545104, -1.338658 51.545104, -1.338658 51.358894))",
"POLYGON ((-1.458660 51.545105, -0.778212 51.545105, -0.778212 51.835466, -1.458660 51.835466, -1.458660 51.545105))",
"POLYGON ((-1.458747 51.835587, -0.764543 51.835587, -0.764543 52.018381, -1.458747 52.018381, -1.458747 51.835587))",
"POLYGON ((-1.445266 52.018508, -0.766403 52.018508, -0.766403 52.284476, -1.445266 52.284476, -1.445266 52.018508))",
"POLYGON ((-1.322009 52.284542, -1.182866 52.284542, -1.182866 52.398311, -1.322009 52.398311, -1.322009 52.284542))",
"POLYGON ((-1.458570 52.398601, -0.779221 52.398601, -0.779221 52.449792, -1.458570 52.449792, -1.458570 52.398601))",
"POLYGON ((-1.447235 52.449808, -0.823349 52.449808, -0.823349 52.724725, -1.447235 52.724725, -1.447235 52.449808))",
"POLYGON ((-1.073861 52.724736, -0.867070 52.724736, -0.867070 52.752911, -1.073861 52.752911, -1.073861 52.724736))",
"POLYGON ((-1.457174 52.752928, -0.870134 52.752928, -0.870134 52.819225, -1.457174 52.819225, -1.457174 52.752928))",
"POLYGON ((-1.457120 52.819274, -0.834176 52.819274, -0.834176 52.929019, -1.457120 52.929019, -1.457120 52.819274))",
"POLYGON ((-1.415134 52.929020, -0.856407 52.929020, -0.856407 53.029845, -1.415134 53.029845, -1.415134 52.929020))",
"POLYGON ((-1.434931 53.029862, -0.762851 53.029862, -0.762851 53.226963, -1.434931 53.226963, -1.434931 53.029862))",
"POLYGON ((-1.436915 53.227162, -0.762895 53.227162, -0.762895 53.474072, -1.436915 53.474072, -1.436915 53.227162))",
"POLYGON ((-1.458428 53.474097, -0.866191 53.474097, -0.866191 53.851571, -1.458428 53.851571, -1.458428 53.474097))",
"POLYGON ((-1.458814 53.851578, -0.935146 53.851578, -0.935146 53.938964, -1.458814 53.938964, -1.458814 53.851578))",
"POLYGON ((-1.307351 53.939094, -1.066699 53.939094, -1.066699 53.955500, -1.307351 53.955500, -1.307351 53.939094))",
"POLYGON ((-1.312162 53.955634, -1.070670 53.955634, -1.070670 53.962556, -1.312162 53.962556, -1.312162 53.955634))",
"POLYGON ((-1.385123 53.962556, -0.772475 53.962556, -0.772475 54.245082, -1.385123 54.245082, -1.385123 53.962556))",
"POLYGON ((-1.444467 54.245156, -0.764033 54.245156, -0.764033 55.014685, -1.444467 55.014685, -1.444467 54.245156))",
"POLYGON ((-1.422320 55.014895, -0.849438 55.014895, -0.849438 60.706705, -1.422320 60.706705, -1.422320 55.014895))",
"POLYGON ((-0.720770 35.189233, -0.475171 35.189233, -0.475171 38.310522, -0.720770 38.310522, -0.720770 35.189233))",
"POLYGON ((-0.733155 38.310580, -0.427397 38.310580, -0.427397 38.422480, -0.733155 38.422480, -0.733155 38.310580))",
"POLYGON ((-0.682397 38.422607, -0.264849 38.422607, -0.264849 38.982537, -0.682397 38.982537, -0.682397 38.422607))",
"POLYGON ((-0.595670 38.982537, -0.299492 38.982537, -0.299492 39.486391, -0.595670 39.486391, -0.595670 38.982537))",
"POLYGON ((-0.458399 39.486406, -0.368334 39.486406, -0.368334 39.502889, -0.458399 39.502889, -0.458399 39.486406))",
"POLYGON ((-0.753608 39.502894, -0.253187 39.502894, -0.253187 41.022836, -0.753608 41.022836, -0.753608 39.502894))",
"POLYGON ((-0.750317 41.022843, -0.343977 41.022843, -0.343977 42.153701, -0.750317 42.153701, -0.750317 41.022843))",
"POLYGON ((-0.720162 42.153705, -0.261511 42.153705, -0.261511 42.993208, -0.720162 42.993208, -0.720162 42.153705))",
"POLYGON ((-0.757529 42.993216, -0.288717 42.993216, -0.288717 43.336743, -0.757529 43.336743, -0.757529 42.993216))",
"POLYGON ((-0.734935 43.336744, -0.261699 43.336744, -0.261699 43.765013, -0.734935 43.765013, -0.734935 43.336744))",
"POLYGON ((-0.588137 43.765044, -0.269362 43.765044, -0.269362 44.663661, -0.588137 44.663661, -0.588137 43.765044))",
"POLYGON ((-0.661258 44.663667, -0.309942 44.663667, -0.309942 44.774109, -0.661258 44.774109, -0.661258 44.663667))",
"POLYGON ((-0.656504 44.774118, -0.282215 44.774118, -0.282215 44.832311, -0.656504 44.832311, -0.656504 44.774118))",
"POLYGON ((-0.647790 44.832318, -0.342583 44.832318, -0.342583 44.848667, -0.647790 44.848667, -0.647790 44.832318))",
"POLYGON ((-0.725545 44.848668, -0.257019 44.848668, -0.257019 44.901189, -0.725545 44.901189, -0.725545 44.848668))",
"POLYGON ((-0.738492 44.901192, -0.467794 44.901192, -0.467794 44.980809, -0.738492 44.980809, -0.738492 44.901192))",
"POLYGON ((-0.743444 44.980820, -0.451814 44.980820, -0.451814 45.145699, -0.743444 45.145699, -0.743444 44.980820))",
"POLYGON ((-0.735185 45.145700, -0.303999 45.145700, -0.303999 45.578297, -0.735185 45.578297, -0.735185 45.145700))",
"POLYGON ((-0.752155 45.578301, -0.256937 45.578301, -0.256937 45.743289, -0.752155 45.743289, -0.752155 45.578301))",
"POLYGON ((-0.748706 45.743289, -0.322044 45.743289, -0.322044 45.889162, -0.748706 45.889162, -0.748706 45.743289))",
"POLYGON ((-0.597186 45.889162, -0.354831 45.889162, -0.354831 46.067788, -0.597186 46.067788, -0.597186 45.889162))",
"POLYGON ((-0.750989 46.067791, -0.252983 46.067791, -0.252983 46.328976, -0.750989 46.328976, -0.750989 46.067791))",
"POLYGON ((-0.749633 46.328978, -0.280279 46.328978, -0.280279 46.526224, -0.749633 46.526224, -0.749633 46.328978))",
"POLYGON ((-0.658865 46.526225, -0.252801 46.526225, -0.252801 46.756418, -0.658865 46.756418, -0.658865 46.526225))",
"POLYGON ((-0.757668 46.756423, -0.392250 46.756423, -0.392250 46.932308, -0.757668 46.932308, -0.757668 46.756423))",
"POLYGON ((-0.742833 46.932326, -0.348342 46.932326, -0.348342 47.118668, -0.742833 47.118668, -0.742833 46.932326))",
"POLYGON ((-0.733118 47.118668, -0.257272 47.118668, -0.257272 47.212361, -0.733118 47.212361, -0.733118 47.118668))",
"POLYGON ((-0.731101 47.212366, -0.275219 47.212366, -0.275219 47.231238, -0.731101 47.231238, -0.731101 47.212366))",
"POLYGON ((-0.728920 47.231239, -0.275797 47.231239, -0.275797 47.469405, -0.728920 47.469405, -0.728920 47.231239))",
"POLYGON ((-0.561592 47.469406, -0.558290 47.469406, -0.558290 47.470274, -0.561592 47.470274, -0.561592 47.469406))",
"POLYGON ((-0.728269 47.470288, -0.300294 47.470288, -0.300294 47.539344, -0.728269 47.539344, -0.728269 47.470288))",
"POLYGON ((-0.717238 47.539344, -0.276701 47.539344, -0.276701 47.946179, -0.717238 47.946179, -0.717238 47.539344))",
"POLYGON ((-0.762596 47.946184, -0.337264 47.946184, -0.337264 48.308592, -0.762596 48.308592, -0.762596 47.946184))",
"POLYGON ((-0.709838 48.308604, -0.276586 48.308604, -0.276586 48.873425, -0.709838 48.873425, -0.709838 48.308604))",
"POLYGON ((-0.705785 48.873916, -0.270463 48.873916, -0.270463 49.186664, -0.705785 49.186664, -0.705785 48.873916))",
"POLYGON ((-0.697284 49.186664, -0.253344 49.186664, -0.253344 49.272019, -0.697284 49.272019, -0.697284 49.186664))",
"POLYGON ((-0.510549 49.272053, -0.254998 49.272053, -0.254998 49.291680, -0.510549 49.291680, -0.510549 49.272053))",
"POLYGON ((-0.683604 49.291707, -0.280884 49.291707, -0.280884 49.338264, -0.683604 49.338264, -0.683604 49.291707))",
"POLYGON ((-0.709920 49.338273, -0.492934 49.338273, -0.492934 49.345925, -0.709920 49.345925, -0.709920 49.338273))",
"POLYGON ((-0.732299 49.345925, -0.295429 49.345925, -0.295429 50.904099, -0.732299 50.904099, -0.732299 49.345925))",
"POLYGON ((-0.743885 50.904122, -0.282608 50.904122, -0.282608 51.149718, -0.743885 51.149718, -0.743885 50.904122))",
"POLYGON ((-0.760212 51.150037, -0.261813 51.150037, -0.261813 51.272663, -0.760212 51.272663, -0.760212 51.150037))",
"POLYGON ((-0.762374 51.272900, -0.292330 51.272900, -0.292330 51.429746, -0.762374 51.429746, -0.762374 51.272900))",
"POLYGON ((-0.720418 51.429749, -0.258480 51.429749, -0.258480 51.500376, -0.720418 51.500376, -0.720418 51.429749))",
"POLYGON ((-0.757197 51.500436, -0.275608 51.500436, -0.275608 51.747415, -0.757197 51.747415, -0.757197 51.500436))",
"POLYGON ((-0.740340 51.747437, -0.265215 51.747437, -0.265215 52.008250, -0.740340 52.008250, -0.740340 51.747437))",
"POLYGON ((-0.761031 52.008253, -0.252968 52.008253, -0.252968 52.511851, -0.761031 52.511851, -0.761031 52.008253))",
"POLYGON ((-0.747169 52.511852, -0.253718 52.511852, -0.253718 52.635249, -0.747169 52.635249, -0.747169 52.511852))",
"POLYGON ((-0.728228 52.635453, -0.272873 52.635453, -0.272873 52.688314, -0.728228 52.688314, -0.728228 52.635453))",
"POLYGON ((-0.655693 52.688380, -0.320990 52.688380, -0.320990 52.799770, -0.655693 52.799770, -0.655693 52.688380))",
"POLYGON ((-0.620524 52.799816, -0.317734 52.799816, -0.317734 52.903154, -0.620524 52.903154, -0.620524 52.799816))",
"POLYGON ((-0.671654 52.903240, -0.255152 52.903240, -0.255152 52.997471, -0.671654 52.997471, -0.671654 52.903240))",
"POLYGON ((-0.641164 52.997477, -0.258095 52.997477, -0.258095 53.091921, -0.641164 53.091921, -0.641164 52.997477))",
"POLYGON ((-0.762565 53.092120, -0.299452 53.092120, -0.299452 53.141188, -0.762565 53.141188, -0.762565 53.092120))",
"POLYGON ((-0.706580 53.141264, -0.330096 53.141264, -0.330096 53.174884, -0.706580 53.174884, -0.706580 53.141264))",
"POLYGON ((-0.627609 53.174884, -0.288419 53.174884, -0.288419 53.211619, -0.627609 53.211619, -0.627609 53.174884))",
"POLYGON ((-0.547558 53.211636, -0.330979 53.211636, -0.330979 53.234255, -0.547558 53.234255, -0.547558 53.211636))",
"POLYGON ((-0.551378 53.234266, -0.454294 53.234266, -0.454294 53.248060, -0.551378 53.248060, -0.551378 53.234266))",
"POLYGON ((-0.760424 53.248180, -0.291912 53.248180, -0.291912 53.340296, -0.760424 53.340296, -0.760424 53.248180))",
"POLYGON ((-0.596724 53.340341, -0.254142 53.340341, -0.254142 53.501745, -0.596724 53.501745, -0.596724 53.340341))",
"POLYGON ((-0.612932 53.501797, -0.308804 53.501797, -0.308804 53.655175, -0.612932 53.655175, -0.612932 53.501797))",
"POLYGON ((-0.718449 53.655175, -0.308826 53.655175, -0.308826 54.039586, -0.718449 54.039586, -0.718449 53.655175))",
"POLYGON ((-0.762478 54.040183, -0.290151 54.040183, -0.290151 54.501616, -0.762478 54.501616, -0.762478 54.040183))",
"POLYGON ((-0.246164 5.533486, 0.261151 5.533486, 0.261151 38.995372, -0.246164 38.995372, -0.246164 5.533486))",
"POLYGON ((-0.251175 38.995374, 0.238011 38.995374, 0.238011 42.318655, -0.251175 42.318655, -0.251175 38.995374))",
"POLYGON ((-0.252609 42.318753, 0.249066 42.318753, 0.249066 42.971801, -0.252609 42.971801, -0.252609 42.318753))",
"POLYGON ((-0.246514 42.971802, 0.258406 42.971802, 0.258406 43.579287, -0.246514 43.579287, -0.246514 42.971802))",
"POLYGON ((-0.209868 43.579287, 0.272182 43.579287, 0.272182 44.431254, -0.209868 44.431254, -0.209868 43.579287))",
"POLYGON ((-0.246927 44.431256, 0.255485 44.431256, 0.255485 44.891962, -0.246927 44.891962, -0.246927 44.431256))",
"POLYGON ((-0.243611 44.891965, 0.214385 44.891965, 0.214385 45.399795, -0.243611 45.399795, -0.243611 44.891965))",
"POLYGON ((-0.250710 45.399803, 0.255896 45.399803, 0.255896 45.699082, -0.250710 45.699082, -0.250710 45.399803))",
"POLYGON ((-0.242644 45.699083, 0.270712 45.699083, 0.270712 46.041199, -0.242644 46.041199, -0.242644 45.699083))",
"POLYGON ((-0.249160 46.041206, 0.173694 46.041206, 0.173694 46.200142, -0.249160 46.200142, -0.249160 46.041206))",
"POLYGON ((-0.245191 46.200176, 0.116520 46.200176, 0.116520 46.261229, -0.245191 46.261229, -0.245191 46.200176))",
"POLYGON ((-0.249036 46.261233, 0.071255 46.261233, 0.071255 46.373246, -0.249036 46.373246, -0.249036 46.261233))",
"POLYGON ((-0.205759 46.373274, 0.232654 46.373274, 0.232654 46.531650, -0.205759 46.531650, -0.205759 46.373274))",
"POLYGON ((-0.252791 46.531662, 0.226815 46.531662, 0.226815 46.648561, -0.252791 46.648561, -0.252791 46.531662))",
"POLYGON ((-0.252794 46.648561, -0.247192 46.648561, -0.247192 46.652020, -0.252794 46.652020, -0.252794 46.648561))",
"POLYGON ((-0.252766 46.652025, 0.199712 46.652025, 0.199712 46.718450, -0.252766 46.718450, -0.252766 46.652025))",
"POLYGON ((-0.251774 46.718454, 0.250976 46.718454, 0.250976 46.925077, -0.251774 46.925077, -0.251774 46.718454))",
"POLYGON ((-0.218262 46.925078, 0.257382 46.925078, 0.257382 47.036134, -0.218262 47.036134, -0.218262 46.925078))",
"POLYGON ((-0.228522 47.036136, 0.256424 47.036136, 0.256424 47.110567, -0.228522 47.110567, -0.228522 47.036136))",
"POLYGON ((-0.155432 47.110570, 0.247074 47.110570, 0.247074 47.133080, -0.155432 47.133080, -0.155432 47.110570))",
"POLYGON ((-0.155376 47.133080, 0.239409 47.133080, 0.239409 47.174359, -0.155376 47.174359, -0.155376 47.133080))",
"POLYGON ((-0.190957 47.174361, 0.257091 47.174361, 0.257091 47.188278, -0.190957 47.188278, -0.190957 47.174361))",
"POLYGON ((-0.090055 47.188278, 0.264049 47.188278, 0.264049 47.256883, -0.090055 47.256883, -0.090055 47.188278))",
"POLYGON ((-0.202131 47.256889, 0.258385 47.256889, 0.258385 47.330413, -0.202131 47.330413, -0.202131 47.256889))",
"POLYGON ((-0.219559 47.330413, 0.234180 47.330413, 0.234180 47.551072, -0.219559 47.551072, -0.219559 47.330413))",
"POLYGON ((-0.246746 47.551073, 0.258792 47.551073, 0.258792 47.678070, -0.246746 47.678070, -0.246746 47.551073))",
"POLYGON ((-0.181952 47.678075, 0.268321 47.678075, 0.268321 47.927187, -0.181952 47.927187, -0.181952 47.678075))",
"POLYGON ((-0.063196 47.927194, 0.224671 47.927194, 0.224671 48.008668, -0.063196 48.008668, -0.063196 47.927194))",
"POLYGON ((-0.102684 48.008671, 0.248732 48.008671, 0.248732 48.082629, -0.102684 48.082629, -0.102684 48.008671))",
"POLYGON ((-0.232535 48.085762, 0.253728 48.085762, 0.253728 48.349669, -0.232535 48.349669, -0.232535 48.085762))",
"POLYGON ((-0.247584 48.349713, 0.267517 48.349713, 0.267517 48.432017, -0.247584 48.432017, -0.247584 48.349713))",
"POLYGON ((-0.212221 48.432059, 0.215826 48.432059, 0.215826 48.743485, -0.212221 48.743485, -0.212221 48.432059))",
"POLYGON ((-0.248393 48.743510, 0.229771 48.743510, 0.229771 48.893654, -0.248393 48.893654, -0.248393 48.743510))",
"POLYGON ((-0.245307 48.893656, 0.261872 48.893656, 0.261872 48.986908, -0.245307 48.986908, -0.245307 48.893656))",
"POLYGON ((-0.247867 48.986915, 0.251648 48.986915, 0.251648 49.281642, -0.247867 49.281642, -0.247867 48.986915))",
"POLYGON ((-0.252594 49.281651, 0.182417 49.281651, 0.182417 49.317503, -0.252594 49.317503, -0.252594 49.281651))",
"POLYGON ((0.021359 49.317506, 0.139958 49.317506, 0.139958 49.397744, 0.021359 49.397744, 0.021359 49.317506))",
"POLYGON ((0.067040 49.398283, 0.247069 49.398283, 0.247069 49.524578, 0.067040 49.524578, 0.067040 49.398283))",
"POLYGON ((0.071928 49.524592, 0.193196 49.524592, 0.193196 49.670959, 0.071928 49.670959, 0.071928 49.524592))",
"POLYGON ((-0.152548 49.670960, 0.206986 49.670960, 0.206986 50.819396, -0.152548 50.819396, -0.152548 49.670960))",
"POLYGON ((-0.250735 50.823366, 0.189658 50.823366, 0.189658 51.242319, -0.250735 51.242319, -0.250735 50.823366))",
"POLYGON ((-0.242679 51.242356, 0.220620 51.242356, 0.220620 51.460877, -0.242679 51.460877, -0.242679 51.242356))",
"POLYGON ((-0.194970 51.460898, 0.129564 51.460898, 0.129564 51.501891, -0.194970 51.501891, -0.194970 51.460898))",
"POLYGON ((-0.227703 51.501892, -0.055900 51.501892, -0.055900 51.507324, -0.227703 51.507324, -0.227703 51.501892))",
"POLYGON ((-0.128086 51.507325, -0.074704 51.507325, -0.074704 51.508121, -0.128086 51.508121, -0.128086 51.507325))",
"POLYGON ((-0.112879 51.508121, 0.086412 51.508121, 0.086412 51.511947, -0.112879 51.511947, -0.112879 51.508121))",
"POLYGON ((-0.201772 51.511953, 0.087828 51.511953, 0.087828 51.517281, -0.201772 51.517281, -0.201772 51.511953))",
"POLYGON ((-0.215777 51.517284, 0.190223 51.517284, 0.190223 51.536259, -0.215777 51.536259, -0.215777 51.517284))",
"POLYGON ((-0.223964 51.536411, 0.268981 51.536411, 0.268981 51.713640, -0.223964 51.713640, -0.223964 51.536411))",
"POLYGON ((-0.240798 51.714135, 0.178157 51.714135, 0.178157 51.897734, -0.240798 51.897734, -0.240798 51.714135))",
"POLYGON ((-0.203399 51.897779, 0.202737 51.897779, 0.202737 52.053281, -0.203399 52.053281, -0.203399 51.897779))",
"POLYGON ((-0.243466 52.053281, 0.260611 52.053281, 0.260611 52.162959, -0.243466 52.162959, -0.243466 52.053281))",
"POLYGON ((-0.128369 52.163077, 0.271950 52.163077, 0.271950 52.267340, -0.128369 52.267340, -0.128369 52.163077))",
"POLYGON ((-0.252367 52.267476, 0.198728 52.267476, 0.198728 52.560249, -0.252367 52.560249, -0.252367 52.267476))",
"POLYGON ((-0.252680 52.560362, 0.143746 52.560362, 0.143746 52.711275, -0.252680 52.711275, -0.252680 52.560362))",
"POLYGON ((-0.136949 52.712141, 0.147724 52.712141, 0.147724 52.927351, -0.136949 52.927351, -0.136949 52.712141))",
"POLYGON ((-0.227945 52.927379, 0.267456 52.927379, 0.267456 53.159519, -0.227945 53.159519, -0.227945 52.927379))",
"POLYGON ((-0.054583 53.159521, 0.017448 53.159521, 0.017448 53.166659, -0.054583 53.166659, -0.054583 53.159521))",
"POLYGON ((-0.224313 53.166667, 0.166986 53.166667, 0.166986 53.232247, -0.224313 53.232247, -0.224313 53.166667))",
"POLYGON ((-0.227146 53.232258, 0.115452 53.232258, 0.115452 53.254771, -0.227146 53.254771, -0.227146 53.232258))",
"POLYGON ((-0.171630 53.254772, -0.009457 53.254772, -0.009457 53.274833, -0.171630 53.274833, -0.171630 53.254772))",
"POLYGON ((-0.250777 53.274883, -0.046569 53.274883, -0.046569 53.347600, -0.250777 53.347600, -0.250777 53.274883))",
"POLYGON ((-0.252509 53.348174, 0.087580 53.348174, 0.087580 53.975904, -0.252509 53.975904, -0.252509 53.348174))",
"POLYGON ((0.272222 5.921619, 1.249642 5.921619, 1.249642 41.035171, 0.272222 41.035171, 0.272222 5.921619))",
"POLYGON ((0.386299 41.035171, 1.258115 41.035171, 1.258115 41.145165, 0.386299 41.145165, 0.386299 41.035171))",
"POLYGON ((0.296010 41.145165, 1.243918 41.145165, 1.243918 41.376963, 0.296010 41.376963, 0.296010 41.145165))",
"POLYGON ((0.318170 41.376977, 1.257154 41.376977, 1.257154 41.617620, 0.318170 41.617620, 0.318170 41.376977))",
"POLYGON ((0.414692 41.617620, 1.230129 41.617620, 1.230129 41.982547, 0.414692 41.982547, 0.414692 41.617620))",
"POLYGON ((0.275291 41.982581, 1.203078 41.982581, 1.203078 42.104886, 0.275291 42.104886, 0.275291 41.982581))",
"POLYGON ((0.299875 42.104891, 1.243745 42.104891, 1.243745 42.517390, 0.299875 42.517390, 0.299875 42.104891))",
"POLYGON ((0.316264 42.517390, 1.214569 42.517390, 1.214569 43.002944, 0.316264 43.002944, 0.316264 42.517390))",
"POLYGON ((0.276226 43.002947, 1.239913 43.002947, 1.239913 43.188604, 0.276226 43.188604, 0.276226 43.002947))",
"POLYGON ((0.290573 43.188611, 1.253687 43.188611, 1.253687 43.574745, 0.290573 43.574745, 0.290573 43.188611))",
"POLYGON ((0.311957 43.574752, 1.241669 43.574752, 1.241669 43.872274, 0.311957 43.872274, 0.311957 43.574752))",
"POLYGON ((0.274941 43.872289, 1.189781 43.872289, 1.189781 44.102722, 0.274941 44.102722, 0.274941 43.872289))",
"POLYGON ((0.272185 44.102742, 1.154888 44.102742, 1.154888 44.206927, 0.272185 44.206927, 0.272185 44.102742))",
"POLYGON ((0.293776 44.206927, 1.234257 44.206927, 1.234257 44.796565, 0.293776 44.796565, 0.293776 44.206927))",
"POLYGON ((0.272694 44.796589, 1.210983 44.796589, 1.210983 45.077936, 0.272694 45.077936, 0.272694 44.796589))",
"POLYGON ((0.431592 45.077953, 1.139435 45.077953, 1.139435 45.240501, 0.431592 45.240501, 0.431592 45.077953))",
"POLYGON ((0.340270 45.240506, 1.145670 45.240506, 1.145670 45.363777, 0.340270 45.363777, 0.340270 45.240506))",
"POLYGON ((0.277859 45.363777, 1.245366 45.363777, 1.245366 45.493056, 0.277859 45.493056, 0.277859 45.363777))",
"POLYGON ((0.285168 45.493057, 1.210109 45.493057, 1.210109 45.670124, 0.285168 45.670124, 0.285168 45.493057))",
"POLYGON ((0.302836 45.671072, 1.214353 45.671072, 1.214353 45.820886, 0.302836 45.820886, 0.302836 45.671072))",
"POLYGON ((0.355839 45.820896, 1.098847 45.820896, 1.098847 45.929376, 0.355839 45.929376, 0.355839 45.820896))",
"POLYGON ((0.378576 45.929394, 1.215769 45.929394, 1.215769 46.152780, 0.378576 46.152780, 0.378576 45.929394))",
"POLYGON ((0.324769 46.152783, 1.249232 46.152783, 1.249232 46.374903, 0.324769 46.374903, 0.324769 46.152783))",
"POLYGON ((0.278782 46.374905, 1.191780 46.374905, 1.191780 46.480116, 0.278782 46.480116, 0.278782 46.374905))",
"POLYGON ((0.273752 46.480123, 1.254832 46.480123, 1.254832 46.568778, 0.273752 46.568778, 0.273752 46.480123))",
"POLYGON ((0.275268 46.568850, 1.091724 46.568850, 1.091724 46.579116, 0.275268 46.579116, 0.275268 46.568850))",
"POLYGON ((0.297611 46.579117, 1.062953 46.579117, 1.062953 46.687021, 0.297611 46.687021, 0.297611 46.579117))",
"POLYGON ((0.311337 46.687024, 1.129498 46.687024, 1.129498 46.766962, 0.311337 46.766962, 0.311337 46.687024))",
"POLYGON ((0.341217 46.766965, 0.987361 46.766965, 0.987361 46.843706, 0.341217 46.843706, 0.341217 46.766965))",
"POLYGON ((0.293283 46.843711, 1.070494 46.843711, 1.070494 46.884070, 0.293283 46.884070, 0.293283 46.843711))",
"POLYGON ((0.316693 46.884071, 0.990605 46.884071, 0.990605 46.963209, 0.316693 46.963209, 0.316693 46.884071))",
"POLYGON ((0.290168 46.963212, 1.139533 46.963212, 1.139533 47.017980, 0.290168 47.017980, 0.290168 46.963212))",
"POLYGON ((0.311545 47.017994, 1.139605 47.017994, 1.139605 47.124116, 0.311545 47.124116, 0.311545 47.017994))",
"POLYGON ((0.994071 47.124123, 1.018906 47.124123, 1.018906 47.129263, 0.994071 47.129263, 0.994071 47.124123))",
"POLYGON ((0.290763 47.129276, 1.201519 47.129276, 1.201519 47.249758, 0.290763 47.249758, 0.290763 47.129276))",
"POLYGON ((0.290718 47.249764, 1.141473 47.249764, 1.141473 47.324956, 0.290718 47.324956, 0.290718 47.249764))",
"POLYGON ((0.406032 47.324960, 1.187221 47.324960, 1.187221 47.367881, 0.406032 47.367881, 0.406032 47.324960))",
"POLYGON ((0.552927 47.367883, 0.838913 47.367883, 0.838913 47.394779, 0.552927 47.394779, 0.552927 47.367883))",
"POLYGON ((0.379765 47.394954, 1.229185 47.394954, 1.229185 47.411826, 0.379765 47.411826, 0.379765 47.394954))",
"POLYGON ((0.423343 47.411833, 1.229068 47.411833, 1.229068 47.479364, 0.423343 47.479364, 0.423343 47.411833))",
"POLYGON ((0.297539 47.479364, 1.194510 47.479364, 1.194510 47.596130, 0.297539 47.596130, 0.297539 47.479364))",
"POLYGON ((0.326926 47.608832, 1.229116 47.608832, 1.229116 47.791633, 0.326926 47.791633, 0.326926 47.608832))",
"POLYGON ((0.286776 47.791639, 1.183782 47.791639, 1.183782 47.940598, 0.286776 47.940598, 0.286776 47.791639))",
"POLYGON ((0.274107 47.941693, 0.843048 47.941693, 0.843048 48.037013, 0.274107 48.037013, 0.274107 47.941693))",
"POLYGON ((0.283058 48.037019, 1.133847 48.037019, 1.133847 48.129426, 0.283058 48.129426, 0.283058 48.037019))",
"POLYGON ((0.312592 48.129459, 1.168241 48.129459, 1.168241 48.252528, 0.312592 48.252528, 0.312592 48.129459))",
"POLYGON ((0.312514 48.252544, 1.239673 48.252544, 1.239673 48.483495, 0.312514 48.483495, 0.312514 48.252544))",
"POLYGON ((0.280633 48.483496, 1.258047 48.483496, 1.258047 49.168664, 0.280633 49.168664, 0.280633 48.483496))",
"POLYGON ((0.328442 49.168674, 1.235185 49.168674, 1.235185 49.339662, 0.328442 49.339662, 0.328442 49.168674))",
"POLYGON ((0.301036 49.339691, 1.186460 49.339691, 1.186460 49.440956, 0.301036 49.440956, 0.301036 49.339691))",
"POLYGON ((0.536028 49.440963, 1.196418 49.440963, 1.196418 49.531214, 0.536028 49.531214, 0.536028 49.440963))",
"POLYGON ((0.304777 49.531225, 1.139632 49.531225, 1.139632 49.794524, 0.304777 49.794524, 0.304777 49.531225))",
"POLYGON ((0.584821 49.794535, 1.177548 49.794535, 1.177548 49.925004, 0.584821 49.925004, 0.584821 49.794535))",
"POLYGON ((0.331193 49.925006, 1.211765 49.925006, 1.211765 50.955443, 0.331193 50.955443, 0.331193 49.925006))",
"POLYGON ((0.543150 50.955477, 1.198004 50.955477, 1.198004 51.087403, 0.543150 51.087403, 0.543150 50.955477))",
"POLYGON ((0.272215 51.087701, 1.257734 51.087701, 1.257734 51.242972, 0.272215 51.242972, 0.272215 51.087701))",
"POLYGON ((0.372480 51.242993, 1.200007 51.242993, 1.200007 51.380588, 0.372480 51.380588, 0.372480 51.242993))",
"POLYGON ((0.372740 51.380588, 0.745479 51.380588, 0.745479 51.464785, 0.372740 51.464785, 0.372740 51.380588))",
"POLYGON ((0.310506 51.464855, 1.140410 51.464855, 1.140410 51.823959, 0.310506 51.823959, 0.310506 51.464855))",
"POLYGON ((0.301986 51.823976, 1.158533 51.823976, 1.158533 52.082385, 0.301986 52.082385, 0.301986 51.823976))",
"POLYGON ((0.272704 52.082386, 1.246286 52.082386, 1.246286 52.470998, 0.272704 52.470998, 0.272704 52.082386))",
"POLYGON ((0.372230 52.470998, 1.243894 52.470998, 1.243894 52.766897, 0.372230 52.766897, 0.372230 52.470998))",
"POLYGON ((0.283111 52.771549, 1.213532 52.771549, 1.213532 53.314546, 0.283111 53.314546, 0.283111 52.771549))",
"POLYGON ((1.264790 6.203719, 2.134151 6.203719, 2.134151 36.556896, 1.264790 36.556896, 1.264790 6.203719))",
"POLYGON ((1.258127 36.556901, 2.149194 36.556901, 2.149194 41.131474, 1.258127 41.131474, 1.258127 36.556901))",
"POLYGON ((1.318907 41.131978, 1.992140 41.131978, 1.992140 41.287977, 1.318907 41.287977, 1.318907 41.131978))",
"POLYGON ((1.456942 41.288668, 2.150684 41.288668, 2.150684 41.406957, 1.456942 41.406957, 1.456942 41.288668))",
"POLYGON ((1.337784 41.406959, 2.150771 41.406959, 2.150771 41.598563, 1.337784 41.598563, 1.337784 41.406959))",
"POLYGON ((1.268659 41.598601, 2.143593 41.598601, 2.143593 41.733623, 1.268659 41.733623, 1.268659 41.598601))",
"POLYGON ((1.288820 41.733774, 2.028048 41.733774, 2.028048 41.935809, 1.288820 41.935809, 1.288820 41.733774))",
"POLYGON ((1.261956 41.935812, 1.988491 41.935812, 1.988491 42.246253, 1.261956 42.246253, 1.261956 41.935812))",
"POLYGON ((1.314851 42.246283, 2.061067 42.246283, 2.061067 42.965528, 1.314851 42.965528, 1.314851 42.246283))",
"POLYGON ((1.269402 42.965547, 2.143041 42.965547, 2.143041 43.330810, 1.269402 43.330810, 1.269402 42.965547))",
"POLYGON ((1.260907 43.330844, 2.133310 43.330844, 2.133310 43.452962, 1.260907 43.452962, 1.260907 43.330844))",
"POLYGON ((1.322254 43.452997, 1.970100 43.452997, 1.970100 43.526576, 1.322254 43.526576, 1.322254 43.452997))",
"POLYGON ((1.271174 43.526584, 2.103742 43.526584, 2.103742 43.596581, 1.271174 43.596581, 1.271174 43.526584))",
"POLYGON ((1.304332 43.596581, 1.451664 43.596581, 1.451664 43.603136, 1.304332 43.603136, 1.304332 43.596581))",
"POLYGON ((1.310607 43.603141, 1.682371 43.603141, 1.682371 43.608620, 1.310607 43.608620, 1.310607 43.603141))",
"POLYGON ((1.286581 43.608621, 2.139323 43.608621, 2.139323 43.719454, 1.286581 43.719454, 1.286581 43.608621))",
"POLYGON ((1.290207 43.719454, 2.145751 43.719454, 2.145751 43.956970, 1.290207 43.956970, 1.290207 43.719454))",
"POLYGON ((1.352586 43.956983, 2.045611 43.956983, 2.045611 44.292690, 1.352586 44.292690, 1.352586 43.956983))",
"POLYGON ((1.793523 44.292697, 2.048833 44.292697, 2.048833 44.366081, 1.793523 44.366081, 1.793523 44.292697))",
"POLYGON ((1.429535 44.366083, 2.046493 44.366083, 2.046493 44.475148, 1.429535 44.475148, 1.429535 44.366083))",
"POLYGON ((1.286270 44.475152, 2.149829 44.475152, 2.149829 44.810455, 1.286270 44.810455, 1.286270 44.475152))",
"POLYGON ((1.268810 44.810464, 2.149779 44.810464, 2.149779 45.174382, 1.268810 45.174382, 1.268810 44.810464))",
"POLYGON ((1.382062 45.174384, 2.149255 45.174384, 2.149255 45.413137, 1.382062 45.413137, 1.382062 45.174384))",
"POLYGON ((1.287007 45.413146, 2.148294 45.413146, 2.148294 45.535108, 1.287007 45.535108, 1.287007 45.413146))",
"POLYGON ((1.268449 45.535117, 2.148328 45.535117, 2.148328 45.784850, 1.268449 45.784850, 1.268449 45.535117))",
"POLYGON ((1.261247 45.784850, 2.121882 45.784850, 2.121882 45.860386, 1.261247 45.860386, 1.261247 45.784850))",
"POLYGON ((1.272968 45.860391, 2.143241 45.860391, 2.143241 46.100436, 1.272968 46.100436, 1.272968 45.860391))",
"POLYGON ((1.394553 46.100439, 2.118252 46.100439, 2.118252 46.513020, 1.394553 46.513020, 1.394553 46.100439))",
"POLYGON ((1.289115 46.513023, 2.004613 46.513023, 2.004613 46.614918, 1.289115 46.614918, 1.289115 46.513023))",
"POLYGON ((1.293542 46.614935, 1.977285 46.614935, 1.977285 46.873305, 1.293542 46.873305, 1.293542 46.614935))",
"POLYGON ((1.311025 46.873313, 1.989927 46.873313, 1.989927 46.989943, 1.311025 46.989943, 1.311025 46.873313))",
"POLYGON ((1.296720 46.990096, 2.040804 46.990096, 2.040804 47.065050, 1.296720 47.065050, 1.296720 46.990096))",
"POLYGON ((1.355952 47.065058, 2.040780 47.065058, 2.040780 47.157020, 1.355952 47.157020, 1.355952 47.065058))",
"POLYGON ((1.294271 47.157020, 2.041378 47.157020, 2.041378 47.439482, 1.294271 47.439482, 1.294271 47.157020))",
"POLYGON ((1.281279 47.439486, 1.674103 47.439486, 1.674103 47.586145, 1.281279 47.586145, 1.281279 47.439486))",
"POLYGON ((1.288612 47.586155, 2.135630 47.586155, 2.135630 47.801141, 1.288612 47.801141, 1.288612 47.586155))",
"POLYGON ((1.693475 47.805055, 2.040445 47.805055, 2.040445 47.833129, 1.693475 47.833129, 1.693475 47.805055))",
"POLYGON ((1.289819 47.833309, 2.150759 47.833309, 2.150759 48.071027, 1.289819 48.071027, 1.289819 47.833309))",
"POLYGON ((1.274244 48.071031, 2.102007 48.071031, 2.102007 48.450815, 1.274244 48.450815, 1.274244 48.071031))",
"POLYGON ((1.913482 48.450823, 2.141882 48.450823, 2.141882 48.529168, 1.913482 48.529168, 1.913482 48.450823))",
"POLYGON ((1.960425 48.529173, 2.131615 48.529173, 2.131615 48.566680, 1.960425 48.566680, 1.960425 48.529173))",
"POLYGON ((1.577951 48.566682, 2.147272 48.566682, 2.147272 48.629732, 1.577951 48.629732, 1.577951 48.566682))",
"POLYGON ((1.361897 48.629733, 2.136313 48.629733, 2.136313 48.743096, 1.361897 48.743096, 1.361897 48.629733))",
"POLYGON ((1.436558 48.743151, 2.141290 48.743151, 2.141290 48.814165, 1.436558 48.814165, 1.436558 48.743151))",
"POLYGON ((1.466279 48.814173, 2.141111 48.814173, 2.141111 48.858090, 1.466279 48.858090, 1.466279 48.814173))",
"POLYGON ((1.273794 48.858092, 2.136447 48.858092, 2.136447 48.894578, 1.273794 48.894578, 1.273794 48.858092))",
"POLYGON ((1.678730 48.894592, 2.100063 48.894592, 2.100063 48.898018, 1.678730 48.898018, 1.678730 48.894592))",
"POLYGON ((1.811396 48.898018, 2.106642 48.898018, 2.106642 48.953611, 1.811396 48.953611, 1.811396 48.898018))",
"POLYGON ((1.282089 48.953643, 2.146593 48.953643, 2.146593 49.035736, 1.282089 49.035736, 1.282089 48.953643))",
"POLYGON ((1.258152 49.035746, 2.117385 49.035746, 2.117385 49.066001, 1.258152 49.066001, 1.258152 49.035746))",
"POLYGON ((1.483964 49.066051, 2.103988 49.066051, 2.103988 49.094500, 1.483964 49.094500, 1.483964 49.066051))",
"POLYGON ((1.288711 49.094515, 2.046784 49.094515, 2.046784 49.202960, 1.288711 49.202960, 1.288711 49.094515))",
"POLYGON ((1.401978 49.202983, 2.140010 49.202983, 2.140010 49.281098, 1.401978 49.281098, 1.401978 49.202983))",
"POLYGON ((1.258966 49.281098, 2.131850 49.281098, 2.131850 49.597708, 1.258966 49.597708, 1.258966 49.281098))",
"POLYGON ((1.275599 49.597712, 2.084548 49.597712, 2.084548 50.061147, 1.275599 50.061147, 1.275599 49.597712))",
"POLYGON ((1.448049 50.061203, 2.150546 50.061203, 2.150546 50.277536, 1.448049 50.277536, 1.448049 50.061203))",
"POLYGON ((1.551213 50.277536, 2.138581 50.277536, 2.138581 50.749462, 1.551213 50.749462, 1.551213 50.277536))",
"POLYGON ((1.593390 50.749462, 1.755334 50.749462, 1.755334 50.843081, 1.593390 50.843081, 1.593390 50.749462))",
"POLYGON ((1.580695 50.843085, 1.676703 50.843085, 1.676703 50.869262, 1.580695 50.869262, 1.580695 50.843085))",
"POLYGON ((1.582109 50.869299, 1.858827 50.869299, 1.858827 50.957476, 1.582109 50.957476, 1.582109 50.869299))",
"POLYGON ((1.289673 50.957559, 2.126171 50.957559, 2.126171 51.128447, 1.289673 51.128447, 1.289673 50.957559))",
"POLYGON ((1.258421 51.128449, 1.482629 51.128449, 1.482629 52.057127, 1.258421 52.057127, 1.258421 51.128449))",
"POLYGON ((1.259167 52.057137, 1.726349 52.057137, 1.726349 52.907708, 1.259167 52.907708, 1.259167 52.057137))",
"POLYGON ((2.218789 6.353466, 2.751888 6.353466, 2.751888 36.595016, 2.218789 36.595016, 2.218789 6.353466))",
"POLYGON ((2.182508 36.595016, 2.769182 36.595016, 2.769182 39.567220, 2.182508 39.567220, 2.182508 36.595016))",
"POLYGON ((2.155626 39.567224, 2.741142 39.567224, 2.741142 41.397954, 2.155626 41.397954, 2.155626 39.567224))",
"POLYGON ((2.150775 41.397954, 2.434862 41.397954, 2.434862 41.531283, 2.150775 41.531283, 2.150775 41.397954))",
"POLYGON ((2.161458 41.531295, 2.680101 41.531295, 2.680101 41.746285, 2.161458 41.746285, 2.161458 41.531295))",
"POLYGON ((2.176492 41.746286, 2.721334 41.746286, 2.721334 41.920326, 2.176492 41.920326, 2.176492 41.746286))",
"POLYGON ((2.154213 41.920409, 2.767196 41.920409, 2.767196 42.240368, 2.154213 42.240368, 2.154213 41.920409))",
"POLYGON ((2.164267 42.240389, 2.775640 42.240389, 2.775640 42.811908, 2.164267 42.811908, 2.164267 42.240389))",
"POLYGON ((2.186523 42.811976, 2.761618 42.811976, 2.761618 43.040741, 2.186523 43.040741, 2.186523 42.811976))",
"POLYGON ((2.197717 43.080753, 2.765411 43.080753, 2.765411 43.252884, 2.197717 43.252884, 2.197717 43.080753))",
"POLYGON ((2.160108 43.252886, 2.757751 43.252886, 2.757751 43.360104, 2.160108 43.360104, 2.160108 43.252886))",
"POLYGON ((2.153627 43.360104, 2.610632 43.360104, 2.610632 43.953293, 2.153627 43.953293, 2.153627 43.360104))",
"POLYGON ((2.169997 43.953303, 2.755459 43.953303, 2.755459 44.351874, 2.169997 44.351874, 2.169997 43.953303))",
"POLYGON ((2.298593 44.351875, 2.600624 44.351875, 2.600624 44.459813, 2.298593 44.459813, 2.298593 44.351875))",
"POLYGON ((2.249741 44.459813, 2.764053 44.459813, 2.764053 44.605603, 2.249741 44.605603, 2.249741 44.459813))",
"POLYGON ((2.179097 44.605609, 2.761012 44.605609, 2.761012 44.922755, 2.179097 44.922755, 2.179097 44.605609))",
"POLYGON ((2.434764 44.922757, 2.594245 44.922757, 2.594245 44.951455, 2.434764 44.951455, 2.434764 44.922757))",
"POLYGON ((2.188552 44.951475, 2.696679 44.951475, 2.696679 45.015924, 2.188552 45.015924, 2.188552 44.951475))",
"POLYGON ((2.225425 45.015925, 2.750494 45.015925, 2.750494 45.138130, 2.225425 45.138130, 2.225425 45.015925))",
"POLYGON ((2.291175 45.138131, 2.767548 45.138131, 2.767548 45.335982, 2.291175 45.335982, 2.291175 45.138131))",
"POLYGON ((2.308788 45.335983, 2.718347 45.335983, 2.718347 45.547041, 2.308788 45.547041, 2.308788 45.335983))",
"POLYGON ((2.154068 45.547041, 2.692681 45.547041, 2.692681 46.168983, 2.154068 46.168983, 2.154068 45.547041))",
"POLYGON ((2.152877 46.168983, 2.702523 46.168983, 2.702523 46.341273, 2.152877 46.341273, 2.152877 46.168983))",
"POLYGON ((2.210579 46.341274, 2.622407 46.341274, 2.622407 46.668076, 2.210579 46.668076, 2.210579 46.341274))",
"POLYGON ((2.178356 46.668077, 2.550269 46.668077, 2.550269 46.784264, 2.178356 46.784264, 2.178356 46.668077))",
"POLYGON ((2.157884 46.784303, 2.748277 46.784303, 2.748277 47.337622, 2.157884 47.337622, 2.157884 46.784303))",
"POLYGON ((2.216490 47.337629, 2.773066 47.337629, 2.773066 47.866594, 2.216490 47.866594, 2.216490 47.337629))",
"POLYGON ((2.157008 47.866637, 2.772323 47.866637, 2.772323 48.150336, 2.157008 48.150336, 2.157008 47.866637))",
"POLYGON ((2.202921 48.150340, 2.754491 48.150340, 2.754491 48.401909, 2.202921 48.401909, 2.202921 48.150340))",
"POLYGON ((2.158218 48.401918, 2.703018 48.401918, 2.703018 48.447393, 2.158218 48.447393, 2.158218 48.401918))",
"POLYGON ((2.182120 48.447395, 2.765833 48.447395, 2.765833 48.532175, 2.182120 48.532175, 2.182120 48.447395))",
"POLYGON ((2.156048 48.532183, 2.768191 48.532183, 2.768191 48.628262, 2.156048 48.628262, 2.156048 48.532183))",
"POLYGON ((2.163401 48.628271, 2.710476 48.628271, 2.710476 48.672349, 2.163401 48.672349, 2.163401 48.628271))",
"POLYGON ((2.155675 48.677025, 2.769142 48.677025, 2.769142 48.731243, 2.155675 48.731243, 2.155675 48.677025))",
"POLYGON ((2.161663 48.731244, 2.744512 48.731244, 2.744512 48.768944, 2.161663 48.768944, 2.161663 48.731244))",
"POLYGON ((2.151570 48.768963, 2.526243 48.768963, 2.526243 48.796541, 2.151570 48.796541, 2.151570 48.768963))",
"POLYGON ((2.151173 48.796542, 2.712559 48.796542, 2.712559 48.821979, 2.151173 48.821979, 2.151173 48.796542))",
"POLYGON ((2.171614 48.822878, 2.753679 48.822878, 2.753679 48.842611, 2.171614 48.842611, 2.171614 48.822878))",
"POLYGON ((2.186773 48.842658, 2.697577 48.842658, 2.697577 48.845861, 2.186773 48.845861, 2.186773 48.842658))",
"POLYGON ((2.233403 48.845862, 2.445657 48.845862, 2.445657 48.852568, 2.233403 48.852568, 2.233403 48.845862))",
"POLYGON ((2.301753 48.852576, 2.604367 48.852576, 2.604367 48.854328, 2.301753 48.854328, 2.301753 48.852576))",
"POLYGON ((2.317876 48.854328, 2.709930 48.854328, 2.709930 48.857364, 2.317876 48.857364, 2.317876 48.854328))",
"POLYGON ((2.325352 48.857368, 2.712492 48.857368, 2.712492 48.860479, 2.325352 48.860479, 2.325352 48.857368))",
"POLYGON ((2.330324 48.860480, 2.393091 48.860480, 2.393091 48.861468, 2.330324 48.861468, 2.330324 48.860480))",
"POLYGON ((2.286190 48.861477, 2.394383 48.861477, 2.394383 48.863587, 2.286190 48.863587, 2.286190 48.861477))",
"POLYGON ((2.164262 48.863680, 2.775978 48.863680, 2.775978 48.874186, 2.164262 48.874186, 2.164262 48.863680))",
"POLYGON ((2.176850 48.874197, 2.774684 48.874197, 2.774684 48.910441, 2.176850 48.910441, 2.176850 48.874197))",
"POLYGON ((2.153320 48.910443, 2.705884 48.910443, 2.705884 49.004844, 2.153320 49.004844, 2.153320 48.910443))",
"POLYGON ((2.162457 49.004844, 2.693320 49.004844, 2.693320 49.126341, 2.162457 49.126341, 2.162457 49.004844))",
"POLYGON ((2.204913 49.126341, 2.761356 49.126341, 2.761356 49.187696, 2.204913 49.187696, 2.204913 49.126341))",
"POLYGON ((2.221285 49.187696, 2.770429 49.187696, 2.770429 49.306208, 2.221285 49.306208, 2.221285 49.187696))",
"POLYGON ((2.312519 49.306210, 2.775178 49.306210, 2.775178 49.395311, 2.312519 49.395311, 2.312519 49.306210))",
"POLYGON ((2.152415 49.395316, 2.765260 49.395316, 2.765260 49.811030, 2.152415 49.811030, 2.152415 49.395316))",
"POLYGON ((2.163944 49.811030, 2.766040 49.811030, 2.766040 49.948361, 2.163944 49.948361, 2.163944 49.811030))",
"POLYGON ((2.232401 49.948361, 2.766209 49.948361, 2.766209 50.073763, 2.232401 50.073763, 2.232401 49.948361))",
"POLYGON ((2.175048 50.074116, 2.751204 50.074116, 2.751204 50.268970, 2.175048 50.268970, 2.175048 50.074116))",
"POLYGON ((2.216485 50.268971, 2.768158 50.268971, 2.768158 50.334549, 2.216485 50.334549, 2.216485 50.268971))",
"POLYGON ((2.202217 50.334571, 2.774556 50.334571, 2.774556 50.423931, 2.202217 50.423931, 2.202217 50.334571))",
"POLYGON ((2.294306 50.423951, 2.772186 50.423951, 2.772186 50.472536, 2.294306 50.472536, 2.294306 50.423951))",
"POLYGON ((2.328638 50.472551, 2.775437 50.472551, 2.775437 50.587744, 2.328638 50.587744, 2.328638 50.472551))",
"POLYGON ((2.182479 50.587749, 2.744540 50.587749, 2.744540 50.839326, 2.182479 50.839326, 2.182479 50.587749))",
"POLYGON ((2.321463 50.839327, 2.748702 50.839327, 2.748702 51.016353, 2.321463 51.016353, 2.321463 50.839327))",
"POLYGON ((2.346845 51.016357, 2.775988 51.016357, 2.775988 51.166839, 2.346845 51.166839, 2.346845 51.016357))",
"POLYGON ((2.835379 13.042376, 3.257864 13.042376, 3.257864 39.342845, 2.835379 39.342845, 2.835379 13.042376))",
"POLYGON ((2.777310 39.342848, 3.256256 39.342848, 3.256256 39.842677, 2.777310 39.842677, 2.777310 39.342848))",
"POLYGON ((2.783584 39.842678, 3.183327 39.842678, 3.183327 41.784864, 2.783584 41.784864, 2.783584 39.842678))",
"POLYGON ((2.803498 41.784894, 3.208816 41.784894, 3.208816 42.031609, 2.803498 42.031609, 2.803498 41.784894))",
"POLYGON ((2.790315 42.031661, 3.250334 42.031661, 3.250334 42.416411, 2.790315 42.416411, 2.790315 42.031661))",
"POLYGON ((2.776548 42.416412, 3.164589 42.416412, 3.164589 42.689475, 2.776548 42.689475, 2.776548 42.416412))",
"POLYGON ((2.884961 42.689532, 2.982702 42.689532, 2.982702 42.760779, 2.884961 42.760779, 2.884961 42.689532))",
"POLYGON ((2.776896 42.760790, 3.172286 42.760790, 3.172286 43.328783, 2.776896 43.328783, 2.776896 42.760790))",
"POLYGON ((2.778361 43.328800, 3.236290 43.328800, 3.236290 43.950181, 2.778361 43.950181, 2.778361 43.328800))",
"POLYGON ((2.780044 43.950199, 3.245333 43.950199, 3.245333 44.279495, 2.780044 44.279495, 2.780044 43.950199))",
"POLYGON ((2.778964 44.279500, 3.225766 44.279500, 3.225766 44.471512, 2.778964 44.471512, 2.778964 44.279500))",
"POLYGON ((2.786048 44.471519, 3.193815 44.471519, 3.193815 44.888891, 2.786048 44.888891, 2.786048 44.471519))",
"POLYGON ((2.807043 44.888893, 3.223977 44.888893, 3.223977 45.032538, 2.807043 45.032538, 2.807043 44.888893))",
"POLYGON ((3.090480 45.032540, 3.099786 45.032540, 3.099786 45.033757, 3.090480 45.033757, 3.090480 45.032540))",
"POLYGON ((2.805964 45.033769, 3.138033 45.033769, 3.138033 45.142366, 2.805964 45.142366, 2.805964 45.033769))",
"POLYGON ((2.785491 45.142369, 3.242895 45.142369, 3.242895 45.226469, 2.785491 45.226469, 2.785491 45.142369))",
"POLYGON ((2.889687 45.226470, 3.242360 45.226470, 3.242360 45.330684, 2.889687 45.330684, 2.889687 45.226470))",
"POLYGON ((2.870684 45.330684, 3.245191 45.330684, 3.245191 45.488160, 2.870684 45.488160, 2.870684 45.330684))",
"POLYGON ((2.848349 45.488164, 3.244332 45.488164, 3.244332 45.538805, 2.848349 45.538805, 2.848349 45.488164))",
"POLYGON ((2.939915 45.538806, 3.250691 45.538806, 3.250691 45.551261, 2.939915 45.551261, 2.939915 45.538806))",
"POLYGON ((2.809794 45.551263, 3.149371 45.551263, 3.149371 45.622211, 2.809794 45.622211, 2.809794 45.551263))",
"POLYGON ((3.092375 45.622217, 3.246829 45.622217, 3.246829 45.667567, 3.092375 45.667567, 3.092375 45.622217))",
"POLYGON ((2.841198 45.667574, 3.256852 45.667574, 3.256852 45.694614, 2.841198 45.694614, 2.841198 45.667574))",
"POLYGON ((2.839137 45.694623, 3.232995 45.694623, 3.232995 45.720955, 2.839137 45.720955, 2.839137 45.694623))",
"POLYGON ((3.023544 45.720962, 3.242141 45.720962, 3.242141 45.755486, 3.023544 45.755486, 3.023544 45.720962))",
"POLYGON ((2.964003 45.755493, 3.242042 45.755493, 3.242042 45.797317, 2.964003 45.797317, 2.964003 45.755493))",
"POLYGON ((2.819415 45.797388, 3.128784 45.797388, 3.128784 45.850700, 2.819415 45.850700, 2.819415 45.797388))",
"POLYGON ((2.796483 45.850711, 3.086439 45.850711, 3.086439 45.908729, 2.796483 45.908729, 2.796483 45.850711))",
"POLYGON ((2.791562 45.908733, 3.171570 45.908733, 3.171570 46.003395, 2.791562 46.003395, 2.791562 45.908733))",
"POLYGON ((2.927834 46.003405, 3.254906 46.003405, 3.254906 46.130860, 2.927834 46.130860, 2.927834 46.003405))",
"POLYGON ((2.939849 46.130873, 3.256791 46.130873, 3.256791 46.988321, 2.939849 46.988321, 2.939849 46.130873))",
"POLYGON ((2.893459 46.988327, 3.255153 46.988327, 3.255153 47.408828, 2.893459 47.408828, 2.893459 46.988327))",
"POLYGON ((2.793903 47.408837, 3.256207 47.408837, 3.256207 47.775591, 2.793903 47.775591, 2.793903 47.408837))",
"POLYGON ((2.783548 47.775592, 3.245510 47.775592, 3.245510 48.150623, 2.783548 48.150623, 2.783548 47.775592))",
"POLYGON ((2.792134 48.150626, 3.253178 48.150626, 3.253178 48.566116, 2.792134 48.566116, 2.792134 48.150626))",
"POLYGON ((2.776157 48.566201, 3.163671 48.566201, 3.163671 48.716094, 2.776157 48.716094, 2.776157 48.566201))",
"POLYGON ((2.775991 48.716094, 3.203126 48.716094, 3.203126 48.944823, 2.775991 48.944823, 2.775991 48.716094))",
"POLYGON ((2.797076 48.944827, 3.137936 48.944827, 3.137936 49.256337, 2.797076 49.256337, 2.797076 48.944827))",
"POLYGON ((2.792807 49.256347, 3.221551 49.256347, 3.221551 49.291644, 2.792807 49.291644, 2.792807 49.256347))",
"POLYGON ((2.793592 49.291650, 2.929169 49.291650, 2.929169 49.310170, 2.793592 49.310170, 2.793592 49.291650))",
"POLYGON ((2.784021 49.310202, 3.018649 49.310202, 3.018649 49.352466, 2.784021 49.352466, 2.784021 49.310202))",
"POLYGON ((2.784348 49.352466, 3.099573 49.352466, 3.099573 49.372577, 2.784348 49.372577, 2.784348 49.352466))",
"POLYGON ((2.776365 49.372589, 3.195594 49.372589, 3.195594 49.394201, 2.776365 49.394201, 2.776365 49.372589))",
"POLYGON ((2.798353 49.394204, 3.198669 49.394204, 3.198669 49.416475, 2.798353 49.416475, 2.798353 49.394204))",
"POLYGON ((2.799614 49.416483, 2.831991 49.416483, 2.831991 49.418541, 2.799614 49.418541, 2.799614 49.416483))",
"POLYGON ((2.803168 49.418544, 2.980022 49.418544, 2.980022 49.436728, 2.803168 49.436728, 2.803168 49.418544))",
"POLYGON ((2.845721 49.436730, 3.071478 49.436730, 3.071478 49.455484, 2.845721 49.455484, 2.845721 49.436730))",
"POLYGON ((2.783605 49.455490, 3.249628 49.455490, 3.249628 49.742689, 2.783605 49.742689, 2.783605 49.455490))",
"POLYGON ((2.781527 49.742693, 3.243604 49.742693, 3.243604 50.080802, 2.781527 50.080802, 2.781527 49.742693))",
"POLYGON ((2.784157 50.080819, 3.233936 50.080819, 3.233936 50.174267, 2.784157 50.174267, 2.784157 50.080819))",
"POLYGON ((2.776921 50.174268, 3.244885 50.174268, 3.244885 50.331405, 2.776921 50.331405, 2.776921 50.174268))",
"POLYGON ((2.777839 50.331410, 3.196329 50.331410, 3.196329 50.369877, 2.777839 50.369877, 2.777839 50.331410))",
"POLYGON ((2.801986 50.369877, 3.253553 50.369877, 3.253553 50.401273, 2.801986 50.401273, 2.801986 50.369877))",
"POLYGON ((2.786040 50.401287, 3.159403 50.401287, 3.159403 50.461488, 2.786040 50.461488, 2.786040 50.401287))",
"POLYGON ((2.776170 50.461502, 3.227014 50.461502, 3.227014 50.610257, 2.776170 50.610257, 2.776170 50.461502))",
"POLYGON ((2.794629 50.610258, 3.239718 50.610258, 3.239718 50.637697, 2.794629 50.637697, 2.794629 50.610258))",
"POLYGON ((3.052648 50.637705, 3.235409 50.637705, 3.235409 50.655400, 3.052648 50.655400, 3.052648 50.637705))",
"POLYGON ((2.777311 50.655400, 3.248534 50.655400, 3.248534 50.680767, 2.777311 50.680767, 2.777311 50.655400))",
"POLYGON ((2.792929 50.680790, 3.248728 50.680790, 3.248728 50.773636, 2.792929 50.773636, 2.792929 50.680790))",
"POLYGON ((2.812238 50.773636, 3.171723 50.773636, 3.171723 50.887873, 2.812238 50.887873, 2.812238 50.773636))",
"POLYGON ((2.777033 50.887874, 3.243709 50.887874, 3.243709 51.143444, 2.777033 51.143444, 2.777033 50.887874))",
"POLYGON ((2.776000 51.143452, 3.242165 51.143452, 3.242165 51.202707, 2.776000 51.202707, 2.776000 51.143452))",
"POLYGON ((2.849775 51.202722, 3.251272 51.202722, 3.251272 51.334613, 2.849775 51.334613, 2.849775 51.202722))",
"POLYGON ((3.257873 33.065778, 3.876537 33.065778, 3.876537 39.953532, 3.257873 39.953532, 3.257873 33.065778))",
"POLYGON ((3.281677 39.953544, 3.876579 39.953544, 3.876579 43.313826, 3.281677 43.313826, 3.281677 39.953544))",
"POLYGON ((3.288672 43.313831, 3.740795 43.313831, 3.740795 43.487485, 3.288672 43.487485, 3.288672 43.313831))",
"POLYGON ((3.287035 43.487485, 3.811786 43.487485, 3.811786 43.594603, 3.287035 43.594603, 3.287035 43.487485))",
"POLYGON ((3.268784 43.594608, 3.881642 43.594608, 3.881642 43.643053, 3.268784 43.643053, 3.268784 43.594608))",
"POLYGON ((3.293000 43.643053, 3.870639 43.643053, 3.870639 43.691598, 3.293000 43.691598, 3.293000 43.643053))",
"POLYGON ((3.268251 43.691882, 3.859555 43.691882, 3.859555 43.835964, 3.268251 43.835964, 3.268251 43.691882))",
"POLYGON ((3.292923 43.835965, 3.879363 43.835965, 3.879363 44.350496, 3.292923 44.350496, 3.292923 43.835965))",
"POLYGON ((3.288509 44.350496, 3.881300 44.350496, 3.881300 44.844128, 3.288509 44.844128, 3.288509 44.350496))",
"POLYGON ((3.426243 44.844131, 3.833011 44.844131, 3.833011 44.926495, 3.426243 44.926495, 3.426243 44.844131))",
"POLYGON ((3.275410 44.926501, 3.881280 44.926501, 3.881280 44.995382, 3.275410 44.995382, 3.275410 44.926501))",
"POLYGON ((3.458259 44.995388, 3.881880 44.995388, 3.881880 45.047520, 3.458259 45.047520, 3.458259 44.995388))",
"POLYGON ((3.550052 45.047521, 3.879888 45.047521, 3.879888 45.069089, 3.550052 45.069089, 3.550052 45.047521))",
"POLYGON ((3.532896 45.069102, 3.860915 45.069102, 3.860915 45.074010, 3.532896 45.074010, 3.532896 45.069102))",
"POLYGON ((3.324563 45.074023, 3.813192 45.074023, 3.813192 45.147269, 3.324563 45.147269, 3.324563 45.074023))",
"POLYGON ((3.305810 45.147269, 3.821397 45.147269, 3.821397 45.187439, 3.305810 45.187439, 3.305810 45.147269))",
"POLYGON ((3.387549 45.187462, 3.827755 45.187462, 3.827755 45.263085, 3.387549 45.263085, 3.387549 45.187462))",
"POLYGON ((3.266416 45.263096, 3.827756 45.263096, 3.827756 45.294746, 3.266416 45.294746, 3.266416 45.263096))",
"POLYGON ((3.342503 45.299429, 3.791893 45.299429, 3.791893 45.321082, 3.342503 45.321082, 3.342503 45.299429))",
"POLYGON ((3.318195 45.321082, 3.847638 45.321082, 3.847638 45.371124, 3.318195 45.371124, 3.318195 45.321082))",
"POLYGON ((3.268773 45.371134, 3.874304 45.371134, 3.874304 45.436929, 3.268773 45.436929, 3.268773 45.371134))",
"POLYGON ((3.271840 45.436931, 3.763254 45.436931, 3.763254 45.531654, 3.271840 45.531654, 3.271840 45.436931))",
"POLYGON ((3.283169 45.531830, 3.827933 45.531830, 3.827933 45.662577, 3.283169 45.662577, 3.283169 45.531830))",
"POLYGON ((3.317826 45.662603, 3.851294 45.662603, 3.851294 45.704229, 3.317826 45.704229, 3.317826 45.662603))",
"POLYGON ((3.264457 45.704256, 3.429954 45.704256, 3.429954 45.722576, 3.264457 45.722576, 3.264457 45.704256))",
"POLYGON ((3.279469 45.722577, 3.617809 45.722577, 3.617809 45.751190, 3.279469 45.751190, 3.279469 45.722577))",
"POLYGON ((3.279563 45.751192, 3.820938 45.751192, 3.820938 45.905556, 3.279563 45.905556, 3.279563 45.751192))",
"POLYGON ((3.262721 45.905578, 3.546012 45.905578, 3.546012 46.121219, 3.262721 46.121219, 3.262721 45.905578))",
"POLYGON ((3.329301 46.121220, 3.852748 46.121220, 3.852748 46.600763, 3.329301 46.600763, 3.329301 46.121220))",
"POLYGON ((3.283159 46.600772, 3.772796 46.600772, 3.772796 47.349011, 3.283159 47.349011, 3.283159 46.600772))",
"POLYGON ((3.284098 47.349028, 3.804341 47.349028, 3.804341 47.509208, 3.284098 47.509208, 3.284098 47.349028))",
"POLYGON ((3.271226 47.509213, 3.868481 47.509213, 3.868481 48.469844, 3.271226 48.469844, 3.271226 47.509213))",
"POLYGON ((3.279335 48.469844, 3.688006 48.469844, 3.688006 48.563759, 3.279335 48.563759, 3.279335 48.469844))",
"POLYGON ((3.281273 48.563789, 3.867027 48.563789, 3.867027 49.047447, 3.281273 49.047447, 3.281273 48.563789))",
"POLYGON ((3.260402 49.047450, 3.881755 49.047450, 3.881755 49.335254, 3.260402 49.335254, 3.260402 49.047450))",
"POLYGON ((3.276019 49.335255, 3.844969 49.335255, 3.844969 49.380695, 3.276019 49.380695, 3.276019 49.335255))",
"POLYGON ((3.324605 49.380695, 3.353136 49.380695, 3.353136 49.382957, 3.324605 49.382957, 3.324605 49.380695))",
"POLYGON ((3.271729 49.382965, 3.599356 49.382965, 3.599356 49.401271, 3.271729 49.401271, 3.271729 49.382965))",
"POLYGON ((3.310128 49.401282, 3.811783 49.401282, 3.811783 49.501575, 3.310128 49.501575, 3.310128 49.401282))",
"POLYGON ((3.568041 49.501594, 3.838078 49.501594, 3.838078 49.518178, 3.568041 49.518178, 3.568041 49.501594))",
"POLYGON ((3.305080 49.518178, 3.798886 49.518178, 3.798886 49.529313, 3.305080 49.529313, 3.305080 49.518178))",
"POLYGON ((3.284578 49.529318, 3.831533 49.529318, 3.831533 49.556113, 3.284578 49.556113, 3.284578 49.529318))",
"POLYGON ((3.583737 49.556151, 3.628149 49.556151, 3.628149 49.562579, 3.583737 49.562579, 3.583737 49.556151))",
"POLYGON ((3.607026 49.562579, 3.630352 49.562579, 3.630352 49.563955, 3.607026 49.563955, 3.607026 49.562579))",
"POLYGON ((3.607200 49.563958, 3.631873 49.563958, 3.631873 49.564337, 3.607200 49.564337, 3.607200 49.563958))",
"POLYGON ((3.607068 49.564338, 3.631656 49.564338, 3.631656 49.564825, 3.607068 49.564825, 3.607068 49.564338))",
"POLYGON ((3.602956 49.564826, 3.632111 49.564826, 3.632111 49.566153, 3.602956 49.566153, 3.602956 49.564826))",
"POLYGON ((3.300579 49.566171, 3.881865 49.566171, 3.881865 49.740001, 3.300579 49.740001, 3.300579 49.566171))",
"POLYGON ((3.259142 49.740021, 3.807869 49.740021, 3.807869 49.957270, 3.259142 49.957270, 3.259142 49.740021))",
"POLYGON ((3.271890 49.957274, 3.872539 49.957274, 3.872539 50.247178, 3.271890 50.247178, 3.271890 49.957274))",
"POLYGON ((3.309217 50.247178, 3.865801 50.247178, 3.865801 50.333281, 3.309217 50.333281, 3.309217 50.247178))",
"POLYGON ((3.272356 50.333437, 3.880421 50.333437, 3.880421 50.378668, 3.272356 50.378668, 3.272356 50.333437))",
"POLYGON ((3.411701 50.378670, 3.599149 50.378670, 3.599149 50.405822, 3.411701 50.405822, 3.411701 50.378670))",
"POLYGON ((3.421245 50.405853, 3.843161 50.405853, 3.843161 50.549651, 3.421245 50.549651, 3.421245 50.405853))",
"POLYGON ((3.265540 50.549651, 3.881253 50.549651, 3.881253 50.827609, 3.265540 50.827609, 3.265540 50.549651))",
"POLYGON ((3.264336 50.827849, 3.824715 50.827849, 3.824715 51.001201, 3.264336 51.001201, 3.264336 50.827849))",
"POLYGON ((3.449415 51.001205, 3.838830 51.001205, 3.838830 51.053808, 3.449415 51.053808, 3.449415 51.001205))",
"POLYGON ((3.288709 51.053842, 3.863791 51.053842, 3.863791 51.130269, 3.288709 51.130269, 3.288709 51.053842))",
"POLYGON ((3.264018 51.130269, 3.866319 51.130269, 3.866319 51.421156, 3.264018 51.421156, 3.264018 51.130269))",
"POLYGON ((3.526204 51.421199, 3.876469 51.421199, 3.876469 51.499243, 3.526204 51.499243, 3.526204 51.421199))",
"POLYGON ((3.465657 51.499243, 3.851108 51.499243, 3.851108 51.697223, 3.465657 51.697223, 3.465657 51.499243))",
"POLYGON ((3.691214 51.697224, 3.815321 51.697224, 3.815321 51.718481, 3.691214 51.718481, 3.691214 51.697224))",
"POLYGON ((3.766734 51.718482, 3.879725 51.718482, 3.879725 51.813229, 3.766734 51.813229, 3.766734 51.718482))",
"POLYGON ((3.892473 7.374912, 4.306311 7.374912, 4.306311 39.873405, 3.892473 39.873405, 3.892473 7.374912))",
"POLYGON ((4.034439 39.873422, 4.288463 39.873422, 4.288463 39.928469, 4.034439 39.928469, 4.034439 39.873422))",
"POLYGON ((3.883270 39.928483, 4.265257 39.928483, 4.265257 43.565272, 3.883270 43.565272, 3.883270 39.928483))",
"POLYGON ((3.887817 43.565283, 4.209439 43.565283, 4.209439 43.660074, 3.887817 43.660074, 3.887817 43.565283))",
"POLYGON ((3.897793 43.660074, 4.280935 43.660074, 4.280935 43.701538, 3.897793 43.701538, 3.897793 43.660074))",
"POLYGON ((3.897630 43.701553, 4.291307 43.701553, 4.291307 43.829344, 3.897630 43.829344, 3.897630 43.701553))",
"POLYGON ((3.893786 43.829347, 4.296884 43.829347, 4.296884 44.495587, 3.893786 44.495587, 3.893786 43.829347))",
"POLYGON ((3.882075 44.495611, 4.287538 44.495611, 4.287538 44.888682, 3.882075 44.888682, 3.882075 44.495611))",
"POLYGON ((3.916793 44.888690, 4.173567 44.888690, 4.173567 44.939908, 3.916793 44.939908, 3.916793 44.888690))",
"POLYGON ((3.884899 44.939913, 4.143323 44.939913, 4.143323 44.995807, 3.884899 44.995807, 3.884899 44.939913))",
"POLYGON ((3.882027 44.995809, 4.124195 44.995809, 4.124195 45.038352, 3.882027 45.038352, 3.882027 44.995809))",
"POLYGON ((3.881965 45.038353, 4.189127 45.038353, 4.189127 45.044663, 3.881965 45.044663, 3.881965 45.038353))",
"POLYGON ((3.881886 45.044667, 3.889744 45.044667, 3.889744 45.045975, 3.881886 45.045975, 3.881886 45.044667))",
"POLYGON ((3.881992 45.045976, 4.239080 45.045976, 4.239080 45.063362, 3.881992 45.063362, 3.881992 45.045976))",
"POLYGON ((3.893887 45.063366, 4.289980 45.063366, 4.289980 45.181289, 3.893887 45.181289, 3.893887 45.063366))",
"POLYGON ((3.941794 45.181331, 4.245488 45.181331, 4.245488 45.283869, 3.941794 45.283869, 3.941794 45.181331))",
"POLYGON ((3.886880 45.283884, 4.277703 45.283884, 4.277703 45.342675, 3.886880 45.342675, 3.886880 45.283884))",
"POLYGON ((3.913959 45.342679, 4.282484 45.342679, 4.282484 45.464849, 3.913959 45.464849, 3.913959 45.342679))",
"POLYGON ((3.918614 45.464889, 4.303596 45.464889, 4.303596 46.032559, 3.918614 46.032559, 3.918614 45.464889))",
"POLYGON ((3.896449 46.032560, 4.289192 46.032560, 4.289192 46.547968, 3.896449 46.547968, 3.896449 46.032560))",
"POLYGON ((3.936501 46.547971, 4.295739 46.547971, 4.295739 46.878564, 3.936501 46.878564, 3.936501 46.547971))",
"POLYGON ((3.902871 46.878582, 4.299976 46.878582, 4.299976 47.361763, 3.902871 47.361763, 3.902871 46.878582))",
"POLYGON ((3.890092 47.361859, 4.303297 47.361859, 4.303297 47.890061, 3.890092 47.890061, 3.890092 47.361859))",
"POLYGON ((3.890488 47.890061, 4.242807 47.890061, 4.242807 48.994366, 3.890488 48.994366, 3.890488 47.890061))",
"POLYGON ((3.882637 48.994397, 4.291603 48.994397, 4.291603 49.314150, 3.882637 49.314150, 3.882637 48.994397))",
"POLYGON ((3.881897 49.314154, 4.225422 49.314154, 4.225422 50.143113, 3.881897 50.143113, 3.881897 49.314154))",
"POLYGON ((3.928288 50.143113, 4.156521 50.143113, 4.156521 50.279204, 3.928288 50.279204, 3.928288 50.143113))",
"POLYGON ((3.882436 50.279208, 4.217875 50.279208, 4.217875 50.430624, 3.882436 50.430624, 3.882436 50.279208))",
"POLYGON ((3.926774 50.430630, 4.295161 50.430630, 4.295161 50.746975, 3.926774 50.746975, 3.926774 50.430630))",
"POLYGON ((3.971112 50.746995, 4.300239 50.746995, 4.300239 50.779976, 3.971112 50.779976, 3.971112 50.746995))",
"POLYGON ((3.993792 50.780522, 4.299352 50.780522, 4.299352 50.826321, 3.993792 50.826321, 3.993792 50.780522))",
"POLYGON ((3.889616 50.826321, 4.302681 50.826321, 4.302681 50.925505, 3.889616 50.925505, 3.889616 50.826321))",
"POLYGON ((3.958313 50.925550, 4.298729 50.925550, 4.298729 51.095142, 3.958313 51.095142, 3.958313 50.925550))",
"POLYGON ((4.028736 51.095142, 4.303389 51.095142, 4.303389 51.344625, 4.028736 51.344625, 4.028736 51.095142))",
"POLYGON ((3.886672 51.344625, 4.304592 51.344625, 4.304592 51.647168, 3.886672 51.647168, 3.886672 51.344625))",
"POLYGON ((3.915940 51.647169, 3.927591 51.647169, 3.927591 51.647693, 3.915940 51.647693, 3.915940 51.647169))",
"POLYGON ((3.915948 51.647694, 4.035728 51.647694, 4.035728 51.648118, 3.915948 51.648118, 3.915948 51.647694))",
"POLYGON ((3.915991 51.648118, 3.926263 51.648118, 3.926263 51.648561, 3.915991 51.648561, 3.915991 51.648118))",
"POLYGON ((3.916284 51.648563, 3.925430 51.648563, 3.925430 51.649016, 3.916284 51.649016, 3.916284 51.648563))",
"POLYGON ((3.916188 51.649017, 4.002351 51.649017, 4.002351 51.649491, 3.916188 51.649491, 3.916188 51.649017))",
"POLYGON ((3.913664 51.649493, 4.001857 51.649493, 4.001857 51.649937, 3.913664 51.649937, 3.913664 51.649493))",
"POLYGON ((3.913570 51.649945, 3.922139 51.649945, 3.922139 51.650367, 3.913570 51.650367, 3.913570 51.649945))",
"POLYGON ((3.913810 51.650368, 4.032417 51.650368, 4.032417 51.651026, 3.913810 51.651026, 3.913810 51.650368))",
"POLYGON ((3.912867 51.651030, 4.032431 51.651030, 4.032431 51.653984, 3.912867 51.653984, 3.912867 51.651030))",
"POLYGON ((3.899227 51.653985, 4.097019 51.653985, 4.097019 51.689356, 3.899227 51.689356, 3.899227 51.653985))",
"POLYGON ((3.928803 51.689359, 4.213011 51.689359, 4.213011 51.694020, 3.928803 51.694020, 3.928803 51.689359))",
"POLYGON ((3.911637 51.694027, 4.293556 51.694027, 4.293556 51.725426, 3.911637 51.725426, 3.911637 51.694027))",
"POLYGON ((3.907649 51.725432, 4.252438 51.725432, 4.252438 51.729165, 3.907649 51.729165, 3.907649 51.725432))",
"POLYGON ((3.882094 51.729165, 4.305990 51.729165, 4.305990 51.750169, 3.882094 51.750169, 3.882094 51.729165))",
"POLYGON ((4.120482 51.750177, 4.166981 51.750177, 4.166981 51.758970, 4.120482 51.758970, 4.120482 51.750177))",
"POLYGON ((4.150360 51.758977, 4.166517 51.758977, 4.166517 51.760476, 4.150360 51.760476, 4.150360 51.758977))",
"POLYGON ((3.929385 51.760477, 4.221504 51.760477, 4.221504 51.808725, 3.929385 51.808725, 3.929385 51.760477))",
"POLYGON ((3.933123 51.808747, 4.266181 51.808747, 4.266181 51.823164, 3.933123 51.823164, 3.933123 51.808747))",
"POLYGON ((3.972788 51.823192, 4.291354 51.823192, 4.291354 51.838309, 3.972788 51.838309, 3.972788 51.823192))",
"POLYGON ((4.096720 51.838309, 4.260465 51.838309, 4.260465 51.861347, 4.096720 51.861347, 4.096720 51.838309))",
"POLYGON ((4.064428 51.861354, 4.258791 51.861354, 4.258791 51.883867, 4.064428 51.883867, 4.064428 51.861354))",
"POLYGON ((4.060358 51.883868, 4.223484 51.883868, 4.223484 51.901515, 4.060358 51.901515, 4.060358 51.883868))",
"POLYGON ((4.070743 51.901516, 4.168092 51.901516, 4.168092 51.902733, 4.070743 51.902733, 4.070743 51.901516))",
"POLYGON ((4.070665 51.902733, 4.168106 51.902733, 4.168106 51.903941, 4.070665 51.903941, 4.070665 51.902733))",
"POLYGON ((4.109610 51.903943, 4.249253 51.903943, 4.249253 51.905487, 4.109610 51.905487, 4.109610 51.903943))",
"POLYGON ((4.160655 51.905497, 4.168617 51.905497, 4.168617 51.906300, 4.160655 51.906300, 4.160655 51.905497))",
"POLYGON ((4.102135 51.906302, 4.168684 51.906302, 4.168684 51.915105, 4.102135 51.915105, 4.102135 51.906302))",
"POLYGON ((4.095556 51.915110, 4.306639 51.915110, 4.306639 52.099546, 4.095556 52.099546, 4.095556 51.915110))",
"POLYGON ((4.308050 26.085356, 4.806424 26.085356, 4.806424 43.671758, 4.308050 43.671758, 4.308050 26.085356))",
"POLYGON ((4.381991 43.671768, 4.789903 43.671768, 4.789903 43.704889, 4.381991 43.704889, 4.381991 43.671768))",
"POLYGON ((4.307192 43.704895, 4.797421 43.704895, 4.797421 43.941982, 4.307192 43.941982, 4.307192 43.704895))",
"POLYGON ((4.419305 43.942034, 4.805732 43.942034, 4.805732 44.054102, 4.419305 44.054102, 4.419305 43.942034))",
"POLYGON ((4.477214 44.054103, 4.814005 44.054103, 4.814005 44.134450, 4.477214 44.134450, 4.477214 44.054103))",
"POLYGON ((4.807459 44.134450, 4.809466 44.134450, 4.809466 44.134703, 4.807459 44.134703, 4.807459 44.134450))",
"POLYGON ((4.807501 44.134703, 4.809215 44.134703, 4.809215 44.134896, 4.807501 44.134896, 4.807501 44.134703))",
"POLYGON ((4.804704 44.134896, 4.808944 44.134896, 4.808944 44.142147, 4.804704 44.142147, 4.804704 44.134896))",
"POLYGON ((4.319086 44.142150, 4.808184 44.142150, 4.808184 44.509990, 4.319086 44.509990, 4.319086 44.142150))",
"POLYGON ((4.324922 44.509990, 4.813738 44.509990, 4.813738 44.905644, 4.324922 44.905644, 4.324922 44.509990))",
"POLYGON ((4.429992 44.905646, 4.778948 44.905646, 4.778948 45.036042, 4.429992 45.036042, 4.429992 44.905646))",
"POLYGON ((4.644918 45.036160, 4.784759 45.036160, 4.784759 45.061094, 4.644918 45.061094, 4.644918 45.036160))",
"POLYGON ((4.394670 45.061164, 4.804784 45.061164, 4.804784 45.143758, 4.394670 45.143758, 4.394670 45.061164))",
"POLYGON ((4.332362 45.144112, 4.815620 45.144112, 4.815620 45.232592, 4.332362 45.232592, 4.332362 45.144112))",
"POLYGON ((4.335972 45.232594, 4.811525 45.232594, 4.811525 45.389387, 4.335972 45.389387, 4.335972 45.232594))",
"POLYGON ((4.329235 45.389406, 4.813501 45.389406, 4.813501 45.543469, 4.329235 45.543469, 4.329235 45.389406))",
"POLYGON ((4.387879 45.543472, 4.813204 45.543472, 4.813204 45.712305, 4.387879 45.712305, 4.387879 45.543472))",
"POLYGON ((4.327638 45.712343, 4.818568 45.712343, 4.818568 45.759593, 4.327638 45.759593, 4.327638 45.712343))",
"POLYGON ((4.364728 45.759596, 4.818572 45.759596, 4.818572 45.912347, 4.364728 45.912347, 4.364728 45.759596))",
"POLYGON ((4.426655 45.912354, 4.804516 45.912354, 4.804516 45.955701, 4.426655 45.955701, 4.426655 45.912354))",
"POLYGON ((4.328446 45.955702, 4.774909 45.955702, 4.774909 46.036542, 4.328446 46.036542, 4.328446 45.955702))",
"POLYGON ((4.328394 46.036554, 4.797412 46.036554, 4.797412 46.274712, 4.328394 46.274712, 4.328394 46.036554))",
"POLYGON ((4.308271 46.274832, 4.817138 46.274832, 4.817138 46.474358, 4.308271 46.474358, 4.308271 46.274832))",
"POLYGON ((4.319797 46.474361, 4.793153 46.474361, 4.793153 46.546314, 4.319797 46.546314, 4.319797 46.474361))",
"POLYGON ((4.330188 46.546323, 4.803442 46.546323, 4.803442 46.626648, 4.330188 46.626648, 4.330188 46.546323))",
"POLYGON ((4.400631 46.626650, 4.818513 46.626650, 4.818513 46.713576, 4.400631 46.713576, 4.400631 46.626650))",
"POLYGON ((4.337055 46.713781, 4.817131 46.713781, 4.817131 46.798018, 4.337055 46.798018, 4.337055 46.713781))",
"POLYGON ((4.431972 46.798019, 4.801984 46.798019, 4.801984 46.898643, 4.431972 46.898643, 4.431972 46.798019))",
"POLYGON ((4.309143 46.898665, 4.818570 46.898665, 4.818570 47.216460, 4.309143 47.216460, 4.309143 46.898665))",
"POLYGON ((4.399371 47.216464, 4.754932 47.216464, 4.754932 47.290657, 4.399371 47.290657, 4.399371 47.216464))",
"POLYGON ((4.307611 47.290662, 4.662528 47.290662, 4.662528 47.594405, 4.307611 47.594405, 4.307611 47.290662))",
"POLYGON ((4.324993 47.594405, 4.815283 47.594405, 4.815283 48.625301, 4.324993 48.625301, 4.324993 47.594405))",
"POLYGON ((4.357166 48.625301, 4.813082 48.625301, 4.813082 48.977093, 4.357166 48.977093, 4.357166 48.625301))",
"POLYGON ((4.314830 48.977096, 4.757671 48.977096, 4.757671 49.509030, 4.314830 49.509030, 4.314830 48.977096))",
"POLYGON ((4.312408 49.509065, 4.818449 49.509065, 4.818449 50.352821, 4.312408 50.352821, 4.312408 49.509065))",
"POLYGON ((4.306737 50.354194, 4.808870 50.354194, 4.808870 50.489269, 4.306737 50.489269, 4.306737 50.354194))",
"POLYGON ((4.393903 50.489284, 4.818508 50.489284, 4.818508 50.677965, 4.393903 50.677965, 4.393903 50.489284))",
"POLYGON ((4.312613 50.677965, 4.752449 50.677965, 4.752449 50.839167, 4.312613 50.839167, 4.312613 50.677965))",
"POLYGON ((4.350751 50.839167, 4.492961 50.839167, 4.492961 50.843457, 4.350751 50.843457, 4.350751 50.839167))",
"POLYGON ((4.344416 50.843464, 4.797964 50.843464, 4.797964 50.876563, 4.344416 50.876563, 4.344416 50.843464))",
"POLYGON ((4.310904 50.876580, 4.714974 50.876580, 4.714974 50.886913, 4.310904 50.886913, 4.310904 50.876580))",
"POLYGON ((4.356737 50.886917, 4.697072 50.886917, 4.697072 50.888636, 4.356737 50.888636, 4.356737 50.886917))",
"POLYGON ((4.328536 50.888636, 4.697251 50.888636, 4.697251 50.930027, 4.328536 50.930027, 4.328536 50.888636))",
"POLYGON ((4.407108 50.930059, 4.771772 50.930059, 4.771772 51.012919, 4.407108 51.012919, 4.407108 50.930059))",
"POLYGON ((4.390894 51.012920, 4.526446 51.012920, 4.526446 51.052166, 4.390894 51.052166, 4.390894 51.012920))",
"POLYGON ((4.340800 51.052166, 4.813046 51.052166, 4.813046 51.078082, 4.340800 51.078082, 4.340800 51.052166))",
"POLYGON ((4.330161 51.078082, 4.783513 51.078082, 4.783513 51.092700, 4.330161 51.092700, 4.330161 51.078082))",
"POLYGON ((4.320780 51.092709, 4.746497 51.092709, 4.746497 51.112513, 4.320780 51.112513, 4.320780 51.092709))",
"POLYGON ((4.333772 51.112513, 4.740636 51.112513, 4.740636 51.150790, 4.333772 51.150790, 4.333772 51.112513))",
"POLYGON ((4.341220 51.151023, 4.755961 51.151023, 4.755961 51.179136, 4.341220 51.179136, 4.341220 51.151023))",
"POLYGON ((4.395601 51.179144, 4.779021 51.179144, 4.779021 51.214733, 4.395601 51.214733, 4.395601 51.179144))",
"POLYGON ((4.397122 51.214752, 4.713569 51.214752, 4.713569 51.236941, 4.397122 51.236941, 4.397122 51.214752))",
"POLYGON ((4.375014 51.236944, 4.791475 51.236944, 4.791475 51.321193, 4.375014 51.321193, 4.375014 51.236944))",
"POLYGON ((4.313627 51.321199, 4.814484 51.321199, 4.814484 51.682470, 4.313627 51.682470, 4.313627 51.321199))",
"POLYGON ((4.316247 51.682475, 4.709496 51.682475, 4.709496 51.827606, 4.316247 51.827606, 4.316247 51.682475))",
"POLYGON ((4.317720 51.827606, 4.784130 51.827606, 4.784130 52.011217, 4.317720 52.011217, 4.317720 51.827606))",
"POLYGON ((4.321182 52.011217, 4.775511 52.011217, 4.775511 52.063901, 4.321182 52.063901, 4.321182 52.011217))",
"POLYGON ((4.306639 52.063902, 4.785951 52.063902, 4.785951 52.081742, 4.306639 52.081742, 4.306639 52.063902))",
"POLYGON ((4.307514 52.081744, 4.815082 52.081744, 4.815082 52.248761, 4.307514 52.248761, 4.307514 52.081744))",
"POLYGON ((4.539949 52.248762, 4.785738 52.248762, 4.785738 52.384441, 4.539949 52.384441, 4.539949 52.248762))",
"POLYGON ((4.550524 52.384463, 4.688117 52.384463, 4.688117 52.454234, 4.550524 52.454234, 4.550524 52.384463))",
"POLYGON ((4.573092 52.454344, 4.684146 52.454344, 4.684146 52.483942, 4.573092 52.483942, 4.573092 52.454344))",
"POLYGON ((4.643254 52.483954, 4.782776 52.483954, 4.782776 53.021846, 4.643254 53.021846, 4.643254 52.483954))",
"POLYGON ((4.865568 27.585427, 5.309240 27.585427, 5.309240 43.337295, 4.865568 43.337295, 4.865568 27.585427))",
"POLYGON ((4.945266 43.337357, 5.249026 43.337357, 5.249026 43.461744, 4.945266 43.461744, 4.945266 43.337357))",
"POLYGON ((4.980608 43.461851, 5.268262 43.461851, 5.268262 43.656764, 4.980608 43.656764, 4.980608 43.461851))",
"POLYGON ((4.831200 43.656766, 5.261613 43.656766, 5.261613 43.774487, 4.831200 43.774487, 4.831200 43.656766))",
"POLYGON ((4.830932 43.774490, 5.286951 43.774490, 5.286951 44.051606, 4.830932 44.051606, 4.830932 43.774490))",
"POLYGON ((4.828465 44.051637, 5.275621 44.051637, 5.275621 44.217401, 4.828465 44.217401, 4.828465 44.051637))",
"POLYGON ((4.833481 44.217408, 5.299586 44.217408, 5.299586 44.497259, 4.833481 44.497259, 4.833481 44.217408))",
"POLYGON ((4.833146 44.497263, 5.277045 44.497263, 5.277045 44.928091, 4.833146 44.928091, 4.833146 44.497263))",
"POLYGON ((4.827155 44.928117, 5.302151 44.928117, 5.302151 45.013364, 4.827155 45.013364, 4.827155 44.928117))",
"POLYGON ((4.827151 45.013370, 5.287701 45.013370, 5.287701 45.165220, 4.827151 45.165220, 4.827151 45.013370))",
"POLYGON ((4.862719 45.165245, 5.273889 45.165245, 5.273889 45.395313, 4.862719 45.395313, 4.862719 45.165245))",
"POLYGON ((4.867834 45.395316, 5.304875 45.395316, 5.304875 45.529386, 4.867834 45.529386, 4.867834 45.395316))",
"POLYGON ((4.825149 45.529386, 5.292546 45.529386, 5.292546 45.670622, 4.825149 45.670622, 4.825149 45.529386))",
"POLYGON ((4.824710 45.670624, 5.240606 45.670624, 5.240606 45.753838, 4.824710 45.753838, 4.824710 45.670624))",
"POLYGON ((4.818572 45.753878, 4.838051 45.753878, 4.838051 45.759913, 4.818572 45.759913, 4.818572 45.753878))",
"POLYGON ((4.818742 45.759913, 5.302290 45.759913, 5.302290 45.798118, 4.818742 45.798118, 4.818742 45.759913))",
"POLYGON ((4.823253 45.798129, 5.278084 45.798129, 5.278084 45.877869, 4.823253 45.877869, 4.823253 45.798129))",
"POLYGON ((4.822582 45.877872, 5.301030 45.877872, 5.301030 45.988575, 4.822582 45.988575, 4.822582 45.877872))",
"POLYGON ((4.828373 45.988583, 5.270013 45.988583, 5.270013 46.118316, 4.828373 46.118316, 4.828373 45.988583))",
"POLYGON ((4.858583 46.118329, 5.294165 46.118329, 5.294165 46.198123, 4.858583 46.198123, 4.858583 46.118329))",
"POLYGON ((4.838053 46.198133, 5.236607 46.198133, 5.236607 46.301612, 4.838053 46.301612, 4.838053 46.198133))",
"POLYGON ((4.831777 46.302387, 5.309241 46.302387, 5.309241 46.541974, 4.831777 46.541974, 4.831777 46.302387))",
"POLYGON ((4.831766 46.541981, 5.309169 46.541981, 5.309169 46.629459, 4.831766 46.629459, 4.831766 46.541981))",
"POLYGON ((4.818607 46.629470, 5.299591 46.629470, 5.299591 46.885714, 4.818607 46.885714, 4.818607 46.629470))",
"POLYGON ((4.818583 46.885736, 5.228346 46.885736, 5.228346 47.196922, 4.818583 47.196922, 4.818583 46.885736))",
"POLYGON ((4.844762 47.196928, 5.264124 47.196928, 5.264124 47.519776, 4.844762 47.519776, 4.844762 47.196928))",
"POLYGON ((4.832603 47.519780, 5.308776 47.519780, 5.308776 48.767333, 4.832603 48.767333, 4.832603 47.519780))",
"POLYGON ((4.887176 48.767354, 5.309223 48.767354, 5.309223 49.652639, 4.887176 49.652639, 4.887176 48.767354))",
"POLYGON ((4.829400 49.661131, 5.267112 49.661131, 5.267112 49.970827, 4.829400 49.970827, 4.829400 49.661131))",
"POLYGON ((4.818672 49.971561, 5.298204 49.971561, 5.298204 50.222732, 4.818672 50.222732, 4.818672 49.971561))",
"POLYGON ((4.895715 50.222835, 5.298136 50.222835, 5.298136 50.374513, 4.895715 50.374513, 4.895715 50.222835))",
"POLYGON ((4.829798 50.374560, 5.288323 50.374560, 5.288323 50.469952, 4.829798 50.469952, 4.829798 50.374560))",
"POLYGON ((4.818619 50.469961, 5.266553 50.469961, 5.266553 50.523556, 4.818619 50.523556, 4.818619 50.469961))",
"POLYGON ((4.865376 50.523599, 5.292974 50.523599, 5.292974 50.565415, 4.865376 50.565415, 4.865376 50.523599))",
"POLYGON ((4.890579 50.565442, 5.308410 50.565442, 5.308410 50.636904, 4.890579 50.636904, 4.890579 50.565442))",
"POLYGON ((4.958921 50.636912, 5.282638 50.636912, 5.282638 50.746813, 4.958921 50.746813, 4.958921 50.636912))",
"POLYGON ((4.835225 50.750254, 5.305087 50.750254, 5.305087 50.836052, 4.835225 50.836052, 4.835225 50.750254))",
"POLYGON ((4.852817 50.836077, 5.309220 50.836077, 5.309220 50.893351, 4.852817 50.893351, 4.852817 50.836077))",
"POLYGON ((4.831781 50.893364, 5.309228 50.893364, 5.309228 50.933563, 4.831781 50.933563, 4.831781 50.893364))",
"POLYGON ((4.831858 50.933565, 5.309241 50.933565, 5.309241 50.969792, 4.831858 50.969792, 4.831858 50.933565))",
"POLYGON ((4.824689 50.969800, 5.291040 50.969800, 5.291040 51.086115, 4.824689 51.086115, 4.824689 50.969800))",
"POLYGON ((4.825265 51.086118, 5.267828 51.086118, 5.267828 51.187801, 4.825265 51.187801, 4.825265 51.086118))",
"POLYGON ((4.822544 51.187805, 5.305607 51.187805, 5.305607 51.326357, 4.822544 51.326357, 4.822544 51.187805))",
"POLYGON ((4.826319 51.326404, 5.265532 51.326404, 5.265532 51.615379, 4.826319 51.615379, 4.826319 51.326404))",
"POLYGON ((4.908982 51.615381, 5.304971 51.615381, 5.304971 51.792557, 4.908982 51.792557, 4.908982 51.615381))",
"POLYGON ((4.895241 51.792568, 5.246836 51.792568, 5.246836 51.816291, 4.895241 51.816291, 4.895241 51.792568))",
"POLYGON ((4.852819 51.816292, 5.280724 51.816292, 5.280724 51.962597, 4.852819 51.962597, 4.852819 51.816292))",
"POLYGON ((5.091662 51.962657, 5.190357 51.962657, 5.190357 51.997818, 5.091662 51.997818, 5.091662 51.962657))",
"POLYGON ((5.035255 51.997856, 5.250018 51.997856, 5.250018 52.064486, 5.035255 52.064486, 5.035255 51.997856))",
"POLYGON ((4.962279 52.064508, 5.259839 52.064508, 5.259839 52.078726, 4.962279 52.078726, 4.962279 52.064508))",
"POLYGON ((4.949887 52.078734, 5.242960 52.078734, 5.242960 52.098384, 4.949887 52.098384, 4.949887 52.078734))",
"POLYGON ((4.995378 52.098393, 5.204037 52.098393, 5.204037 52.114257, 4.995378 52.114257, 4.995378 52.098393))",
"POLYGON ((4.983188 52.114296, 5.195859 52.114296, 5.195859 52.126843, 4.983188 52.126843, 4.983188 52.114296))",
"POLYGON ((5.014710 52.127168, 5.198349 52.127168, 5.198349 52.162395, 5.014710 52.162395, 5.014710 52.127168))",
"POLYGON ((5.001881 52.162401, 5.303367 52.162401, 5.303367 52.203663, 5.001881 52.203663, 5.001881 52.162401))",
"POLYGON ((4.836440 52.203668, 5.299684 52.203668, 5.299684 52.251545, 4.836440 52.251545, 4.836440 52.203668))",
"POLYGON ((4.955789 52.255477, 5.171200 52.255477, 5.171200 52.298042, 4.955789 52.298042, 4.955789 52.255477))",
"POLYGON ((4.847588 52.298108, 5.169961 52.298108, 5.169961 52.329505, 4.847588 52.329505, 4.847588 52.298108))",
"POLYGON ((4.865640 52.329511, 5.109430 52.329511, 5.109430 52.370037, 4.865640 52.370037, 4.865640 52.329511))",
"POLYGON ((4.883567 52.370038, 5.014709 52.370038, 5.014709 52.373921, 4.883567 52.373921, 4.883567 52.370038))",
"POLYGON ((4.873495 52.373926, 5.068842 52.373926, 5.068842 52.517526, 4.873495 52.517526, 4.873495 52.373926))",
"POLYGON ((4.831939 52.517526, 5.069728 52.517526, 5.069728 52.570976, 4.831939 52.570976, 4.831939 52.517526))",
"POLYGON ((4.889408 52.570977, 5.309167 52.570977, 5.309167 62.051562, 4.889408 62.051562, 4.889408 52.570977))",
"POLYGON ((5.403535 6.254173, 5.936874 6.254173, 5.936874 43.155774, 5.403535 43.155774, 5.403535 6.254173))",
"POLYGON ((5.337140 43.155821, 5.884244 43.155821, 5.884244 43.232487, 5.337140 43.232487, 5.337140 43.155821))",
"POLYGON ((5.309258 43.232489, 5.910005 43.232489, 5.910005 43.289762, 5.309258 43.289762, 5.309258 43.232489))",
"POLYGON ((5.357752 43.289763, 5.662626 43.289763, 5.662626 43.298140, 5.357752 43.298140, 5.357752 43.289763))",
"POLYGON ((5.309617 43.298141, 5.911117 43.298141, 5.911117 43.404056, 5.309617 43.404056, 5.309617 43.298141))",
"POLYGON ((5.351637 43.404060, 5.923274 43.404060, 5.923274 43.582306, 5.351637 43.582306, 5.351637 43.404060))",
"POLYGON ((5.310874 43.582481, 5.858530 43.582481, 5.858530 43.726949, 5.310874 43.726949, 5.310874 43.582481))",
"POLYGON ((5.310325 43.726956, 5.917541 43.726956, 5.917541 43.924912, 5.310325 43.924912, 5.310325 43.726956))",
"POLYGON ((5.340525 43.924915, 5.938831 43.924915, 5.938831 44.507510, 5.340525 44.507510, 5.340525 43.924915))",
"POLYGON ((5.313343 44.507525, 5.906471 44.507525, 5.906471 45.075657, 5.313343 45.075657, 5.313343 44.507525))",
"POLYGON ((5.314711 45.075657, 5.832702 45.075657, 5.832702 45.154844, 5.314711 45.154844, 5.314711 45.075657))",
"POLYGON ((5.314725 45.154853, 5.859484 45.154853, 5.859484 45.216618, 5.314725 45.216618, 5.314725 45.154853))",
"POLYGON ((5.439328 45.216624, 5.926383 45.216624, 5.926383 45.292638, 5.439328 45.292638, 5.439328 45.216624))",
"POLYGON ((5.390588 45.293063, 5.918878 45.293063, 5.918878 45.379637, 5.390588 45.379637, 5.390588 45.293063))",
"POLYGON ((5.329111 45.379655, 5.837974 45.379655, 5.837974 45.479726, 5.329111 45.479726, 5.329111 45.379655))",
"POLYGON ((5.310073 45.479739, 5.937663 45.479739, 5.937663 45.631309, 5.310073 45.631309, 5.310073 45.479739))",
"POLYGON ((5.372521 45.631310, 5.918364 45.631310, 5.918364 45.727825, 5.372521 45.727825, 5.372521 45.631310))",
"POLYGON ((5.316243 45.727829, 5.932602 45.727829, 5.932602 45.825614, 5.316243 45.825614, 5.316243 45.727829))",
"POLYGON ((5.318448 45.825644, 5.803217 45.825644, 5.803217 45.903807, 5.318448 45.903807, 5.318448 45.825644))",
"POLYGON ((5.333207 45.903807, 5.908658 45.903807, 5.908658 46.026717, 5.333207 46.026717, 5.333207 45.903807))",
"POLYGON ((5.313611 46.026747, 5.666086 46.026747, 5.666086 46.152269, 5.313611 46.152269, 5.313611 46.026747))",
"POLYGON ((5.330273 46.152277, 5.658077 46.152277, 5.658077 46.360203, 5.330273 46.360203, 5.330273 46.152277))",
"POLYGON ((5.341505 46.360213, 5.866788 46.360213, 5.866788 46.475519, 5.341505 46.475519, 5.341505 46.360213))",
"POLYGON ((5.309247 46.475534, 5.811007 46.475534, 5.811007 46.713631, 5.309247 46.713631, 5.309247 46.475534))",
"POLYGON ((5.437237 46.713639, 5.925816 46.713639, 5.925816 46.836260, 5.437237 46.836260, 5.437237 46.713639))",
"POLYGON ((5.328957 46.836276, 5.941388 46.836276, 5.941388 47.123844, 5.328957 47.123844, 5.328957 46.836276))",
"POLYGON ((5.335428 47.123846, 5.926894 47.123846, 5.926894 47.221265, 5.335428 47.221265, 5.335428 47.123846))",
"POLYGON ((5.310010 47.245321, 5.939846 47.245321, 5.939846 47.864116, 5.310010 47.864116, 5.310010 47.245321))",
"POLYGON ((5.333909 47.864118, 5.902377 47.864118, 5.902377 48.385634, 5.333909 48.385634, 5.333909 47.864118))",
"POLYGON ((5.309692 48.385653, 5.939443 48.385653, 5.939443 48.649756, 5.309692 48.649756, 5.309692 48.385653))",
"POLYGON ((5.701036 48.649815, 5.940197 48.649815, 5.940197 48.733854, 5.701036 48.733854, 5.701036 48.649815))",
"POLYGON ((5.309296 48.733960, 5.889101 48.733960, 5.889101 48.992421, 5.309296 48.992421, 5.309296 48.733960))",
"POLYGON ((5.309294 48.992423, 5.882044 48.992423, 5.882044 49.207479, 5.309294 49.207479, 5.309294 48.992423))",
"POLYGON ((5.371633 49.207483, 5.936249 49.207483, 5.936249 49.456242, 5.371633 49.456242, 5.371633 49.207483))",
"POLYGON ((5.357917 49.456260, 5.924403 49.456260, 5.924403 49.516210, 5.357917 49.516210, 5.357917 49.456260))",
"POLYGON ((5.351426 49.516236, 5.922359 49.516236, 5.922359 49.567197, 5.351426 49.567197, 5.351426 49.516236))",
"POLYGON ((5.310135 49.567212, 5.941070 49.567212, 5.941070 49.730480, 5.310135 49.730480, 5.310135 49.567212))",
"POLYGON ((5.325319 49.730624, 5.934628 49.730624, 5.934628 50.394539, 5.325319 50.394539, 5.325319 49.730624))",
"POLYGON ((5.336283 50.394548, 5.923536 50.394548, 5.923536 50.546683, 5.336283 50.546683, 5.336283 50.394548))",
"POLYGON ((5.312953 50.546691, 5.864642 50.546691, 5.864642 50.612403, 5.312953 50.612403, 5.312953 50.546691))",
"POLYGON ((5.318973 50.612420, 5.848180 50.612420, 5.848180 50.647519, 5.318973 50.647519, 5.318973 50.612420))",
"POLYGON ((5.318838 50.647519, 5.904218 50.647519, 5.904218 50.731293, 5.318838 50.731293, 5.318838 50.647519))",
"POLYGON ((5.332183 50.731374, 5.884536 50.731374, 5.884536 50.779377, 5.332183 50.779377, 5.332183 50.731374))",
"POLYGON ((5.342922 50.779407, 5.791325 50.779407, 5.791325 50.801745, 5.342922 50.801745, 5.342922 50.779407))",
"POLYGON ((5.309257 50.801766, 5.891119 50.801766, 5.891119 50.852175, 5.309257 50.852175, 5.309257 50.801766))",
"POLYGON ((5.320855 50.852302, 5.856501 50.852302, 5.856501 50.878019, 5.320855 50.878019, 5.320855 50.852302))",
"POLYGON ((5.309851 50.878030, 5.935092 50.878030, 5.935092 50.897561, 5.309851 50.897561, 5.309851 50.878030))",
"POLYGON ((5.327842 50.897569, 5.934904 50.897569, 5.934904 50.932493, 5.327842 50.932493, 5.327842 50.897569))",
"POLYGON ((5.309244 50.932499, 5.926247 50.932499, 5.926247 50.962716, 5.309244 50.962716, 5.309244 50.932499))",
"POLYGON ((5.320132 50.962717, 5.933944 50.962717, 5.933944 51.023371, 5.320132 51.023371, 5.320132 50.962717))",
"POLYGON ((5.324031 51.023387, 5.939940 51.023387, 5.939940 51.084922, 5.324031 51.084922, 5.324031 51.023387))",
"POLYGON ((5.327687 51.084922, 5.941397 51.084922, 5.941397 51.559006, 5.327687 51.559006, 5.327687 51.084922))",
"POLYGON ((5.350022 51.559009, 5.819624 51.559009, 5.819624 51.659213, 5.350022 51.659213, 5.350022 51.559009))",
"POLYGON ((5.342007 51.659214, 5.870886 51.659214, 5.870886 51.821592, 5.342007 51.821592, 5.342007 51.659214))",
"POLYGON ((5.310392 51.821594, 5.900997 51.821594, 5.900997 51.953492, 5.310392 51.953492, 5.310392 51.821594))",
"POLYGON ((5.343207 51.953506, 5.902603 51.953506, 5.902603 51.987660, 5.343207 51.987660, 5.343207 51.953506))",
"POLYGON ((5.319960 51.987667, 5.933352 51.987667, 5.933352 52.050613, 5.319960 52.050613, 5.319960 51.987667))",
"POLYGON ((5.313716 52.050718, 5.833215 52.050718, 5.833215 52.134658, 5.313716 52.134658, 5.313716 52.050718))",
"POLYGON ((5.357362 52.134658, 5.457062 52.134658, 5.457062 52.156485, 5.357362 52.156485, 5.357362 52.134658))",
"POLYGON ((5.384620 52.156486, 5.401683 52.156486, 5.401683 52.158214, 5.384620 52.158214, 5.384620 52.156486))",
"POLYGON ((5.311972 52.158218, 5.939517 52.158218, 5.939517 52.277978, 5.311972 52.277978, 5.311972 52.158218))",
"POLYGON ((5.476221 52.277991, 5.927249 52.277991, 5.927249 53.233292, 5.476221 53.233292, 5.476221 52.277991))",
"POLYGON ((5.309441 53.233295, 5.941278 53.233295, 5.941278 60.399739, 5.309441 60.399739, 5.309441 53.233295))",
"POLYGON ((6.022259 31.687820, 6.270641 31.687820, 6.270641 43.105593, 6.022259 43.105593, 6.022259 31.687820))",
"POLYGON ((5.941757 43.105594, 6.311768 43.105594, 6.311768 43.404556, 5.941757 43.404556, 5.941757 43.105594))",
"POLYGON ((5.970667 43.404560, 6.316173 43.404560, 6.316173 44.127058, 5.970667 44.127058, 5.970667 43.404560))",
"POLYGON ((5.945714 44.127071, 6.313675 44.127071, 6.313675 45.500800, 5.945714 45.500800, 5.945714 44.127071))",
"POLYGON ((5.949874 45.500839, 6.317034 45.500839, 6.317034 45.684435, 5.949874 45.684435, 5.949874 45.500839))",
"POLYGON ((5.950056 45.684438, 6.315681 45.684438, 6.315681 46.237241, 5.950056 46.237241, 5.950056 45.684438))",
"POLYGON ((6.023867 46.237314, 6.298522 46.237314, 6.298522 46.858827, 6.023867 46.858827, 6.023867 46.237314))",
"POLYGON ((5.941449 46.858888, 6.260271 46.858888, 6.260271 47.234041, 5.941449 47.234041, 5.941449 46.858888))",
"POLYGON ((5.988490 47.234042, 6.079338 47.234042, 6.079338 47.248414, 5.988490 47.248414, 5.988490 47.234042))",
"POLYGON ((5.950823 47.248416, 6.312293 47.248416, 6.312293 48.476162, 5.950823 48.476162, 5.950823 47.248416))",
"POLYGON ((5.957528 48.476164, 6.312132 48.476164, 6.312132 48.671714, 5.957528 48.671714, 5.957528 48.476164))",
"POLYGON ((5.945539 48.671731, 6.255090 48.671731, 6.255090 48.735784, 5.945539 48.735784, 5.945539 48.671731))",
"POLYGON ((6.002307 48.735797, 6.270003 48.735797, 6.270003 49.097090, 6.002307 49.097090, 6.002307 48.735797))",
"POLYGON ((6.052135 49.097102, 6.283956 49.097102, 6.283956 49.117684, 6.052135 49.117684, 6.052135 49.097102))",
"POLYGON ((6.044250 49.117691, 6.255522 49.117691, 6.255522 49.124089, 6.044250 49.124089, 6.044250 49.117691))",
"POLYGON ((6.053677 49.124095, 6.274579 49.124095, 6.274579 49.152175, 6.053677 49.152175, 6.053677 49.124095))",
"POLYGON ((5.993973 49.152231, 6.181195 49.152231, 6.181195 49.358033, 5.993973 49.358033, 5.993973 49.152231))",
"POLYGON ((5.979780 49.358034, 6.301102 49.358034, 6.301102 49.413296, 5.979780 49.413296, 5.979780 49.358034))",
"POLYGON ((5.949473 49.413327, 6.310721 49.413327, 6.310721 49.470061, 5.949473 49.470061, 5.949473 49.413327))",
"POLYGON ((5.942386 49.470174, 6.238568 49.470174, 6.238568 49.511684, 5.942386 49.511684, 5.942386 49.470174))",
"POLYGON ((6.096303 49.511880, 6.264015 49.511880, 6.264015 49.568771, 6.096303 49.568771, 6.096303 49.511880))",
"POLYGON ((6.096659 49.568792, 6.281578 49.568792, 6.281578 49.610030, 6.096659 49.610030, 6.096659 49.568792))",
"POLYGON ((6.122752 49.610031, 6.292254 49.610031, 6.292254 49.616743, 6.122752 49.616743, 6.122752 49.610031))",
"POLYGON ((5.941526 49.616744, 6.309567 49.616744, 6.309567 49.702875, 5.941526 49.702875, 5.941526 49.616744))",
"POLYGON ((5.942663 49.702876, 6.307681 49.702876, 6.307681 49.935385, 5.942663 49.935385, 5.942663 49.702876))",
"POLYGON ((5.942887 49.935390, 6.313154 49.935390, 6.313154 50.661320, 5.942887 50.661320, 5.942887 49.935390))",
"POLYGON ((5.944399 50.661630, 6.233234 50.661630, 6.233234 50.767524, 5.944399 50.767524, 5.944399 50.661630))",
"POLYGON ((5.993760 50.767526, 6.186840 50.767526, 6.186840 50.785358, 5.993760 50.785358, 5.993760 50.767526))",
"POLYGON ((5.957638 50.785360, 6.310883 50.785360, 6.310883 50.886797, 5.957638 50.886797, 5.957638 50.785360))",
"POLYGON ((5.948760 50.886806, 6.299349 50.886806, 6.299349 50.923516, 5.948760 50.923516, 5.948760 50.886806))",
"POLYGON ((6.062277 50.923544, 6.306570 50.923544, 6.306570 50.964323, 6.062277 50.964323, 6.062277 50.923544))",
"POLYGON ((5.965988 50.964327, 6.188098 50.964327, 6.188098 50.992088, 5.965988 50.992088, 5.965988 50.964327))",
"POLYGON ((5.958713 50.992088, 6.213588 50.992088, 6.213588 50.997754, 5.958713 50.997754, 5.958713 50.992088))",
"POLYGON ((5.958731 50.997756, 6.243393 50.997756, 6.243393 51.010240, 5.958731 51.010240, 5.958731 50.997756))",
"POLYGON ((5.951242 51.010843, 6.272753 51.010843, 6.272753 51.021285, 5.951242 51.021285, 5.951242 51.010843))",
"POLYGON ((5.958948 51.021289, 6.317134 51.021289, 6.317134 51.039231, 5.958948 51.039231, 5.958948 51.021289))",
"POLYGON ((5.996888 51.039252, 6.304872 51.039252, 6.304872 51.052534, 5.996888 51.052534, 5.996888 51.039252))",
"POLYGON ((5.993580 51.052534, 6.315670 51.052534, 6.315670 51.057632, 5.993580 51.057632, 5.993580 51.052534))",
"POLYGON ((6.091260 51.057634, 6.255892 51.057634, 6.255892 51.059069, 6.091260 51.059069, 6.091260 51.057634))",
"POLYGON ((5.984011 51.059075, 6.225886 51.059075, 6.225886 51.062282, 5.984011 51.062282, 5.984011 51.059075))",
"POLYGON ((5.987381 51.062282, 6.303048 51.062282, 6.303048 51.065474, 5.987381 51.065474, 5.987381 51.062282))",
"POLYGON ((5.985693 51.065475, 6.314153 51.065475, 6.314153 51.077194, 5.985693 51.077194, 5.985693 51.065475))",
"POLYGON ((6.052527 51.077204, 6.317068 51.077204, 6.317068 51.083870, 6.052527 51.083870, 6.052527 51.077204))",
"POLYGON ((6.031928 51.083878, 6.317145 51.083878, 6.317145 51.094924, 6.031928 51.094924, 6.031928 51.083878))",
"POLYGON ((6.039760 51.094924, 6.299833 51.094924, 6.299833 51.099930, 6.039760 51.099930, 6.039760 51.094924))",
"POLYGON ((6.074402 51.099932, 6.287563 51.099932, 6.287563 51.104981, 6.074402 51.104981, 6.074402 51.099932))",
"POLYGON ((6.071895 51.104982, 6.314351 51.104982, 6.314351 51.120141, 6.071895 51.120141, 6.071895 51.104982))",
"POLYGON ((5.941397 51.120142, 6.310942 51.120142, 6.310942 51.132848, 5.941397 51.132848, 5.941397 51.120142))",
"POLYGON ((5.949981 51.132850, 6.307545 51.132850, 6.307545 51.166719, 5.949981 51.166719, 5.949981 51.132850))",
"POLYGON ((5.982608 51.166741, 6.305972 51.166741, 6.305972 51.240011, 5.982608 51.240011, 5.982608 51.166741))",
"POLYGON ((5.987336 51.240017, 6.314360 51.240017, 6.314360 51.419671, 5.987336 51.419671, 5.987336 51.240017))",
"POLYGON ((5.950349 51.419674, 6.313160 51.419674, 6.313160 51.806078, 5.950349 51.806078, 5.950349 51.419674))",
"POLYGON ((5.942896 51.806079, 6.305125 51.806079, 6.305125 52.006236, 5.942896 52.006236, 5.942896 51.806079))",
"POLYGON ((5.963178 52.006236, 6.315003 52.006236, 6.315003 52.075854, 5.963178 52.075854, 5.963178 52.006236))",
"POLYGON ((6.081218 52.075855, 6.286543 52.075855, 6.286543 52.100719, 6.081218 52.100719, 6.081218 52.075855))",
"POLYGON ((6.028576 52.100737, 6.313893 52.100737, 6.313893 52.145422, 6.028576 52.145422, 6.028576 52.100737))",
"POLYGON ((5.942191 52.145436, 6.302537 52.145436, 6.302537 52.246399, 5.942191 52.246399, 5.942191 52.145436))",
"POLYGON ((5.964952 52.246411, 6.231995 52.246411, 6.231995 52.331663, 5.964952 52.331663, 5.964952 52.246411))",
"POLYGON ((5.953774 52.331670, 6.294416 52.331670, 6.294416 52.772679, 5.953774 52.772679, 5.953774 52.331670))",
"POLYGON ((6.238471 52.772682, 6.264988 52.772682, 6.264988 52.783860, 6.238471 52.783860, 6.238471 52.772682))",
"POLYGON ((6.112167 52.783863, 6.258392 52.783863, 6.258392 52.792385, 6.112167 52.792385, 6.112167 52.783863))",
"POLYGON ((6.226652 52.792388, 6.234066 52.792388, 6.234066 52.796850, 6.226652 52.796850, 6.226652 52.792388))",
"POLYGON ((5.941793 52.796852, 6.309869 52.796852, 6.309869 62.496546, 5.941793 62.496546, 5.941793 52.796852))",
"POLYGON ((6.321951 0.340811, 6.736465 0.340811, 6.736465 43.274762, 6.321951 43.274762, 6.321951 0.340811))",
"POLYGON ((6.320927 43.274776, 6.756362 43.274776, 6.756362 43.779780, 6.320927 43.779780, 6.320927 43.274776))",
"POLYGON ((6.322763 43.779784, 6.756281 43.779784, 6.756281 44.595955, 6.322763 44.595955, 6.322763 43.779784))",
"POLYGON ((6.333940 44.596014, 6.721215 44.596014, 6.721215 44.894607, 6.333940 44.894607, 6.333940 44.596014))",
"POLYGON ((6.641222 44.894607, 6.685460 44.894607, 6.685460 44.905130, 6.641222 44.905130, 6.641222 44.894607))",
"POLYGON ((6.385721 44.905136, 6.756243 44.905136, 6.756243 45.028916, 6.385721 45.028916, 6.385721 44.905136))",
"POLYGON ((6.350623 45.028918, 6.706622 45.028918, 6.706622 45.041792, 6.350623 45.041792, 6.350623 45.028918))",
"POLYGON ((6.317160 45.041803, 6.718022 45.041803, 6.718022 45.072272, 6.317160 45.072272, 6.317160 45.041803))",
"POLYGON ((6.486314 45.072272, 6.717886 45.072272, 6.717886 45.104575, 6.486314 45.104575, 6.486314 45.072272))",
"POLYGON ((6.606924 45.104585, 6.752314 45.104585, 6.752314 45.142325, 6.606924 45.142325, 6.606924 45.104585))",
"POLYGON ((6.338831 45.142465, 6.740356 45.142465, 6.740356 45.435634, 6.338831 45.435634, 6.338831 45.142465))",
"POLYGON ((6.319901 45.435647, 6.756336 45.435647, 6.756336 45.723122, 6.319901 45.723122, 6.319901 45.435647))",
"POLYGON ((6.325086 45.736893, 6.754094 45.736893, 6.754094 46.374175, 6.325086 46.374175, 6.325086 45.736893))",
"POLYGON ((6.340832 46.374182, 6.754941 46.374182, 6.754941 46.517515, 6.340832 46.517515, 6.340832 46.374182))",
"POLYGON ((6.372997 46.517547, 6.709009 46.517547, 6.709009 46.871945, 6.372997 46.871945, 6.372997 46.517547))",
"POLYGON ((6.321608 46.872055, 6.756031 46.872055, 6.756031 47.486874, 6.321608 47.486874, 6.321608 46.872055))",
"POLYGON ((6.361951 47.486881, 6.687592 47.486881, 6.687592 47.709163, 6.361951 47.709163, 6.361951 47.486881))",
"POLYGON ((6.379677 47.709168, 6.753521 47.709168, 6.753521 48.037562, 6.379677 48.037562, 6.379677 47.709168))",
"POLYGON ((6.318395 48.037679, 6.753395 48.037679, 6.753395 48.311575, 6.318395 48.311575, 6.318395 48.037679))",
"POLYGON ((6.332170 48.311578, 6.690932 48.311578, 6.690932 48.672384, 6.332170 48.672384, 6.332170 48.311578))",
"POLYGON ((6.353528 48.691560, 6.754686 48.691560, 6.754686 49.107147, 6.353528 49.107147, 6.353528 48.691560))",
"POLYGON ((6.381321 49.107149, 6.740368 49.107149, 6.740368 49.198736, 6.381321 49.198736, 6.381321 49.107149))",
"POLYGON ((6.340496 49.198751, 6.723193 49.198751, 6.723193 49.301843, 6.340496 49.301843, 6.340496 49.198751))",
"POLYGON ((6.361978 49.301929, 6.751897 49.301929, 6.751897 49.349771, 6.361978 49.349771, 6.361978 49.301929))",
"POLYGON ((6.323058 49.349777, 6.733901 49.349777, 6.733901 49.368882, 6.323058 49.368882, 6.323058 49.349777))",
"POLYGON ((6.356644 49.369116, 6.724247 49.369116, 6.724247 49.457062, 6.356644 49.457062, 6.356644 49.369116))",
"POLYGON ((6.364010 49.457082, 6.747891 49.457082, 6.747891 49.664467, 6.364010 49.664467, 6.364010 49.457082))",
"POLYGON ((6.330246 49.664476, 6.748127 49.664476, 6.748127 49.750022, 6.330246 49.750022, 6.330246 49.664476))",
"POLYGON ((6.321261 49.750028, 6.707990 49.750028, 6.707990 49.866060, 6.321261 49.866060, 6.321261 49.750028))",
"POLYGON ((6.365266 49.866065, 6.750053 49.866065, 6.750053 50.432972, 6.365266 50.432972, 6.365266 49.866065))",
"POLYGON ((6.420917 50.433018, 6.751292 50.433018, 6.751292 50.564975, 6.420917 50.564975, 6.420917 50.433018))",
"POLYGON ((6.325629 50.565044, 6.744445 50.565044, 6.744445 50.690350, 6.325629 50.690350, 6.325629 50.565044))",
"POLYGON ((6.446819 50.690354, 6.723155 50.690354, 6.723155 50.766513, 6.446819 50.766513, 6.446819 50.690354))",
"POLYGON ((6.326769 50.766513, 6.744586 50.766513, 6.744586 50.842601, 6.326769 50.842601, 6.326769 50.766513))",
"POLYGON ((6.348784 50.842695, 6.744286 50.842695, 6.744286 51.009184, 6.348784 51.009184, 6.348784 50.842695))",
"POLYGON ((6.347882 51.009185, 6.559807 51.009185, 6.559807 51.026693, 6.347882 51.026693, 6.347882 51.009185))",
"POLYGON ((6.317165 51.026695, 6.684288 51.026695, 6.684288 51.060892, 6.317165 51.060892, 6.317165 51.026695))",
"POLYGON ((6.319377 51.060906, 6.753263 51.060906, 6.753263 51.076886, 6.319377 51.076886, 6.319377 51.060906))",
"POLYGON ((6.317153 51.076899, 6.753208 51.076899, 6.753208 51.083075, 6.317153 51.083075, 6.317153 51.076899))",
"POLYGON ((6.317149 51.083086, 6.720246 51.083086, 6.720246 51.106325, 6.317149 51.106325, 6.317149 51.083086))",
"POLYGON ((6.318350 51.106326, 6.696365 51.106326, 6.696365 51.121711, 6.318350 51.121711, 6.318350 51.106326))",
"POLYGON ((6.320281 51.121713, 6.684188 51.121713, 6.684188 51.124970, 6.320281 51.124970, 6.320281 51.121713))",
"POLYGON ((6.317180 51.124980, 6.745627 51.124980, 6.745627 51.155919, 6.317180 51.155919, 6.317180 51.124980))",
"POLYGON ((6.431042 51.155923, 6.724137 51.155923, 6.724137 51.185191, 6.431042 51.185191, 6.431042 51.155923))",
"POLYGON ((6.430226 51.185191, 6.698851 51.185191, 6.698851 51.203729, 6.430226 51.203729, 6.430226 51.185191))",
"POLYGON ((6.329882 51.203729, 6.750607 51.203729, 6.750607 51.258640, 6.329882 51.258640, 6.329882 51.203729))",
"POLYGON ((6.368619 51.258740, 6.674964 51.258740, 6.674964 51.267325, 6.368619 51.267325, 6.368619 51.258740))",
"POLYGON ((6.324265 51.267326, 6.674743 51.267326, 6.674743 51.286215, 6.324265 51.286215, 6.324265 51.267326))",
"POLYGON ((6.356381 51.286228, 6.734732 51.286228, 6.734732 51.322287, 6.356381 51.322287, 6.356381 51.286228))",
"POLYGON ((6.341012 51.322293, 6.640099 51.322293, 6.640099 51.341203, 6.341012 51.341203, 6.341012 51.322293))",
"POLYGON ((6.337630 51.341238, 6.750399 51.341238, 6.750399 51.366613, 6.337630 51.366613, 6.337630 51.341238))",
"POLYGON ((6.325520 51.366669, 6.741739 51.366669, 6.741739 51.454989, 6.325520 51.454989, 6.325520 51.366669))",
"POLYGON ((6.377588 51.454995, 6.732326 51.454995, 6.732326 51.649499, 6.377588 51.649499, 6.377588 51.454995))",
"POLYGON ((6.324794 51.649502, 6.642556 51.649502, 6.642556 51.662895, 6.324794 51.662895, 6.324794 51.649502))",
"POLYGON ((6.348562 51.662899, 6.568685 51.662899, 6.568685 51.728327, 6.348562 51.728327, 6.348562 51.662899))",
"POLYGON ((6.333600 51.728359, 6.618990 51.728359, 6.618990 51.838331, 6.333600 51.838331, 6.333600 51.728359))",
"POLYGON ((6.318223 51.838331, 6.721195 51.838331, 6.721195 52.097786, 6.318223 52.097786, 6.318223 51.838331))",
"POLYGON ((6.317300 52.097838, 6.741413 52.097838, 6.741413 52.662448, 6.317300 52.662448, 6.317300 52.097838))",
"POLYGON ((6.325731 52.662452, 6.747945 52.662452, 6.747945 53.210864, 6.325731 53.210864, 6.325731 52.662452))",
"POLYGON ((6.549095 53.210864, 6.567296 53.210864, 6.567296 53.218721, 6.549095 53.218721, 6.549095 53.210864))",
"POLYGON ((6.333726 53.218727, 6.570460 53.218727, 6.570460 53.224380, 6.333726 53.224380, 6.333726 53.218727))",
"POLYGON ((6.333652 53.224383, 6.756032 53.224383, 6.756032 58.127625, 6.333652 58.127625, 6.333652 53.224383))",
"POLYGON ((6.340716 58.127910, 6.756318 58.127910, 6.756318 62.509756, 6.340716 62.509756, 6.340716 58.127910))",
"POLYGON ((6.756371 36.871232, 6.969403 36.871232, 6.969403 43.713006, 6.756371 43.713006, 6.756371 36.871232))",
"POLYGON ((6.757360 43.713006, 6.978477 43.713006, 6.978477 44.306590, 6.757360 44.306590, 6.757360 43.713006))",
"POLYGON ((6.756431 44.306856, 6.979931 44.306856, 6.979931 44.553452, 6.756431 44.553452, 6.756431 44.306856))",
"POLYGON ((6.756506 44.553452, 6.979060 44.553452, 6.979060 45.076333, 6.756506 45.076333, 6.756506 44.553452))",
"POLYGON ((6.761027 45.079599, 6.973640 45.079599, 6.973640 45.230251, 6.761027 45.230251, 6.761027 45.079599))",
"POLYGON ((6.756379 45.230402, 6.983940 45.230402, 6.983940 45.923842, 6.756379 45.923842, 6.756379 45.230402))",
"POLYGON ((6.759116 45.923843, 6.977401 45.923843, 6.977401 46.949191, 6.759116 46.949191, 6.759116 45.923843))",
"POLYGON ((6.757669 46.949192, 6.953787 46.949192, 6.953787 47.587919, 6.757669 47.587919, 6.757669 46.949192))",
"POLYGON ((6.802940 47.588057, 6.928175 47.588057, 6.928175 47.674619, 6.802940 47.674619, 6.802940 47.588057))",
"POLYGON ((6.767461 47.674620, 6.982551 47.674620, 6.982551 48.370122, 6.767461 48.370122, 6.767461 47.674620))",
"POLYGON ((6.773882 48.370134, 6.957977 48.370134, 6.957977 48.956357, 6.773882 48.956357, 6.773882 48.370134))",
"POLYGON ((6.760804 48.961938, 6.950416 48.961938, 6.950416 49.162079, 6.760804 49.162079, 6.760804 48.961938))",
"POLYGON ((6.796321 49.171344, 6.983168 49.171344, 6.983168 49.227466, 6.796321 49.227466, 6.796321 49.171344))",
"POLYGON ((6.840569 49.227507, 6.983703 49.227507, 6.983703 49.255647, 6.840569 49.255647, 6.840569 49.227507))",
"POLYGON ((6.775689 49.255753, 6.983414 49.255753, 6.983414 49.297034, 6.775689 49.297034, 6.775689 49.255753))",
"POLYGON ((6.763303 49.297186, 6.971792 49.297186, 6.971792 49.357065, 6.763303 49.357065, 6.763303 49.297186))",
"POLYGON ((6.757160 49.357068, 6.981128 49.357068, 6.981128 49.579960, 6.757160 49.579960, 6.757160 49.357068))",
"POLYGON ((6.764169 49.604526, 6.983722 49.604526, 6.983722 49.831486, 6.764169 49.831486, 6.764169 49.604526))",
"POLYGON ((6.757060 49.831578, 6.982160 49.831578, 6.982160 50.552061, 6.757060 50.552061, 6.757060 49.831578))",
"POLYGON ((6.760967 50.552062, 6.764935 50.552062, 6.764935 50.553449, 6.760967 50.553449, 6.760967 50.552062))",
"POLYGON ((6.760386 50.553450, 6.765847 50.553450, 6.765847 50.554550, 6.760386 50.554550, 6.760386 50.553450))",
"POLYGON ((6.761054 50.554550, 6.766484 50.554550, 6.766484 50.555601, 6.761054 50.555601, 6.761054 50.554550))",
"POLYGON ((6.761464 50.555602, 6.771616 50.555602, 6.771616 50.564268, 6.761464 50.564268, 6.761464 50.555602))",
"POLYGON ((6.770904 50.564268, 6.973836 50.564268, 6.973836 50.668289, 6.770904 50.668289, 6.770904 50.564268))",
"POLYGON ((6.771046 50.668305, 6.983814 50.668305, 6.983814 50.761914, 6.771046 50.761914, 6.771046 50.668305))",
"POLYGON ((6.763128 50.761941, 6.951079 50.761941, 6.951079 50.805003, 6.763128 50.805003, 6.763128 50.761941))",
"POLYGON ((6.794821 50.805003, 6.980472 50.805003, 6.980472 50.906515, 6.794821 50.906515, 6.794821 50.805003))",
"POLYGON ((6.890674 50.906516, 6.977061 50.906516, 6.977061 50.922822, 6.890674 50.922822, 6.890674 50.906516))",
"POLYGON ((6.929778 50.922825, 6.973771 50.922825, 6.973771 50.932957, 6.929778 50.932957, 6.929778 50.922825))",
"POLYGON ((6.897891 50.932960, 6.973756 50.932960, 6.973756 50.936052, 6.897891 50.936052, 6.897891 50.932960))",
"POLYGON ((6.939888 50.936052, 6.983478 50.936052, 6.983478 50.937304, 6.939888 50.937304, 6.939888 50.936052))",
"POLYGON ((6.940782 50.937305, 6.968891 50.937305, 6.968891 50.938392, 6.940782 50.938392, 6.940782 50.937305))",
"POLYGON ((6.940808 50.938401, 6.962457 50.938401, 6.962457 50.938980, 6.940808 50.938980, 6.940808 50.938401))",
"POLYGON ((6.879384 50.938980, 6.962379 50.938980, 6.962379 50.940042, 6.879384 50.940042, 6.879384 50.938980))",
"POLYGON ((6.879327 50.940042, 6.968892 50.940042, 6.968892 50.941308, 6.879327 50.941308, 6.879327 50.940042))",
"POLYGON ((6.939932 50.941311, 6.958620 50.941311, 6.958620 50.942484, 6.939932 50.942484, 6.939932 50.941311))",
"POLYGON ((6.874896 50.942484, 6.958301 50.942484, 6.958301 50.943357, 6.874896 50.943357, 6.874896 50.942484))",
"POLYGON ((6.902432 50.943361, 6.970007 50.943361, 6.970007 50.947689, 6.902432 50.947689, 6.902432 50.943361))",
"POLYGON ((6.915476 50.947701, 6.965537 50.947701, 6.965537 50.949409, 6.915476 50.949409, 6.915476 50.947701))",
"POLYGON ((6.913967 50.949415, 6.966952 50.949415, 6.966952 50.951714, 6.913967 50.951714, 6.913967 50.949415))",
"POLYGON ((6.781607 50.951717, 6.960297 50.951717, 6.960297 50.954261, 6.781607 50.954261, 6.781607 50.951717))",
"POLYGON ((6.919324 50.954291, 6.926333 50.954291, 6.926333 50.955715, 6.919324 50.955715, 6.919324 50.954291))",
"POLYGON ((6.775675 50.955715, 6.924422 50.955715, 6.924422 50.956644, 6.775675 50.956644, 6.775675 50.955715))",
"POLYGON ((6.774005 50.956647, 6.977191 50.956647, 6.977191 50.960675, 6.774005 50.960675, 6.774005 50.956647))",
"POLYGON ((6.863939 50.960675, 6.982417 50.960675, 6.982417 50.997409, 6.863939 50.997409, 6.863939 50.960675))",
"POLYGON ((6.771031 50.997410, 6.975765 50.997410, 6.975765 51.119690, 6.771031 51.119690, 6.771031 50.997410))",
"POLYGON ((6.846989 51.119692, 6.851782 51.119692, 6.851782 51.121354, 6.846989 51.121354, 6.846989 51.119692))",
"POLYGON ((6.784863 51.121398, 6.909128 51.121398, 6.909128 51.140178, 6.784863 51.140178, 6.784863 51.121398))",
"POLYGON ((6.756579 51.140180, 6.969701 51.140180, 6.969701 51.182634, 6.756579 51.182634, 6.756579 51.140180))",
"POLYGON ((6.756579 51.182634, 6.946906 51.182634, 6.946906 51.212574, 6.756579 51.212574, 6.756579 51.182634))",
"POLYGON ((6.765132 51.212575, 6.947109 51.212575, 6.947109 51.233225, 6.765132 51.233225, 6.765132 51.212575))",
"POLYGON ((6.757516 51.233824, 6.942500 51.233824, 6.942500 51.357748, 6.757516 51.357748, 6.757516 51.233824))",
"POLYGON ((6.758016 51.357762, 6.945823 51.357762, 6.945823 51.362309, 6.758016 51.362309, 6.758016 51.357762))",
"POLYGON ((6.935755 51.362309, 6.953621 51.362309, 6.953621 51.363529, 6.935755 51.363529, 6.935755 51.362309))",
"POLYGON ((6.908803 51.363529, 6.979368 51.363529, 6.979368 51.368139, 6.908803 51.368139, 6.908803 51.363529))",
"POLYGON ((6.772248 51.368148, 6.983869 51.368148, 6.983869 51.420576, 6.772248 51.420576, 6.772248 51.368148))",
"POLYGON ((6.759977 51.420581, 6.983766 51.420581, 6.983766 51.443650, 6.759977 51.443650, 6.759977 51.420581))",
"POLYGON ((6.854032 51.443656, 6.983550 51.443656, 6.983550 51.461847, 6.854032 51.461847, 6.854032 51.443656))",
"POLYGON ((6.856040 51.461847, 6.980667 51.461847, 6.980667 51.474264, 6.856040 51.474264, 6.856040 51.461847))",
"POLYGON ((6.796092 51.474270, 6.958036 51.474270, 6.958036 51.498959, 6.796092 51.498959, 6.796092 51.474270))",
"POLYGON ((6.763475 51.498987, 6.981842 51.498987, 6.981842 51.862883, 6.763475 51.862883, 6.763475 51.498987))",
"POLYGON ((6.777549 51.862883, 6.983936 51.862883, 6.983936 52.374112, 6.777549 52.374112, 6.777549 51.862883))",
"POLYGON ((6.757678 52.374113, 6.982595 52.374113, 6.982595 62.783163, 6.757678 62.783163, 6.757678 52.374113))",
"POLYGON ((7.013792 35.322191, 7.235022 35.322191, 7.235022 43.660370, 7.013792 43.660370, 7.013792 35.322191))",
"POLYGON ((7.006148 43.660372, 7.252048 43.660372, 7.252048 44.192231, 7.006148 44.192231, 7.006148 43.660372))",
"POLYGON ((6.984747 44.192272, 7.242416 44.192272, 7.242416 44.845430, 6.984747 44.845430, 6.984747 44.192272))",
"POLYGON ((7.003992 44.845573, 7.247169 44.845573, 7.247169 45.141552, 7.003992 45.141552, 7.003992 44.845573))",
"POLYGON ((6.983968 45.141563, 7.249358 45.141563, 7.249358 45.710410, 6.983968 45.710410, 6.983968 45.141563))",
"POLYGON ((6.993051 45.710415, 7.252053 45.710415, 7.252053 46.871083, 6.993051 46.871083, 6.993051 45.710415))",
"POLYGON ((7.012605 46.871091, 7.245589 46.871091, 7.245589 47.861384, 7.012605 47.861384, 7.012605 46.871091))",
"POLYGON ((6.988495 47.861460, 7.251971 47.861460, 7.251971 48.454530, 6.988495 48.454530, 6.988495 47.861460))",
"POLYGON ((6.991409 48.454578, 7.251763 48.454578, 7.251763 48.900035, 6.991409 48.900035, 6.991409 48.454578))",
"POLYGON ((6.993483 48.900035, 7.245050 48.900035, 7.245050 49.160142, 6.993483 49.160142, 6.993483 48.900035))",
"POLYGON ((6.984117 49.160260, 7.210626 49.160260, 7.210626 49.224122, 6.984117 49.224122, 6.984117 49.160260))",
"POLYGON ((6.987407 49.224124, 7.049600 49.224124, 7.049600 49.231892, 6.987407 49.231892, 6.987407 49.224124))",
"POLYGON ((6.985387 49.231892, 7.095234 49.231892, 7.095234 49.235038, 6.985387 49.235038, 6.985387 49.231892))",
"POLYGON ((6.984319 49.235049, 7.240576 49.235049, 7.240576 49.315031, 6.984319 49.315031, 6.984319 49.235049))",
"POLYGON ((7.001556 49.315089, 7.251329 49.315089, 7.251329 49.439302, 7.001556 49.439302, 7.001556 49.315089))",
"POLYGON ((6.999761 49.439371, 7.227894 49.439371, 7.227894 49.651569, 6.999761 49.651569, 6.999761 49.439371))",
"POLYGON ((6.984040 49.653084, 7.251409 49.653084, 7.251409 50.104404, 6.984040 50.104404, 6.984040 49.653084))",
"POLYGON ((6.993851 50.104406, 7.244992 50.104406, 7.244992 50.540676, 6.993851 50.540676, 6.993851 50.104406))",
"POLYGON ((7.000917 50.540713, 7.251078 50.540713, 7.251078 50.663862, 7.000917 50.663862, 7.000917 50.540713))",
"POLYGON ((7.034523 50.663866, 7.251474 50.663866, 7.251474 50.717608, 7.034523 50.717608, 7.034523 50.663866))",
"POLYGON ((7.040259 50.717661, 7.186888 50.717661, 7.186888 50.732555, 7.040259 50.732555, 7.040259 50.717661))",
"POLYGON ((6.984284 50.732564, 7.147169 50.732564, 7.147169 50.754442, 6.984284 50.754442, 6.984284 50.732564))",
"POLYGON ((6.993693 50.754442, 7.217005 50.754442, 7.217005 50.798733, 6.993693 50.798733, 6.993693 50.754442))",
"POLYGON ((6.988040 50.798735, 7.209813 50.798735, 7.209813 50.805467, 6.988040 50.805467, 6.988040 50.798735))",
"POLYGON ((7.019108 50.805469, 7.189936 50.805469, 7.189936 50.813612, 7.019108 50.813612, 7.019108 50.805469))",
"POLYGON ((7.139910 50.813613, 7.174444 50.813613, 7.174444 50.819830, 7.139910 50.819830, 7.139910 50.813613))",
"POLYGON ((7.004716 50.819836, 7.217010 50.819836, 7.217010 50.905962, 7.004716 50.905962, 7.004716 50.819836))",
"POLYGON ((6.995224 50.905972, 7.198625 50.905972, 7.198625 50.937004, 6.995224 50.937004, 6.995224 50.905972))",
"POLYGON ((6.997203 50.937005, 7.198462 50.937005, 7.198462 50.938879, 6.997203 50.938879, 6.997203 50.937005))",
"POLYGON ((6.999061 50.938879, 7.036062 50.938879, 7.036062 50.942754, 6.999061 50.942754, 6.999061 50.938879))",
"POLYGON ((6.997510 50.942781, 7.162319 50.942781, 7.162319 50.983783, 6.997510 50.983783, 6.997510 50.942781))",
"POLYGON ((6.985592 50.983788, 7.218404 50.983788, 7.218404 51.137254, 6.985592 51.137254, 6.985592 50.983788))",
"POLYGON ((6.991804 51.137254, 7.231160 51.137254, 7.231160 51.249483, 6.991804 51.249483, 6.991804 51.137254))",
"POLYGON ((6.989242 51.249486, 7.249895 51.249486, 7.249895 51.372084, 6.989242 51.372084, 6.989242 51.249486))",
"POLYGON ((6.992741 51.372084, 7.246168 51.372084, 7.246168 51.383654, 6.992741 51.383654, 6.992741 51.372084))",
"POLYGON ((6.983942 51.383679, 7.184554 51.383679, 7.184554 51.395021, 6.983942 51.395021, 6.983942 51.383679))",
"POLYGON ((6.998491 51.395060, 7.230620 51.395060, 7.230620 51.412234, 6.998491 51.412234, 6.998491 51.395060))",
"POLYGON ((7.003502 51.412254, 7.127484 51.412254, 7.127484 51.424950, 7.003502 51.424950, 7.003502 51.412254))",
"POLYGON ((7.000999 51.424951, 7.164395 51.424951, 7.164395 51.429820, 7.000999 51.429820, 7.000999 51.424951))",
"POLYGON ((6.985387 51.429822, 7.230609 51.429822, 7.230609 51.436891, 6.985387 51.436891, 6.985387 51.429822))",
"POLYGON ((6.997391 51.436893, 7.135587 51.436893, 7.135587 51.439689, 6.997391 51.439689, 6.997391 51.436893))",
"POLYGON ((7.002478 51.439690, 7.119848 51.439690, 7.119848 51.443985, 7.002478 51.443985, 7.002478 51.439690))",
"POLYGON ((7.005004 51.444001, 7.092429 51.444001, 7.092429 51.444940, 7.005004 51.444940, 7.005004 51.444001))",
"POLYGON ((7.005472 51.444940, 7.115518 51.444940, 7.115518 51.446186, 7.005472 51.446186, 7.005472 51.444940))",
"POLYGON ((7.010048 51.446191, 7.194222 51.446191, 7.194222 51.447074, 7.010048 51.447074, 7.010048 51.446191))",
"POLYGON ((7.002904 51.447074, 7.194194 51.447074, 7.194194 51.448104, 7.002904 51.448104, 7.002904 51.447074))",
"POLYGON ((7.005679 51.448106, 7.193547 51.448106, 7.193547 51.451320, 7.005679 51.451320, 7.005679 51.448106))",
"POLYGON ((7.008941 51.451371, 7.103263 51.451371, 7.103263 51.455493, 7.008941 51.455493, 7.008941 51.451371))",
"POLYGON ((7.009647 51.455494, 7.031705 51.455494, 7.031705 51.457199, 7.009647 51.457199, 7.009647 51.455494))",
"POLYGON ((6.993349 51.457202, 7.229053 51.457202, 7.229053 51.459712, 6.993349 51.459712, 6.993349 51.457202))",
"POLYGON ((6.994733 51.459723, 7.228709 51.459723, 7.228709 51.465241, 6.994733 51.465241, 6.994733 51.459723))",
"POLYGON ((7.015076 51.465245, 7.084636 51.465245, 7.084636 51.467157, 7.015076 51.467157, 7.015076 51.465245))",
"POLYGON ((7.015731 51.467165, 7.093112 51.467165, 7.093112 51.469773, 7.015731 51.469773, 7.015731 51.467165))",
"POLYGON ((6.998421 51.469783, 7.231434 51.469783, 7.231434 51.481480, 6.998421 51.481480, 6.998421 51.469783))",
"POLYGON ((7.009282 51.481488, 7.228888 51.481488, 7.228888 51.490207, 7.009282 51.490207, 7.009282 51.481488))",
"POLYGON ((7.007596 51.490208, 7.230256 51.490208, 7.230256 51.497233, 7.007596 51.497233, 7.007596 51.490208))",
"POLYGON ((7.006786 51.497258, 7.150183 51.497258, 7.150183 51.501643, 7.006786 51.501643, 7.006786 51.497258))",
"POLYGON ((6.989773 51.501661, 7.252058 51.501661, 7.252058 51.644451, 6.989773 51.644451, 6.989773 51.501661))",
"POLYGON ((7.014207 51.644451, 7.229469 51.644451, 7.229469 51.946081, 7.014207 51.946081, 7.014207 51.644451))",
"POLYGON ((6.984004 51.946107, 7.251753 51.946107, 7.251753 52.210117, 6.984004 52.210117, 6.984004 51.946107))",
"POLYGON ((6.994230 52.210118, 7.209078 52.210118, 7.209078 53.007291, 6.994230 53.007291, 6.994230 52.210118))",
"POLYGON ((6.995689 53.007319, 7.233878 53.007319, 7.233878 53.502341, 6.995689 53.502341, 6.995689 53.007319))",
"POLYGON ((6.991161 53.621777, 7.204974 53.621777, 7.204974 61.280528, 6.991161 61.280528, 6.991161 53.621777))",
"POLYGON ((7.262327 36.378652, 7.764585 36.378652, 7.764585 43.744688, 7.262327 43.744688, 7.262327 36.378652))",
"POLYGON ((7.253792 43.744902, 7.759831 43.744902, 7.759831 43.891854, 7.253792 43.891854, 7.253792 43.744902))",
"POLYGON ((7.252168 43.901105, 7.765975 43.901105, 7.765975 44.025714, 7.252168 44.025714, 7.252168 43.901105))",
"POLYGON ((7.259601 44.025756, 7.741291 44.025756, 7.741291 44.064941, 7.259601 44.064941, 7.259601 44.025756))",
"POLYGON ((7.455514 44.065156, 7.715402 44.065156, 7.715402 44.119731, 7.455514 44.119731, 7.455514 44.065156))",
"POLYGON ((7.253139 44.119732, 7.671173 44.119732, 7.671173 44.305985, 7.253139 44.305985, 7.253139 44.119732))",
"POLYGON ((7.284959 44.305989, 7.728469 44.305989, 7.728469 44.720546, 7.284959 44.720546, 7.284959 44.305989))",
"POLYGON ((7.283470 44.720565, 7.755575 44.720565, 7.755575 45.032049, 7.283470 45.032049, 7.283470 44.720565))",
"POLYGON ((7.432436 45.032049, 7.709771 45.032049, 7.709771 45.068785, 7.432436 45.068785, 7.432436 45.032049))",
"POLYGON ((7.507930 45.068791, 7.767156 45.068791, 7.767156 45.097993, 7.507930 45.097993, 7.507930 45.068791))",
"POLYGON ((7.323972 45.098010, 7.762468 45.098010, 7.762468 45.390802, 7.323972 45.390802, 7.323972 45.098010))",
"POLYGON ((7.293758 45.390815, 7.766437 45.390815, 7.766437 45.682765, 7.293758 45.682765, 7.293758 45.390815))",
"POLYGON ((7.252077 45.682778, 7.757598 45.682778, 7.757598 45.744935, 7.252077 45.744935, 7.252077 45.682778))",
"POLYGON ((7.252064 45.744956, 7.752236 45.744956, 7.752236 46.673433, 7.252064 46.673433, 7.252064 45.744956))",
"POLYGON ((7.257441 46.673544, 7.688720 46.673544, 7.688720 46.942948, 7.257441 46.942948, 7.257441 46.673544))",
"POLYGON ((7.260089 46.942957, 7.628862 46.942957, 7.628862 47.054513, 7.260089 47.054513, 7.260089 46.942957))",
"POLYGON ((7.341880 47.054578, 7.763576 47.054578, 7.763576 47.381852, 7.341880 47.381852, 7.341880 47.054578))",
"POLYGON ((7.292330 47.381852, 7.753017 47.381852, 7.753017 47.532777, 7.292330 47.532777, 7.292330 47.381852))",
"POLYGON ((7.330524 47.532834, 7.732021 47.532834, 7.732021 47.752882, 7.330524 47.752882, 7.330524 47.532834))",
"POLYGON ((7.252204 47.752882, 7.767534 47.752882, 7.767534 48.134246, 7.252204 48.134246, 7.252204 47.752882))",
"POLYGON ((7.262212 48.134251, 7.767858 48.134251, 7.767858 48.323247, 7.262212 48.323247, 7.262212 48.134251))",
"POLYGON ((7.345027 48.323257, 7.699733 48.323257, 7.699733 48.439820, 7.345027 48.439820, 7.345027 48.323257))",
"POLYGON ((7.252416 48.439839, 7.563832 48.439839, 7.563832 48.493151, 7.252416 48.493151, 7.252416 48.439839))",
"POLYGON ((7.265995 48.493152, 7.751618 48.493152, 7.751618 48.526620, 7.265995 48.526620, 7.265995 48.493152))",
"POLYGON ((7.283185 48.526670, 7.760530 48.526670, 7.760530 48.581432, 7.283185 48.581432, 7.283185 48.526670))",
"POLYGON ((7.313627 48.581450, 7.760160 48.581450, 7.760160 48.641164, 7.313627 48.641164, 7.313627 48.581450))",
"POLYGON ((7.252782 48.641164, 7.767973 48.641164, 7.767973 48.872172, 7.252782 48.872172, 7.252782 48.641164))",
"POLYGON ((7.281276 48.872253, 7.747905 48.872253, 7.747905 49.051296, 7.281276 49.051296, 7.281276 48.872253))",
"POLYGON ((7.259670 49.051297, 7.738760 49.051297, 7.738760 49.157949, 7.259670 49.157949, 7.259670 49.051297))",
"POLYGON ((7.261532 49.158020, 7.767980 49.158020, 7.767980 49.451780, 7.261532 49.451780, 7.261532 49.158020))",
"POLYGON ((7.256031 49.451780, 7.767985 49.451780, 7.767985 49.776776, 7.256031 49.776776, 7.256031 49.451780))",
"POLYGON ((7.617740 49.776776, 7.740718 49.776776, 7.740718 49.787309, 7.617740 49.787309, 7.617740 49.776776))",
"POLYGON ((7.389399 49.787324, 7.764870 49.787324, 7.764870 49.846703, 7.389399 49.846703, 7.389399 49.787324))",
"POLYGON ((7.344683 49.846703, 7.767341 49.846703, 7.767341 50.057305, 7.344683 50.057305, 7.344683 49.846703))",
"POLYGON ((7.285459 50.057319, 7.766328 50.057319, 7.766328 50.205180, 7.285459 50.205180, 7.285459 50.057319))",
"POLYGON ((7.286325 50.205189, 7.664208 50.205189, 7.664208 50.306232, 7.286325 50.306232, 7.286325 50.205189))",
"POLYGON ((7.256246 50.306235, 7.725230 50.306235, 7.725230 50.362389, 7.256246 50.362389, 7.256246 50.306235))",
"POLYGON ((7.297817 50.362390, 7.757998 50.362390, 7.757998 50.427816, 7.297817 50.427816, 7.297817 50.362390))",
"POLYGON ((7.304969 50.428565, 7.659528 50.428565, 7.659528 50.515779, 7.304969 50.515779, 7.304969 50.428565))",
"POLYGON ((7.271883 50.515808, 7.666216 50.515808, 7.666216 50.752571, 7.271883 50.752571, 7.271883 50.515808))",
"POLYGON ((7.262712 50.752575, 7.600481 50.752575, 7.600481 50.813976, 7.262712 50.813976, 7.262712 50.752575))",
"POLYGON ((7.259091 50.813991, 7.736725 50.813991, 7.736725 50.905166, 7.259091 50.905166, 7.259091 50.813991))",
"POLYGON ((7.268940 50.905168, 7.751839 50.905168, 7.751839 51.217318, 7.268940 51.217318, 7.268940 50.905168))",
"POLYGON ((7.314467 51.217351, 7.766708 51.217351, 7.766708 51.345786, 7.314467 51.345786, 7.314467 51.217351))",
"POLYGON ((7.261936 51.345794, 7.686722 51.345794, 7.686722 51.366370, 7.261936 51.366370, 7.261936 51.345794))",
"POLYGON ((7.269794 51.366391, 7.696832 51.366391, 7.696832 51.387005, 7.269794 51.387005, 7.269794 51.366391))",
"POLYGON ((7.368049 51.387007, 7.490139 51.387007, 7.490139 51.390144, 7.368049 51.390144, 7.368049 51.387007))",
"POLYGON ((7.254137 51.390169, 7.761506 51.390169, 7.761506 51.409213, 7.254137 51.409213, 7.254137 51.390169))",
"POLYGON ((7.257752 51.409216, 7.567952 51.409216, 7.567952 51.424640, 7.257752 51.424640, 7.257752 51.409216))",
"POLYGON ((7.280611 51.424657, 7.354116 51.424657, 7.354116 51.435253, 7.280611 51.435253, 7.280611 51.424657))",
"POLYGON ((7.269413 51.435258, 7.532203 51.435258, 7.532203 51.439939, 7.269413 51.439939, 7.269413 51.435258))",
"POLYGON ((7.319502 51.439943, 7.479390 51.439943, 7.479390 51.451649, 7.319502 51.451649, 7.319502 51.439943))",
"POLYGON ((7.298179 51.451655, 7.710073 51.451655, 7.710073 51.492887, 7.298179 51.492887, 7.298179 51.451655))",
"POLYGON ((7.252872 51.492893, 7.750431 51.492893, 7.750431 51.620432, 7.252872 51.620432, 7.252872 51.492893))",
"POLYGON ((7.269653 51.620475, 7.764463 51.620475, 7.764463 51.732641, 7.269653 51.732641, 7.269653 51.620475))",
"POLYGON ((7.262629 51.732641, 7.748434 51.732641, 7.748434 51.895422, 7.262629 51.895422, 7.262629 51.732641))",
"POLYGON ((7.360809 51.895428, 7.730754 51.895428, 7.730754 51.961096, 7.360809 51.961096, 7.360809 51.895428))",
"POLYGON ((7.277557 51.961156, 7.634964 51.961156, 7.634964 51.963491, 7.277557 51.963491, 7.277557 51.961156))",
"POLYGON ((7.274880 51.963491, 7.626153 51.963491, 7.626153 51.975852, 7.274880 51.975852, 7.274880 51.963491))",
"POLYGON ((7.273139 51.975860, 7.648328 51.975860, 7.648328 52.016073, 7.273139 52.016073, 7.273139 51.975860))",
"POLYGON ((7.261250 52.016096, 7.726651 52.016096, 7.726651 52.278783, 7.261250 52.278783, 7.261250 52.016096))",
"POLYGON ((7.294267 52.278788, 7.761946 52.278788, 7.761946 52.773140, 7.294267 52.773140, 7.294267 52.278788))",
"POLYGON ((7.282831 52.773266, 7.765405 52.773266, 7.765405 58.072797, 7.282831 58.072797, 7.282831 52.773266))",
"POLYGON ((7.901294 35.400332, 8.424000 35.400332, 8.424000 40.560472, 7.901294 40.560472, 7.901294 35.400332))",
"POLYGON ((8.149864 40.560474, 8.406666 40.560474, 8.406666 40.836965, 8.149864 40.836965, 8.149864 40.560474))",
"POLYGON ((7.773254 40.836986, 8.416851 40.836986, 8.416851 43.899838, 7.773254 43.899838, 7.773254 40.836986))",
"POLYGON ((7.894642 43.899903, 7.967940 43.899903, 7.967940 43.918416, 7.894642 43.918416, 7.894642 43.899903))",
"POLYGON ((7.851067 43.918422, 8.227399 43.918422, 8.227399 44.041148, 7.851067 44.041148, 7.851067 43.918422))",
"POLYGON ((7.861747 44.041162, 8.424710 44.041162, 8.424710 44.203537, 7.861747 44.203537, 7.861747 44.041162))",
"POLYGON ((7.879196 44.203541, 8.419154 44.203541, 8.419154 44.315894, 7.879196 44.315894, 7.879196 44.203541))",
"POLYGON ((7.835420 44.315897, 8.422856 44.315897, 8.422856 44.649860, 7.835420 44.649860, 7.835420 44.315897))",
"POLYGON ((7.773028 44.649879, 8.409016 44.649879, 8.409016 45.094051, 7.773028 45.094051, 7.773028 44.649879))",
"POLYGON ((7.817009 45.094051, 8.424719 45.094051, 8.424719 45.392079, 7.817009 45.392079, 7.817009 45.094051))",
"POLYGON ((7.788820 45.392081, 8.282679 45.392081, 8.282679 45.468270, 7.788820 45.468270, 7.788820 45.392081))",
"POLYGON ((7.778843 45.468270, 8.316717 45.468270, 8.316717 45.545874, 7.778843 45.545874, 7.778843 45.468270))",
"POLYGON ((7.790202 45.545874, 8.424692 45.545874, 8.424692 45.625796, 7.790202 45.625796, 7.790202 45.545874))",
"POLYGON ((7.796082 45.625811, 8.422365 45.625811, 8.422365 47.051318, 7.796082 47.051318, 7.796082 45.625811))",
"POLYGON ((7.771287 47.051318, 8.423898 47.051318, 8.423898 47.332269, 7.771287 47.332269, 7.771287 47.051318))",
"POLYGON ((7.771187 47.332270, 8.317923 47.332270, 8.317923 47.473256, 7.771187 47.473256, 7.771187 47.332270))",
"POLYGON ((7.794891 47.473258, 8.419213 47.473258, 8.419213 47.759852, 7.794891 47.759852, 7.794891 47.473258))",
"POLYGON ((7.769402 47.759870, 8.410412 47.759870, 8.410412 47.968116, 7.769402 47.968116, 7.769402 47.759870))",
"POLYGON ((7.768663 47.968120, 8.397427 47.968120, 8.397427 48.129775, 7.768663 48.129775, 7.768663 47.968120))",
"POLYGON ((7.768008 48.132801, 8.414293 48.132801, 8.414293 48.334740, 7.768008 48.334740, 7.768008 48.132801))",
"POLYGON ((7.768038 48.334746, 8.404502 48.334746, 8.404502 48.569411, 7.768038 48.569411, 7.768038 48.334746))",
"POLYGON ((7.771724 48.569411, 8.394190 48.569411, 8.394190 48.776399, 7.771724 48.776399, 7.771724 48.569411))",
"POLYGON ((7.767990 48.776400, 8.298785 48.776400, 8.298785 48.858004, 7.767990 48.858004, 7.767990 48.776400))",
"POLYGON ((7.912151 48.858011, 8.421648 48.858011, 8.421648 48.972176, 7.912151 48.972176, 7.912151 48.858011))",
"POLYGON ((7.769807 48.972179, 8.418233 48.972179, 8.418233 49.011111, 7.769807 49.011111, 7.769807 48.972179))",
"POLYGON ((7.939544 49.011150, 8.421176 49.011150, 8.421176 49.037267, 7.939544 49.037267, 7.939544 49.011150))",
"POLYGON ((7.783553 49.037270, 8.286324 49.037270, 8.286324 49.140725, 7.783553 49.140725, 7.783553 49.037270))",
"POLYGON ((7.771547 49.140729, 8.401925 49.140729, 8.401925 49.248007, 7.771547 49.248007, 7.771547 49.140729))",
"POLYGON ((7.767990 49.248020, 8.411510 49.248020, 8.411510 49.387357, 7.767990 49.387357, 7.767990 49.248020))",
"POLYGON ((7.767993 49.387399, 8.392882 49.387399, 8.392882 49.471900, 7.767993 49.471900, 7.767993 49.387399))",
"POLYGON ((7.936512 49.471991, 8.405873 49.471991, 8.405873 49.543004, 7.936512 49.543004, 7.936512 49.471991))",
"POLYGON ((7.779832 49.543004, 8.392916 49.543004, 8.392916 49.750282, 7.779832 49.750282, 7.779832 49.543004))",
"POLYGON ((7.782279 49.750288, 8.357175 49.750288, 8.357175 49.878268, 7.782279 49.878268, 7.782279 49.750288))",
"POLYGON ((7.768549 49.878269, 8.419091 49.878269, 8.419091 49.976049, 7.768549 49.976049, 7.768549 49.878269))",
"POLYGON ((7.857125 49.976049, 8.292113 49.976049, 8.292113 49.989310, 7.857125 49.989310, 7.857125 49.976049))",
"POLYGON ((7.858183 49.989317, 8.409465 49.989317, 8.409465 49.996971, 7.858183 49.996971, 7.858183 49.989317))",
"POLYGON ((7.852874 49.996973, 8.419940 49.996973, 8.419940 50.006614, 7.852874 50.006614, 7.852874 49.996973))",
"POLYGON ((7.787270 50.006626, 8.402979 50.006626, 8.402979 50.047325, 7.787270 50.047325, 7.787270 50.006626))",
"POLYGON ((7.767993 50.047326, 8.402855 50.047326, 8.402855 50.222005, 7.767993 50.222005, 7.767993 50.047326))",
"POLYGON ((7.791357 50.222009, 8.410790 50.222009, 8.410790 50.339199, 7.791357 50.339199, 7.791357 50.222009))",
"POLYGON ((7.818290 50.339228, 8.406173 50.339228, 8.406173 50.555744, 7.818290 50.555744, 7.818290 50.339228))",
"POLYGON ((7.798848 50.555746, 8.417850 50.555746, 8.417850 50.875687, 7.798848 50.875687, 7.798848 50.555746))",
"POLYGON ((7.789404 50.875695, 8.398238 50.875695, 8.398238 50.960783, 7.789404 50.960783, 7.789404 50.875695))",
"POLYGON ((7.941252 50.960783, 8.043229 50.960783, 8.043229 50.972411, 7.941252 50.972411, 7.941252 50.960783))",
"POLYGON ((7.953248 50.972415, 8.388871 50.972415, 8.388871 51.051176, 7.953248 51.051176, 7.953248 50.972415))",
"POLYGON ((7.777325 51.051179, 8.402877 51.051179, 8.402877 51.307882, 7.777325 51.307882, 7.777325 51.051179))",
"POLYGON ((7.844493 51.307891, 8.401965 51.307891, 8.401965 51.373598, 7.844493 51.373598, 7.844493 51.307891))",
"POLYGON ((7.801137 51.377652, 8.323414 51.377652, 8.323414 51.434517, 7.801137 51.434517, 7.801137 51.377652))",
"POLYGON ((7.782799 51.434517, 8.410525 51.434517, 8.410525 51.547803, 7.782799 51.547803, 7.782799 51.434517))",
"POLYGON ((7.770725 51.547804, 8.399960 51.547804, 8.399960 51.635288, 7.770725 51.635288, 7.770725 51.547804))",
"POLYGON ((7.791192 51.644068, 8.422185 51.644068, 8.422185 51.675312, 7.791192 51.675312, 7.791192 51.644068))",
"POLYGON ((7.808823 51.675313, 8.348194 51.675313, 8.348194 51.678492, 7.808823 51.678492, 7.808823 51.675313))",
"POLYGON ((7.793156 51.678495, 8.340842 51.678495, 8.340842 51.681568, 7.793156 51.681568, 7.793156 51.678495))",
"POLYGON ((7.788828 51.681569, 8.067486 51.681569, 8.067486 51.707927, 7.788828 51.707927, 7.788828 51.681569))",
"POLYGON ((7.776121 51.707938, 8.400131 51.707938, 8.400131 51.843999, 7.776121 51.843999, 7.776121 51.707938))",
"POLYGON ((7.785816 51.843999, 8.381984 51.843999, 8.381984 52.146683, 7.785816 52.146683, 7.785816 51.843999))",
"POLYGON ((7.806826 52.146716, 8.423245 52.146716, 8.423245 52.219614, 7.806826 52.219614, 7.806826 52.146716))",
"POLYGON ((7.795159 52.219619, 8.201326 52.219619, 8.201326 52.276262, 7.795159 52.276262, 7.795159 52.219619))",
"POLYGON ((7.770599 52.276265, 8.377616 52.276265, 8.377616 53.137745, 7.770599 53.137745, 7.770599 52.276265))",
"POLYGON ((8.197078 53.137747, 8.251379 53.137747, 8.251379 53.148602, 8.197078 53.148602, 8.197078 53.137747))",
"POLYGON ((7.889107 53.148628, 8.356762 53.148628, 8.356762 53.532507, 7.889107 53.532507, 7.889107 53.148628))",
"POLYGON ((7.782972 53.532507, 8.423561 53.532507, 8.423561 55.560984, 7.782972 55.560984, 7.782972 53.532507))",
"POLYGON ((7.826839 55.560984, 8.374140 55.560984, 8.374140 63.134742, 7.826839 63.134742, 7.826839 55.560984))",
"POLYGON ((8.451888 26.495231, 8.799577 26.495231, 8.799577 36.493053, 8.451888 36.493053, 8.451888 26.495231))",
"POLYGON ((8.572269 36.493091, 8.759502 36.493091, 8.759502 36.957361, 8.572269 36.957361, 8.572269 36.493091))",
"POLYGON ((8.424771 36.957368, 8.803278 36.957368, 8.803278 39.387843, 8.424771 39.387843, 8.424771 36.957368))",
"POLYGON ((8.500063 39.387903, 8.814420 39.387903, 8.814420 39.678321, 8.500063 39.678321, 8.500063 39.387903))",
"POLYGON ((8.438064 39.678349, 8.797388 39.678349, 8.797388 39.919590, 8.438064 39.919590, 8.438064 39.678349))",
"POLYGON ((8.452600 39.919663, 8.809050 39.919663, 8.809050 40.000281, 8.452600 40.000281, 8.452600 39.919663))",
"POLYGON ((8.437554 40.000326, 8.794050 40.000326, 8.794050 40.051943, 8.437554 40.051943, 8.437554 40.000326))",
"POLYGON ((8.437588 40.051944, 8.790521 40.051944, 8.790521 40.176694, 8.437588 40.176694, 8.437588 40.051944))",
"POLYGON ((8.457935 40.176786, 8.808732 40.176786, 8.808732 40.246334, 8.457935 40.246334, 8.457935 40.176786))",
"POLYGON ((8.528421 40.246349, 8.815326 40.246349, 8.815326 40.284404, 8.528421 40.284404, 8.528421 40.246349))",
"POLYGON ((8.474223 40.284481, 8.813619 40.284481, 8.813619 40.324421, 8.474223 40.324421, 8.474223 40.284481))",
"POLYGON ((8.437771 40.324427, 8.813513 40.324427, 8.813513 40.589007, 8.437771 40.589007, 8.437771 40.324427))",
"POLYGON ((8.441389 40.589012, 8.765219 40.589012, 8.765219 40.802527, 8.441389 40.802527, 8.441389 40.589012))",
"POLYGON ((8.443596 40.802528, 8.814848 40.802528, 8.814848 42.133527, 8.443596 42.133527, 8.443596 40.802528))",
"POLYGON ((8.424745 42.133528, 8.799867 42.133528, 8.799867 44.424597, 8.424745 44.424597, 8.424745 42.133528))",
"POLYGON ((8.425450 44.424604, 8.805602 44.424604, 8.805602 44.842764, 8.425450 44.842764, 8.425450 44.424604))",
"POLYGON ((8.444617 44.842910, 8.716470 44.842910, 8.716470 45.137712, 8.444617 45.137712, 8.444617 44.842910))",
"POLYGON ((8.424727 45.137712, 8.800070 45.137712, 8.800070 45.511278, 8.424727 45.511278, 8.424727 45.137712))",
"POLYGON ((8.424740 45.511384, 8.815796 45.511384, 8.815796 45.870429, 8.424740 45.870429, 8.424740 45.511384))",
"POLYGON ((8.458888 45.870429, 8.804802 45.870429, 8.804802 47.000331, 8.458888 47.000331, 8.458888 45.870429))",
"POLYGON ((8.428127 47.000384, 8.815691 47.000384, 8.815691 47.517002, 8.428127 47.517002, 8.428127 47.000384))",
"POLYGON ((8.429659 47.517002, 8.815953 47.517002, 8.815953 47.836285, 8.429659 47.836285, 8.429659 47.517002))",
"POLYGON ((8.442917 47.836285, 8.804753 47.836285, 8.804753 48.586546, 8.442917 48.586546, 8.442917 47.836285))",
"POLYGON ((8.436338 48.586561, 8.802364 48.586561, 8.802364 48.887867, 8.436338 48.887867, 8.436338 48.586561))",
"POLYGON ((8.426970 48.887924, 8.815328 48.887924, 8.815328 49.015183, 8.426970 49.015183, 8.426970 48.887924))",
"POLYGON ((8.450852 49.015183, 8.778108 49.015183, 8.778108 49.252521, 8.450852 49.252521, 8.450852 49.015183))",
"POLYGON ((8.445278 49.252522, 8.719417 49.252522, 8.719417 49.311331, 8.445278 49.311331, 8.445278 49.252522))",
"POLYGON ((8.441195 49.311336, 8.447995 49.311336, 8.447995 49.312628, 8.441195 49.312628, 8.441195 49.311336))",
"POLYGON ((8.426394 49.312630, 8.561685 49.312630, 8.561685 49.323619, 8.426394 49.323619, 8.426394 49.312630))",
"POLYGON ((8.559718 49.323875, 8.809521 49.323875, 8.809521 49.410332, 8.559718 49.410332, 8.559718 49.323875))",
"POLYGON ((8.662812 49.410340, 8.718185 49.410340, 8.718185 49.419232, 8.662812 49.419232, 8.662812 49.410340))",
"POLYGON ((8.427987 49.419236, 8.709616 49.419236, 8.709616 49.424108, 8.427987 49.424108, 8.427987 49.419236))",
"POLYGON ((8.675380 49.424118, 8.710679 49.424118, 8.710679 49.427706, 8.675380 49.427706, 8.675380 49.424118))",
"POLYGON ((8.427347 49.427710, 8.710070 49.427710, 8.710070 49.484254, 8.427347 49.484254, 8.427347 49.427710))",
"POLYGON ((8.431802 49.484272, 8.808828 49.484272, 8.808828 49.589982, 8.431802 49.589982, 8.431802 49.484272))",
"POLYGON ((8.460689 49.590006, 8.813522 49.590006, 8.813522 49.872898, 8.460689 49.872898, 8.460689 49.590006))",
"POLYGON ((8.430869 49.875213, 8.796760 49.875213, 8.796760 50.038060, 8.430869 50.038060, 8.430869 49.875213))",
"POLYGON ((8.430583 50.038062, 8.808955 50.038062, 8.808955 50.100753, 8.430583 50.100753, 8.430583 50.038062))",
"POLYGON ((8.551528 50.100864, 8.765103 50.100864, 8.765103 50.110756, 8.551528 50.110756, 8.551528 50.100864))",
"POLYGON ((8.452024 50.110761, 8.801956 50.110761, 8.801956 50.183356, 8.452024 50.183356, 8.452024 50.110761))",
"POLYGON ((8.440399 50.183401, 8.802407 50.183401, 8.802407 50.218400, 8.440399 50.218400, 8.440399 50.183401))",
"POLYGON ((8.435532 50.218423, 8.771645 50.218423, 8.771645 50.240125, 8.435532 50.240125, 8.435532 50.218423))",
"POLYGON ((8.428106 50.240286, 8.802993 50.240286, 8.802993 50.331090, 8.428106 50.331090, 8.428106 50.240286))",
"POLYGON ((8.491837 50.331155, 8.777489 50.331155, 8.777489 50.450983, 8.491837 50.450983, 8.491837 50.331155))",
"POLYGON ((8.431476 50.450987, 8.815948 50.450987, 8.815948 50.704802, 8.431476 50.704802, 8.431476 50.450987))",
"POLYGON ((8.478905 50.704805, 8.815930 50.704805, 8.815930 50.827172, 8.478905 50.827172, 8.478905 50.704805))",
"POLYGON ((8.425102 50.827227, 8.814355 50.827227, 8.814355 51.393488, 8.425102 51.393488, 8.425102 50.827227))",
"POLYGON ((8.425005 51.393492, 8.772914 51.393492, 8.772914 51.490893, 8.425005 51.490893, 8.425005 51.393492))",
"POLYGON ((8.424997 51.490894, 8.814086 51.490894, 8.814086 51.607201, 8.424997 51.607201, 8.424997 51.490894))",
"POLYGON ((8.425917 51.607206, 8.796419 51.607206, 8.796419 51.715965, 8.425917 51.715965, 8.425917 51.607206))",
"POLYGON ((8.592002 51.715966, 8.764914 51.715966, 8.764914 51.736348, 8.592002 51.736348, 8.592002 51.715966))",
"POLYGON ((8.425147 51.736419, 8.795435 51.736419, 8.795435 52.115021, 8.425147 52.115021, 8.425147 51.736419))",
"POLYGON ((8.455667 52.115022, 8.813341 52.115022, 8.813341 52.233688, 8.455667 52.233688, 8.455667 52.115022))",
"POLYGON ((8.466644 52.233699, 8.805836 52.233699, 8.805836 52.302811, 8.466644 52.302811, 8.466644 52.233699))",
"POLYGON ((8.427022 52.302902, 8.745594 52.302902, 8.745594 53.053883, 8.427022 53.053883, 8.427022 52.302902))",
"POLYGON ((8.635904 53.053886, 8.813950 53.053886, 8.813950 53.073863, 8.635904 53.073863, 8.635904 53.053886))",
"POLYGON ((8.802201 53.073870, 8.809835 53.073870, 8.809835 53.076325, 8.802201 53.076325, 8.802201 53.073870))",
"POLYGON ((8.524071 53.076329, 8.815955 53.076329, 8.815955 53.097645, 8.524071 53.097645, 8.524071 53.076329))",
"POLYGON ((8.448609 53.097653, 8.791875 53.097653, 8.791875 53.179399, 8.448609 53.179399, 8.448609 53.097653))",
"POLYGON ((8.426307 53.179405, 8.794598 53.179405, 8.794598 53.521804, 8.426307 53.521804, 8.426307 53.179405))",
"POLYGON ((8.516074 53.521804, 8.790880 53.521804, 8.790880 53.652006, 8.516074 53.652006, 8.516074 53.521804))",
"POLYGON ((8.427988 53.652086, 8.814973 53.652086, 8.814973 54.812185, 8.427988 54.812185, 8.427988 53.652086))",
"POLYGON ((8.426270 54.812391, 8.809178 54.812391, 8.809178 61.625801, 8.426270 61.625801, 8.426270 54.812391))",
"POLYGON ((8.839462 9.912893, 9.210192 9.912893, 9.210192 35.853838, 8.839462 35.853838, 8.839462 9.912893))",
"POLYGON ((9.018864 35.853860, 9.221591 35.853860, 9.221591 36.422000, 9.018864 36.422000, 9.018864 35.853860))",
"POLYGON ((8.877374 36.422015, 9.221511 36.422015, 9.221511 38.953043, 8.877374 38.953043, 8.877374 36.422015))",
"POLYGON ((8.963248 38.953045, 9.207532 38.953045, 9.207532 39.239680, 8.963248 39.239680, 8.963248 38.953045))",
"POLYGON ((8.817113 39.239694, 9.195957 39.239694, 9.195957 39.589731, 8.817113 39.589731, 8.817113 39.239694))",
"POLYGON ((8.819578 39.589738, 9.173462 39.589738, 9.173462 39.917084, 8.819578 39.917084, 8.819578 39.589738))",
"POLYGON ((8.819327 39.917098, 9.111356 39.917098, 9.111356 40.175542, 8.819327 40.175542, 8.819327 39.917098))",
"POLYGON ((8.825568 40.175556, 9.047217 40.175556, 9.047217 40.275152, 8.825568 40.275152, 8.825568 40.175556))",
"POLYGON ((8.818519 40.275159, 9.222068 40.275159, 9.222068 41.386932, 8.818519 41.386932, 8.818519 40.275159))",
"POLYGON ((8.918081 41.387392, 9.214687 41.387392, 9.214687 42.306518, 8.918081 42.306518, 8.918081 41.387392))",
"POLYGON ((8.838297 42.306521, 9.214146 42.306521, 9.214146 44.407838, 8.838297 44.407838, 8.838297 42.306521))",
"POLYGON ((8.890356 44.407866, 9.070513 44.407866, 9.070513 44.413190, 8.890356 44.413190, 8.890356 44.407866))",
"POLYGON ((8.861182 44.413194, 8.999448 44.413194, 8.999448 44.434397, 8.861182 44.434397, 8.861182 44.413194))",
"POLYGON ((8.873079 44.434399, 9.203299 44.434399, 9.203299 44.590943, 8.873079 44.590943, 8.873079 44.434399))",
"POLYGON ((8.844438 44.590945, 9.197816 44.590945, 9.197816 44.891619, 8.844438 44.891619, 8.844438 44.590945))",
"POLYGON ((8.873688 44.891621, 9.204224 44.891621, 9.204224 45.180008, 8.873688 45.180008, 8.873688 44.891621))",
"POLYGON ((8.856363 45.180023, 9.207243 45.180023, 9.207243 45.464456, 8.856363 45.464456, 8.856363 45.180023))",
"POLYGON ((8.816334 45.464456, 9.211022 45.464456, 9.211022 45.539445, 8.816334 45.539445, 8.816334 45.464456))",
"POLYGON ((9.157296 45.545338, 9.221892 45.545338, 9.221892 45.553963, 9.157296 45.553963, 9.157296 45.545338))",
"POLYGON ((8.815981 45.553970, 9.223303 45.553970, 9.223303 45.601204, 8.815981 45.601204, 8.815981 45.553970))",
"POLYGON ((8.816292 45.601223, 8.874504 45.601223, 8.874504 45.611819, 8.816292 45.611819, 8.816292 45.601223))",
"POLYGON ((8.822418 45.611820, 8.865752 45.611820, 8.865752 45.614795, 8.822418 45.614795, 8.822418 45.611820))",
"POLYGON ((8.816024 45.614799, 9.080923 45.614799, 9.080923 45.631015, 8.816024 45.631015, 8.816024 45.614799))",
"POLYGON ((8.820527 45.631127, 9.180589 45.631127, 9.180589 45.729304, 8.820527 45.729304, 8.820527 45.631127))",
"POLYGON ((8.826575 45.729311, 9.216443 45.729311, 9.216443 45.822229, 8.826575 45.822229, 8.826575 45.729311))",
"POLYGON ((8.828776 45.822247, 9.223687 45.822247, 9.223687 45.877519, 8.828776 45.877519, 8.828776 45.822247))",
"POLYGON ((8.824526 45.877544, 9.211270 45.877544, 9.211270 45.959514, 8.824526 45.959514, 8.824526 45.877544))",
"POLYGON ((8.927622 45.959514, 9.206570 45.959514, 9.206570 45.989731, 8.927622 45.989731, 8.927622 45.959514))",
"POLYGON ((8.960838 45.989752, 9.218774 45.989752, 9.218774 46.192934, 8.960838 46.192934, 8.960838 45.989752))",
"POLYGON ((8.816358 46.192946, 9.193933 46.192946, 9.193933 47.325209, 8.816358 47.325209, 8.816358 46.192946))",
"POLYGON ((8.816019 47.325209, 9.200173 47.325209, 9.200173 47.763718, 8.816019 47.763718, 8.816019 47.325209))",
"POLYGON ((8.816004 47.763733, 9.181575 47.763733, 9.181575 47.768466, 8.816004 47.768466, 8.816004 47.763733))",
"POLYGON ((8.833345 47.768467, 9.198364 47.768467, 9.198364 48.055704, 8.833345 48.055704, 8.833345 47.768467))",
"POLYGON ((8.819877 48.055711, 9.222486 48.055711, 9.222486 48.517013, 8.819877 48.517013, 8.819877 48.055711))",
"POLYGON ((8.843944 48.517017, 9.213253 48.517017, 9.213253 48.749549, 8.843944 48.749549, 8.843944 48.517017))",
"POLYGON ((8.850434 48.749549, 9.209012 48.749549, 9.209012 48.805962, 8.850434 48.805962, 8.850434 48.749549))",
"POLYGON ((8.861485 48.806010, 9.218100 48.806010, 9.218100 48.895944, 8.861485 48.895944, 8.861485 48.806010))",
"POLYGON ((8.816332 48.895948, 9.209452 48.895948, 9.209452 48.930767, 8.816332 48.930767, 8.816332 48.895948))",
"POLYGON ((8.825168 48.930767, 9.204855 48.930767, 9.204855 48.978606, 8.825168 48.978606, 8.825168 48.930767))",
"POLYGON ((8.815961 48.978607, 9.212709 48.978607, 9.212709 49.075923, 8.815961 49.075923, 8.815961 48.978607))",
"POLYGON ((8.847569 49.076019, 9.216909 49.076019, 9.216909 49.142153, 8.847569 49.142153, 8.847569 49.076019))",
"POLYGON ((8.877016 49.142169, 9.220797 49.142169, 9.220797 49.237796, 8.877016 49.237796, 8.877016 49.142169))",
"POLYGON ((8.816110 49.237804, 9.214932 49.237804, 9.214932 49.349162, 8.816110 49.349162, 8.816110 49.237804))",
"POLYGON ((8.818316 49.349168, 9.213815 49.349168, 9.213815 49.596587, 8.818316 49.596587, 8.818316 49.349168))",
"POLYGON ((8.847427 49.596763, 9.220861 49.596763, 9.220861 49.874492, 8.847427 49.874492, 8.847427 49.596763))",
"POLYGON ((8.838268 49.874512, 9.215334 49.874512, 9.215334 50.041535, 8.838268 50.041535, 8.838268 49.874512))",
"POLYGON ((8.816155 50.041552, 9.077576 50.041552, 9.077576 50.121931, 8.816155 50.121931, 8.816155 50.041552))",
"POLYGON ((8.837009 50.121953, 9.195841 50.121953, 9.195841 50.291331, 8.837009 50.291331, 8.837009 50.121953))",
"POLYGON ((9.113877 50.291369, 9.121484 50.291369, 9.121484 50.294370, 9.113877 50.294370, 9.113877 50.291369))",
"POLYGON ((8.816013 50.294379, 9.203367 50.294379, 9.203367 50.674587, 8.816013 50.674587, 8.816013 50.294379))",
"POLYGON ((8.815970 50.674680, 9.146410 50.674680, 9.146410 50.883427, 8.815970 50.883427, 8.815970 50.674680))",
"POLYGON ((8.836455 50.883427, 9.192075 50.883427, 9.192075 51.205098, 8.836455 51.205098, 8.836455 50.883427))",
"POLYGON ((8.873324 51.205306, 9.100453 51.205306, 9.100453 51.272001, 8.873324 51.272001, 8.873324 51.205306))",
"POLYGON ((8.851418 51.272013, 9.198670 51.272013, 9.198670 51.601637, 8.851418 51.601637, 8.851418 51.272013))",
"POLYGON ((8.822423 51.601671, 9.214413 51.601671, 9.214413 51.871037, 8.822423 51.871037, 8.822423 51.601671))",
"POLYGON ((8.847934 51.871038, 9.200644 51.871038, 9.200644 52.106287, 8.847934 52.106287, 8.847934 51.871038))",
"POLYGON ((8.825696 52.106289, 9.200701 52.106289, 9.200701 52.286017, 8.825696 52.286017, 8.825696 52.106289))",
"POLYGON ((8.905922 52.286020, 8.938792 52.286020, 8.938792 52.291125, 8.905922 52.291125, 8.905922 52.286020))",
"POLYGON ((8.897252 52.291126, 9.221201 52.291126, 9.221201 52.485626, 8.897252 52.485626, 8.897252 52.291126))",
"POLYGON ((8.815958 52.485672, 9.223348 52.485672, 9.223348 53.084051, 8.815958 53.084051, 8.815958 52.485672))",
"POLYGON ((8.816015 53.084051, 9.188055 53.084051, 9.188055 54.085688, 8.816015 54.085688, 8.816015 53.084051))",
"POLYGON ((8.827833 54.085715, 9.142126 54.085715, 9.142126 54.619312, 8.827833 54.619312, 8.827833 54.085715))",
"POLYGON ((8.833269 54.619351, 9.223727 54.619351, 9.223727 62.713490, 8.833269 62.713490, 8.833269 54.619351))",
"POLYGON ((9.224192 4.156919, 9.826836 4.156919, 9.826836 36.035192, 9.224192 36.035192, 9.224192 4.156919))",
"POLYGON ((9.244765 36.035482, 9.870632 36.035482, 9.870632 39.236611, 9.244765 39.236611, 9.244765 36.035482))",
"POLYGON ((9.249633 39.236622, 9.683238 39.236622, 9.683238 39.844429, 9.249633 39.844429, 9.249633 39.236622))",
"POLYGON ((9.331247 39.844435, 9.706602 39.844435, 9.706602 40.108138, 9.331247 40.108138, 9.331247 39.844435))",
"POLYGON ((9.256210 40.108145, 9.633804 40.108145, 9.633804 40.323892, 9.256210 40.323892, 9.256210 40.108145))",
"POLYGON ((9.225449 40.323894, 9.777938 40.323894, 9.777938 41.994961, 9.225449 41.994961, 9.225449 40.323894))",
"POLYGON ((9.258613 42.102234, 9.858511 42.102234, 9.858511 44.064028, 9.258613 44.064028, 9.258613 42.102234))",
"POLYGON ((9.225255 44.064072, 9.841681 44.064072, 9.841681 44.339145, 9.225255 44.339145, 9.225255 44.064072))",
"POLYGON ((9.226192 44.339161, 9.850271 44.339161, 9.850271 44.913984, 9.226192 44.913984, 9.226192 44.339161))",
"POLYGON ((9.225175 44.913984, 9.807730 44.913984, 9.807730 45.356083, 9.225175 45.356083, 9.225175 44.913984))",
"POLYGON ((9.236172 45.356133, 9.790987 45.356133, 9.790987 45.584307, 9.236172 45.584307, 9.236172 45.356133))",
"POLYGON ((9.264905 45.584331, 9.870492 45.584331, 9.870492 45.663792, 9.264905 45.663792, 9.264905 45.584331))",
"POLYGON ((9.287281 45.663828, 9.823949 45.663828, 9.823949 45.706824, 9.287281 45.706824, 9.287281 45.663828))",
"POLYGON ((9.224412 45.706824, 9.772557 45.706824, 9.772557 45.752441, 9.224412 45.752441, 9.224412 45.706824))",
"POLYGON ((9.225638 45.752481, 9.864051 45.752481, 9.864051 45.827019, 9.225638 45.827019, 9.225638 45.752481))",
"POLYGON ((9.223911 45.827023, 9.715263 45.827023, 9.715263 45.850462, 9.223911 45.850462, 9.223911 45.827023))",
"POLYGON ((9.249595 45.850478, 9.839383 45.850478, 9.839383 45.956446, 9.249595 45.956446, 9.249595 45.850478))",
"POLYGON ((9.224556 45.956447, 9.804449 45.956447, 9.804449 46.020048, 9.224556 46.020048, 9.224556 45.956447))",
"POLYGON ((9.229996 46.020048, 9.782544 46.020048, 9.782544 46.064174, 9.229996 46.064174, 9.229996 46.020048))",
"POLYGON ((9.232273 46.064174, 9.868661 46.064174, 9.868661 46.691641, 9.232273 46.691641, 9.232273 46.064174))",
"POLYGON ((9.266068 46.691641, 9.851007 46.691641, 9.851007 47.236311, 9.266068 47.236311, 9.266068 46.691641))",
"POLYGON ((9.544994 47.236313, 9.715516 47.236313, 9.715516 47.389929, 9.544994 47.389929, 9.544994 47.236313))",
"POLYGON ((9.240412 47.389939, 9.760602 47.389939, 9.760602 47.547235, 9.240412 47.547235, 9.240412 47.389939))",
"POLYGON ((9.270058 47.547295, 9.863769 47.547295, 9.863769 47.776665, 9.270058 47.776665, 9.270058 47.547295))",
"POLYGON ((9.245375 47.776729, 9.830339 47.776729, 9.830339 48.061952, 9.245375 48.061952, 9.245375 47.776729))",
"POLYGON ((9.259623 48.061957, 9.830510 48.061957, 9.830510 48.273910, 9.259623 48.273910, 9.259623 48.061957))",
"POLYGON ((9.225341 48.273917, 9.865046 48.273917, 9.865046 48.531908, 9.225341 48.531908, 9.225341 48.273917))",
"POLYGON ((9.259944 48.531908, 9.849503 48.531908, 9.849503 48.743121, 9.259944 48.743121, 9.259944 48.531908))",
"POLYGON ((9.253634 48.743126, 9.786925 48.743126, 9.786925 48.802124, 9.253634 48.802124, 9.253634 48.743126))",
"POLYGON ((9.227858 48.802125, 9.786938 48.802125, 9.786938 48.936723, 9.227858 48.936723, 9.227858 48.802125))",
"POLYGON ((9.225513 48.936723, 9.771002 48.936723, 9.771002 49.088483, 9.225513 49.088483, 9.225513 48.936723))",
"POLYGON ((9.235409 49.088484, 9.771558 49.088484, 9.771558 49.190416, 9.235409 49.190416, 9.235409 49.088484))",
"POLYGON ((9.261405 49.190434, 9.843893 49.190434, 9.843893 49.324839, 9.261405 49.324839, 9.261405 49.190434))",
"POLYGON ((9.224349 49.324842, 9.746090 49.324842, 9.746090 49.618614, 9.224349 49.618614, 9.224349 49.324842))",
"POLYGON ((9.234792 49.620594, 9.816347 49.620594, 9.816347 49.712294, 9.234792 49.712294, 9.234792 49.620594))",
"POLYGON ((9.263746 49.712295, 9.778047 49.712295, 9.778047 49.787882, 9.263746 49.787882, 9.263746 49.712295))",
"POLYGON ((9.252925 49.787884, 9.870217 49.787884, 9.870217 50.030603, 9.252925 50.030603, 9.252925 49.787884))",
"POLYGON ((9.268647 50.030933, 9.757441 50.030933, 9.757441 50.227871, 9.268647 50.227871, 9.268647 50.030933))",
"POLYGON ((9.282178 50.227890, 9.845168 50.227890, 9.845168 50.346279, 9.282178 50.346279, 9.282178 50.227890))",
"POLYGON ((9.236208 50.346311, 9.862545 50.346311, 9.862545 50.563155, 9.236208 50.563155, 9.236208 50.346311))",
"POLYGON ((9.266465 50.563159, 9.851440 50.563159, 9.851440 50.770262, 9.266465 50.770262, 9.266465 50.563159))",
"POLYGON ((9.341280 50.770263, 9.857558 50.770263, 9.857558 50.985841, 9.341280 50.985841, 9.341280 50.770263))",
"POLYGON ((9.269360 50.985847, 9.811395 50.985847, 9.811395 51.133156, 9.269360 51.133156, 9.269360 50.985847))",
"POLYGON ((9.230611 51.133243, 9.832615 51.133243, 9.832615 51.418002, 9.230611 51.418002, 9.230611 51.133243))",
"POLYGON ((9.235303 51.418032, 9.852625 51.418032, 9.852625 51.764744, 9.235303 51.764744, 9.235303 51.418032))",
"POLYGON ((9.224868 51.764937, 9.865365 51.764937, 9.865365 51.931977, 9.224868 51.931977, 9.224868 51.764937))",
"POLYGON ((9.241299 51.931977, 9.799143 51.931977, 9.799143 52.369294, 9.241299 52.369294, 9.241299 51.931977))",
"POLYGON ((9.602867 52.369295, 9.780506 52.369295, 9.780506 52.379859, 9.602867 52.379859, 9.602867 52.369295))",
"POLYGON ((9.229058 52.379873, 9.764425 52.379873, 9.764425 52.596336, 9.229058 52.596336, 9.229058 52.379873))",
"POLYGON ((9.223771 52.596340, 9.849303 52.596340, 9.849303 53.361750, 9.223771 53.361750, 9.223771 52.596340))",
"POLYGON ((9.312955 53.361750, 9.866189 53.361750, 9.866189 53.550690, 9.312955 53.550690, 9.312955 53.361750))",
"POLYGON ((9.814058 53.550698, 9.866019 53.550698, 9.866019 53.555356, 9.814058 53.555356, 9.814058 53.550698))",
"POLYGON ((9.798162 53.555373, 9.865037 53.555373, 9.865037 53.558107, 9.798162 53.558107, 9.798162 53.555373))",
"POLYGON ((9.785874 53.558108, 9.839354 53.558108, 9.839354 53.559956, 9.785874 53.559956, 9.785874 53.558108))",
"POLYGON ((9.778920 53.559956, 9.838723 53.559956, 9.838723 53.562453, 9.778920 53.562453, 9.778920 53.559956))",
"POLYGON ((9.740896 53.562453, 9.836312 53.562453, 9.836312 53.567601, 9.740896 53.567601, 9.740896 53.562453))",
"POLYGON ((9.739070 53.567606, 9.847748 53.567606, 9.847748 53.576352, 9.739070 53.576352, 9.739070 53.567606))",
"POLYGON ((9.443072 53.576357, 9.799768 53.576357, 9.799768 53.600999, 9.443072 53.600999, 9.443072 53.576357))",
"POLYGON ((9.242894 53.600999, 9.857217 53.600999, 9.857217 54.340108, 9.242894 54.340108, 9.242894 53.600999))",
"POLYGON ((9.268060 54.341212, 9.602896 54.341212, 9.602896 54.494476, 9.268060 54.494476, 9.268060 54.341212))",
"POLYGON ((9.418817 54.494479, 9.867244 54.494479, 9.867244 55.757144, 9.418817 55.757144, 9.418817 54.494479))",
"POLYGON ((9.252668 55.757193, 9.854075 55.757193, 9.854075 57.380671, 9.252668 57.380671, 9.252668 55.757193))",
"POLYGON ((9.265892 57.380672, 9.851451 57.380672, 9.851451 62.711276, 9.265892 62.711276, 9.265892 57.380672))",
"POLYGON ((9.900071 4.959966, 10.306879 4.959966, 10.306879 36.650821, 9.900071 36.650821, 9.900071 4.959966))",
"POLYGON ((9.870723 36.650836, 10.304539 36.650836, 10.304539 42.781676, 9.870723 42.781676, 9.870723 36.650836))",
"POLYGON ((10.140042 42.781676, 10.313232 42.781676, 10.313232 43.994649, 10.140042 43.994649, 10.140042 42.781676))",
"POLYGON ((9.895952 43.994649, 10.310520 43.994649, 10.310520 44.112654, 9.895952 44.112654, 9.895952 43.994649))",
"POLYGON ((9.871253 44.112674, 10.306203 44.112674, 10.306203 45.397227, 9.871253 45.397227, 9.871253 44.112674))",
"POLYGON ((9.871277 45.397264, 10.311810 45.397264, 10.311810 45.546452, 9.871277 45.546452, 9.871277 45.397264))",
"POLYGON ((9.871099 45.547885, 10.309394 45.547885, 10.309394 45.633127, 9.871099 45.633127, 9.871099 45.547885))",
"POLYGON ((9.870725 45.633132, 10.299621 45.633132, 10.299621 45.832740, 9.870725 45.832740, 9.870725 45.633132))",
"POLYGON ((9.876326 45.832750, 10.298936 45.832750, 10.298936 46.173686, 9.876326 46.173686, 9.876326 45.832750))",
"POLYGON ((9.870971 46.173700, 10.310239 46.173700, 10.310239 47.571382, 9.870971 47.571382, 9.870971 46.173700))",
"POLYGON ((9.895602 47.615223, 10.313313 47.615223, 10.313313 48.259489, 9.895602 48.259489, 9.895602 47.615223))",
"POLYGON ((9.879964 48.259494, 10.302587 48.259494, 10.302587 48.621386, 9.879964 48.621386, 9.879964 48.259494))",
"POLYGON ((9.877233 48.621553, 10.263419 48.621553, 10.263419 48.884392, 9.877233 48.884392, 9.877233 48.621553))",
"POLYGON ((9.880945 48.884392, 10.286328 48.884392, 10.286328 49.362182, 9.880945 49.362182, 9.880945 48.884392))",
"POLYGON ((10.171444 49.362182, 10.195258 49.362182, 10.195258 49.376161, 10.171444 49.376161, 10.171444 49.362182))",
"POLYGON ((10.173689 49.376161, 10.184809 49.376161, 10.184809 49.380593, 10.173689 49.380593, 10.173689 49.376161))",
"POLYGON ((9.874858 49.380629, 10.266682 49.380629, 10.266682 49.667968, 9.874858 49.667968, 9.874858 49.380629))",
"POLYGON ((9.917358 49.667971, 10.279946 49.667971, 10.279946 49.792968, 9.917358 49.792968, 9.917358 49.667971))",
"POLYGON ((9.870644 49.792968, 10.230756 49.792968, 10.230756 49.831514, 9.870644 49.831514, 9.870644 49.792968))",
"POLYGON ((9.871336 49.831514, 10.227532 49.831514, 10.227532 50.001844, 9.871336 50.001844, 9.871336 49.831514))",
"POLYGON ((9.871817 50.001854, 10.286153 50.001854, 10.286153 50.420786, 9.871817 50.420786, 9.871817 50.001854))",
"POLYGON ((9.897807 50.420885, 10.310714 50.420885, 10.310714 50.813039, 9.897807 50.813039, 9.897807 50.420885))",
"POLYGON ((9.949524 50.813044, 10.308702 50.813044, 10.308702 51.138005, 9.949524 51.138005, 9.949524 50.813044))",
"POLYGON ((9.872047 51.138013, 10.283039 51.138013, 10.283039 51.284403, 9.872047 51.284403, 9.872047 51.138013))",
"POLYGON ((9.907228 51.284454, 10.269431 51.284454, 10.269431 51.517184, 9.907228 51.517184, 9.907228 51.284454))",
"POLYGON ((9.877359 51.517267, 10.312596 51.517267, 10.312596 51.890812, 9.877359 51.890812, 9.877359 51.517267))",
"POLYGON ((9.891297 51.890919, 10.311805 51.890919, 10.311805 52.288269, 9.891297 52.288269, 9.891297 51.890919))",
"POLYGON ((9.875130 52.288362, 10.310306 52.288362, 10.310306 53.342822, 9.875130 53.342822, 9.875130 52.288362))",
"POLYGON ((9.940349 53.342845, 10.304405 53.342845, 10.304405 53.425997, 9.940349 53.425997, 9.940349 53.342845))",
"POLYGON ((10.117625 53.426025, 10.295294 53.426025, 10.295294 53.431451, 10.117625 53.431451, 10.117625 53.426025))",
"POLYGON ((10.117369 53.431454, 10.302699 53.431454, 10.302699 53.445693, 10.117369 53.445693, 10.117369 53.431454))",
"POLYGON ((9.981615 53.445755, 10.251283 53.445755, 10.251283 53.461451, 9.981615 53.461451, 9.981615 53.445755))",
"POLYGON ((9.968309 53.461459, 10.268084 53.461459, 10.268084 53.471110, 9.968309 53.471110, 9.968309 53.461459))",
"POLYGON ((9.981015 53.471127, 10.254727 53.471127, 10.254727 53.484640, 9.981015 53.484640, 9.981015 53.471127))",
"POLYGON ((10.017835 53.484648, 10.221344 53.484648, 10.221344 53.488750, 10.017835 53.488750, 10.017835 53.484648))",
"POLYGON ((10.093895 53.488758, 10.223203 53.488758, 10.223203 53.490168, 10.093895 53.490168, 10.093895 53.488758))",
"POLYGON ((10.207546 53.490170, 10.228601 53.490170, 10.228601 53.492471, 10.207546 53.492471, 10.207546 53.490170))",
"POLYGON ((9.992421 53.492473, 10.226313 53.492473, 10.226313 53.502767, 9.992421 53.502767, 9.992421 53.492473))",
"POLYGON ((9.948093 53.502782, 10.254294 53.502782, 10.254294 53.522717, 9.948093 53.522717, 9.948093 53.502782))",
"POLYGON ((9.871225 53.522717, 10.111069 53.522717, 10.111069 53.535271, 9.871225 53.535271, 9.871225 53.522717))",
"POLYGON ((9.878751 53.535321, 10.110501 53.535321, 10.110501 53.543578, 9.878751 53.543578, 9.878751 53.535321))",
"POLYGON ((9.912434 53.543578, 10.036768 53.543578, 10.036768 53.546072, 9.912434 53.546072, 9.912434 53.543578))",
"POLYGON ((9.933778 53.546075, 10.033854 53.546075, 10.033854 53.548249, 9.933778 53.548249, 9.933778 53.546075))",
"POLYGON ((9.933461 53.548251, 10.060764 53.548251, 10.060764 53.549653, 9.933461 53.549653, 9.933461 53.548251))",
"POLYGON ((9.948957 53.549674, 10.060509 53.549674, 10.060509 53.550640, 9.948957 53.550640, 9.948957 53.549674))",
"POLYGON ((9.935820 53.550641, 10.046558 53.550641, 10.046558 53.552955, 9.935820 53.552955, 9.935820 53.550641))",
"POLYGON ((9.935770 53.552955, 10.219938 53.552955, 10.219938 53.555508, 9.935770 53.555508, 9.935770 53.552955))",
"POLYGON ((9.969253 53.555512, 10.025222 53.555512, 10.025222 53.556549, 9.969253 53.556549, 9.969253 53.555512))",
"POLYGON ((9.969618 53.556550, 10.023671 53.556550, 10.023671 53.557775, 9.969618 53.557775, 9.969618 53.556550))",
"POLYGON ((9.976375 53.557775, 10.100257 53.557775, 10.100257 53.562300, 9.976375 53.562300, 9.976375 53.557775))",
"POLYGON ((9.949492 53.562334, 10.221264 53.562334, 10.221264 53.570090, 9.949492 53.570090, 9.949492 53.562334))",
"POLYGON ((9.968171 53.570090, 10.132774 53.570090, 10.132774 53.574830, 9.968171 53.574830, 9.968171 53.570090))",
"POLYGON ((9.940661 53.575434, 10.107675 53.575434, 10.107675 53.580755, 9.940661 53.580755, 9.940661 53.575434))",
"POLYGON ((9.968918 53.580778, 10.057930 53.580778, 10.057930 53.586522, 9.968918 53.586522, 9.968918 53.580778))",
"POLYGON ((9.986720 53.586522, 10.238177 53.586522, 10.238177 53.589179, 9.986720 53.589179, 9.986720 53.586522))",
"POLYGON ((9.943048 53.589183, 10.058350 53.589183, 10.058350 53.606171, 9.943048 53.606171, 9.943048 53.589183))",
"POLYGON ((9.890218 53.606195, 10.188472 53.606195, 10.188472 53.615499, 9.890218 53.615499, 9.890218 53.606195))",
"POLYGON ((9.913916 53.615502, 10.164438 53.615502, 10.164438 53.631379, 9.913916 53.631379, 9.913916 53.615502))",
"POLYGON ((9.906764 53.631395, 10.253794 53.631395, 10.253794 53.693999, 9.906764 53.693999, 9.906764 53.631395))",
"POLYGON ((9.881566 53.693999, 10.313305 53.693999, 10.313305 54.270242, 9.881566 54.270242, 9.881566 53.693999))",
"POLYGON ((9.887673 54.270251, 10.308206 54.270251, 10.308206 56.129755, 9.887673 56.129755, 9.887673 54.270251))",
"POLYGON ((9.892005 56.129763, 10.303076 56.129763, 10.303076 59.813731, 9.892005 59.813731, 9.892005 56.129763))",
"POLYGON ((9.900426 59.814059, 10.306957 59.814059, 10.306957 63.422445, 9.900426 63.422445, 9.900426 59.814059))",
"POLYGON ((10.318003 5.395784, 10.764634 5.395784, 10.764634 34.738365, 10.318003 34.738365, 10.318003 5.395784))",
"POLYGON ((10.322290 34.738384, 10.762471 34.738384, 10.762471 36.851443, 10.322290 36.851443, 10.322290 34.738384))",
"POLYGON ((10.314530 36.851504, 10.348523 36.851504, 10.348523 36.869341, 10.314530 36.869341, 10.314530 36.851504))",
"POLYGON ((10.328212 36.869341, 10.757686 36.869341, 10.757686 43.122678, 10.328212 43.122678, 10.328212 36.869341))",
"POLYGON ((10.334476 43.122733, 10.767693 43.122733, 10.767693 43.490531, 10.334476 43.490531, 10.334476 43.122733))",
"POLYGON ((10.313404 43.490565, 10.749432 43.490565, 10.749432 43.639650, 10.313404 43.639650, 10.313404 43.490565))",
"POLYGON ((10.386839 43.642958, 10.614434 43.642958, 10.614434 43.723663, 10.386839 43.723663, 10.386839 43.642958))",
"POLYGON ((10.361129 43.723665, 10.619686 43.723665, 10.619686 43.842634, 10.361129 43.842634, 10.361129 43.723665))",
"POLYGON ((10.423984 43.842652, 10.777603 43.842652, 10.777603 43.897174, 10.423984 43.897174, 10.423984 43.842652))",
"POLYGON ((10.339337 43.897211, 10.764350 43.897211, 10.764350 43.968738, 10.339337 43.968738, 10.339337 43.897211))",
"POLYGON ((10.313487 43.968738, 10.778942 43.968738, 10.778942 44.179172, 10.313487 44.179172, 10.313487 43.968738))",
"POLYGON ((10.321456 44.179338, 10.779548 44.179338, 10.779548 44.808052, 10.321456 44.808052, 10.321456 44.179338))",
"POLYGON ((10.321976 44.808062, 10.772856 44.808062, 10.772856 45.469214, 10.321976 45.469214, 10.321976 44.808062))",
"POLYGON ((10.327818 45.469628, 10.777743 45.469628, 10.777743 45.731892, 10.327818 45.731892, 10.327818 45.469628))",
"POLYGON ((10.315990 45.731903, 10.777726 45.731903, 10.777726 45.850687, 10.315990 45.850687, 10.315990 45.731903))",
"POLYGON ((10.315820 45.850753, 10.777546 45.850753, 10.777546 46.098126, 10.315820 46.098126, 10.315820 45.850753))",
"POLYGON ((10.316561 46.098126, 10.702816 46.098126, 10.702816 46.282447, 10.316561 46.282447, 10.316561 46.098126))",
"POLYGON ((10.328675 46.282479, 10.779477 46.282479, 10.779477 46.555660, 10.328675 46.555660, 10.328675 46.282479))",
"POLYGON ((10.326347 46.555673, 10.779466 46.555673, 10.779466 47.136372, 10.326347 47.136372, 10.326347 46.555673))",
"POLYGON ((10.313334 47.136381, 10.754174 47.136381, 10.754174 48.037132, 10.313334 48.037132, 10.313334 47.136381))",
"POLYGON ((10.344811 48.045783, 10.758437 48.045783, 10.758437 48.566804, 10.344811 48.566804, 10.344811 48.045783))",
"POLYGON ((10.329003 48.566807, 10.779543 48.566807, 10.779543 48.775739, 10.329003 48.775739, 10.329003 48.566807))",
"POLYGON ((10.447418 48.775757, 10.768223 48.775757, 10.768223 48.849826, 10.447418 48.849826, 10.447418 48.775757))",
"POLYGON ((10.316079 48.849842, 10.742338 48.849842, 10.742338 49.045462, 10.316079 49.045462, 10.316079 48.849842))",
"POLYGON ((10.313776 49.045465, 10.759894 49.045465, 10.759894 49.360264, 10.313776 49.360264, 10.313776 49.045465))",
"POLYGON ((10.319479 49.360382, 10.665398 49.360382, 10.665398 49.496288, 10.319479 49.496288, 10.319479 49.360382))",
"POLYGON ((10.322442 49.496299, 10.775608 49.496299, 10.775608 49.899487, 10.322442 49.899487, 10.322442 49.496299))",
"POLYGON ((10.336279 49.899489, 10.750278 49.899489, 10.750278 50.018260, 10.336279 50.018260, 10.336279 49.899489))",
"POLYGON ((10.368873 50.018326, 10.771631 50.018326, 10.771631 50.273061, 10.368873 50.273061, 10.368873 50.018326))",
"POLYGON ((10.316773 50.273067, 10.756575 50.273067, 10.756575 50.533444, 10.316773 50.533444, 10.316773 50.273067))",
"POLYGON ((10.337640 50.533460, 10.775988 50.533460, 10.775988 50.818771, 10.337640 50.818771, 10.337640 50.533460))",
"POLYGON ((10.317655 50.818774, 10.778286 50.818774, 10.778286 50.992327, 10.317655 50.992327, 10.317655 50.818774))",
"POLYGON ((10.329265 50.992353, 10.733766 50.992353, 10.733766 51.163576, 10.329265 51.163576, 10.329265 50.992353))",
"POLYGON ((10.319584 51.163601, 10.771310 51.163601, 10.771310 51.309890, 10.319584 51.309890, 10.319584 51.163601))",
"POLYGON ((10.315544 51.310049, 10.748712 51.310049, 10.748712 51.656649, 10.315544 51.656649, 10.315544 51.310049))",
"POLYGON ((10.317341 51.656651, 10.720388 51.656651, 10.720388 51.722388, 10.317341 51.722388, 10.317341 51.656651))",
"POLYGON ((10.438334 51.722735, 10.684991 51.722735, 10.684991 51.764241, 10.438334 51.764241, 10.438334 51.722735))",
"POLYGON ((10.323283 51.764246, 10.537852 51.764246, 10.537852 51.782834, 10.323283 51.782834, 10.323283 51.764246))",
"POLYGON ((10.318176 51.782859, 10.551504 51.782859, 10.551504 51.795534, 10.318176 51.795534, 10.318176 51.782859))",
"POLYGON ((10.322818 51.795553, 10.694915 51.795553, 10.694915 51.826840, 10.322818 51.826840, 10.322818 51.795553))",
"POLYGON ((10.322919 51.826845, 10.772980 51.826845, 10.772980 51.899559, 10.322919 51.899559, 10.322919 51.826845))",
"POLYGON ((10.419516 51.899565, 10.540795 51.899565, 10.540795 52.011811, 10.419516 52.011811, 10.419516 51.899565))",
"POLYGON ((10.332650 52.012070, 10.763315 52.012070, 10.763315 52.104294, 10.332650 52.104294, 10.332650 52.012070))",
"POLYGON ((10.315248 52.104302, 10.757422 52.104302, 10.757422 52.278848, 10.315248 52.278848, 10.315248 52.104302))",
"POLYGON ((10.370709 52.278848, 10.778767 52.278848, 10.778767 52.544743, 10.370709 52.544743, 10.370709 52.278848))",
"POLYGON ((10.382626 52.544813, 10.738121 52.544813, 10.738121 53.260480, 10.382626 53.260480, 10.382626 52.544813))",
"POLYGON ((10.315871 53.260533, 10.773128 53.260533, 10.773128 53.755962, 10.315871 53.755962, 10.315871 53.260533))",
"POLYGON ((10.318470 53.755962, 10.765731 53.755962, 10.765731 53.864203, 10.318470 53.864203, 10.318470 53.755962))",
"POLYGON ((10.373872 53.864216, 10.770680 53.864216, 10.770680 54.027752, 10.373872 54.027752, 10.373872 53.864216))",
"POLYGON ((10.313822 54.027752, 10.778198 54.027752, 10.778198 55.238622, 10.313822 55.238622, 10.313822 54.027752))",
"POLYGON ((10.334659 55.238629, 10.715573 55.238629, 10.715573 57.729916, 10.334659 57.729916, 10.334659 55.238629))",
"POLYGON ((10.324450 57.729928, 10.774435 57.729928, 10.774435 59.844649, 10.324450 59.844649, 10.324450 57.729928))",
"POLYGON ((10.377711 59.844830, 10.773841 59.844830, 10.773841 59.903447, 10.377711 59.903447, 10.377711 59.844830))",
"POLYGON ((10.396086 59.903450, 10.779288 59.903450, 10.779288 59.941094, 10.396086 59.941094, 10.396086 59.903450))",
"POLYGON ((10.553723 59.941111, 10.771074 59.941111, 10.771074 59.968059, 10.553723 59.968059, 10.553723 59.941111))",
"POLYGON ((10.505054 59.968063, 10.755126 59.968063, 10.755126 59.980533, 10.505054 59.980533, 10.505054 59.968063))",
"POLYGON ((10.416954 59.980552, 10.705793 59.980552, 10.705793 59.992580, 10.416954 59.992580, 10.416954 59.980552))",
"POLYGON ((10.415736 59.992602, 10.765245 59.992602, 10.765245 60.004249, 10.415736 60.004249, 10.415736 59.992602))",
"POLYGON ((10.359173 60.004261, 10.777976 60.004261, 10.777976 60.025433, 10.359173 60.025433, 10.359173 60.004261))",
"POLYGON ((10.357990 60.025452, 10.779204 60.025452, 10.779204 60.074361, 10.357990 60.074361, 10.357990 60.025452))",
"POLYGON ((10.382580 60.074575, 10.779507 60.074575, 10.779507 60.112600, 10.382580 60.112600, 10.382580 60.074575))",
"POLYGON ((10.324392 60.112725, 10.779194 60.112725, 10.779194 60.168447, 10.324392 60.168447, 10.324392 60.112725))",
"POLYGON ((10.324714 60.168459, 10.779403 60.168459, 10.779403 63.584322, 10.324714 63.584322, 10.324714 60.168459))",
"POLYGON ((10.828019 5.732000, 11.116873 5.732000, 11.116873 42.335568, 10.828019 42.335568, 10.828019 5.732000))",
"POLYGON ((10.786854 42.335568, 11.118396 42.335568, 11.118396 43.112819, 10.786854 43.112819, 10.786854 42.335568))",
"POLYGON ((10.783971 43.112885, 11.118497 43.112885, 11.118497 43.401744, 10.783971 43.401744, 10.783971 43.112885))",
"POLYGON ((10.794402 43.401770, 11.111313 43.401770, 11.111313 43.718778, 10.794402 43.718778, 10.794402 43.401770))",
"POLYGON ((10.799698 43.721189, 11.112808 43.721189, 11.112808 43.879039, 10.799698 43.879039, 10.799698 43.721189))",
"POLYGON ((10.788590 43.879061, 11.118459 43.879061, 11.118459 43.961867, 10.788590 43.961867, 10.788590 43.879061))",
"POLYGON ((10.791739 43.961902, 11.107704 43.961902, 11.107704 44.536498, 10.791739 44.536498, 10.791739 43.961902))",
"POLYGON ((10.779571 44.536511, 11.104188 44.536511, 11.104188 45.142323, 10.779571 45.142323, 10.779571 44.536511))",
"POLYGON ((10.780507 45.142361, 11.114971 45.142361, 11.114971 45.433721, 10.780507 45.433721, 10.780507 45.142361))",
"POLYGON ((10.928928 45.433734, 11.015771 45.433734, 11.015771 45.445797, 10.928928 45.445797, 10.928928 45.433734))",
"POLYGON ((10.809859 45.446472, 11.105816 45.446472, 11.105816 45.621908, 10.809859 45.621908, 10.809859 45.446472))",
"POLYGON ((10.791678 45.621909, 11.120956 45.621909, 11.120956 45.882334, 10.791678 45.882334, 10.791678 45.621909))",
"POLYGON ((10.821332 45.882420, 11.113527 45.882420, 11.113527 46.037687, 10.821332 46.037687, 10.821332 45.882420))",
"POLYGON ((10.785099 46.037687, 11.120913 46.037687, 11.120913 46.236687, 10.785099 46.236687, 10.785099 46.037687))",
"POLYGON ((10.779560 46.236710, 11.112377 46.236710, 11.112377 46.627945, 10.779560 46.627945, 10.779560 46.236710))",
"POLYGON ((10.780489 46.628004, 11.118702 46.628004, 11.118702 47.784080, 10.780489 47.784080, 10.780489 46.628004))",
"POLYGON ((10.797916 47.784091, 11.098376 47.784091, 11.098376 48.053363, 10.797916 48.053363, 10.797916 47.784091))",
"POLYGON ((10.785090 48.053366, 11.094855 48.053366, 11.094855 48.307036, 10.785090 48.307036, 10.785090 48.053366))",
"POLYGON ((10.785025 48.313990, 11.086209 48.313990, 11.086209 48.356130, 10.785025 48.356130, 10.785025 48.313990))",
"POLYGON ((10.796424 48.356134, 11.108613 48.356134, 11.108613 48.372416, 10.796424 48.372416, 10.796424 48.356134))",
"POLYGON ((10.887908 48.372418, 11.096138 48.372418, 11.096138 48.378928, 10.887908 48.378928, 10.887908 48.372418))",
"POLYGON ((10.817136 48.378936, 11.119539 48.378936, 11.119539 48.428869, 10.817136 48.428869, 10.817136 48.378936))",
"POLYGON ((10.814664 48.428874, 11.110762 48.428874, 11.110762 48.583788, 10.814664 48.583788, 10.814664 48.428874))",
"POLYGON ((10.779745 48.583828, 11.103119 48.583828, 11.103119 48.990128, 10.779745 48.990128, 10.779745 48.583828))",
"POLYGON ((10.891752 48.990129, 11.119964 48.990129, 11.119964 49.061371, 10.891752 49.061371, 10.891752 48.990129))",
"POLYGON ((10.788840 49.061391, 11.109478 49.061391, 11.109478 49.447447, 10.788840 49.447447, 10.788840 49.061391))",
"POLYGON ((11.065916 49.447450, 11.083368 49.447450, 11.083368 49.451149, 11.065916 49.451149, 11.065916 49.447450))",
"POLYGON ((10.953410 49.451155, 11.087970 49.451155, 11.087970 49.455125, 10.953410 49.455125, 10.953410 49.451155))",
"POLYGON ((10.852529 49.455125, 11.088302 49.455125, 11.088302 49.458060, 10.852529 49.458060, 10.852529 49.455125))",
"POLYGON ((10.789644 49.458060, 11.104745 49.458060, 11.104745 49.494509, 10.789644 49.494509, 10.789644 49.458060))",
"POLYGON ((10.793580 49.494535, 11.051813 49.494535, 11.051813 49.594417, 10.793580 49.594417, 10.793580 49.494535))",
"POLYGON ((10.994330 49.594423, 11.051858 49.594423, 11.051858 49.595782, 10.994330 49.595782, 10.994330 49.594423))",
"POLYGON ((10.994329 49.595783, 11.026909 49.595783, 11.026909 49.596691, 10.994329 49.596691, 10.994329 49.595783))",
"POLYGON ((10.977477 49.596694, 11.025899 49.596694, 11.025899 49.598052, 10.977477 49.598052, 10.977477 49.596694))",
"POLYGON ((10.999253 49.598069, 11.024569 49.598069, 11.024569 49.599055, 10.999253 49.599055, 10.999253 49.598069))",
"POLYGON ((11.002189 49.599064, 11.019567 49.599064, 11.019567 49.601013, 11.002189 49.601013, 11.002189 49.599064))",
"POLYGON ((10.982014 49.601014, 11.019447 49.601014, 11.019447 49.603097, 10.982014 49.603097, 10.982014 49.601014))",
"POLYGON ((10.871417 49.603107, 11.097952 49.603107, 11.097952 49.719872, 10.871417 49.719872, 10.871417 49.603107))",
"POLYGON ((10.779656 49.719893, 11.075257 49.719893, 11.075257 50.253571, 10.779656 50.253571, 10.779656 49.719893))",
"POLYGON ((10.787503 50.253690, 11.095281 50.253690, 11.095281 50.684811, 10.787503 50.684811, 10.787503 50.253690))",
"POLYGON ((10.788198 50.685676, 11.119799 50.685676, 11.119799 50.818800, 10.788198 50.818800, 10.788198 50.685676))",
"POLYGON ((10.780021 50.818851, 11.101736 50.818851, 11.101736 50.898878, 10.780021 50.898878, 10.780021 50.818851))",
"POLYGON ((10.798317 50.898923, 11.090014 50.898923, 11.090014 50.968018, 10.798317 50.968018, 10.798317 50.898923))",
"POLYGON ((10.782987 50.968052, 11.100081 50.968052, 11.100081 51.120354, 10.782987 51.120354, 10.782987 50.968052))",
"POLYGON ((10.831850 51.120422, 11.120961 51.120422, 11.120961 51.164585, 10.831850 51.164585, 10.831850 51.120422))",
"POLYGON ((10.797456 51.164592, 11.116182 51.164592, 11.116182 51.395037, 10.797456 51.395037, 10.797456 51.164592))",
"POLYGON ((10.782235 51.395043, 11.112754 51.395043, 11.112754 51.575195, 10.782235 51.575195, 10.782235 51.395043))",
"POLYGON ((10.781649 51.576264, 11.116187 51.576264, 11.116187 51.837719, 10.781649 51.837719, 10.781649 51.576264))",
"POLYGON ((10.779976 51.837720, 11.109410 51.837720, 11.109410 52.240082, 10.779976 52.240082, 10.779976 51.837720))",
"POLYGON ((10.779555 52.240187, 11.120778 52.240187, 11.120778 53.490837, 10.779555 53.490837, 10.779555 52.240187))",
"POLYGON ((10.779670 53.490857, 11.117767 53.490857, 11.117767 54.201793, 10.779670 54.201793, 10.779670 53.490857))",
"POLYGON ((10.784890 54.201798, 11.106060 54.201798, 11.106060 59.203496, 10.784890 59.203496, 10.784890 54.201798))",
"POLYGON ((10.791362 59.203498, 11.100961 59.203498, 11.100961 59.859788, 10.791362 59.859788, 10.791362 59.203498))",
"POLYGON ((10.789569 59.859797, 11.090433 59.859797, 11.090433 59.889727, 10.789569 59.889727, 10.789569 59.859797))",
"POLYGON ((10.785501 59.889727, 11.112299 59.889727, 11.112299 59.898865, 10.785501 59.898865, 10.785501 59.889727))",
"POLYGON ((10.782685 59.898925, 11.111228 59.898925, 11.111228 59.940074, 10.782685 59.940074, 10.782685 59.898925))",
"POLYGON ((10.780118 59.940299, 10.982284 59.940299, 10.982284 59.976569, 10.780118 59.976569, 10.780118 59.940299))",
"POLYGON ((10.780342 59.976573, 10.945706 59.976573, 10.945706 60.022067, 10.780342 60.022067, 10.780342 59.976573))",
"POLYGON ((10.780008 60.022069, 11.064330 60.022069, 11.064330 60.055656, 10.780008 60.055656, 10.780008 60.022069))",
"POLYGON ((10.779738 60.055698, 11.003291 60.055698, 11.003291 60.086279, 10.779738 60.086279, 10.779738 60.055698))",
"POLYGON ((10.779585 60.086297, 10.951092 60.086297, 10.951092 60.100297, 10.779585 60.100297, 10.779585 60.086297))",
"POLYGON ((10.779568 60.100301, 10.988152 60.100301, 10.988152 60.114460, 10.779568 60.114460, 10.779568 60.100301))",
"POLYGON ((10.779734 60.114462, 11.117497 60.114462, 11.117497 63.544059, 10.779734 63.544059, 10.779734 60.114462))",
"POLYGON ((11.126843 3.501746, 11.628414 3.501746, 11.628414 42.552799, 11.126843 42.552799, 11.126843 3.501746))",
"POLYGON ((11.124636 42.552808, 11.619165 42.552808, 11.619165 43.057385, 11.124636 43.057385, 11.124636 42.552808))",
"POLYGON ((11.126447 43.073880, 11.487028 43.073880, 11.487028 43.218783, 11.126447 43.218783, 11.126447 43.073880))",
"POLYGON ((11.148834 43.218783, 11.458323 43.218783, 11.458323 43.271676, 11.148834 43.271676, 11.148834 43.218783))",
"POLYGON ((11.142568 43.271740, 11.331586 43.271740, 11.331586 43.295085, 11.142568 43.295085, 11.142568 43.271740))",
"POLYGON ((11.153180 43.295114, 11.439296 43.295114, 11.439296 43.320788, 11.153180 43.320788, 11.153180 43.295114))",
"POLYGON ((11.135706 43.320806, 11.336764 43.320806, 11.336764 43.390791, 11.135706 43.390791, 11.135706 43.320806))",
"POLYGON ((11.128421 43.390792, 11.530429 43.390792, 11.530429 43.702493, 11.128421 43.702493, 11.128421 43.390792))",
"POLYGON ((11.175965 43.702508, 11.473648 43.702508, 11.473648 43.808023, 11.175965 43.808023, 11.175965 43.702508))",
"POLYGON ((11.122992 43.808050, 11.489222 43.808050, 11.489222 44.220702, 11.122992 44.220702, 11.122992 43.808050))",
"POLYGON ((11.126625 44.220722, 11.603951 44.220722, 11.603951 44.714994, 11.126625 44.714994, 11.126625 44.220722))",
"POLYGON ((11.286182 44.715082, 11.627395 44.715082, 11.627395 44.849324, 11.286182 44.849324, 11.286182 44.715082))",
"POLYGON ((11.145173 44.849326, 11.625649 44.849326, 11.625649 45.232118, 11.145173 45.232118, 11.145173 44.849326))",
"POLYGON ((11.247677 45.232119, 11.611933 45.232119, 11.611933 45.332774, 11.247677 45.332774, 11.247677 45.232119))",
"POLYGON ((11.135943 45.332798, 11.558326 45.332798, 11.558326 45.423964, 11.135943 45.423964, 11.135943 45.332798))",
"POLYGON ((11.191028 45.423966, 11.621732 45.423966, 11.621732 45.550448, 11.191028 45.550448, 11.191028 45.423966))",
"POLYGON ((11.144539 45.550509, 11.625382 45.550509, 11.625382 45.714368, 11.144539 45.714368, 11.144539 45.550509))",
"POLYGON ((11.121099 45.714373, 11.626136 45.714373, 11.626136 45.800367, 11.121099 45.800367, 11.121099 45.714373))",
"POLYGON ((11.155656 45.800371, 11.552365 45.800371, 11.552365 45.886386, 11.155656 45.886386, 11.155656 45.800371))",
"POLYGON ((11.146968 45.886397, 11.591544 45.886397, 11.591544 45.989971, 11.146968 45.989971, 11.146968 45.886397))",
"POLYGON ((11.155145 45.989992, 11.563568 45.989992, 11.563568 46.049884, 11.155145 46.049884, 11.155145 45.989992))",
"POLYGON ((11.123016 46.049884, 11.615488 46.049884, 11.615488 46.065091, 11.123016 46.065091, 11.123016 46.049884))",
"POLYGON ((11.120983 46.065091, 11.614805 46.065091, 11.614805 46.070853, 11.120983 46.070853, 11.120983 46.065091))",
"POLYGON ((11.124242 46.070853, 11.597066 46.070853, 11.597066 46.235280, 11.124242 46.235280, 11.124242 46.070853))",
"POLYGON ((11.203139 46.235283, 11.513133 46.235283, 11.513133 46.379041, 11.203139 46.379041, 11.203139 46.235283))",
"POLYGON ((11.218628 46.379061, 11.469260 46.379061, 11.469260 46.526221, 11.218628 46.526221, 11.218628 46.379061))",
"POLYGON ((11.135798 46.526222, 11.607779 46.526222, 11.607779 46.691346, 11.135798 46.691346, 11.135798 46.526222))",
"POLYGON ((11.134279 46.691369, 11.627900 46.691369, 11.627900 47.225461, 11.134279 47.225461, 11.134279 46.691369))",
"POLYGON ((11.162938 47.232559, 11.623498 47.232559, 11.623498 47.658514, 11.162938 47.658514, 11.162938 47.232559))",
"POLYGON ((11.142569 47.658535, 11.618102 47.658535, 11.618102 48.012503, 11.142569 48.012503, 11.142569 47.658535))",
"POLYGON ((11.169557 48.012545, 11.622661 48.012545, 11.622661 48.129107, 11.169557 48.129107, 11.169557 48.012545))",
"POLYGON ((11.228778 48.129195, 11.604577 48.129195, 11.604577 48.140007, 11.228778 48.140007, 11.228778 48.129195))",
"POLYGON ((11.217685 48.140049, 11.614507 48.140049, 11.614507 48.152697, 11.217685 48.152697, 11.217685 48.140049))",
"POLYGON ((11.389739 48.152699, 11.628209 48.152699, 11.628209 48.176397, 11.389739 48.176397, 11.389739 48.152699))",
"POLYGON ((11.132661 48.176408, 11.613867 48.176408, 11.613867 48.452466, 11.132661 48.452466, 11.132661 48.176408))",
"POLYGON ((11.124234 48.452466, 11.598638 48.452466, 11.598638 48.697910, 11.124234 48.697910, 11.124234 48.452466))",
"POLYGON ((11.142361 48.697913, 11.617323 48.697913, 11.617323 48.770160, 11.142361 48.770160, 11.142361 48.697913))",
"POLYGON ((11.167267 48.770195, 11.617441 48.770195, 11.617441 48.948417, 11.167267 48.948417, 11.167267 48.770195))",
"POLYGON ((11.132293 48.948485, 11.610976 48.948485, 11.610976 49.275991, 11.132293 49.275991, 11.132293 48.948485))",
"POLYGON ((11.121462 49.275994, 11.599321 49.275994, 11.599321 49.402377, 11.121462 49.402377, 11.121462 49.275994))",
"POLYGON ((11.122736 49.402382, 11.593370 49.402382, 11.593370 49.509978, 11.122736 49.509978, 11.122736 49.402382))",
"POLYGON ((11.126699 49.510000, 11.620288 49.510000, 11.620288 49.748903, 11.126699 49.748903, 11.126699 49.510000))",
"POLYGON ((11.147542 49.751985, 11.626698 49.751985, 11.626698 49.983099, 11.147542 49.983099, 11.147542 49.751985))",
"POLYGON ((11.152266 49.983136, 11.621537 49.983136, 11.621537 50.512909, 11.152266 50.512909, 11.152266 49.983136))",
"POLYGON ((11.163380 50.512914, 11.609108 50.512914, 11.609108 50.661659, 11.163380 50.661659, 11.163380 50.512914))",
"POLYGON ((11.150373 50.661666, 11.628179 50.661666, 11.628179 50.785284, 11.150373 50.785284, 11.150373 50.661666))",
"POLYGON ((11.129018 50.785284, 11.620512 50.785284, 11.620512 50.894262, 11.129018 50.894262, 11.129018 50.785284))",
"POLYGON ((11.210571 50.894265, 11.626594 50.894265, 11.626594 50.930130, 11.210571 50.930130, 11.210571 50.894265))",
"POLYGON ((11.320331 50.930132, 11.590711 50.930132, 11.590711 50.976625, 11.320331 50.976625, 11.320331 50.930132))",
"POLYGON ((11.229287 50.976628, 11.579648 50.976628, 11.579648 50.996725, 11.229287 50.996725, 11.229287 50.976628))",
"POLYGON ((11.143601 50.996787, 11.517781 50.996787, 11.517781 51.079652, 11.143601 51.079652, 11.143601 50.996787))",
"POLYGON ((11.121059 51.079667, 11.624206 51.079667, 11.624206 51.177769, 11.121059 51.177769, 11.121059 51.079667))",
"POLYGON ((11.151251 51.177882, 11.606885 51.177882, 11.606885 51.211257, 11.151251 51.211257, 11.151251 51.177882))",
"POLYGON ((11.136419 51.211297, 11.615299 51.211297, 11.615299 51.417252, 11.136419 51.417252, 11.136419 51.211297))",
"POLYGON ((11.136587 51.417367, 11.626506 51.417367, 11.626506 51.718197, 11.136587 51.718197, 11.136587 51.417367))",
"POLYGON ((11.137290 51.718221, 11.582845 51.718221, 11.582845 51.789743, 11.137290 51.789743, 11.137290 51.718221))",
"POLYGON ((11.139032 51.789748, 11.626579 51.789748, 11.626579 51.972968, 11.139032 51.972968, 11.139032 51.789748))",
"POLYGON ((11.176809 51.972976, 11.628347 51.972976, 11.628347 52.127292, 11.176809 52.127292, 11.176809 51.972976))",
"POLYGON ((11.144950 52.127294, 11.626467 52.127294, 11.626467 52.527203, 11.144950 52.527203, 11.144950 52.127294))",
"POLYGON ((11.123716 52.527208, 11.556430 52.527208, 11.556430 53.144954, 11.123716 53.144954, 11.123716 52.527208))",
"POLYGON ((11.179050 53.144957, 11.627286 53.144957, 11.627286 53.813225, 11.179050 53.813225, 11.179050 53.144957))",
"POLYGON ((11.158085 53.813274, 11.612826 53.813274, 11.612826 58.975063, 11.158085 58.975063, 11.158085 53.813274))",
"POLYGON ((11.123487 58.975086, 11.519241 58.975086, 11.519241 63.803982, 11.123487 63.803982, 11.123487 58.975086))",
"POLYGON ((11.848079 -4.814206, 12.109750 -4.814206, 12.109750 42.003552, 11.848079 42.003552, 11.848079 -4.814206))",
"POLYGON ((12.093639 42.003553, 12.108042 42.003553, 12.108042 42.007296, 12.093639 42.007296, 12.093639 42.003553))",
"POLYGON ((12.099991 42.007298, 12.110377 42.007298, 12.110377 42.008290, 12.099991 42.008290, 12.099991 42.007298))",
"POLYGON ((11.785663 42.008290, 12.146122 42.008290, 12.146122 42.148475, 11.785663 42.148475, 11.785663 42.008290))",
"POLYGON ((11.811931 42.148478, 12.138712 42.148478, 12.138712 42.216945, 11.811931 42.216945, 11.811931 42.148478))",
"POLYGON ((11.765211 42.217112, 12.021174 42.217112, 12.021174 42.250359, 11.765211 42.250359, 11.765211 42.217112))",
"POLYGON ((11.640610 42.250375, 12.125898 42.250375, 12.125898 42.415366, 11.640610 42.415366, 11.640610 42.250375))",
"POLYGON ((11.628428 42.415369, 11.887370 42.415369, 11.887370 42.429659, 11.628428 42.429659, 11.628428 42.415369))",
"POLYGON ((11.632078 42.429664, 12.145074 42.429664, 12.145074 42.719723, 11.632078 42.719723, 11.632078 42.429664))",
"POLYGON ((11.633406 42.719781, 12.129507 42.719781, 12.129507 43.413368, 11.633406 43.413368, 11.633406 42.719781))",
"POLYGON ((11.677914 43.413376, 12.089922 43.413376, 12.089922 43.541254, 11.677914 43.541254, 11.677914 43.413376))",
"POLYGON ((11.637279 43.541254, 12.146857 43.541254, 12.146857 44.187077, 11.637279 44.187077, 11.637279 43.541254))",
"POLYGON ((11.705949 44.187078, 12.127372 44.187078, 12.127372 44.353626, 11.705949 44.353626, 11.705949 44.187078))",
"POLYGON ((11.668839 44.353626, 12.031976 44.353626, 12.031976 44.753129, 11.668839 44.753129, 11.668839 44.353626))",
"POLYGON ((11.628717 44.753174, 12.141163 44.753174, 12.141163 44.966083, 11.628717 44.966083, 11.628717 44.753174))",
"POLYGON ((11.637081 44.966184, 12.095579 44.966184, 12.095579 45.189738, 11.637081 45.189738, 11.637081 44.966184))",
"POLYGON ((11.634442 45.189779, 12.070860 45.189779, 12.070860 45.241049, 11.634442 45.241049, 11.634442 45.189779))",
"POLYGON ((11.666852 45.241367, 12.099978 45.241367, 12.099978 45.298021, 11.666852 45.298021, 11.666852 45.241367))",
"POLYGON ((11.640684 45.298025, 12.124249 45.298025, 12.124249 45.369776, 11.640684 45.369776, 11.640684 45.298025))",
"POLYGON ((11.629623 45.369776, 12.077383 45.369776, 12.077383 45.401074, 11.629623 45.401074, 11.629623 45.369776))",
"POLYGON ((11.710968 45.401077, 12.019284 45.401077, 12.019284 45.416194, 11.710968 45.416194, 11.710968 45.401077))",
"POLYGON ((11.672558 45.416208, 12.146874 45.416208, 12.146874 45.445120, 11.672558 45.445120, 11.672558 45.416208))",
"POLYGON ((11.663248 45.445127, 12.130642 45.445127, 12.130642 45.499199, 11.663248 45.499199, 11.663248 45.445127))",
"POLYGON ((11.639979 45.499205, 12.134476 45.499205, 12.134476 45.547194, 11.639979 45.547194, 11.639979 45.499205))",
"POLYGON ((11.629912 45.547214, 12.122467 45.547214, 12.122467 45.593846, 11.629912 45.593846, 11.629912 45.547214))",
"POLYGON ((11.655579 45.593922, 12.099463 45.593922, 12.099463 45.648834, 11.655579 45.648834, 11.655579 45.593922))",
"POLYGON ((11.629257 45.648845, 12.104577 45.648845, 12.104577 45.766892, 11.629257 45.766892, 11.629257 45.648845))",
"POLYGON ((11.649421 45.766893, 12.092431 45.766893, 12.092431 45.789349, 11.649421 45.789349, 11.649421 45.766893))",
"POLYGON ((11.630504 45.789379, 12.138869 45.789379, 12.138869 45.882859, 11.630504 45.882859, 11.630504 45.789379))",
"POLYGON ((11.662428 45.882889, 12.147200 45.882889, 12.147200 46.038625, 11.662428 46.038625, 11.662428 45.882889))",
"POLYGON ((11.630653 46.038650, 12.133056 46.038650, 12.133056 46.078455, 11.630653 46.078455, 11.630653 46.038650))",
"POLYGON ((11.673947 46.078455, 12.143195 46.078455, 12.143195 46.611088, 11.673947 46.611088, 11.673947 46.078455))",
"POLYGON ((11.642675 46.611097, 12.142497 46.611097, 12.142497 46.738353, 11.642675 46.738353, 11.642675 46.611097))",
"POLYGON ((11.628745 46.738361, 12.113517 46.738361, 12.113517 46.823272, 11.628745 46.823272, 11.628745 46.738361))",
"POLYGON ((11.645102 46.823301, 12.108604 46.823301, 12.108604 47.438801, 11.645102 47.438801, 11.645102 46.823301))",
"POLYGON ((11.708925 47.438824, 12.143544 47.438824, 12.143544 47.853735, 11.708925 47.853735, 11.708925 47.438824))",
"POLYGON ((11.665929 47.853742, 12.144487 47.853742, 12.144487 47.882801, 11.665929 47.882801, 11.665929 47.853742))",
"POLYGON ((11.628527 47.882801, 12.057599 47.882801, 12.057599 48.281621, 11.628527 48.281621, 11.628527 47.882801))",
"POLYGON ((11.881088 48.281623, 12.088570 48.281623, 12.088570 48.308414, 11.881088 48.308414, 11.881088 48.281623))",
"POLYGON ((11.637514 48.308414, 12.118756 48.308414, 12.118756 48.488439, 11.637514 48.488439, 11.637514 48.308414))",
"POLYGON ((11.653008 48.488786, 12.147246 48.488786, 12.147246 49.015002, 11.653008 49.015002, 11.653008 48.488786))",
"POLYGON ((12.038671 49.015004, 12.108625 49.015004, 12.108625 49.022393, 12.038671 49.022393, 12.038671 49.015004))",
"POLYGON ((11.718881 49.022401, 12.142392 49.022401, 12.142392 49.155063, 11.718881 49.155063, 11.718881 49.022401))",
"POLYGON ((11.720125 49.155063, 12.146194 49.155063, 12.146194 49.221716, 11.720125 49.221716, 11.720125 49.155063))",
"POLYGON ((11.792997 49.221912, 12.144800 49.221912, 12.144800 49.297889, 11.792997 49.297889, 11.792997 49.221912))",
"POLYGON ((11.645421 49.298141, 12.084427 49.298141, 12.084427 49.556449, 11.645421 49.556449, 11.645421 49.298141))",
"POLYGON ((11.645198 49.556451, 12.145620 49.556451, 12.145620 50.310495, 11.645198 50.310495, 11.645198 49.556451))",
"POLYGON ((11.634841 50.310498, 12.138262 50.310498, 12.138262 50.611312, 11.634841 50.611312, 11.634841 50.310498))",
"POLYGON ((11.629348 50.611312, 12.131608 50.611312, 12.131608 51.005914, 11.629348 51.005914, 11.629348 50.611312))",
"POLYGON ((11.629687 51.005914, 12.139110 51.005914, 12.139110 51.178070, 11.629687 51.178070, 11.629687 51.005914))",
"POLYGON ((11.628526 51.178070, 12.108912 51.178070, 12.108912 51.221576, 11.628526 51.221576, 11.628526 51.178070))",
"POLYGON ((11.656789 51.221576, 12.146178 51.221576, 12.146178 51.359691, 11.656789 51.359691, 11.656789 51.221576))",
"POLYGON ((11.658318 51.359693, 12.143078 51.359693, 12.143078 51.494147, 11.658318 51.494147, 11.658318 51.359693))",
"POLYGON ((11.729512 51.494147, 12.135207 51.494147, 12.135207 51.620275, 11.729512 51.620275, 11.729512 51.494147))",
"POLYGON ((11.651616 51.620293, 12.116308 51.620293, 12.116308 51.855283, 11.651616 51.855283, 11.651616 51.620293))",
"POLYGON ((11.632830 51.855552, 12.111584 51.855552, 12.111584 52.123193, 11.632830 52.123193, 11.632830 51.855552))",
"POLYGON ((11.631108 52.123213, 12.084105 52.123213, 12.084105 52.426660, 11.631108 52.426660, 11.631108 52.123213))",
"POLYGON ((11.634148 52.426676, 12.016510 52.426676, 12.016510 52.656903, 11.634148 52.656903, 11.634148 52.426676))",
"POLYGON ((11.645984 52.656909, 12.106080 52.656909, 12.106080 53.824421, 11.645984 53.824421, 11.645984 52.656909))",
"POLYGON ((11.685484 53.824429, 12.147189 53.824429, 12.147189 54.095884, 11.685484 54.095884, 11.685484 53.824429))",
"POLYGON ((11.693658 54.095894, 12.123915 54.095894, 12.123915 55.616707, 11.693658 55.616707, 11.693658 54.095894))",
"POLYGON ((11.750624 55.616729, 12.138593 55.616729, 12.138593 57.709381, 11.750624 57.709381, 11.750624 55.616729))",
"POLYGON ((11.646868 57.709393, 12.137272 57.709393, 12.137272 60.199906, 11.646868 60.199906, 11.646868 57.709393))",
"POLYGON ((12.252087 21.015252, 12.496489 21.015252, 12.496489 41.759123, 12.252087 41.759123, 12.252087 21.015252))",
"POLYGON ((12.254145 41.759133, 12.507779 41.759133, 12.507779 41.866706, 12.254145 41.866706, 12.254145 41.759133))",
"POLYGON ((12.423316 41.866706, 12.506611 41.866706, 12.506611 41.878837, 12.423316 41.878837, 12.423316 41.866706))",
"POLYGON ((12.461385 41.878840, 12.506091 41.878840, 12.506091 41.884969, 12.461385 41.884969, 12.461385 41.878840))",
"POLYGON ((12.299670 41.884974, 12.508369 41.884974, 12.508369 41.890256, 12.299670 41.890256, 12.299670 41.884974))",
"POLYGON ((12.460066 41.890258, 12.499670 41.890258, 12.499670 41.892442, 12.460066 41.892442, 12.460066 41.890258))",
"POLYGON ((12.459322 41.892449, 12.501525 41.892449, 12.501525 41.894856, 12.459322 41.894856, 12.459322 41.892449))",
"POLYGON ((12.445744 41.894861, 12.507882 41.894861, 12.507882 41.902205, 12.445744 41.902205, 12.445744 41.894861))",
"POLYGON ((12.446583 41.902205, 12.507645 41.902205, 12.507645 41.903813, 12.446583 41.903813, 12.446583 41.902205))",
"POLYGON ((12.448844 41.903839, 12.508305 41.903839, 12.508305 41.910677, 12.448844 41.910677, 12.448844 41.903839))",
"POLYGON ((12.153407 41.910683, 12.498784 41.910683, 12.498784 42.025438, 12.153407 42.025438, 12.153407 41.910683))",
"POLYGON ((12.147618 42.026152, 12.394443 42.026152, 12.394443 42.163470, 12.147618 42.163470, 12.147618 42.026152))",
"POLYGON ((12.191942 42.163517, 12.503095 42.163517, 12.503095 42.472219, 12.191942 42.472219, 12.191942 42.163517))",
"POLYGON ((12.148485 42.473831, 12.508406 42.473831, 12.508406 42.695544, 12.148485 42.695544, 12.148485 42.473831))",
"POLYGON ((12.182612 42.695637, 12.507269 42.695637, 12.507269 43.457962, 12.182612 43.457962, 12.182612 42.695637))",
"POLYGON ((12.148291 43.457988, 12.452698 43.457988, 12.452698 43.935416, 12.148291 43.935416, 12.148291 43.457988))",
"POLYGON ((12.150561 43.935475, 12.503162 43.935475, 12.503162 44.421865, 12.150561 44.421865, 12.150561 43.935475))",
"POLYGON ((12.165386 44.421875, 12.460088 44.421875, 12.460088 45.219216, 12.165386 45.219216, 12.165386 44.421875))",
"POLYGON ((12.152252 45.219230, 12.439008 45.219230, 12.439008 45.523809, 12.152252 45.523809, 12.152252 45.219230))",
"POLYGON ((12.151065 45.523855, 12.463677 45.523855, 12.463677 45.669579, 12.151065 45.669579, 12.151065 45.523855))",
"POLYGON ((12.160924 45.669579, 12.501152 45.669579, 12.501152 45.894902, 12.160924 45.894902, 12.160924 45.669579))",
"POLYGON ((12.154830 45.895087, 12.505676 45.895087, 12.505676 46.162899, 12.154830 46.162899, 12.154830 45.895087))",
"POLYGON ((12.202376 46.162988, 12.504516 46.162988, 12.504516 46.616151, 12.202376 46.616151, 12.202376 46.162988))",
"POLYGON ((12.240204 46.616157, 12.252522 46.616157, 12.252522 46.624344, 12.240204 46.624344, 12.240204 46.616157))",
"POLYGON ((12.167395 46.624344, 12.501954 46.624344, 12.501954 47.150739, 12.167395 47.150739, 12.167395 46.624344))",
"POLYGON ((12.153882 47.150739, 12.485033 47.150739, 12.485033 47.765906, 12.153882 47.765906, 12.153882 47.150739))",
"POLYGON ((12.148825 47.765915, 12.507849 47.765915, 12.507849 48.531449, 12.148825 48.531449, 12.148825 47.765915))",
"POLYGON ((12.148486 48.531454, 12.162938 48.531454, 12.162938 48.537138, 12.148486 48.537138, 12.148486 48.531454))",
"POLYGON ((12.151255 48.537169, 12.508267 48.537169, 12.508267 49.093579, 12.151255 49.093579, 12.151255 48.537169))",
"POLYGON ((12.153074 49.093606, 12.498120 49.093606, 12.498120 49.736454, 12.153074 49.736454, 12.153074 49.093606))",
"POLYGON ((12.156934 49.736651, 12.490206 49.736651, 12.490206 50.675798, 12.156934 50.675798, 12.156934 49.736651))",
"POLYGON ((12.169515 50.675866, 12.505623 50.675866, 12.505623 51.157308, 12.169515 51.157308, 12.169515 50.675866))",
"POLYGON ((12.156413 51.157308, 12.507749 51.157308, 12.507749 51.294510, 12.156413 51.294510, 12.156413 51.157308))",
"POLYGON ((12.222974 51.294526, 12.428585 51.294526, 12.428585 51.309767, 12.222974 51.309767, 12.222974 51.294526))",
"POLYGON ((12.174614 51.309769, 12.417748 51.309769, 12.417748 51.319712, 12.174614 51.319712, 12.174614 51.309769))",
"POLYGON ((12.341123 51.319712, 12.460555 51.319712, 12.460555 51.324900, 12.341123 51.324900, 12.341123 51.319712))",
"POLYGON ((12.326373 51.324916, 12.459770 51.324916, 12.459770 51.330560, 12.326373 51.330560, 12.326373 51.324916))",
"POLYGON ((12.347232 51.330567, 12.479403 51.330567, 12.479403 51.334843, 12.347232 51.334843, 12.347232 51.330567))",
"POLYGON ((12.333474 51.334843, 12.411792 51.334843, 12.411792 51.336714, 12.333474 51.336714, 12.333474 51.334843))",
"POLYGON ((12.357489 51.336716, 12.380907 51.336716, 12.380907 51.339441, 12.357489 51.339441, 12.357489 51.336716))",
"POLYGON ((12.254967 51.339441, 12.379100 51.339441, 12.379100 51.342738, 12.254967 51.342738, 12.254967 51.339441))",
"POLYGON ((12.355390 51.342742, 12.364919 51.342742, 12.364919 51.344276, 12.355390 51.344276, 12.355390 51.342742))",
"POLYGON ((12.355015 51.344278, 12.424663 51.344278, 12.424663 51.344830, 12.355015 51.344830, 12.355015 51.344278))",
"POLYGON ((12.354675 51.344831, 12.424425 51.344831, 12.424425 51.345314, 12.354675 51.345314, 12.354675 51.344831))",
"POLYGON ((12.353842 51.345315, 12.415069 51.345315, 12.415069 51.345761, 12.353842 51.345761, 12.353842 51.345315))",
"POLYGON ((12.350143 51.345762, 12.405541 51.345762, 12.405541 51.346362, 12.350143 51.346362, 12.350143 51.345762))",
"POLYGON ((12.349827 51.346363, 12.372664 51.346363, 12.372664 51.347361, 12.349827 51.347361, 12.349827 51.346363))",
"POLYGON ((12.351326 51.347361, 12.456761 51.347361, 12.456761 51.350348, 12.351326 51.350348, 12.351326 51.347361))",
"POLYGON ((12.368422 51.350348, 12.401550 51.350348, 12.401550 51.353452, 12.368422 51.353452, 12.368422 51.350348))",
"POLYGON ((12.212470 51.353452, 12.375604 51.353452, 12.375604 51.355364, 12.212470 51.355364, 12.212470 51.353452))",
"POLYGON ((12.220727 51.355366, 12.376353 51.355366, 12.376353 51.357705, 12.220727 51.357705, 12.220727 51.355366))",
"POLYGON ((12.147722 51.357707, 12.439635 51.357707, 12.439635 51.363564, 12.147722 51.363564, 12.147722 51.357707))",
"POLYGON ((12.333348 51.363564, 12.377465 51.363564, 12.377465 51.369141, 12.333348 51.369141, 12.333348 51.363564))",
"POLYGON ((12.318820 51.369164, 12.417994 51.369164, 12.417994 51.375260, 12.318820 51.375260, 12.318820 51.369164))",
"POLYGON ((12.194293 51.375264, 12.504520 51.375264, 12.504520 51.442646, 12.194293 51.442646, 12.194293 51.375264))",
"POLYGON ((12.155862 51.443909, 12.462045 51.443909, 12.462045 51.711478, 12.155862 51.711478, 12.155862 51.443909))",
"POLYGON ((12.178864 51.711508, 12.464508 51.711508, 12.464508 51.835349, 12.178864 51.835349, 12.178864 51.711508))",
"POLYGON ((12.178810 51.835384, 12.457237 51.835384, 12.457237 51.861224, 12.178810 51.861224, 12.178810 51.835384))",
"POLYGON ((12.153720 51.861246, 12.507833 51.861246, 12.507833 52.532797, 12.153720 52.532797, 12.153720 51.861246))",
"POLYGON ((12.165006 52.532809, 12.503999 52.532809, 12.503999 53.283303, 12.165006 53.283303, 12.165006 52.532809))",
"POLYGON ((12.147576 53.283314, 12.499938 53.283314, 12.499938 55.354500, 12.147576 55.354500, 12.147576 53.283314))",
"POLYGON ((12.154943 55.354504, 12.378777 55.354504, 12.378777 55.747500, 12.154943 55.747500, 12.154943 55.354504))",
"POLYGON ((12.186458 55.747520, 12.499208 55.747520, 12.499208 65.798212, 12.186458 65.798212, 12.186458 55.747520))",
"POLYGON ((12.590077 6.518103, 13.090991 6.518103, 13.090991 37.661880, 12.590077 37.661880, 12.590077 6.518103))",
"POLYGON ((12.515512 37.661939, 12.987239 37.661939, 12.987239 37.912798, 12.515512 37.912798, 12.515512 37.661939))",
"POLYGON ((12.590186 37.912804, 13.089697 37.912804, 13.089697 41.224563, 12.590186 41.224563, 12.590186 37.912804))",
"POLYGON ((12.510145 41.238556, 13.083578 41.238556, 13.083578 41.591762, 12.510145 41.591762, 12.510145 41.238556))",
"POLYGON ((12.588522 41.591991, 13.026822 41.591991, 13.026822 41.795037, 12.588522 41.795037, 12.588522 41.591991))",
"POLYGON ((12.566119 41.795073, 12.799811 41.795073, 12.799811 41.812435, 12.566119 41.812435, 12.566119 41.795073))",
"POLYGON ((12.544391 41.812445, 12.974116 41.812445, 12.974116 41.830522, 12.544391 41.830522, 12.544391 41.812445))",
"POLYGON ((12.532285 41.830524, 12.974528 41.830524, 12.974528 41.844113, 12.532285 41.844113, 12.532285 41.830524))",
"POLYGON ((12.509228 41.844121, 12.896267 41.844121, 12.896267 41.869722, 12.509228 41.869722, 12.509228 41.844121))",
"POLYGON ((12.508421 41.869776, 13.065145 41.869776, 13.065145 41.890591, 12.508421 41.890591, 12.508421 41.869776))",
"POLYGON ((12.508434 41.890603, 12.830556 41.890603, 12.830556 41.912649, 12.508434 41.912649, 12.508434 41.890603))",
"POLYGON ((12.511969 41.912671, 12.929155 41.912671, 12.929155 41.941627, 12.511969 41.941627, 12.511969 41.912671))",
"POLYGON ((12.535704 41.941651, 12.892387 41.941651, 12.892387 42.015471, 12.535704 42.015471, 12.535704 41.941651))",
"POLYGON ((12.522004 42.015480, 13.039484 42.015480, 13.039484 42.291927, 12.522004 42.291927, 12.522004 42.015480))",
"POLYGON ((12.508834 42.295028, 13.015840 42.295028, 13.015840 42.612439, 12.508834 42.612439, 12.508834 42.295028))",
"POLYGON ((12.524060 42.612490, 13.092696 42.612490, 13.092696 43.069007, 12.524060 43.069007, 12.524060 42.612490))",
"POLYGON ((12.572416 43.069024, 13.093765 43.069024, 13.093765 43.462230, 12.572416 43.462230, 12.572416 43.069024))",
"POLYGON ((12.727242 43.462302, 13.086947 43.462302, 13.086947 43.582149, 12.727242 43.582149, 12.727242 43.462302))",
"POLYGON ((12.512644 43.582158, 13.087146 43.582158, 13.087146 44.034577, 12.512644 44.034577, 12.512644 43.582158))",
"POLYGON ((12.519196 44.034602, 13.075495 44.034602, 13.075495 45.781268, 12.519196 45.781268, 12.519196 44.034602))",
"POLYGON ((12.521758 45.781275, 13.075501 45.781275, 13.075501 45.956181, 12.521758 45.956181, 12.521758 45.781275))",
"POLYGON ((12.541851 45.956200, 13.069364 45.956200, 13.069364 46.110523, 12.541851 46.110523, 12.541851 45.956200))",
"POLYGON ((12.511244 46.110523, 13.094183 46.110523, 13.094183 46.288831, 12.511244 46.288831, 12.511244 46.110523))",
"POLYGON ((12.548909 46.288842, 13.090279 46.288842, 13.090279 46.569892, 12.548909 46.569892, 12.548909 46.288842))",
"POLYGON ((12.509044 46.569892, 13.070413 46.569892, 13.070413 47.048818, 12.509044 47.048818, 12.509044 46.569892))",
"POLYGON ((12.529540 47.048926, 13.093534 47.048926, 13.093534 47.701335, 12.529540 47.701335, 12.529540 47.048926))",
"POLYGON ((12.753846 47.701343, 13.090221 47.701343, 13.090221 47.736556, 12.753846 47.736556, 12.753846 47.701343))",
"POLYGON ((12.645327 47.736617, 13.095121 47.736617, 13.095121 47.795508, 12.645327 47.795508, 12.645327 47.736617))",
"POLYGON ((12.640171 47.795515, 13.060142 47.795515, 13.060142 47.801272, 12.640171 47.801272, 12.640171 47.795515))",
"POLYGON ((12.587960 47.801274, 13.088980 47.801274, 13.088980 47.938683, 12.587960 47.938683, 12.587960 47.801274))",
"POLYGON ((12.520547 47.938738, 13.093534 47.938738, 13.093534 48.667201, 12.520547 48.667201, 12.520547 47.938738))",
"POLYGON ((12.562349 48.667201, 13.082915 48.667201, 13.082915 48.884550, 12.562349 48.884550, 12.562349 48.667201))",
"POLYGON ((12.509131 48.884563, 13.087652 48.884563, 13.087652 49.709826, 12.509131 49.709826, 12.509131 48.884563))",
"POLYGON ((12.604822 49.709832, 13.016288 49.709832, 13.016288 50.022308, 12.604822 50.022308, 12.604822 49.709832))",
"POLYGON ((12.513638 50.022333, 13.077533 50.022333, 13.077533 50.560727, 12.513638 50.560727, 12.513638 50.022333))",
"POLYGON ((12.566006 50.560788, 13.064866 50.560788, 13.064866 50.654693, 12.566006 50.654693, 12.566006 50.560788))",
"POLYGON ((12.537194 50.654709, 13.069067 50.654709, 13.069067 50.816758, 12.537194 50.816758, 12.537194 50.654709))",
"POLYGON ((12.537441 50.816763, 13.087750 50.816763, 13.087750 51.038546, 12.537441 51.038546, 12.537441 50.816763))",
"POLYGON ((12.509578 51.038566, 13.018042 51.038566, 13.018042 51.158939, 12.509578 51.158939, 12.509578 51.038566))",
"POLYGON ((12.509897 51.158941, 13.048486 51.158941, 13.048486 51.252311, 12.509897 51.252311, 12.509897 51.158941))",
"POLYGON ((12.518649 51.252354, 13.082887 51.252354, 13.082887 51.364696, 12.518649 51.364696, 12.518649 51.252354))",
"POLYGON ((12.521425 51.364713, 13.020785 51.364713, 13.020785 51.441073, 12.521425 51.441073, 12.521425 51.364713))",
"POLYGON ((12.590100 51.441077, 13.081091 51.441077, 13.081091 51.553455, 12.590100 51.553455, 12.590100 51.441077))",
"POLYGON ((12.547932 51.553663, 13.062089 51.553663, 13.062089 51.716146, 12.547932 51.716146, 12.547932 51.553663))",
"POLYGON ((12.516371 51.716156, 13.088225 51.716156, 13.088225 52.010915, 12.516371 52.010915, 12.516371 51.716156))",
"POLYGON ((12.885602 52.010932, 13.007590 52.010932, 13.007590 52.028756, 12.885602 52.028756, 12.885602 52.010932))",
"POLYGON ((12.579478 52.028767, 13.092965 52.028767, 13.092965 52.235059, 12.579478 52.235059, 12.579478 52.028767))",
"POLYGON ((12.852728 52.235068, 13.019887 52.235068, 13.019887 52.262330, 12.852728 52.262330, 12.852728 52.235068))",
"POLYGON ((12.838301 52.262331, 12.983048 52.262331, 12.983048 52.310682, 12.838301 52.310682, 12.838301 52.262331))",
"POLYGON ((12.539865 52.310690, 13.087253 52.310690, 13.087253 52.393951, 12.539865 52.393951, 12.539865 52.310690))",
"POLYGON ((13.013388 52.393974, 13.070835 52.393974, 13.070835 52.400336, 13.013388 52.400336, 13.013388 52.393974))",
"POLYGON ((12.554406 52.400343, 13.093499 52.400343, 13.093499 52.403702, 12.554406 52.403702, 12.554406 52.400343))",
"POLYGON ((12.557661 52.403756, 13.093657 52.403756, 13.093657 52.409059, 12.557661 52.409059, 12.557661 52.403756))",
"POLYGON ((12.512059 52.409060, 13.095494 52.409060, 13.095494 52.540602, 12.512059 52.540602, 12.512059 52.409060))",
"POLYGON ((12.601833 52.540603, 13.092617 52.540603, 13.092617 52.669391, 12.601833 52.669391, 12.601833 52.540603))",
"POLYGON ((12.603015 52.672764, 13.094689 52.672764, 13.094689 52.975167, 12.603015 52.975167, 12.603015 52.672764))",
"POLYGON ((12.519678 52.975216, 13.064789 52.975216, 13.064789 53.385279, 12.519678 53.385279, 12.519678 52.975216))",
"POLYGON ((12.541910 53.385287, 13.092648 53.385287, 13.092648 53.773159, 12.541910 53.773159, 12.541910 53.385287))",
"POLYGON ((12.513019 53.773160, 13.087867 53.773160, 13.087867 54.310659, 12.513019 54.310659, 12.513019 53.773160))",
"POLYGON ((12.521480 54.310659, 13.092313 54.310659, 13.092313 55.488013, 12.521480 55.488013, 12.521480 54.310659))",
"POLYGON ((12.563185 55.488014, 13.084922 55.488014, 13.084922 55.688477, 12.563185 55.688477, 12.563185 55.488014))",
"POLYGON ((12.515139 55.688480, 13.043852 55.688480, 13.043852 56.046538, 12.515139 56.046538, 12.515139 55.688480))",
"POLYGON ((12.529622 56.046552, 13.087893 56.046552, 13.087893 59.618065, 12.529622 59.618065, 12.529622 56.046552))",
"POLYGON ((13.103821 -8.864796, 13.420527 -8.864796, 13.420527 38.083737, 13.103821 38.083737, 13.103821 -8.864796))",
"POLYGON ((13.101887 38.083759, 13.430812 38.083759, 13.430812 41.288441, 13.101887 41.288441, 13.101887 38.083759))",
"POLYGON ((13.167019 41.288445, 13.429432 41.288445, 13.429432 41.641651, 13.167019 41.641651, 13.167019 41.288445))",
"POLYGON ((13.111391 41.641671, 13.419130 41.641671, 13.419130 41.778329, 13.111391 41.778329, 13.111391 41.641671))",
"POLYGON ((13.107147 41.778333, 13.428286 41.778333, 13.428286 42.076835, 13.107147 42.076835, 13.107147 41.778333))",
"POLYGON ((13.410441 42.076848, 13.413054 42.076848, 13.413054 42.080042, 13.410441 42.080042, 13.410441 42.076848))",
"POLYGON ((13.096390 42.080044, 13.412534 42.080044, 13.412534 43.137626, 13.096390 43.137626, 13.096390 42.080044))",
"POLYGON ((13.096121 43.141385, 13.424073 43.141385, 13.424073 43.715603, 13.096121 43.715603, 13.096121 43.141385))",
"POLYGON ((13.109502 43.715613, 13.408351 43.715613, 13.408351 45.907291, 13.109502 45.907291, 13.109502 43.715613))",
"POLYGON ((13.111367 45.907291, 13.430830 45.907291, 13.430830 46.095609, 13.111367 46.095609, 13.111367 45.907291))",
"POLYGON ((13.108053 46.095614, 13.392666 46.095614, 13.392666 46.303636, 13.108053 46.303636, 13.108053 46.095614))",
"POLYGON ((13.097443 46.303638, 13.420347 46.303638, 13.420347 47.088032, 13.097443 47.088032, 13.097443 46.303638))",
"POLYGON ((13.098400 47.088109, 13.368550 47.088109, 13.368550 47.367130, 13.098400 47.367130, 13.098400 47.088109))",
"POLYGON ((13.095756 47.367294, 13.427315 47.367294, 13.427315 48.277894, 13.095756 48.277894, 13.095756 47.367294))",
"POLYGON ((13.118680 48.277899, 13.428600 48.277899, 13.428600 49.538625, 13.118680 49.538625, 13.118680 48.277899))",
"POLYGON ((13.146841 49.538743, 13.431607 49.538743, 13.431607 50.650155, 13.146841 50.650155, 13.146841 49.538743))",
"POLYGON ((13.098438 50.650223, 13.431099 50.650223, 13.431099 50.849269, 13.098438 50.849269, 13.098438 50.650223))",
"POLYGON ((13.293020 50.849274, 13.332889 50.849274, 13.332889 50.856661, 13.293020 50.856661, 13.293020 50.849274))",
"POLYGON ((13.247523 50.856666, 13.340731 50.856666, 13.340731 50.865352, 13.247523 50.865352, 13.247523 50.856666))",
"POLYGON ((13.274528 50.865384, 13.346053 50.865384, 13.346053 50.872673, 13.274528 50.872673, 13.274528 50.865384))",
"POLYGON ((13.283686 50.872674, 13.344657 50.872674, 13.344657 50.876469, 13.283686 50.876469, 13.283686 50.872674))",
"POLYGON ((13.290537 50.876490, 13.352255 50.876490, 13.352255 50.884357, 13.290537 50.884357, 13.290537 50.876490))",
"POLYGON ((13.271245 50.884371, 13.356716 50.884371, 13.356716 50.893956, 13.271245 50.893956, 13.271245 50.884371))",
"POLYGON ((13.154926 50.893985, 13.398815 50.893985, 13.398815 50.954399, 13.154926 50.954399, 13.154926 50.893985))",
"POLYGON ((13.106294 50.954666, 13.432712 50.954666, 13.432712 51.197638, 13.106294 51.197638, 13.106294 50.954666))",
"POLYGON ((13.095568 51.197661, 13.419411 51.197661, 13.419411 51.384529, 13.095568 51.384529, 13.095568 51.197661))",
"POLYGON ((13.098931 51.384668, 13.431925 51.384668, 13.431925 51.869559, 13.098931 51.869559, 13.098931 51.384668))",
"POLYGON ((13.100812 51.869572, 13.433338 51.869572, 13.433338 52.047853, 13.100812 52.047853, 13.100812 51.869572))",
"POLYGON ((13.155238 52.047870, 13.372519 52.047870, 13.372519 52.102692, 13.155238 52.102692, 13.155238 52.047870))",
"POLYGON ((13.124094 52.102692, 13.433258 52.102692, 13.433258 52.244564, 13.124094 52.244564, 13.124094 52.102692))",
"POLYGON ((13.110688 52.244860, 13.417754 52.244860, 13.417754 52.388018, 13.110688 52.388018, 13.110688 52.244860))",
"POLYGON ((13.096078 52.388873, 13.433096 52.388873, 13.433096 52.409322, 13.096078 52.409322, 13.096078 52.388873))",
"POLYGON ((13.095697 52.409322, 13.431084 52.409322, 13.431084 52.422307, 13.095697 52.422307, 13.095697 52.409322))",
"POLYGON ((13.116118 52.422371, 13.333724 52.422371, 13.333724 52.429143, 13.116118 52.429143, 13.116118 52.422371))",
"POLYGON ((13.223432 52.429161, 13.329768 52.429161, 13.329768 52.434484, 13.223432 52.434484, 13.223432 52.429161))",
"POLYGON ((13.270460 52.434484, 13.369979 52.434484, 13.369979 52.435416, 13.270460 52.435416, 13.270460 52.434484))",
"POLYGON ((13.239383 52.435426, 13.369460 52.435426, 13.369460 52.436603, 13.239383 52.436603, 13.239383 52.435426))",
"POLYGON ((13.233377 52.436606, 13.369076 52.436606, 13.369076 52.437721, 13.233377 52.437721, 13.233377 52.436606))",
"POLYGON ((13.220784 52.437736, 13.368914 52.437736, 13.368914 52.439942, 13.220784 52.439942, 13.220784 52.437736))",
"POLYGON ((13.220730 52.439946, 13.375760 52.439946, 13.375760 52.443363, 13.220730 52.443363, 13.220730 52.439946))",
"POLYGON ((13.276365 52.443363, 13.376032 52.443363, 13.376032 52.448322, 13.276365 52.448322, 13.276365 52.443363))",
"POLYGON ((13.269585 52.448335, 13.362053 52.448335, 13.362053 52.453498, 13.269585 52.453498, 13.269585 52.448335))",
"POLYGON ((13.265740 52.453525, 13.359098 52.453525, 13.359098 52.457453, 13.265740 52.457453, 13.265740 52.453525))",
"POLYGON ((13.274764 52.457455, 13.326750 52.457455, 13.326750 52.460823, 13.274764 52.460823, 13.274764 52.457455))",
"POLYGON ((13.277076 52.460825, 13.327968 52.460825, 13.327968 52.464616, 13.277076 52.464616, 13.277076 52.460825))",
"POLYGON ((13.255959 52.464618, 13.421098 52.464618, 13.421098 52.467617, 13.255959 52.467617, 13.255959 52.464618))",
"POLYGON ((13.275315 52.467619, 13.429639 52.467619, 13.429639 52.479651, 13.275315 52.479651, 13.275315 52.467619))",
"POLYGON ((13.288711 52.479652, 13.428772 52.479652, 13.428772 52.487805, 13.288711 52.487805, 13.288711 52.479652))",
"POLYGON ((13.239017 52.487807, 13.431903 52.487807, 13.431903 52.504700, 13.239017 52.504700, 13.239017 52.487807))",
"POLYGON ((13.321897 52.504705, 13.431603 52.504705, 13.431603 52.515893, 13.321897 52.515893, 13.321897 52.504705))",
"POLYGON ((13.136456 52.515896, 13.416169 52.515896, 13.416169 52.528940, 13.136456 52.528940, 13.136456 52.515896))",
"POLYGON ((13.275110 52.528976, 13.383324 52.528976, 13.383324 52.531158, 13.275110 52.531158, 13.275110 52.528976))",
"POLYGON ((13.202490 52.531161, 13.433049 52.531161, 13.433049 52.538588, 13.202490 52.538588, 13.202490 52.531161))",
"POLYGON ((13.205996 52.538599, 13.432927 52.538599, 13.432927 52.547095, 13.205996 52.547095, 13.205996 52.538599))",
"POLYGON ((13.103903 52.547098, 13.432425 52.547098, 13.432425 52.561035, 13.103903 52.561035, 13.103903 52.547098))",
"POLYGON ((13.191959 52.561157, 13.405930 52.561157, 13.405930 52.582601, 13.191959 52.582601, 13.191959 52.561157))",
"POLYGON ((13.095538 52.582613, 13.433032 52.582613, 13.433032 52.625096, 13.095538 52.625096, 13.095538 52.582613))",
"POLYGON ((13.097255 52.625889, 13.408285 52.625889, 13.408285 52.710901, 13.097255 52.710901, 13.097255 52.625889))",
"POLYGON ((13.095731 52.711104, 13.390868 52.711104, 13.390868 53.008700, 13.095731 53.008700, 13.095731 52.711104))",
"POLYGON ((13.101341 53.008847, 13.338667 53.008847, 13.338667 53.690367, 13.101341 53.690367, 13.101341 53.008847))",
"POLYGON ((13.097764 53.690367, 13.433136 53.690367, 13.433136 55.419936, 13.097764 55.419936, 13.097764 53.690367))",
"POLYGON ((13.099921 55.419942, 13.428611 55.419942, 13.428611 55.704496, 13.099921 55.704496, 13.099921 55.419942))",
"POLYGON ((13.099703 55.704525, 13.429967 55.704525, 13.429967 66.934878, 13.099703 66.934878, 13.099703 55.704525))",
"POLYGON ((13.476518 13.105816, 14.052926 13.105816, 14.052926 37.290110, 13.476518 37.290110, 13.476518 13.105816))",
"POLYGON ((13.439085 37.290114, 14.058289 37.290114, 14.058289 38.094097, 13.439085 38.094097, 13.439085 37.290114))",
"POLYGON ((13.529737 38.094126, 14.058611 38.094126, 14.058611 41.210468, 13.529737 41.210468, 13.529737 38.094126))",
"POLYGON ((13.433929 41.210495, 13.989934 41.210495, 13.989934 41.353478, 13.433929 41.353478, 13.433929 41.210495))",
"POLYGON ((13.435560 41.353486, 14.047026 41.353486, 14.047026 41.649997, 13.435560 41.649997, 13.435560 41.353486))",
"POLYGON ((13.460332 41.650000, 14.044821 41.650000, 14.044821 42.166931, 13.460332 42.166931, 13.460332 41.650000))",
"POLYGON ((13.494488 42.166938, 13.958907 42.166938, 13.958907 42.286909, 13.494488 42.286909, 13.494488 42.166938))",
"POLYGON ((13.458619 42.286950, 13.969699 42.286950, 13.969699 43.101678, 13.458619 43.101678, 13.458619 42.286950))",
"POLYGON ((13.440881 43.101679, 13.925350 43.101679, 13.925350 44.882086, 13.440881 44.882086, 13.440881 43.101679))",
"POLYGON ((13.582945 44.882157, 14.037758 44.882157, 14.037758 45.228105, 13.582945 45.228105, 13.582945 44.882157))",
"POLYGON ((13.506605 45.228115, 14.051519 45.228115, 14.051519 45.605016, 13.506605 45.605016, 13.506605 45.228115))",
"POLYGON ((13.666061 45.605017, 14.007022 45.605017, 14.007022 45.765799, 13.666061 45.765799, 13.666061 45.605017))",
"POLYGON ((13.532588 45.765821, 13.748173 45.765821, 13.748173 45.812118, 13.532588 45.812118, 13.532588 45.765821))",
"POLYGON ((13.510642 45.812123, 13.596636 45.812123, 13.596636 45.826021, 13.510642 45.826021, 13.510642 45.812123))",
"POLYGON ((13.516570 45.826083, 13.573428 45.826083, 13.573428 45.836724, 13.516570 45.836724, 13.516570 45.826083))",
"POLYGON ((13.447231 45.836730, 13.796166 45.836730, 13.796166 45.921545, 13.447231 45.921545, 13.447231 45.836730))",
"POLYGON ((13.434117 45.921548, 13.980231 45.921548, 13.980231 46.341769, 13.434117 46.341769, 13.434117 45.921548))",
"POLYGON ((13.437048 46.341781, 14.053440 46.341781, 14.053440 46.629317, 13.437048 46.629317, 13.437048 46.341781))",
"POLYGON ((13.443412 46.629334, 13.988278 46.629334, 13.988278 46.907135, 13.443412 46.907135, 13.443412 46.629334))",
"POLYGON ((13.462679 46.907137, 13.768696 46.907137, 13.768696 46.950049, 13.462679 46.950049, 13.462679 46.907137))",
"POLYGON ((13.506585 46.950078, 13.973885 46.950078, 13.973885 47.257354, 13.506585 47.257354, 13.506585 46.950078))",
"POLYGON ((13.439726 47.329388, 13.982361 47.329388, 13.982361 47.610529, 13.439726 47.610529, 13.439726 47.329388))",
"POLYGON ((13.541200 47.610532, 13.967096 47.610532, 13.967096 47.905337, 13.541200 47.905337, 13.541200 47.610532))",
"POLYGON ((13.455339 47.905354, 14.044597 47.905354, 14.044597 48.311296, 13.455339 48.311296, 13.455339 47.905354))",
"POLYGON ((13.437643 48.311296, 14.036169 48.311296, 14.036169 48.502024, 13.437643 48.502024, 13.437643 48.311296))",
"POLYGON ((13.439743 48.502031, 14.041333 48.502031, 14.041333 48.577483, 13.439743 48.577483, 13.439743 48.502031))",
"POLYGON ((13.444144 48.577486, 14.036791 48.577486, 14.036791 49.167691, 13.444144 49.167691, 13.444144 48.577486))",
"POLYGON ((13.439345 49.167691, 14.049950 49.167691, 14.049950 49.837458, 13.439345 49.837458, 13.439345 49.167691))",
"POLYGON ((13.510413 49.837488, 14.044193 49.837488, 14.044193 50.520581, 13.510413 50.520581, 13.510413 49.837488))",
"POLYGON ((13.454499 50.520586, 14.051768 50.520586, 14.051768 50.682642, 13.454499 50.682642, 13.454499 50.520586))",
"POLYGON ((13.447097 50.682653, 13.957191 50.682653, 13.957191 50.775232, 13.447097 50.775232, 13.447097 50.682653))",
"POLYGON ((13.434354 50.775362, 13.980688 50.775362, 13.980688 50.894573, 13.434354 50.894573, 13.434354 50.775362))",
"POLYGON ((13.471941 50.894581, 14.058604 50.894581, 14.058604 50.937096, 13.471941 50.937096, 13.471941 50.894581))",
"POLYGON ((13.580344 50.937122, 14.010136 50.937122, 14.010136 51.016103, 13.580344 51.016103, 13.580344 50.937122))",
"POLYGON ((13.476376 51.016111, 14.058476 51.016111, 14.058476 51.052513, 13.476376 51.052513, 13.476376 51.016111))",
"POLYGON ((13.502485 51.052520, 13.962850 51.052520, 13.962850 51.080490, 13.502485 51.080490, 13.502485 51.052520))",
"POLYGON ((13.436625 51.080611, 13.946573 51.080611, 13.946573 51.161874, 13.436625 51.161874, 13.436625 51.080611))",
"POLYGON ((13.468845 51.161944, 13.854864 51.161944, 13.854864 51.169474, 13.468845 51.169474, 13.468845 51.161944))",
"POLYGON ((13.458103 51.171664, 13.990524 51.171664, 13.990524 51.372654, 13.458103 51.372654, 13.458103 51.171664))",
"POLYGON ((13.433978 51.372686, 14.055350 51.372686, 14.055350 51.656953, 13.433978 51.656953, 13.433978 51.372686))",
"POLYGON ((13.449993 51.685094, 14.055330 51.685094, 14.055330 51.852115, 13.449993 51.852115, 13.449993 51.685094))",
"POLYGON ((13.433545 51.852117, 14.058180 51.852117, 14.058180 51.933578, 13.433545 51.933578, 13.433545 51.852117))",
"POLYGON ((13.597310 51.933610, 14.054105 51.933610, 14.054105 51.971328, 13.597310 51.971328, 13.597310 51.933610))",
"POLYGON ((13.543864 51.971563, 14.030454 51.971563, 14.030454 52.021376, 13.543864 52.021376, 13.543864 51.971563))",
"POLYGON ((13.497435 52.021376, 14.048109 52.021376, 14.048109 52.051430, 13.497435 52.051430, 13.497435 52.021376))",
"POLYGON ((13.433804 52.051433, 13.920912 52.051433, 13.920912 52.258576, 13.433804 52.258576, 13.433804 52.051433))",
"POLYGON ((13.433383 52.258602, 14.058495 52.258602, 14.058495 52.367908, 13.433383 52.367908, 13.433383 52.258602))",
"POLYGON ((13.486940 52.367908, 14.057447 52.367908, 14.057447 52.399045, 13.486940 52.399045, 13.486940 52.367908))",
"POLYGON ((13.435842 52.399045, 13.573752 52.399045, 13.573752 52.439871, 13.435842 52.439871, 13.435842 52.399045))",
"POLYGON ((13.437712 52.439916, 13.573300 52.439916, 13.573300 52.448350, 13.437712 52.448350, 13.437712 52.439916))",
"POLYGON ((13.455224 52.448371, 13.795934 52.448371, 13.795934 52.475695, 13.455224 52.475695, 13.455224 52.448371))",
"POLYGON ((13.437662 52.475704, 13.796544 52.475704, 13.796544 52.491399, 13.437662 52.491399, 13.437662 52.475704))",
"POLYGON ((13.433876 52.491411, 13.841349 52.491411, 13.841349 52.506398, 13.433876 52.506398, 13.433876 52.491411))",
"POLYGON ((13.434547 52.506452, 13.749781 52.506452, 13.749781 52.532460, 13.434547 52.532460, 13.434547 52.506452))",
"POLYGON ((13.433374 52.532460, 13.980637 52.532460, 13.980637 52.609031, 13.433374 52.609031, 13.433374 52.532460))",
"POLYGON ((13.436630 52.609032, 13.989506 52.609032, 13.989506 52.775664, 13.436630 52.775664, 13.436630 52.609032))",
"POLYGON ((13.447291 52.775669, 14.056151 52.775669, 14.056151 53.117830, 13.447291 53.117830, 13.447291 52.775669))",
"POLYGON ((13.495370 53.117835, 13.504050 53.117835, 13.504050 53.122750, 13.495370 53.122750, 13.495370 53.117835))",
"POLYGON ((13.444921 53.122751, 14.033232 53.122751, 14.033232 53.408541, 13.444921 53.408541, 13.444921 53.122751))",
"POLYGON ((13.441453 53.408542, 14.049035 53.408542, 14.049035 54.089686, 13.441453 54.089686, 13.441453 53.408542))",
"POLYGON ((13.441089 54.105922, 13.826955 54.105922, 13.826955 54.511939, 13.441089 54.511939, 13.441089 54.105922))",
"POLYGON ((13.435635 54.512103, 14.054777 54.512103, 14.054777 55.734743, 13.435635 55.734743, 13.435635 54.512103))",
"POLYGON ((13.437037 55.734745, 14.057964 55.734745, 14.057964 66.919386, 13.437037 66.919386, 13.437037 55.734745))",
"POLYGON ((14.068672 -23.996349, 14.564628 -23.996349, 14.564628 35.876611, 14.068672 35.876611, 14.068672 -23.996349))",
"POLYGON ((14.343291 35.876617, 14.532924 35.876617, 14.532924 35.932581, 14.343291 35.932581, 14.343291 35.876617))",
"POLYGON ((14.191918 35.932581, 14.775406 35.932581, 14.775406 36.968326, 14.191918 36.968326, 14.191918 35.932581))",
"POLYGON ((14.138446 36.968358, 14.781346 36.968358, 14.781346 37.309620, 14.138446 37.309620, 14.138446 36.968358))",
"POLYGON ((14.076163 37.309620, 14.700335 37.309620, 14.700335 37.451546, 14.076163 37.451546, 14.076163 37.309620))",
"POLYGON ((14.058647 37.451654, 14.789755 37.451654, 14.789755 37.966252, 14.058647 37.966252, 14.058647 37.451654))",
"POLYGON ((14.198848 37.966290, 14.780399 37.966290, 14.780399 40.688567, 14.198848 40.688567, 14.198848 37.966290))",
"POLYGON ((14.473938 40.688570, 14.716862 40.688570, 14.716862 40.749620, 14.473938 40.749620, 14.473938 40.688570))",
"POLYGON ((14.478041 40.749622, 14.643087 40.749622, 14.643087 40.752299, 14.478041 40.752299, 14.478041 40.749622))",
"POLYGON ((14.373952 40.752303, 14.756191 40.752303, 14.756191 40.790071, 14.373952 40.790071, 14.373952 40.752303))",
"POLYGON ((14.062217 40.790114, 14.753856 40.790114, 14.753856 40.831630, 14.062217 40.831630, 14.062217 40.790114))",
"POLYGON ((14.059026 40.831752, 14.789710 40.831752, 14.789710 40.855791, 14.059026 40.855791, 14.059026 40.831752))",
"POLYGON ((14.109006 40.856349, 14.788110 40.856349, 14.788110 41.062170, 14.109006 41.062170, 14.109006 40.856349))",
"POLYGON ((14.249191 41.062238, 14.783673 41.062238, 14.783673 41.130949, 14.249191 41.130949, 14.249191 41.062238))",
"POLYGON ((14.131402 41.130980, 14.779736 41.130980, 14.779736 41.338536, 14.131402 41.338536, 14.131402 41.130980))",
"POLYGON ((14.506225 41.338763, 14.660608 41.338763, 14.660608 41.433188, 14.506225 41.433188, 14.506225 41.338763))",
"POLYGON ((14.345422 41.433191, 14.640261 41.433191, 14.640261 41.520759, 14.345422 41.520759, 14.345422 41.433191))",
"POLYGON ((14.067276 41.520798, 14.783653 41.520798, 14.783653 41.913605, 14.067276 41.913605, 14.067276 41.520798))",
"POLYGON ((14.061775 41.913618, 14.762548 41.913618, 14.762548 44.960960, 14.061775 44.960960, 14.061775 41.913618))",
"POLYGON ((14.093049 44.960985, 14.744182 44.960985, 14.744182 46.048193, 14.093049 46.048193, 14.093049 44.960985))",
"POLYGON ((14.304616 46.048207, 14.744411 46.048207, 14.744411 46.264607, 14.304616 46.264607, 14.304616 46.048207))",
"POLYGON ((14.080682 46.264608, 14.757754 46.264608, 14.757754 46.649075, 14.080682 46.649075, 14.080682 46.264608))",
"POLYGON ((14.094046 46.657716, 14.768878 46.657716, 14.768878 46.755817, 14.094046 46.755817, 14.094046 46.657716))",
"POLYGON ((14.070367 46.755828, 14.581577 46.755828, 14.581577 46.865441, 14.070367 46.865441, 14.070367 46.755828))",
"POLYGON ((14.162578 46.865519, 14.789757 46.865519, 14.789757 47.263394, 14.162578 47.263394, 14.162578 46.865519))",
"POLYGON ((14.068125 47.263414, 14.782116 47.263414, 14.782116 47.885084, 14.068125 47.885084, 14.068125 47.263414))",
"POLYGON ((14.124618 47.885123, 14.776354 47.885123, 14.776354 48.138569, 14.124618 48.138569, 14.124618 47.885123))",
"POLYGON ((14.091974 48.151799, 14.779535 48.151799, 14.779535 48.305869, 14.091974 48.305869, 14.091974 48.151799))",
"POLYGON ((14.059236 48.305886, 14.789141 48.305886, 14.789141 48.453178, 14.059236 48.453178, 14.059236 48.305886))",
"POLYGON ((14.168048 48.453180, 14.787549 48.453180, 14.787549 49.142691, 14.168048 49.142691, 14.168048 48.453180))",
"POLYGON ((14.064881 49.142699, 14.772245 49.142699, 14.772245 49.616888, 14.064881 49.616888, 14.064881 49.142699))",
"POLYGON ((14.116576 49.616927, 14.785899 49.616927, 14.785899 49.877655, 14.116576 49.877655, 14.116576 49.616927))",
"POLYGON ((14.070967 49.877655, 14.710767 49.877655, 14.710767 50.034751, 14.070967 50.034751, 14.070967 49.877655))",
"POLYGON ((14.192295 50.034771, 14.733878 50.034771, 14.733878 50.085238, 14.192295 50.085238, 14.192295 50.034771))",
"POLYGON ((14.381491 50.085246, 14.422419 50.085246, 14.422419 50.088040, 14.381491 50.088040, 14.381491 50.085246))",
"POLYGON ((14.376195 50.088061, 14.449198 50.088061, 14.449198 50.110132, 14.376195 50.110132, 14.376195 50.088061))",
"POLYGON ((14.191266 50.110139, 14.774321 50.110139, 14.774321 50.346063, 14.191266 50.346063, 14.191266 50.110139))",
"POLYGON ((14.098843 50.346070, 14.764150 50.346070, 14.764150 50.756689, 14.098843 50.756689, 14.098843 50.346070))",
"POLYGON ((14.151433 50.756712, 14.760196 50.756712, 14.760196 50.848297, 14.151433 50.848297, 14.151433 50.756712))",
"POLYGON ((14.058648 50.848297, 14.789648 50.848297, 14.789648 51.024321, 14.058648 51.024321, 14.058648 50.848297))",
"POLYGON ((14.081800 51.024542, 14.784009 51.024542, 14.784009 51.146548, 14.081800 51.146548, 14.081800 51.024542))",
"POLYGON ((14.125799 51.146552, 14.781071 51.146552, 14.781071 51.183141, 14.125799 51.183141, 14.125799 51.146552))",
"POLYGON ((14.199107 51.183182, 14.788366 51.183182, 14.788366 51.298547, 14.199107 51.298547, 14.199107 51.183182))",
"POLYGON ((14.101323 51.298582, 14.788692 51.298582, 14.788692 51.482525, 14.101323 51.482525, 14.101323 51.298582))",
"POLYGON ((14.528941 51.482812, 14.780406 51.482812, 14.780406 51.529702, 14.528941 51.529702, 14.528941 51.482812))",
"POLYGON ((14.219629 51.529729, 14.728740 51.529729, 14.728740 51.696608, 14.219629 51.696608, 14.219629 51.529729))",
"POLYGON ((14.066202 51.697040, 14.771870 51.697040, 14.771870 51.802258, 14.066202 51.802258, 14.066202 51.697040))",
"POLYGON ((14.141305 51.802754, 14.669519 51.802754, 14.669519 51.904799, 14.141305 51.904799, 14.141305 51.802754))",
"POLYGON ((14.058717 51.904856, 14.727757 51.904856, 14.727757 51.970128, 14.058717 51.970128, 14.058717 51.904856))",
"POLYGON ((14.127315 51.970435, 14.665641 51.970435, 14.665641 52.145512, 14.127315 52.145512, 14.127315 51.970435))",
"POLYGON ((14.067067 52.145532, 14.701414 52.145532, 14.701414 52.338546, 14.067067 52.338546, 14.067067 52.145532))",
"POLYGON ((14.059431 52.338552, 14.553205 52.338552, 14.553205 52.407600, 14.059431 52.407600, 14.059431 52.338552))",
"POLYGON ((14.135798 52.407617, 14.479387 52.407617, 14.479387 52.504704, 14.135798 52.504704, 14.135798 52.407617))",
"POLYGON ((14.093134 52.504748, 14.688729 52.504748, 14.688729 52.600538, 14.093134 52.600538, 14.093134 52.504748))",
"POLYGON ((14.070410 52.600583, 14.762360 52.600583, 14.762360 52.637235, 14.070410 52.637235, 14.070410 52.600583))",
"POLYGON ((14.059939 52.637235, 14.784673 52.637235, 14.784673 52.980698, 14.059939 52.980698, 14.059939 52.637235))",
"POLYGON ((14.073192 52.980721, 14.623304 52.980721, 14.623304 53.396984, 14.073192 53.396984, 14.073192 52.980721))",
"POLYGON ((14.197952 53.396985, 14.700199 53.396985, 14.700199 53.555819, 14.197952 53.555819, 14.197952 53.396985))",
"POLYGON ((14.066949 53.556001, 14.769932 53.556001, 14.769932 53.969458, 14.066949 53.969458, 14.066949 53.556001))",
"POLYGON ((14.754204 53.969460, 14.774380 53.969460, 14.774380 55.271568, 14.754204 55.271568, 14.754204 53.969460))",
"POLYGON ((14.100069 55.271589, 14.778596 55.271589, 14.778596 59.800567, 14.100069 59.800567, 14.100069 55.271589))",
"POLYGON ((14.069709 59.800746, 14.270772 59.800746, 14.270772 60.366852, 14.069709 60.366852, 14.069709 59.800746))",
"POLYGON ((14.108871 60.367728, 14.762825 60.367728, 14.762825 78.067507, 14.108871 78.067507, 14.108871 60.367728))",
"POLYGON ((15.219759 -26.723643, 15.243271 -26.723643, 15.243271 -26.701980, 15.219759 -26.701980, 15.219759 -26.723643))",
"POLYGON ((14.893368 -26.701939, 15.420762 -26.701939, 15.420762 12.116022, 14.893368 12.116022, 14.893368 -26.701939))",
"POLYGON ((15.033508 12.116022, 15.367571 12.116022, 15.367571 14.115201, 15.033508 14.115201, 15.033508 12.116022))",
"POLYGON ((14.801564 14.115709, 15.413728 14.115709, 15.413728 36.931838, 14.801564 36.931838, 14.801564 14.115709))",
"POLYGON ((14.810828 36.931845, 15.295842 36.931845, 15.295842 37.074192, 14.810828 37.074192, 14.810828 36.931845))",
"POLYGON ((14.824534 37.074196, 15.296805 37.074196, 15.296805 37.082324, 14.824534 37.082324, 14.824534 37.074196))",
"POLYGON ((14.797369 37.082328, 15.285629 37.082328, 15.285629 37.500465, 14.797369 37.500465, 14.797369 37.082328))",
"POLYGON ((15.074994 37.500482, 15.094932 37.500482, 15.094932 37.507565, 15.074994 37.507565, 15.074994 37.500482))",
"POLYGON ((14.795698 37.511713, 15.175124 37.511713, 15.175124 37.660873, 14.795698 37.660873, 14.795698 37.511713))",
"POLYGON ((14.790165 37.660876, 15.292721 37.660876, 15.292721 37.896556, 14.790165 37.896556, 14.790165 37.660876))",
"POLYGON ((14.811731 37.896598, 15.507197 37.896598, 15.507197 38.148625, 14.811731 38.148625, 14.811731 37.896598))",
"POLYGON ((14.971362 38.148625, 14.973116 38.148625, 14.973116 38.148820, 14.971362 38.148820, 14.971362 38.148625))",
"POLYGON ((14.972095 38.148820, 14.973126 38.148820, 14.973126 38.148965, 14.972095 38.148965, 14.972095 38.148820))",
"POLYGON ((14.971472 38.148965, 14.972930 38.148965, 14.972930 38.149193, 14.971472 38.149193, 14.971472 38.148965))",
"POLYGON ((14.857278 38.149193, 15.568910 38.149193, 15.568910 38.188399, 14.857278 38.188399, 14.857278 38.149193))",
"POLYGON ((15.219483 38.188425, 15.574632 38.188425, 15.574632 38.231436, 15.219483 38.231436, 15.219483 38.188425))",
"POLYGON ((14.909725 38.231446, 15.612692 38.231446, 15.612692 40.077240, 14.909725 40.077240, 14.909725 38.231446))",
"POLYGON ((14.932289 40.077684, 15.624969 40.077684, 15.624969 40.353504, 14.932289 40.353504, 14.932289 40.077684))",
"POLYGON ((14.987938 40.353566, 15.626306 40.353566, 15.626306 40.424449, 14.987938 40.424449, 14.987938 40.353566))",
"POLYGON ((14.886318 40.424470, 15.626429 40.424470, 15.626429 40.595006, 14.886318 40.595006, 14.886318 40.424470))",
"POLYGON ((14.809652 40.595108, 15.490744 40.595108, 15.490744 40.765039, 14.809652 40.765039, 14.809652 40.595108))",
"POLYGON ((14.789847 40.765086, 15.604187 40.765086, 15.604187 41.218625, 14.789847 41.218625, 14.789847 40.765086))",
"POLYGON ((14.807680 41.218628, 15.623704 41.218628, 15.623704 42.004175, 14.807680 42.004175, 14.807680 41.218628))",
"POLYGON ((14.886837 42.004176, 15.610011 42.004176, 15.610011 45.407887, 14.886837 45.407887, 14.886837 42.004176))",
"POLYGON ((14.853430 45.408046, 15.627509 45.408046, 15.627509 45.862827, 14.853430 45.862827, 14.853430 45.408046))",
"POLYGON ((14.800593 45.862856, 15.626214 45.862856, 15.626214 46.723055, 14.800593 46.723055, 14.800593 45.862856))",
"POLYGON ((14.822515 46.723055, 15.587537 46.723055, 15.587537 46.796706, 14.822515 46.796706, 14.822515 46.723055))",
"POLYGON ((14.789922 46.796713, 15.586393 46.796713, 15.586393 47.031390, 14.789922 47.031390, 14.789922 46.796713))",
"POLYGON ((15.124665 47.031394, 15.497791 47.031394, 15.497791 47.083077, 15.124665 47.083077, 15.124665 47.031394))",
"POLYGON ((14.808212 47.083123, 15.634897 47.083123, 15.634897 47.210316, 14.808212 47.210316, 14.808212 47.083123))",
"POLYGON ((14.802292 47.217047, 15.609347 47.217047, 15.609347 47.424723, 14.802292 47.424723, 14.802292 47.217047))",
"POLYGON ((14.797598 47.424749, 15.623656 47.424749, 15.623656 47.974962, 14.797598 47.974962, 14.797598 47.424749))",
"POLYGON ((14.797009 47.974962, 15.595878 47.974962, 15.595878 48.143104, 14.797009 48.143104, 14.797009 47.974962))",
"POLYGON ((14.892006 48.143126, 15.627875 48.143126, 15.627875 48.249936, 14.892006 48.249936, 14.892006 48.143126))",
"POLYGON ((14.792670 48.249984, 15.614972 48.249984, 15.614972 48.372225, 14.792670 48.372225, 14.792670 48.249984))",
"POLYGON ((14.925235 48.377867, 15.622482 48.377867, 15.622482 48.511734, 14.925235 48.511734, 14.925235 48.377867))",
"POLYGON ((14.840945 48.511754, 15.634809 48.511754, 15.634809 48.740450, 14.840945 48.740450, 14.840945 48.511754))",
"POLYGON ((14.805719 48.743818, 15.622924 48.743818, 15.622924 49.066949, 14.805719 49.066949, 14.805719 48.743818))",
"POLYGON ((14.812510 49.066951, 15.629738 49.066951, 15.629738 49.475690, 14.812510 49.475690, 14.812510 49.066951))",
"POLYGON ((14.818470 49.475699, 15.632853 49.475699, 15.632853 49.879053, 14.818470 49.879053, 14.818470 49.475699))",
"POLYGON ((14.867493 49.879104, 15.586917 49.879104, 15.586917 49.982611, 14.867493 49.982611, 14.867493 49.879104))",
"POLYGON ((14.823187 49.982616, 15.619155 49.982616, 15.619155 50.202050, 14.823187 50.202050, 14.823187 49.982616))",
"POLYGON ((14.822158 50.202056, 15.604827 50.202056, 15.604827 50.494625, 14.822158 50.494625, 14.822158 50.202056))",
"POLYGON ((14.799304 50.494625, 15.609329 50.494625, 15.609329 50.768027, 14.799304 50.768027, 14.799304 50.494625))",
"POLYGON ((14.792363 50.768041, 15.612603 50.768041, 15.612603 50.892163, 14.792363 50.892163, 14.792363 50.768041))",
"POLYGON ((14.790156 50.892237, 15.625177 50.892237, 15.625177 51.045000, 14.790156 51.045000, 14.790156 50.892237))",
"POLYGON ((14.794242 51.045053, 15.615854 51.045053, 15.615854 51.180089, 14.794242 51.180089, 14.794242 51.045053))",
"POLYGON ((14.790970 51.180104, 15.590280 51.180104, 15.590280 51.485251, 14.790970 51.485251, 14.790970 51.180104))",
"POLYGON ((14.816401 51.485282, 15.619354 51.485282, 15.619354 52.233958, 14.816401 52.233958, 14.816401 51.485282))",
"POLYGON ((15.229697 52.234022, 15.603585 52.234022, 15.603585 52.388243, 15.229697 52.388243, 15.229697 52.234022))",
"POLYGON ((14.797095 52.388243, 15.578304 52.388243, 15.578304 52.669204, 14.797095 52.669204, 14.797095 52.388243))",
"POLYGON ((14.795010 52.669215, 15.614895 52.669215, 15.614895 52.724913, 14.795010 52.724913, 14.795010 52.669215))",
"POLYGON ((15.004897 52.724946, 15.630740 52.724946, 15.630740 52.749158, 15.004897 52.749158, 15.004897 52.724946))",
"POLYGON ((14.935386 52.749164, 15.628934 52.749164, 15.628934 52.785384, 14.935386 52.785384, 14.935386 52.749164))",
"POLYGON ((14.834863 52.785427, 15.633844 52.785427, 15.633844 52.832765, 14.834863 52.832765, 14.834863 52.785427))",
"POLYGON ((14.863164 52.832779, 15.601496 52.832779, 15.601496 53.147338, 14.863164 53.147338, 14.863164 52.832779))",
"POLYGON ((14.906361 53.147340, 15.619216 53.147340, 15.619216 53.337544, 14.906361 53.337544, 14.906361 53.147340))",
"POLYGON ((14.936757 53.337810, 15.632387 53.337810, 15.632387 53.552445, 14.936757 53.552445, 14.936757 53.337810))",
"POLYGON ((14.827686 53.552453, 15.621313 53.552453, 15.621313 54.000767, 14.827686 54.000767, 14.827686 53.552453))",
"POLYGON ((14.907096 54.000768, 15.593998 54.000768, 15.593998 54.179628, 14.907096 54.179628, 14.907096 54.000768))",
"POLYGON ((14.896530 54.179628, 15.622556 54.179628, 15.622556 56.220438, 14.896530 56.220438, 14.896530 54.179628))",
"POLYGON ((14.793515 56.220647, 15.618097 56.220647, 15.618097 58.601060, 14.793515 58.601060, 14.793515 56.220647))",
"POLYGON ((14.870752 58.601107, 15.633427 58.601107, 15.633427 62.287678, 14.870752 62.287678, 14.870752 58.601107))",
"POLYGON ((15.639387 -27.776705, 16.300667 -27.776705, 16.300667 38.210186, 15.639387 38.210186, 15.639387 -27.776705))",
"POLYGON ((15.640638 38.210930, 16.471486 38.210930, 16.471486 38.579229, 15.640638 38.579229, 15.640638 38.210930))",
"POLYGON ((15.801352 38.579229, 16.407351 38.579229, 16.407351 39.717891, 15.801352 39.717891, 15.801352 38.579229))",
"POLYGON ((15.679251 39.755098, 16.449796 39.755098, 16.449796 40.285008, 15.679251 40.285008, 15.679251 39.755098))",
"POLYGON ((15.635224 40.285091, 16.441641 40.285091, 16.441641 40.521111, 15.635224 40.521111, 15.635224 40.285091))",
"POLYGON ((16.048044 40.521121, 16.101022 40.521121, 16.101022 40.576447, 16.048044 40.576447, 16.048044 40.521121))",
"POLYGON ((16.094813 40.576447, 16.103195 40.576447, 16.103195 40.576900, 16.094813 40.576900, 16.094813 40.576447))",
"POLYGON ((16.094413 40.576903, 16.103592 40.576903, 16.103592 40.577448, 16.094413 40.577448, 16.094413 40.576903))",
"POLYGON ((16.094772 40.577449, 16.098502 40.577449, 16.098502 40.578430, 16.094772 40.578430, 16.094772 40.577449))",
"POLYGON ((15.652263 40.578441, 16.420650 40.578441, 16.420650 40.998631, 15.652263 40.998631, 15.652263 40.578441))",
"POLYGON ((15.652373 40.998640, 16.429237 40.998640, 16.429237 41.782535, 15.652373 41.782535, 15.652373 40.998640))",
"POLYGON ((15.763584 41.782553, 16.449340 41.782553, 16.449340 43.507129, 15.763584 43.507129, 15.763584 41.782553))",
"POLYGON ((15.677641 43.507168, 16.475575 43.507168, 16.475575 44.035216, 15.677641 44.035216, 15.677641 43.507168))",
"POLYGON ((15.668993 44.035216, 16.474224 44.035216, 16.474224 45.138900, 15.668993 45.138900, 15.668993 44.035216))",
"POLYGON ((15.693096 45.138940, 16.458408 45.138940, 16.458408 45.884166, 15.693096 45.884166, 15.693096 45.138940))",
"POLYGON ((15.651171 45.884183, 16.464455 45.884183, 16.464455 46.263309, 15.651171 46.263309, 15.651171 45.884183))",
"POLYGON ((15.648309 46.263317, 16.452910 46.263317, 16.452910 46.662589, 15.648309 46.662589, 15.648309 46.263317))",
"POLYGON ((15.659204 46.662593, 16.454276 46.662593, 16.454276 47.004788, 15.659204 47.004788, 15.659204 46.662593))",
"POLYGON ((15.635130 47.004794, 16.462201 47.004794, 16.462201 47.242783, 15.635130 47.242783, 15.635130 47.004794))",
"POLYGON ((15.646697 47.242797, 16.245157 47.242797, 16.245157 47.281534, 15.646697 47.281534, 15.646697 47.242797))",
"POLYGON ((15.689655 47.281546, 16.462919 47.281546, 16.462919 47.439090, 15.689655 47.439090, 15.689655 47.281546))",
"POLYGON ((15.748158 47.439111, 16.436698 47.439111, 16.436698 47.653791, 15.748158 47.653791, 15.748158 47.439111))",
"POLYGON ((15.706286 47.653818, 16.407428 47.653818, 16.407428 47.776549, 15.706286 47.776549, 15.706286 47.653818))",
"POLYGON ((15.784324 47.776552, 16.460124 47.776552, 16.460124 47.938243, 15.784324 47.938243, 15.784324 47.776552))",
"POLYGON ((15.745402 47.938335, 16.470571 47.938335, 16.470571 48.066607, 15.745402 48.066607, 15.745402 47.938335))",
"POLYGON ((15.922399 48.066678, 16.388813 48.066678, 16.388813 48.088570, 15.922399 48.088570, 15.922399 48.066678))",
"POLYGON ((15.915815 48.088642, 16.468686 48.088642, 16.468686 48.152300, 15.915815 48.152300, 15.915815 48.088642))",
"POLYGON ((15.725112 48.152300, 16.443594 48.152300, 16.443594 48.197739, 15.725112 48.197739, 15.725112 48.152300))",
"POLYGON ((15.809587 48.197740, 16.376644 48.197740, 16.376644 48.209380, 15.809587 48.209380, 15.809587 48.197740))",
"POLYGON ((15.696296 48.209384, 16.458164 48.209384, 16.458164 48.249450, 15.696296 48.249450, 15.696296 48.209384))",
"POLYGON ((15.694969 48.249451, 16.397227 48.249451, 16.397227 48.298364, 15.694969 48.298364, 15.694969 48.249451))",
"POLYGON ((15.651154 48.298366, 16.422107 48.298366, 16.422107 48.474803, 15.651154 48.474803, 15.651154 48.298366))",
"POLYGON ((15.647202 48.474810, 16.421717 48.474810, 16.421717 48.585575, 15.647202 48.585575, 15.647202 48.474810))",
"POLYGON ((15.634940 48.585575, 16.461378 48.585575, 16.461378 48.758970, 15.634940 48.758970, 15.634940 48.585575))",
"POLYGON ((15.710154 48.758979, 16.388072 48.758979, 16.388072 48.856610, 15.710154 48.856610, 15.710154 48.758979))",
"POLYGON ((15.685331 48.856817, 16.314084 48.856817, 16.314084 48.946568, 15.685331 48.946568, 15.685331 48.856817))",
"POLYGON ((15.925824 48.946569, 16.473071 48.946569, 16.473071 49.090779, 15.925824 49.090779, 15.925824 48.946569))",
"POLYGON ((15.671372 49.090780, 16.469544 49.090780, 16.469544 49.417040, 15.671372 49.417040, 15.671372 49.090780))",
"POLYGON ((15.650636 49.417043, 16.467321 49.417043, 16.467321 49.953611, 15.650636 49.953611, 15.650636 49.417043))",
"POLYGON ((15.641767 49.953623, 16.468158 49.953623, 16.468158 50.334959, 15.641767 50.334959, 15.641767 49.953623))",
"POLYGON ((15.644092 50.335563, 16.382961 50.335563, 16.382961 50.389619, 15.644092 50.389619, 15.644092 50.335563))",
"POLYGON ((15.678037 50.389667, 16.399072 50.389667, 16.399072 50.404994, 15.678037 50.404994, 15.678037 50.389667))",
"POLYGON ((15.687005 50.405000, 16.462871 50.405000, 16.462871 50.637967, 15.687005 50.637967, 15.687005 50.405000))",
"POLYGON ((15.689907 50.637970, 16.443206 50.637970, 16.443206 50.765556, 15.689907 50.765556, 15.689907 50.637970))",
"POLYGON ((15.724001 50.765558, 16.300536 50.765558, 16.300536 50.817473, 15.724001 50.817473, 15.724001 50.765558))",
"POLYGON ((15.643570 50.817473, 16.370017 50.817473, 16.370017 50.856868, 15.643570 50.856868, 15.643570 50.817473))",
"POLYGON ((15.678195 50.856876, 16.326154 50.856876, 16.326154 50.864983, 15.678195 50.864983, 15.678195 50.856876))",
"POLYGON ((15.678449 50.864985, 16.416360 50.864985, 16.416360 50.876072, 15.678449 50.876072, 15.678449 50.864985))",
"POLYGON ((15.712815 50.876080, 16.393429 50.876080, 16.393429 50.903613, 15.712815 50.903613, 15.712815 50.876080))",
"POLYGON ((15.731869 50.903625, 16.298412 50.903625, 16.298412 50.959643, 15.731869 50.959643, 15.731869 50.903625))",
"POLYGON ((15.638118 50.959650, 16.469937 50.959650, 16.469937 51.261661, 15.638118 51.261661, 15.638118 50.959650))",
"POLYGON ((15.712474 51.261661, 16.472672 51.261661, 16.472672 52.092129, 15.712474 52.092129, 15.712474 51.261661))",
"POLYGON ((15.649214 52.092147, 16.462703 52.092147, 16.462703 52.308571, 15.649214 52.308571, 15.649214 52.092147))",
"POLYGON ((15.891183 52.308604, 16.464296 52.308604, 16.464296 52.709554, 15.891183 52.709554, 15.891183 52.308604))",
"POLYGON ((15.638972 52.709595, 16.373320 52.709595, 16.373320 52.817087, 15.638972 52.817087, 15.638972 52.709595))",
"POLYGON ((15.637687 52.817107, 15.947996 52.817107, 15.947996 52.853492, 15.637687 52.853492, 15.637687 52.817107))",
"POLYGON ((15.640793 52.853501, 16.297083 52.853501, 16.297083 52.963185, 15.640793 52.963185, 15.640793 52.853501))",
"POLYGON ((15.651354 52.963208, 16.460969 52.963208, 16.460969 53.311277, 15.651354 53.311277, 15.651354 52.963208))",
"POLYGON ((15.635863 53.311305, 16.436534 53.311305, 16.436534 53.566746, 15.635863 53.566746, 15.635863 53.311305))",
"POLYGON ((15.653788 53.566950, 16.472534 53.566950, 16.472534 53.807432, 15.653788 53.807432, 15.653788 53.566950))",
"POLYGON ((15.635116 53.807436, 16.475480 53.807436, 16.475480 54.054917, 15.635116 54.054917, 15.635116 53.807436))",
"POLYGON ((15.638469 54.054969, 16.437630 54.054969, 16.437630 58.432347, 15.638469 58.432347, 15.638469 54.054969))",
"POLYGON ((15.637359 58.432348, 16.473923 58.432348, 16.473923 60.637701, 15.637359 60.637701, 15.637359 58.432348))",
"POLYGON ((16.480966 -25.257272, 17.022194 -25.257272, 17.022194 39.110294, 16.480966 39.110294, 16.480966 -25.257272))",
"POLYGON ((16.689529 39.110296, 17.004176 39.110296, 17.004176 39.543680, 16.689529 39.543680, 16.689529 39.110296))",
"POLYGON ((16.488046 39.543691, 16.827192 39.543691, 16.827192 40.567477, 16.488046 40.567477, 16.488046 39.543691))",
"POLYGON ((16.499450 40.567480, 17.007399 40.567480, 17.007399 40.668029, 16.499450 40.668029, 16.499450 40.567480))",
"POLYGON ((16.536478 40.668095, 17.022792 40.668095, 17.022792 41.064068, 16.536478 41.064068, 16.536478 40.668095))",
"POLYGON ((16.501487 41.064068, 17.000678 41.064068, 17.000678 41.243640, 16.501487 41.243640, 16.501487 41.064068))",
"POLYGON ((16.477276 41.243645, 16.946092 41.243645, 16.946092 43.539788, 16.477276 43.539788, 16.477276 41.243645))",
"POLYGON ((16.475575 43.539790, 17.032811 43.539790, 17.032811 46.625854, 16.475575 46.625854, 16.475575 43.539790))",
"POLYGON ((16.487163 46.625858, 17.024956 46.625858, 17.024956 47.389618, 16.487163 47.389618, 16.487163 46.625858))",
"POLYGON ((16.483914 47.389646, 16.986712 47.389646, 16.986712 48.022229, 16.483914 48.022229, 16.483914 47.389646))",
"POLYGON ((16.489215 48.022229, 17.026824 48.022229, 17.026824 48.146733, 16.489215 48.146733, 16.489215 48.022229))",
"POLYGON ((16.475749 48.146847, 17.017460 48.146847, 17.017460 48.243579, 16.475749 48.243579, 16.475749 48.146847))",
"POLYGON ((16.564401 48.245040, 16.979397 48.245040, 16.979397 48.356282, 16.564401 48.356282, 16.564401 48.245040))",
"POLYGON ((16.478410 48.356284, 16.940573 48.356284, 16.940573 48.569701, 16.478410 48.569701, 16.478410 48.356284))",
"POLYGON ((16.488811 48.569703, 17.016221 48.569703, 17.016221 48.782947, 16.488811 48.782947, 16.488811 48.569703))",
"POLYGON ((16.477970 48.782956, 17.023251 48.782956, 17.023251 49.035545, 16.477970 49.035545, 16.477970 48.782956))",
"POLYGON ((16.555794 49.035619, 17.029333 49.035619, 17.029333 49.150778, 16.555794 49.150778, 16.555794 49.035619))",
"POLYGON ((16.538551 49.150959, 16.963691 49.150959, 16.963691 49.191082, 16.538551 49.191082, 16.538551 49.150959))",
"POLYGON ((16.479313 49.191084, 17.030109 49.191084, 17.030109 49.467787, 16.479313 49.467787, 16.479313 49.191084))",
"POLYGON ((16.510819 49.467801, 17.020772 49.467801, 17.020772 49.720504, 16.510819 49.720504, 16.510819 49.467801))",
"POLYGON ((16.509014 49.720507, 17.029536 49.720507, 17.029536 50.191836, 16.509014 50.191836, 16.509014 49.720507))",
"POLYGON ((16.481670 50.191846, 16.873540 50.191846, 16.873540 50.293905, 16.481670 50.293905, 16.481670 50.191846))",
"POLYGON ((16.576909 50.293907, 16.874185 50.293907, 16.874185 50.300973, 16.576909 50.300973, 16.576909 50.293907))",
"POLYGON ((16.564243 50.300980, 16.946714 50.300980, 16.946714 50.354661, 16.564243 50.354661, 16.564243 50.300980))",
"POLYGON ((16.574456 50.354776, 17.013669 50.354776, 17.013669 50.436917, 16.574456 50.436917, 16.574456 50.354776))",
"POLYGON ((16.648653 50.436923, 16.661178 50.436923, 16.661178 50.438796, 16.648653 50.438796, 16.648653 50.436923))",
"POLYGON ((16.540699 50.438797, 17.014267 50.438797, 17.014267 50.465535, 16.540699 50.465535, 16.540699 50.438797))",
"POLYGON ((16.483585 50.465536, 17.008792 50.465536, 17.008792 50.573233, 16.483585 50.573233, 16.483585 50.465536))",
"POLYGON ((16.475844 50.573233, 16.900376 50.573233, 16.900376 50.687038, 16.475844 50.687038, 16.475844 50.573233))",
"POLYGON ((16.484897 50.687048, 17.017102 50.687048, 17.017102 50.841996, 16.484897 50.841996, 16.484897 50.687048))",
"POLYGON ((16.483319 50.841997, 16.493295 50.841997, 16.493295 50.843926, 16.483319 50.843926, 16.483319 50.841997))",
"POLYGON ((16.476289 50.843930, 16.838982 50.843930, 16.838982 50.885396, 16.476289 50.885396, 16.476289 50.843930))",
"POLYGON ((16.486577 50.885402, 17.003442 50.885402, 17.003442 51.069674, 16.486577 51.069674, 16.486577 50.885402))",
"POLYGON ((16.849521 51.069700, 17.035082 51.069700, 17.035082 51.100944, 16.849521 51.100944, 16.849521 51.069700))",
"POLYGON ((16.829733 51.100949, 17.034259 51.100949, 17.034259 51.103769, 16.829733 51.103769, 16.829733 51.100949))",
"POLYGON ((17.026639 51.103781, 17.032341 51.103781, 17.032341 51.105916, 17.026639 51.105916, 17.026639 51.103781))",
"POLYGON ((17.018263 51.105917, 17.035238 51.105917, 17.035238 51.107565, 17.018263 51.107565, 17.018263 51.105917))",
"POLYGON ((17.017937 51.107567, 17.035242 51.107567, 17.035242 51.108526, 17.017937 51.108526, 17.017937 51.107567))",
"POLYGON ((17.017828 51.108526, 17.033254 51.108526, 17.033254 51.109125, 17.017828 51.109125, 17.017828 51.108526))",
"POLYGON ((17.017889 51.109126, 17.035214 51.109126, 17.035214 51.109440, 17.017889 51.109440, 17.017889 51.109126))",
"POLYGON ((17.024269 51.109441, 17.035227 51.109441, 17.035227 51.109871, 17.024269 51.109871, 17.024269 51.109441))",
"POLYGON ((16.817734 51.109871, 17.034078 51.109871, 17.034078 51.110370, 16.817734 51.110370, 16.817734 51.109871))",
"POLYGON ((17.020853 51.110372, 17.033575 51.110372, 17.033575 51.110958, 17.020853 51.110958, 17.020853 51.110372))",
"POLYGON ((17.020976 51.110959, 17.034201 51.110959, 17.034201 51.111538, 17.020976 51.111538, 17.020976 51.110959))",
"POLYGON ((17.022226 51.111538, 17.032772 51.111538, 17.032772 51.112383, 17.022226 51.112383, 17.022226 51.111538))",
"POLYGON ((17.020154 51.112387, 17.035226 51.112387, 17.035226 51.114115, 17.020154 51.114115, 17.020154 51.112387))",
"POLYGON ((16.873379 51.114117, 17.034996 51.114117, 17.034996 51.125456, 16.873379 51.125456, 16.873379 51.114117))",
"POLYGON ((16.478244 51.125457, 17.032985 51.125457, 17.032985 51.370623, 16.478244 51.370623, 16.478244 51.125457))",
"POLYGON ((16.491593 51.370635, 17.025846 51.370635, 17.025846 51.634105, 16.491593 51.634105, 16.491593 51.370635))",
"POLYGON ((16.529829 51.634108, 17.029610 51.634108, 17.029610 51.840934, 16.529829 51.840934, 16.529829 51.634108))",
"POLYGON ((16.495161 51.840936, 17.035179 51.840936, 17.035179 52.013895, 16.495161 52.013895, 16.495161 51.840936))",
"POLYGON ((16.526425 52.013901, 17.026175 52.013901, 17.026175 52.096731, 16.526425 52.096731, 16.526425 52.013901))",
"POLYGON ((16.489062 52.096758, 17.019497 52.096758, 17.019497 52.268615, 16.489062 52.268615, 16.489062 52.096758))",
"POLYGON ((16.486489 52.268773, 17.025677 52.268773, 17.025677 52.391846, 16.486489 52.391846, 16.486489 52.268773))",
"POLYGON ((16.639195 52.391861, 16.998770 52.391861, 16.998770 52.405866, 16.639195 52.405866, 16.639195 52.391861))",
"POLYGON ((16.856743 52.405866, 16.939914 52.405866, 16.939914 52.408275, 16.856743 52.408275, 16.856743 52.405866))",
"POLYGON ((16.917247 52.408278, 16.959034 52.408278, 16.959034 52.410372, 16.917247 52.410372, 16.917247 52.408278))",
"POLYGON ((16.748286 52.410373, 17.019763 52.410373, 17.019763 52.437134, 16.748286 52.437134, 16.748286 52.410373))",
"POLYGON ((16.503822 52.437155, 17.023018 52.437155, 17.023018 52.599648, 16.503822 52.599648, 16.503822 52.437155))",
"POLYGON ((16.510062 52.599665, 17.035196 52.599665, 17.035196 52.852338, 16.510062 52.852338, 16.510062 52.599665))",
"POLYGON ((16.497075 52.852355, 17.006858 52.852355, 17.006858 53.297269, 16.497075 53.297269, 16.497075 52.852355))",
"POLYGON ((16.475900 53.297275, 17.035091 53.297275, 17.035091 54.160948, 16.475900 54.160948, 16.475900 53.297275))",
"POLYGON ((16.477690 54.160948, 17.003492 54.160948, 17.003492 68.584105, 16.477690 68.584105, 16.477690 54.160948))",
"POLYGON ((17.051314 -25.880239, 17.776609 -25.880239, 17.776609 39.080522, 17.051314 39.080522, 17.051314 -25.880239))",
"POLYGON ((17.096268 39.080528, 17.910122 39.080528, 17.910122 40.562439, 17.096268 40.562439, 17.096268 39.080528))",
"POLYGON ((17.230315 40.562441, 17.597733 40.562441, 17.597733 40.623370, 17.230315 40.623370, 17.230315 40.562441))",
"POLYGON ((17.429885 40.623436, 17.610236 40.623436, 17.610236 40.638984, 17.429885 40.638984, 17.429885 40.623436))",
"POLYGON ((17.436630 40.638984, 17.620315 40.638984, 17.620315 40.646424, 17.436630 40.646424, 17.436630 40.638984))",
"POLYGON ((17.436602 40.646426, 17.546872 40.646426, 17.546872 40.647288, 17.436602 40.647288, 17.436602 40.646426))",
"POLYGON ((17.457059 40.647290, 17.597598 40.647290, 17.597598 40.654608, 17.457059 40.654608, 17.457059 40.647290))",
"POLYGON ((17.447876 40.654653, 17.566270 40.654653, 17.566270 40.673001, 17.447876 40.673001, 17.447876 40.654653))",
"POLYGON ((17.116107 40.673015, 17.799954 40.673015, 17.799954 40.894284, 17.116107 40.894284, 17.116107 40.673015))",
"POLYGON ((17.049326 40.894289, 17.697139 40.894289, 17.697139 42.839281, 17.049326 42.839281, 17.049326 40.894289))",
"POLYGON ((17.130510 42.839282, 17.903241 42.839282, 17.903241 43.340847, 17.130510 43.340847, 17.130510 42.839282))",
"POLYGON ((17.203045 43.340851, 17.797255 43.340851, 17.797255 43.920078, 17.203045 43.920078, 17.203045 43.340851))",
"POLYGON ((17.053806 43.920078, 17.885550 43.920078, 17.885550 45.511401, 17.053806 45.511401, 17.053806 43.920078))",
"POLYGON ((17.068413 45.511414, 17.858701 45.511414, 17.858701 46.831396, 17.068413 46.831396, 17.068413 45.511414))",
"POLYGON ((17.045034 46.831400, 17.900392 46.831400, 17.900392 47.255422, 17.045034 47.255422, 17.045034 46.831400))",
"POLYGON ((17.076706 47.255424, 17.763891 47.255424, 17.763891 48.052611, 17.076706 48.052611, 17.076706 47.255424))",
"POLYGON ((17.058042 48.052613, 17.695442 48.052613, 17.695442 48.141979, 17.058042 48.141979, 17.058042 48.052613))",
"POLYGON ((17.099401 48.141980, 17.746002 48.141980, 17.746002 48.391008, 17.099401 48.391008, 17.099401 48.141980))",
"POLYGON ((17.268245 48.391026, 17.873249 48.391026, 17.873249 48.731505, 17.268245 48.731505, 17.268245 48.391026))",
"POLYGON ((17.082257 48.731518, 17.901235 48.731518, 17.901235 49.025102, 17.082257 49.025102, 17.082257 48.731518))",
"POLYGON ((17.091554 49.025143, 17.853184 49.025143, 17.853184 49.231360, 17.091554 49.231360, 17.091554 49.025143))",
"POLYGON ((17.067201 49.231360, 17.866357 49.231360, 17.866357 49.400610, 17.067201 49.400610, 17.067201 49.231360))",
"POLYGON ((17.113417 49.400632, 17.673596 49.400632, 17.673596 49.473322, 17.113417 49.473322, 17.113417 49.400632))",
"POLYGON ((17.040786 49.473331, 17.870370 49.473331, 17.870370 49.593905, 17.040786 49.593905, 17.040786 49.473331))",
"POLYGON ((17.244372 49.593916, 17.357545 49.593916, 17.357545 49.596161, 17.244372 49.596161, 17.244372 49.593916))",
"POLYGON ((17.062317 49.596163, 17.263440 49.596163, 17.263440 49.598417, 17.062317 49.598417, 17.062317 49.596163))",
"POLYGON ((17.062235 49.598419, 17.882094 49.598419, 17.882094 49.940932, 17.062235 49.940932, 17.062235 49.598419))",
"POLYGON ((17.247936 49.940936, 17.918090 49.940936, 17.918090 49.952032, 17.247936 49.952032, 17.247936 49.940936))",
"POLYGON ((17.437787 49.952036, 17.918175 49.952036, 17.918175 50.011155, 17.437787 50.011155, 17.437787 49.952036))",
"POLYGON ((17.080519 50.011155, 17.910443 50.011155, 17.910443 50.152621, 17.080519 50.152621, 17.080519 50.011155))",
"POLYGON ((17.212951 50.152628, 17.912069 50.152628, 17.912069 50.245539, 17.212951 50.245539, 17.212951 50.152628))",
"POLYGON ((17.046957 50.245549, 17.879538 50.245549, 17.879538 50.379864, 17.046957 50.379864, 17.046957 50.245549))",
"POLYGON ((17.044531 50.380609, 17.768588 50.380609, 17.768588 50.456080, 17.044531 50.456080, 17.044531 50.380609))",
"POLYGON ((17.168185 50.456080, 17.737509 50.456080, 17.737509 50.490580, 17.168185 50.490580, 17.168185 50.456080))",
"POLYGON ((17.036057 50.490706, 17.919430 50.490706, 17.919430 50.692010, 17.036057 50.692010, 17.036057 50.490706))",
"POLYGON ((17.039048 50.692033, 17.856270 50.692033, 17.856270 51.075544, 17.039048 51.075544, 17.039048 50.692033))",
"POLYGON ((17.035317 51.075719, 17.720462 51.075719, 17.720462 51.105353, 17.035317 51.105353, 17.035317 51.075719))",
"POLYGON ((17.035249 51.105353, 17.078310 51.105353, 17.078310 51.107495, 17.035249 51.107495, 17.035249 51.105353))",
"POLYGON ((17.035243 51.107497, 17.082806 51.107497, 17.082806 51.109184, 17.035243 51.109184, 17.035243 51.107497))",
"POLYGON ((17.035243 51.109184, 17.057719 51.109184, 17.057719 51.110272, 17.035243 51.110272, 17.035243 51.109184))",
"POLYGON ((17.035886 51.110273, 17.102875 51.110273, 17.102875 51.112673, 17.035886 51.112673, 17.035886 51.110273))",
"POLYGON ((17.035922 51.112675, 17.040410 51.112675, 17.040410 51.113093, 17.035922 51.113093, 17.035922 51.112675))",
"POLYGON ((17.035257 51.113093, 17.039920 51.113093, 17.039920 51.113830, 17.035257 51.113830, 17.035257 51.113093))",
"POLYGON ((17.035258 51.113830, 17.097141 51.113830, 17.097141 51.114590, 17.035258 51.114590, 17.035258 51.113830))",
"POLYGON ((17.040724 51.114592, 17.888940 51.114592, 17.888940 51.117500, 17.040724 51.117500, 17.040724 51.114592))",
"POLYGON ((17.050368 51.117502, 17.243969 51.117502, 17.243969 51.121568, 17.050368 51.121568, 17.050368 51.117502))",
"POLYGON ((17.039413 51.121570, 17.537893 51.121570, 17.537893 51.138516, 17.039413 51.138516, 17.039413 51.121570))",
"POLYGON ((17.042406 51.138516, 17.909351 51.138516, 17.909351 51.504167, 17.042406 51.504167, 17.042406 51.138516))",
"POLYGON ((17.059303 51.504203, 17.850766 51.504203, 17.850766 51.650532, 17.059303 51.650532, 17.059303 51.504203))",
"POLYGON ((17.074186 51.650549, 17.865131 51.650549, 17.865131 51.767536, 17.074186 51.767536, 17.074186 51.650549))",
"POLYGON ((17.035244 51.767537, 17.858139 51.767537, 17.858139 51.909020, 17.035244 51.909020, 17.035244 51.767537))",
"POLYGON ((17.060895 51.909052, 17.869979 51.909052, 17.869979 52.001246, 17.060895 52.001246, 17.060895 51.909052))",
"POLYGON ((17.153560 52.001265, 17.839873 52.001265, 17.839873 52.165553, 17.153560 52.165553, 17.153560 52.001265))",
"POLYGON ((17.275538 52.165554, 17.897598 52.165554, 17.897598 52.227917, 17.275538 52.227917, 17.275538 52.165554))",
"POLYGON ((17.090545 52.227917, 17.867532 52.227917, 17.867532 52.286320, 17.090545 52.286320, 17.090545 52.227917))",
"POLYGON ((17.077958 52.286344, 17.873488 52.286344, 17.873488 52.366857, 17.077958 52.366857, 17.077958 52.286344))",
"POLYGON ((17.075230 52.366858, 17.919107 52.366858, 17.919107 52.449031, 17.075230 52.449031, 17.075230 52.366858))",
"POLYGON ((17.068786 52.449055, 17.785226 52.449055, 17.785226 52.538941, 17.068786 52.538941, 17.068786 52.449055))",
"POLYGON ((17.035304 52.538948, 17.817904 52.538948, 17.817904 52.804071, 17.035304 52.804071, 17.035304 52.538948))",
"POLYGON ((17.061566 52.804072, 17.881609 52.804072, 17.881609 54.002995, 17.061566 54.002995, 17.061566 52.804072))",
"POLYGON ((17.057397 54.003016, 17.917477 54.003016, 17.917477 59.320969, 17.057397 59.320969, 17.057397 54.003016))",
"POLYGON ((17.075943 59.320996, 17.907844 59.320996, 17.907844 59.853123, 17.075943 59.853123, 17.075943 59.320996))",
"POLYGON ((17.143792 59.853140, 17.856652 59.853140, 17.856652 68.422665, 17.143792 68.422665, 17.143792 59.853140))",
"POLYGON ((17.999070 -34.171903, 18.450413 -34.171903, 18.450413 40.007492, 17.999070 40.007492, 17.999070 -34.171903))",
"POLYGON ((17.925726 40.007497, 18.496385 40.007497, 18.496385 40.224801, 17.925726 40.224801, 17.925726 40.007497))",
"POLYGON ((18.191927 40.224802, 18.267063 40.224802, 18.267063 40.229848, 18.191927 40.229848, 18.191927 40.224802))",
"POLYGON ((18.062749 40.229848, 18.280771 40.229848, 18.280771 40.232708, 18.062749 40.232708, 18.062749 40.229848))",
"POLYGON ((18.200965 40.232709, 18.312688 40.232709, 18.312688 40.235561, 18.200965 40.235561, 18.200965 40.232709))",
"POLYGON ((18.194264 40.235563, 18.312694 40.235563, 18.312694 40.236982, 18.194264 40.236982, 18.194264 40.235563))",
"POLYGON ((18.196442 40.236983, 18.314988 40.236983, 18.314988 40.238947, 18.196442 40.238947, 18.196442 40.236983))",
"POLYGON ((18.057061 40.238948, 18.312536 40.238948, 18.312536 40.241201, 18.057061 40.241201, 18.057061 40.238948))",
"POLYGON ((18.062823 40.241202, 18.313787 40.241202, 18.313787 40.244657, 18.062823 40.244657, 18.062823 40.241202))",
"POLYGON ((18.062806 40.244659, 18.307533 40.244659, 18.307533 40.248013, 18.062806 40.248013, 18.062806 40.244659))",
"POLYGON ((18.021026 40.248019, 18.316395 40.248019, 18.316395 40.250686, 18.021026 40.250686, 18.021026 40.248019))",
"POLYGON ((18.035847 40.250686, 18.301377 40.250686, 18.301377 40.252952, 18.035847 40.252952, 18.035847 40.250686))",
"POLYGON ((18.065211 40.252952, 18.298954 40.252952, 18.298954 40.254943, 18.065211 40.254943, 18.065211 40.252952))",
"POLYGON ((18.058931 40.254944, 18.302103 40.254944, 18.302103 40.257347, 18.058931 40.257347, 18.058931 40.254944))",
"POLYGON ((18.078040 40.257348, 18.301579 40.257348, 18.301579 40.260592, 18.078040 40.260592, 18.078040 40.257348))",
"POLYGON ((18.065626 40.260592, 18.293757 40.260592, 18.293757 40.262969, 18.065626 40.262969, 18.065626 40.260592))",
"POLYGON ((18.078196 40.262972, 18.296000 40.262972, 18.296000 40.265625, 18.078196 40.265625, 18.078196 40.262972))",
"POLYGON ((18.016836 40.265627, 18.294015 40.265627, 18.294015 40.268627, 18.016836 40.268627, 18.016836 40.265627))",
"POLYGON ((18.073916 40.268630, 18.336665 40.268630, 18.336665 40.270928, 18.073916 40.270928, 18.073916 40.268630))",
"POLYGON ((18.043152 40.270930, 18.292778 40.270930, 18.292778 40.273897, 18.043152 40.273897, 18.043152 40.270930))",
"POLYGON ((18.044001 40.273897, 18.430225 40.273897, 18.430225 40.276884, 18.044001 40.276884, 18.044001 40.273897))",
"POLYGON ((18.072122 40.276884, 18.293771 40.276884, 18.293771 40.280493, 18.072122 40.280493, 18.072122 40.276884))",
"POLYGON ((18.062267 40.280493, 18.427896 40.280493, 18.427896 40.299823, 18.062267 40.299823, 18.062267 40.280493))",
"POLYGON ((18.029407 40.299824, 18.375209 40.299824, 18.375209 40.344293, 18.029407 40.344293, 18.029407 40.299824))",
"POLYGON ((17.959632 40.344293, 18.300276 40.344293, 18.300276 40.431093, 17.959632 40.431093, 17.959632 40.344293))",
"POLYGON ((17.928125 40.431100, 18.159992 40.431100, 18.159992 40.657622, 17.928125 40.657622, 17.928125 40.431100))",
"POLYGON ((17.936318 40.657754, 18.409459 40.657754, 18.409459 42.641999, 17.936318 42.641999, 17.936318 40.657754))",
"POLYGON ((17.923335 42.642001, 18.507013 42.642001, 18.507013 43.856800, 17.923335 43.856800, 17.923335 42.642001))",
"POLYGON ((17.925542 43.856875, 18.515742 43.856875, 18.515742 46.029793, 17.925542 46.029793, 17.925542 43.856875))",
"POLYGON ((17.925449 46.029801, 18.470682 46.029801, 18.470682 47.091385, 17.925449 47.091385, 17.925449 46.029801))",
"POLYGON ((18.035318 47.091387, 18.495841 47.091387, 18.495841 47.754201, 18.035318 47.754201, 18.035318 47.091387))",
"POLYGON ((17.950643 47.754224, 18.414544 47.754224, 18.414544 48.299196, 17.950643 48.299196, 17.950643 47.754224))",
"POLYGON ((17.932839 48.299196, 18.420782 48.299196, 18.420782 48.604709, 17.932839 48.604709, 17.932839 48.299196))",
"POLYGON ((17.928697 48.604712, 18.494875 48.604712, 18.494875 49.113910, 17.928697 49.113910, 17.928697 48.604712))",
"POLYGON ((17.929399 49.114001, 18.484399 49.114001, 18.484399 49.533435, 17.929399 49.533435, 17.929399 49.114001))",
"POLYGON ((17.961110 49.533484, 18.400060 49.533484, 18.400060 49.593352, 17.961110 49.593352, 17.961110 49.533484))",
"POLYGON ((17.989982 49.593352, 18.490940 49.593352, 18.490940 49.673591, 17.989982 49.673591, 17.989982 49.593352))",
"POLYGON ((18.034598 49.673599, 18.504081 49.673599, 18.504081 49.717890, 18.034598 49.717890, 18.034598 49.673599))",
"POLYGON ((18.015611 49.717893, 18.429018 49.717893, 18.429018 49.757859, 18.015611 49.757859, 18.015611 49.717893))",
"POLYGON ((17.937308 49.757865, 18.443373 49.757865, 18.443373 49.848820, 17.937308 49.848820, 17.937308 49.757865))",
"POLYGON ((17.944028 49.848825, 18.492249 49.848825, 18.492249 49.909754, 17.944028 49.909754, 17.944028 49.848825))",
"POLYGON ((17.976936 49.909754, 18.422691 49.909754, 18.422691 49.920731, 17.976936 49.920731, 17.976936 49.909754))",
"POLYGON ((17.945771 49.920744, 18.477850 49.920744, 18.477850 49.928305, 17.945771 49.928305, 17.945771 49.920744))",
"POLYGON ((17.927623 49.928312, 18.448419 49.928312, 18.448419 49.951781, 17.927623 49.951781, 17.927623 49.928312))",
"POLYGON ((17.919930 49.951803, 18.464082 49.951803, 18.464082 50.022523, 17.919930 50.022523, 17.919930 49.951803))",
"POLYGON ((17.941781 50.022524, 18.425814 50.022524, 18.425814 50.089460, 17.941781 50.089460, 17.941781 50.022524))",
"POLYGON ((17.926751 50.089471, 18.272175 50.089471, 18.272175 50.094675, 17.926751 50.094675, 17.926751 50.089471))",
"POLYGON ((17.927079 50.094694, 18.480167 50.094694, 18.480167 50.153589, 17.927079 50.153589, 17.927079 50.094694))",
"POLYGON ((17.983477 50.153590, 18.465190 50.153590, 18.465190 50.196913, 17.983477 50.196913, 17.983477 50.153590))",
"POLYGON ((17.931921 50.196983, 18.516464 50.196983, 18.516464 50.305749, 17.931921 50.305749, 17.931921 50.196983))",
"POLYGON ((17.940794 50.305775, 18.487275 50.305775, 18.487275 50.349782, 17.940794 50.349782, 17.940794 50.305775))",
"POLYGON ((18.061031 50.349846, 18.518104 50.349846, 18.518104 50.456069, 18.061031 50.456069, 18.061031 50.349846))",
"POLYGON ((17.919431 50.456069, 18.517375 50.456069, 18.517375 50.843452, 17.919431 50.843452, 17.919431 50.456069))",
"POLYGON ((17.955236 50.843452, 18.493282 50.843452, 18.493282 50.943383, 17.955236 50.943383, 17.955236 50.843452))",
"POLYGON ((17.977356 50.943392, 18.482558 50.943392, 18.482558 51.082888, 17.977356 51.082888, 17.977356 50.943392))",
"POLYGON ((17.954059 51.094655, 18.514782 51.094655, 18.514782 51.290918, 17.954059 51.290918, 17.954059 51.094655))",
"POLYGON ((17.928914 51.291168, 18.514192 51.291168, 18.514192 51.760000, 17.928914 51.760000, 17.928914 51.291168))",
"POLYGON ((18.058164 51.760092, 18.341714 51.760092, 18.341714 51.797767, 18.058164 51.797767, 18.058164 51.760092))",
"POLYGON ((17.930183 51.797770, 18.502993 51.797770, 18.502993 52.231417, 17.930183 52.231417, 17.930183 51.797770))",
"POLYGON ((17.942926 52.231435, 18.501576 52.231435, 18.501576 53.188329, 17.942926 53.188329, 17.942926 52.231435))",
"POLYGON ((17.935185 53.293616, 18.508539 53.293616, 18.508539 59.112762, 17.935185 59.112762, 17.935185 53.293616))",
"POLYGON ((17.991483 59.112768, 18.490687 59.112768, 18.490687 59.362821, 17.991483 59.362821, 17.991483 59.112768))",
"POLYGON ((17.928574 59.362833, 18.497374 59.362833, 18.497374 62.928902, 17.928574 62.928902, 17.928574 59.362833))",
"POLYGON ((18.523749 -33.936896, 19.032084 -33.936896, 19.032084 42.450226, 18.523749 42.450226, 18.523749 -33.936896))",
"POLYGON ((18.533494 42.450226, 19.002871 42.450226, 19.002871 43.665110, 18.533494 43.665110, 18.533494 42.450226))",
"POLYGON ((18.544675 43.665205, 19.032157 43.665205, 19.032157 47.313390, 18.544675 47.313390, 18.544675 43.665205))",
"POLYGON ((18.544143 47.313549, 19.032162 47.313549, 19.032162 47.458598, 18.544143 47.458598, 18.544143 47.313549))",
"POLYGON ((18.608882 47.458737, 19.032450 47.458737, 19.032450 47.549520, 18.608882 47.549520, 18.608882 47.458737))",
"POLYGON ((18.675410 47.549521, 19.001490 47.549521, 19.001490 48.218037, 18.675410 48.218037, 18.675410 47.549521))",
"POLYGON ((18.523278 48.218049, 18.943301 48.218049, 18.943301 48.780373, 18.523278 48.780373, 18.523278 48.218049))",
"POLYGON ((18.527512 48.853511, 18.971776 48.853511, 18.971776 49.570342, 18.527512 49.570342, 18.527512 48.853511))",
"POLYGON ((18.524767 49.570349, 18.921784 49.570349, 18.921784 49.674925, 18.524767 49.674925, 18.524767 49.570349))",
"POLYGON ((18.540852 49.674925, 18.843362 49.674925, 18.843362 49.743132, 18.540852 49.743132, 18.540852 49.674925))",
"POLYGON ((18.613709 49.743543, 18.832419 49.743543, 18.832419 49.749422, 18.613709 49.749422, 18.613709 49.743543))",
"POLYGON ((18.568271 49.749424, 19.017069 49.749424, 19.017069 49.800134, 18.568271 49.800134, 18.568271 49.749424))",
"POLYGON ((18.592279 49.800134, 19.030621 49.800134, 19.030621 49.844895, 18.592279 49.844895, 18.592279 49.800134))",
"POLYGON ((18.539364 49.844895, 19.005666 49.844895, 19.005666 49.947277, 18.539364 49.947277, 18.539364 49.844895))",
"POLYGON ((18.568217 49.947293, 19.007293 49.947293, 19.007293 49.978236, 18.568217 49.978236, 18.568217 49.947293))",
"POLYGON ((18.558043 49.978244, 18.969228 49.978244, 18.969228 50.079972, 18.558043 50.079972, 18.558043 49.978244))",
"POLYGON ((18.521242 50.079998, 18.992495 50.079998, 18.992495 50.130541, 18.521242 50.130541, 18.521242 50.079998))",
"POLYGON ((18.549688 50.130555, 18.990961 50.130555, 18.990961 50.170298, 18.549688 50.170298, 18.549688 50.130555))",
"POLYGON ((18.560482 50.170309, 18.971161 50.170309, 18.971161 50.227200, 18.560482 50.227200, 18.560482 50.170309))",
"POLYGON ((18.569072 50.227209, 19.019670 50.227209, 19.019670 50.251244, 18.569072 50.251244, 18.569072 50.227209))",
"POLYGON ((18.679923 50.251245, 19.019519 50.251245, 19.019519 50.255058, 18.679923 50.255058, 18.679923 50.251245))",
"POLYGON ((18.550222 50.255058, 19.030669 50.255058, 19.030669 50.266056, 18.550222 50.266056, 18.550222 50.255058))",
"POLYGON ((18.528349 50.266086, 18.952570 50.266086, 18.952570 50.276700, 18.528349 50.276700, 18.528349 50.266086))",
"POLYGON ((18.814420 50.276709, 18.952203 50.276709, 18.952203 50.284077, 18.814420 50.284077, 18.814420 50.276709))",
"POLYGON ((18.596681 50.284078, 18.931654 50.284078, 18.931654 50.285261, 18.596681 50.285261, 18.596681 50.284078))",
"POLYGON ((18.831150 50.285263, 18.940465 50.285263, 18.940465 50.288058, 18.831150 50.288058, 18.831150 50.285263))",
"POLYGON ((18.654755 50.288070, 18.953865 50.288070, 18.953865 50.294521, 18.654755 50.294521, 18.654755 50.288070))",
"POLYGON ((18.656596 50.294524, 18.965530 50.294524, 18.965530 50.297435, 18.656596 50.297435, 18.656596 50.294524))",
"POLYGON ((18.649153 50.297436, 19.032469 50.297436, 19.032469 50.301816, 18.649153 50.301816, 18.649153 50.297436))",
"POLYGON ((18.693365 50.301816, 19.032019 50.301816, 19.032019 50.304271, 18.693365 50.304271, 18.693365 50.301816))",
"POLYGON ((18.768635 50.304271, 19.032421 50.304271, 19.032421 50.307683, 18.768635 50.307683, 18.768635 50.304271))",
"POLYGON ((18.561908 50.307683, 19.031830 50.307683, 19.031830 50.311036, 18.561908 50.311036, 18.561908 50.307683))",
"POLYGON ((18.662710 50.311036, 19.011375 50.311036, 19.011375 50.313060, 18.662710 50.313060, 18.662710 50.311036))",
"POLYGON ((18.688545 50.313066, 19.010879 50.313066, 19.010879 50.319474, 18.688545 50.319474, 18.688545 50.313066))",
"POLYGON ((18.793412 50.319478, 19.003695 50.319478, 19.003695 50.324738, 18.793412 50.324738, 18.793412 50.319478))",
"POLYGON ((18.616765 50.324754, 18.932058 50.324754, 18.932058 50.338297, 18.616765 50.338297, 18.616765 50.324754))",
"POLYGON ((18.617310 50.338299, 19.010705 50.338299, 19.010705 50.346087, 18.617310 50.346087, 18.617310 50.338299))",
"POLYGON ((18.538934 50.346092, 18.982049 50.346092, 18.982049 50.348392, 18.538934 50.348392, 18.538934 50.346092))",
"POLYGON ((18.875609 50.348393, 18.982208 50.348393, 18.982208 50.353425, 18.875609 50.353425, 18.875609 50.348393))",
"POLYGON ((18.709845 50.353487, 18.993169 50.353487, 18.993169 50.379212, 18.709845 50.379212, 18.709845 50.353487))",
"POLYGON ((18.535077 50.379219, 19.001668 50.379219, 19.001668 50.421407, 18.535077 50.421407, 18.535077 50.379219))",
"POLYGON ((18.601104 50.421408, 18.952995 50.421408, 18.952995 50.443209, 18.601104 50.443209, 18.601104 50.421408))",
"POLYGON ((18.518136 50.443216, 18.949010 50.443216, 18.949010 50.494515, 18.518136 50.494515, 18.518136 50.443216))",
"POLYGON ((18.533971 50.494570, 19.000156 50.494570, 19.000156 50.637454, 18.533971 50.637454, 18.533971 50.494570))",
"POLYGON ((18.532457 50.637454, 18.908330 50.637454, 18.908330 50.797022, 18.532457 50.797022, 18.532457 50.637454))",
"POLYGON ((18.546101 50.797108, 19.027255 50.797108, 19.027255 51.221131, 18.546101 51.221131, 18.546101 50.797108))",
"POLYGON ((18.536311 51.221132, 18.995290 51.221132, 18.995290 52.086864, 18.536311 52.086864, 18.536311 51.221132))",
"POLYGON ((18.550048 52.100460, 19.022482 52.100460, 19.022482 52.835390, 18.550048 52.835390, 18.550048 52.100460))",
"POLYGON ((18.573945 52.835418, 18.902444 52.835418, 18.902444 52.881471, 18.573945 52.881471, 18.573945 52.835418))",
"POLYGON ((18.571950 52.881536, 18.811582 52.881536, 18.811582 53.009342, 18.571950 53.009342, 18.571950 52.881536))",
"POLYGON ((18.603340 53.009342, 18.610986 53.009342, 18.610986 53.010695, 18.603340 53.010695, 18.603340 53.009342))",
"POLYGON ((18.563900 53.010695, 18.935630 53.010695, 18.935630 53.388254, 18.563900 53.388254, 18.563900 53.010695))",
"POLYGON ((18.619321 53.388295, 18.968043 53.388295, 18.968043 53.489141, 18.619321 53.489141, 18.619321 53.388295))",
"POLYGON ((18.734066 53.489150, 18.952593 53.489150, 18.952593 53.543880, 18.734066 53.543880, 18.734066 53.489150))",
"POLYGON ((18.628032 53.543880, 19.029900 53.543880, 19.029900 54.322716, 18.628032 54.322716, 18.628032 53.543880))",
"POLYGON ((18.632568 54.322718, 18.659652 54.322718, 18.659652 54.345790, 18.632568 54.345790, 18.632568 54.322718))",
"POLYGON ((18.643060 54.345791, 18.669097 54.345791, 18.669097 54.349877, 18.643060 54.349877, 18.643060 54.345791))",
"POLYGON ((18.646420 54.349878, 18.660897 54.349878, 18.660897 54.352895, 18.646420 54.352895, 18.646420 54.349878))",
"POLYGON ((18.611159 54.352898, 18.662989 54.352898, 18.662989 54.373998, 18.611159 54.373998, 18.611159 54.352898))",
"POLYGON ((18.536745 54.374001, 18.682375 54.374001, 18.682375 54.517732, 18.536745 54.517732, 18.536745 54.374001))",
"POLYGON ((18.523424 54.517739, 18.813749 54.517739, 18.813749 54.595961, 18.523424 54.595961, 18.523424 54.517739))",
"POLYGON ((18.777018 54.595973, 18.824133 54.595973, 18.824133 54.641397, 18.777018 54.641397, 18.777018 54.595973))",
"POLYGON ((18.549278 54.641404, 19.019617 54.641404, 19.019617 74.505163, 18.549278 74.505163, 18.549278 54.641404))",
"POLYGON ((19.196957 -32.385896, 19.933914 -32.385896, 19.933914 40.590864, 19.196957 40.590864, 19.196957 -32.385896))",
"POLYGON ((19.444533 40.591298, 19.944785 40.591298, 19.944785 41.314815, 19.444533 41.314815, 19.444533 40.591298))",
"POLYGON ((19.219560 41.314920, 19.826206 41.314920, 19.826206 42.045887, 19.219560 42.045887, 19.219560 41.314920))",
"POLYGON ((19.491991 42.045893, 19.548597 42.045893, 19.548597 42.068983, 19.491991 42.068983, 19.491991 42.045893))",
"POLYGON ((19.060935 42.068985, 19.756540 42.068985, 19.756540 42.128927, 19.060935 42.128927, 19.060935 42.068985))",
"POLYGON ((19.123607 42.128927, 19.826266 42.128927, 19.826266 42.144913, 19.123607 42.144913, 19.123607 42.128927))",
"POLYGON ((19.097688 42.145023, 19.805382 42.145023, 19.805382 42.175300, 19.097688 42.175300, 19.097688 42.145023))",
"POLYGON ((19.074696 42.175494, 19.789645 42.175494, 19.789645 42.234319, 19.074696 42.234319, 19.074696 42.175494))",
"POLYGON ((19.089558 42.234563, 19.812301 42.234563, 19.812301 42.289456, 19.089558 42.289456, 19.089558 42.234563))",
"POLYGON ((19.128137 42.289570, 19.891592 42.289570, 19.891592 42.320509, 19.128137 42.320509, 19.128137 42.289570))",
"POLYGON ((19.414847 42.320573, 19.915755 42.320573, 19.915755 42.354324, 19.414847 42.354324, 19.414847 42.320573))",
"POLYGON ((19.426040 42.354334, 19.928463 42.354334, 19.928463 42.391088, 19.426040 42.391088, 19.426040 42.354334))",
"POLYGON ((19.250121 42.391259, 19.866285 42.391259, 19.866285 42.449956, 19.250121 42.449956, 19.250121 42.391259))",
"POLYGON ((19.123038 42.449958, 19.943855 42.449958, 19.943855 43.869447, 19.123038 43.869447, 19.123038 42.449958))",
"POLYGON ((19.059015 43.869482, 19.937083 43.869482, 19.937083 46.426441, 19.059015 46.426441, 19.059015 43.869482))",
"POLYGON ((19.034218 46.426663, 19.890984 46.426663, 19.890984 47.497659, 19.034218 47.497659, 19.034218 46.426663))",
"POLYGON ((19.032469 47.497667, 19.103208 47.497667, 19.103208 47.502960, 19.032469 47.502960, 19.032469 47.497667))",
"POLYGON ((19.032554 47.502979, 19.653829 47.502979, 19.653829 47.604613, 19.032554 47.604613, 19.032554 47.502979))",
"POLYGON ((19.046266 47.604613, 19.857277 47.604613, 19.857277 48.243923, 19.046266 48.243923, 19.046266 47.604613))",
"POLYGON ((19.089211 48.243930, 19.896562 48.243930, 19.896562 48.648946, 19.089211 48.648946, 19.089211 48.243930))",
"POLYGON ((19.086645 48.648991, 19.803256 48.648991, 19.803256 49.105252, 19.086645 49.105252, 19.086645 48.648991))",
"POLYGON ((19.058525 49.105269, 19.944744 49.105269, 19.944744 49.577092, 19.058525 49.577092, 19.058525 49.105269))",
"POLYGON ((19.069460 49.577117, 19.847671 49.577117, 19.847671 49.689450, 19.069460 49.689450, 19.069460 49.577117))",
"POLYGON ((19.034925 49.689458, 19.894673 49.689458, 19.894673 49.818202, 19.034925 49.818202, 19.034925 49.689458))",
"POLYGON ((19.032742 49.818224, 19.088146 49.818224, 19.088146 49.821238, 19.032742 49.821238, 19.032742 49.818224))",
"POLYGON ((19.032867 49.821242, 19.062862 49.821242, 19.062862 49.822944, 19.032867 49.822944, 19.032867 49.821242))",
"POLYGON ((19.032991 49.822946, 19.062940 49.822946, 19.062940 49.829049, 19.032991 49.829049, 19.032991 49.822946))",
"POLYGON ((19.034471 49.829072, 19.883452 49.829072, 19.883452 49.977675, 19.034471 49.977675, 19.034471 49.829072))",
"POLYGON ((19.047734 49.977675, 19.938711 49.977675, 19.938711 50.028073, 19.047734 50.028073, 19.047734 49.977675))",
"POLYGON ((19.164282 50.030696, 19.866143 50.030696, 19.866143 50.036586, 19.164282 50.036586, 19.164282 50.030696))",
"POLYGON ((19.167477 50.036589, 19.179548 50.036589, 19.179548 50.038682, 19.167477 50.038682, 19.167477 50.036589))",
"POLYGON ((19.163378 50.038682, 19.921687 50.038682, 19.921687 50.040668, 19.163378 50.040668, 19.163378 50.038682))",
"POLYGON ((19.164550 50.040684, 19.943510 50.040684, 19.943510 50.060949, 19.164550 50.060949, 19.164550 50.040684))",
"POLYGON ((19.828829 50.060953, 19.943531 50.060953, 19.943531 50.067487, 19.828829 50.067487, 19.828829 50.060953))",
"POLYGON ((19.179962 50.067487, 19.943597 50.067487, 19.943597 50.086500, 19.179962 50.086500, 19.179962 50.067487))",
"POLYGON ((19.092348 50.086517, 19.944112 50.086517, 19.944112 50.151920, 19.092348 50.151920, 19.092348 50.086517))",
"POLYGON ((19.155481 50.151924, 19.938968 50.151924, 19.938968 50.236294, 19.155481 50.236294, 19.155481 50.151924))",
"POLYGON ((19.085660 50.236395, 19.780938 50.236395, 19.780938 50.244488, 19.085660 50.244488, 19.085660 50.236395))",
"POLYGON ((19.087255 50.244496, 19.780443 50.244496, 19.780443 50.274251, 19.087255 50.274251, 19.087255 50.244496))",
"POLYGON ((19.066628 50.274251, 19.559075 50.274251, 19.559075 50.278291, 19.066628 50.278291, 19.066628 50.274251))",
"POLYGON ((19.113550 50.278292, 19.606854 50.278292, 19.606854 50.288240, 19.113550 50.288240, 19.113550 50.278292))",
"POLYGON ((19.075187 50.288415, 19.397931 50.288415, 19.397931 50.298900, 19.075187 50.298900, 19.075187 50.288415))",
"POLYGON ((19.143063 50.298906, 19.593191 50.298906, 19.593191 50.300744, 19.143063 50.300744, 19.143063 50.298906))",
"POLYGON ((19.032497 50.300752, 19.381102 50.300752, 19.381102 50.308540, 19.032497 50.308540, 19.032497 50.300752))",
"POLYGON ((19.068990 50.308552, 19.513276 50.308552, 19.513276 50.323141, 19.068990 50.323141, 19.068990 50.308552))",
"POLYGON ((19.121574 50.323152, 19.429885 50.323152, 19.429885 50.330009, 19.121574 50.330009, 19.121574 50.323152))",
"POLYGON ((19.040175 50.330009, 19.756293 50.330009, 19.756293 50.438364, 19.040175 50.438364, 19.040175 50.330009))",
"POLYGON ((19.033104 50.438426, 19.860873 50.438426, 19.860873 50.634619, 19.033104 50.634619, 19.033104 50.438426))",
"POLYGON ((19.135201 50.634619, 19.921585 50.634619, 19.921585 51.734512, 19.135201 51.734512, 19.135201 50.634619))",
"POLYGON ((19.298831 51.734517, 19.724830 51.734517, 19.724830 51.766375, 19.298831 51.766375, 19.298831 51.734517))",
"POLYGON ((19.402254 51.766379, 19.461598 51.766379, 19.461598 51.771013, 19.402254 51.771013, 19.402254 51.766379))",
"POLYGON ((19.398774 51.771016, 19.475184 51.771016, 19.475184 51.776574, 19.398774 51.776574, 19.398774 51.771016))",
"POLYGON ((19.399367 51.776574, 19.472730 51.776574, 19.472730 51.783298, 19.399367 51.783298, 19.399367 51.776574))",
"POLYGON ((19.175481 51.783298, 19.847895 51.783298, 19.847895 51.858643, 19.175481 51.858643, 19.175481 51.783298))",
"POLYGON ((19.064322 51.858643, 19.943066 51.858643, 19.943066 52.104291, 19.064322 52.104291, 19.064322 51.858643))",
"POLYGON ((19.906673 52.104295, 19.944821 52.104295, 19.944821 52.106706, 19.906673 52.106706, 19.906673 52.104295))",
"POLYGON ((19.933123 52.106706, 19.944812 52.106706, 19.944812 52.108056, 19.933123 52.108056, 19.933123 52.106706))",
"POLYGON ((19.573221 52.108059, 19.944820 52.108059, 19.944820 52.146236, 19.573221 52.146236, 19.573221 52.108059))",
"POLYGON ((19.221236 52.146237, 19.881214 52.146237, 19.881214 52.323715, 19.221236 52.323715, 19.221236 52.146237))",
"POLYGON ((19.036965 52.323898, 19.922676 52.323898, 19.922676 53.673991, 19.036965 53.673991, 19.036965 52.323898))",
"POLYGON ((19.039264 53.673997, 19.927125 53.673997, 19.927125 54.161986, 19.039264 54.161986, 19.039264 53.673997))",
"POLYGON ((19.396891 54.161986, 19.413491 54.161986, 19.413491 54.169742, 19.396891 54.169742, 19.396891 54.161986))",
"POLYGON ((19.056080 54.169748, 19.932975 54.169748, 19.932975 69.674685, 19.056080 69.674685, 19.056080 54.169748))",
"POLYGON ((20.032598 -34.004987, 21.860664 -34.004987, 21.860664 32.820436, 20.032598 32.820436, 20.032598 -34.004987))",
"POLYGON ((21.703352 32.820588, 22.234962 32.820588, 22.234962 36.891523, 21.703352 36.891523, 21.703352 32.820588))",
"POLYGON ((21.656523 36.891570, 22.234863 36.891570, 22.234863 37.178546, 21.656523 37.178546, 21.656523 36.891570))",
"POLYGON ((21.627728 37.178572, 22.045725 37.178572, 22.045725 37.637796, 21.627728 37.637796, 21.627728 37.178572))",
"POLYGON ((20.624704 37.637818, 22.163469 37.637818, 22.163469 37.889885, 20.624704 37.889885, 20.624704 37.637818))",
"POLYGON ((20.521525 37.889927, 22.204347 37.889927, 22.204347 38.393013, 20.521525 38.393013, 20.521525 37.889927))",
"POLYGON ((20.551948 38.393019, 21.919232 38.393019, 21.919232 38.672084, 20.551948 38.672084, 20.551948 38.393019))",
"POLYGON ((20.719066 38.672155, 21.319603 38.672155, 21.319603 39.023782, 20.719066 39.023782, 20.719066 38.672155))",
"POLYGON ((20.187865 39.023791, 22.034448 39.023791, 22.034448 39.260608, 20.187865 39.260608, 20.187865 39.023791))",
"POLYGON ((20.107057 39.260694, 22.091170 39.260694, 22.091170 39.509941, 20.107057 39.509941, 20.107057 39.260694))",
"POLYGON ((20.172165 39.510117, 22.196106 39.510117, 22.196106 39.634440, 20.172165 39.634440, 20.172165 39.510117))",
"POLYGON ((19.992242 39.634480, 22.063208 39.634480, 22.063208 39.877517, 19.992242 39.877517, 19.992242 39.634480))",
"POLYGON ((20.020570 39.877520, 22.219369 39.877520, 22.219369 40.473763, 20.020570 40.473763, 20.020570 39.877520))",
"POLYGON ((19.944881 40.473875, 22.058465 40.473875, 22.058465 41.112472, 19.944881 41.112472, 19.944881 40.473875))",
"POLYGON ((20.079559 41.112473, 20.791755 41.112473, 20.791755 41.113592, 20.079559 41.113592, 20.079559 41.112473))",
"POLYGON ((20.079506 41.113593, 21.554807 41.113593, 21.554807 41.374327, 20.079506 41.374327, 20.079506 41.113593))",
"POLYGON ((19.966418 41.374339, 22.101316 41.374339, 22.101316 41.998364, 19.966418 41.998364, 19.966418 41.374339))",
"POLYGON ((19.972243 41.998364, 22.229370 41.998364, 22.229370 42.433669, 19.972243 42.433669, 19.972243 41.998364))",
"POLYGON ((19.958484 42.433716, 22.044470 42.433716, 22.044470 43.486655, 19.958484 43.486655, 19.958484 42.433716))",
"POLYGON ((19.976666 43.486750, 22.256816 43.486750, 22.256816 44.848604, 19.976666 44.848604, 19.976666 43.486750))",
"POLYGON ((20.074715 45.078331, 22.235983 45.078331, 22.235983 46.168590, 20.074715 46.168590, 20.074715 45.078331))",
"POLYGON ((20.096926 46.168597, 21.942685 46.168597, 21.942685 47.050078, 20.096926 47.050078, 20.096926 46.168597))",
"POLYGON ((19.955121 47.050436, 22.148441 47.050436, 22.148441 48.293263, 19.955121 48.293263, 19.955121 47.050436))",
"POLYGON ((20.003538 48.293285, 21.976476 48.293285, 21.976476 48.656738, 20.003538 48.656738, 20.003538 48.293285))",
"POLYGON ((20.363637 48.656747, 21.950175 48.656747, 21.950175 48.977079, 20.363637 48.977079, 20.363637 48.656747))",
"POLYGON ((20.070708 48.977100, 22.227472 48.977100, 22.227472 49.246635, 20.070708 49.246635, 20.070708 48.977100))",
"POLYGON ((19.948329 49.246646, 21.278955 49.246646, 21.278955 49.294544, 19.948329 49.294544, 19.948329 49.246646))",
"POLYGON ((19.945261 49.294545, 21.566023 49.294545, 21.566023 49.317918, 19.945261 49.317918, 19.945261 49.294545))",
"POLYGON ((19.978683 49.317923, 22.129087 49.317923, 22.129087 49.559192, 19.978683 49.559192, 19.978683 49.317923))",
"POLYGON ((20.219150 49.559216, 22.220544 49.559216, 22.220544 49.581525, 20.219150 49.581525, 20.219150 49.559216))",
"POLYGON ((19.945946 49.581526, 22.057012 49.581526, 22.057012 49.645420, 19.945946 49.645420, 19.945946 49.581526))",
"POLYGON ((21.143249 49.645424, 22.243347 49.645424, 22.243347 49.673066, 21.143249 49.673066, 21.143249 49.645424))",
"POLYGON ((20.435432 49.674542, 22.236370 49.674542, 22.236370 49.733660, 20.435432 49.733660, 20.435432 49.674542))",
"POLYGON ((20.691498 49.733660, 22.239080 49.733660, 22.239080 49.782909, 20.691498 49.782909, 20.691498 49.733660))",
"POLYGON ((20.008580 49.782990, 22.237746 49.782990, 22.237746 49.943175, 20.008580 49.943175, 20.008580 49.782990))",
"POLYGON ((19.955811 49.943255, 22.027088 49.943255, 22.027088 50.011653, 19.955811 50.011653, 19.955811 49.943255))",
"POLYGON ((20.421248 50.011660, 20.990417 50.011660, 20.990417 50.014977, 20.421248 50.014977, 20.421248 50.011660))",
"POLYGON ((19.954145 50.014977, 22.247584 50.014977, 22.247584 50.056987, 19.954145 50.056987, 19.954145 50.014977))",
"POLYGON ((19.945846 50.060135, 22.256761 50.060135, 22.256761 50.094765, 19.945846 50.094765, 19.945846 50.060135))",
"POLYGON ((19.947850 50.095062, 22.248217 50.095062, 22.248217 50.187475, 19.947850 50.187475, 19.947850 50.095062))",
"POLYGON ((19.970163 50.187476, 22.032908 50.187476, 22.032908 50.341901, 19.970163 50.341901, 19.970163 50.187476))",
"POLYGON ((20.026381 50.341902, 20.708126 50.341902, 20.708126 50.379626, 20.026381 50.379626, 20.026381 50.341902))",
"POLYGON ((20.188922 50.379628, 22.262444 50.379628, 22.262444 50.521542, 20.188922 50.521542, 20.188922 50.379628))",
"POLYGON ((19.970139 50.521544, 22.221518 50.521544, 22.221518 50.671965, 19.970139 50.671965, 19.970139 50.521544))",
"POLYGON ((20.066042 50.671988, 21.748953 50.671988, 21.748953 50.758282, 20.066042 50.758282, 20.066042 50.671988))",
"POLYGON ((20.111991 50.758291, 21.781929 50.758291, 21.781929 50.850992, 20.111991 50.850992, 20.111991 50.758291))",
"POLYGON ((19.952769 50.851007, 22.221008 50.851007, 22.221008 50.944543, 19.952769 50.944543, 19.952769 50.851007))",
"POLYGON ((20.076631 50.944543, 21.612569 50.944543, 21.612569 51.053103, 20.076631 51.053103, 20.076631 50.944543))",
"POLYGON ((20.077703 51.053117, 21.072937 51.053117, 21.072937 51.057117, 20.077703 51.057117, 20.077703 51.053117))",
"POLYGON ((20.034121 51.057143, 22.141394 51.057143, 22.141394 51.288498, 20.034121 51.288498, 20.034121 51.057143))",
"POLYGON ((19.972977 51.288858, 22.019040 51.288858, 22.019040 51.514372, 19.972977 51.514372, 19.972977 51.288858))",
"POLYGON ((19.970296 51.514697, 22.244452 51.514697, 22.244452 52.059003, 19.970296 52.059003, 19.970296 51.514697))",
"POLYGON ((19.944828 52.059003, 22.109802 52.059003, 22.109802 52.119936, 19.944828 52.119936, 19.944828 52.059003))",
"POLYGON ((19.954000 52.119964, 22.247230 52.119964, 22.247230 52.200027, 19.954000 52.200027, 19.954000 52.119964))",
"POLYGON ((19.962694 52.200043, 21.135936 52.200043, 21.135936 52.258958, 19.962694 52.258958, 19.962694 52.200043))",
"POLYGON ((20.035909 52.258983, 22.027478 52.258983, 22.027478 52.432309, 20.035909 52.432309, 20.035909 52.258983))",
"POLYGON ((20.379810 52.432309, 22.057867 52.432309, 22.057867 52.703981, 20.379810 52.703981, 20.379810 52.432309))",
"POLYGON ((19.947388 52.703984, 22.258763 52.703984, 22.258763 54.030002, 19.947388 54.030002, 19.947388 52.703984))",
"POLYGON ((19.966332 54.030023, 22.052259 54.030023, 22.052259 54.660494, 19.966332 54.660494, 19.966332 54.030023))",
"POLYGON ((20.374548 54.660779, 22.254128 54.660779, 22.254128 54.733645, 20.374548 54.733645, 20.374548 54.660779))",
"POLYGON ((20.011891 54.733647, 22.180430 54.733647, 22.180430 56.554699, 20.011891 56.554699, 20.011891 54.733647))",
"POLYGON ((19.946413 56.554713, 22.253678 56.554713, 22.253678 61.127045, 19.946413 61.127045, 19.946413 56.554713))",
"POLYGON ((20.894020 61.127050, 22.140657 61.127050, 22.140657 65.648387, 20.894020 65.648387, 20.894020 61.127050))",
"POLYGON ((22.416310 -34.009563, 24.435391 -34.009563, 24.435391 13.165098, 22.416310 13.165098, 22.416310 -34.009563))",
"POLYGON ((22.275839 13.165993, 24.466266 13.165993, 24.466266 35.223023, 22.275839 35.223023, 22.275839 13.165993))",
"POLYGON ((23.679060 35.223132, 24.432995 35.223132, 24.432995 35.292838, 23.679060 35.292838, 23.679060 35.223132))",
"POLYGON ((22.991340 35.292856, 24.472759 35.292856, 24.472759 36.145896, 22.991340 36.145896, 22.991340 35.292856))",
"POLYGON ((22.363862 36.145898, 23.201743 36.145898, 23.201743 36.666968, 22.363862 36.666968, 22.363862 36.145898))",
"POLYGON ((22.377941 36.666969, 23.056850 36.666969, 23.056850 36.689020, 22.377941 36.689020, 22.377941 36.666969))",
"POLYGON ((22.264752 36.689030, 24.467038 36.689030, 24.467038 37.523111, 22.264752 37.523111, 22.264752 36.689030))",
"POLYGON ((22.670967 37.523114, 23.481260 37.523114, 23.481260 37.598496, 22.670967 37.598496, 22.670967 37.523114))",
"POLYGON ((22.391584 37.598497, 24.326630 37.598497, 24.326630 37.728401, 22.391584 37.728401, 22.391584 37.598497))",
"POLYGON ((22.748219 37.728417, 22.759892 37.728417, 22.759892 37.738018, 22.748219 37.738018, 22.748219 37.728417))",
"POLYGON ((22.455755 37.738022, 23.534063 37.738022, 23.534063 37.890633, 22.455755 37.890633, 22.455755 37.738022))",
"POLYGON ((22.868622 37.890636, 22.877931 37.890636, 22.877931 37.891928, 22.868622 37.891928, 22.868622 37.890636))",
"POLYGON ((22.869592 37.891929, 23.996780 37.891929, 23.996780 37.922418, 22.869592 37.922418, 22.869592 37.891929))",
"POLYGON ((22.284453 37.922462, 23.997602 37.922462, 23.997602 37.970831, 22.284453 37.970831, 22.284453 37.922462))",
"POLYGON ((23.718111 37.970832, 23.744039 37.970832, 23.744039 37.974650, 23.718111 37.974650, 23.718111 37.970832))",
"POLYGON ((22.710751 37.974654, 24.421991 37.974654, 24.421991 38.013044, 22.710751 38.013044, 22.710751 37.974654))",
"POLYGON ((23.535109 38.013070, 24.431691 38.013070, 24.431691 38.105572, 23.535109 38.105572, 23.535109 38.013070))",
"POLYGON ((22.377350 38.105573, 23.981025 38.105573, 23.981025 38.189971, 22.377350 38.189971, 22.377350 38.105573))",
"POLYGON ((22.499312 38.189984, 24.028029 38.189984, 24.028029 38.480290, 22.499312 38.480290, 22.499312 38.189984))",
"POLYGON ((22.496637 38.480290, 22.509144 38.480290, 22.509144 38.482385, 22.496637 38.482385, 22.496637 38.480290))",
"POLYGON ((22.277241 38.482401, 23.490374 38.482401, 23.490374 39.343022, 22.277241 39.343022, 22.277241 38.482401))",
"POLYGON ((22.401280 39.343022, 24.309978 39.343022, 24.309978 40.290951, 22.401280 40.290951, 22.401280 39.343022))",
"POLYGON ((22.318771 40.291000, 24.212873 40.291000, 24.212873 40.635036, 22.318771 40.635036, 22.318771 40.291000))",
"POLYGON ((22.932157 40.635203, 22.963109 40.635203, 22.963109 40.643856, 22.932157 40.643856, 22.932157 40.635203))",
"POLYGON ((22.514229 40.643857, 24.144842 40.643857, 24.144842 40.764022, 22.514229 40.764022, 22.514229 40.643857))",
"POLYGON ((22.288250 40.764088, 24.442627 40.764088, 24.442627 42.019852, 22.288250 42.019852, 22.288250 40.764088))",
"POLYGON ((23.104118 42.019860, 24.601620 42.019860, 24.601620 42.163177, 23.104118 42.163177, 23.104118 42.019860))",
"POLYGON ((22.361362 42.163242, 24.586721 42.163242, 24.586721 42.223020, 22.361362 42.223020, 22.361362 42.163242))",
"POLYGON ((24.251631 42.223036, 24.595894 42.223036, 24.595894 42.252983, 24.251631 42.252983, 24.251631 42.223036))",
"POLYGON ((24.235990 42.252983, 24.594410 42.252983, 24.594410 42.306628, 24.235990 42.306628, 24.235990 42.252983))",
"POLYGON ((23.381939 42.306645, 24.543351 42.306645, 24.543351 42.361156, 23.381939 42.361156, 23.381939 42.306645))",
"POLYGON ((24.197516 42.364604, 24.587081 42.364604, 24.587081 42.489477, 24.197516 42.489477, 24.197516 42.364604))",
"POLYGON ((22.881750 42.489528, 24.601621 42.489528, 24.601621 42.704257, 22.881750 42.704257, 22.881750 42.489528))",
"POLYGON ((22.415685 42.704267, 24.530674 42.704267, 24.530674 44.169844, 22.415685 44.169844, 22.415685 42.704267))",
"POLYGON ((22.403216 44.170433, 24.554405 44.170433, 24.554405 45.621513, 22.403216 45.621513, 22.403216 44.170433))",
"POLYGON ((23.153545 45.621552, 23.315602 45.621552, 23.315602 45.681130, 23.153545 45.681130, 23.153545 45.621552))",
"POLYGON ((22.887450 45.681130, 24.158190 45.681130, 24.158190 45.801952, 22.887450 45.801952, 22.887450 45.681130))",
"POLYGON ((22.735824 45.801970, 24.598734 45.801970, 24.598734 46.571506, 22.735824 46.571506, 22.735824 45.801970))",
"POLYGON ((22.349979 46.572031, 24.500163 46.572031, 24.500163 47.177930, 22.349979 47.177930, 22.349979 46.572031))",
"POLYGON ((22.407280 47.177984, 24.573964 47.177984, 24.573964 48.484839, 22.407280 48.484839, 22.407280 47.177984))",
"POLYGON ((22.296510 48.484839, 24.555562 48.484839, 24.555562 49.347048, 22.296510 49.347048, 22.296510 48.484839))",
"POLYGON ((23.489147 49.347625, 23.514220 49.347625, 23.514220 49.352274, 23.489147 49.352274, 23.489147 49.347625))",
"POLYGON ((23.494883 49.352276, 23.515123 49.352276, 23.515123 49.354385, 23.494883 49.354385, 23.494883 49.352276))",
"POLYGON ((22.324305 49.354390, 24.600602 49.354390, 24.600602 49.470102, 22.324305 49.470102, 22.324305 49.354390))",
"POLYGON ((22.262677 49.470118, 24.433829 49.470118, 24.433829 49.755657, 22.262677 49.755657, 22.262677 49.470118))",
"POLYGON ((22.378025 49.755889, 23.917571 49.755889, 23.917571 49.796620, 22.378025 49.796620, 22.378025 49.755889))",
"POLYGON ((22.710103 49.796630, 24.148805 49.796630, 24.148805 49.830496, 22.710103 49.830496, 22.710103 49.796630))",
"POLYGON ((22.539689 49.830496, 24.065875 49.830496, 24.065875 50.018115, 22.539689 50.018115, 22.539689 49.830496))",
"POLYGON ((22.262554 50.018120, 24.018908 50.018120, 24.018908 50.378218, 22.262554 50.378218, 22.262554 50.018120))",
"POLYGON ((22.267621 50.378340, 24.018919 50.378340, 24.018919 50.568848, 22.267621 50.568848, 22.267621 50.378340))",
"POLYGON ((22.308041 50.568848, 24.319181 50.568848, 24.319181 51.131400, 22.308041 51.131400, 22.308041 50.568848))",
"POLYGON ((22.376787 51.131403, 23.554102 51.131403, 23.554102 51.307158, 22.376787 51.307158, 22.376787 51.131403))",
"POLYGON ((22.444379 51.307160, 23.750969 51.307160, 23.750969 52.081976, 22.444379 52.081976, 22.444379 51.307160))",
"POLYGON ((22.284180 52.081979, 23.819562 52.081979, 23.819562 52.975119, 22.284180 52.975119, 22.284180 52.081979))",
"POLYGON ((22.659228 52.975128, 24.488550 52.975128, 24.488550 53.206833, 22.659228 53.206833, 22.659228 52.975128))",
"POLYGON ((22.309406 53.208830, 24.421606 53.208830, 24.421606 53.676317, 22.309406 53.676317, 22.309406 53.208830))",
"POLYGON ((22.347022 53.676317, 24.126349 53.676317, 24.126349 54.406404, 22.347022 54.406404, 22.347022 53.676317))",
"POLYGON ((22.709893 54.406404, 24.523565 54.406404, 24.523565 54.945113, 22.709893 54.945113, 22.709893 54.406404))",
"POLYGON ((22.283351 54.945559, 24.418176 54.945559, 24.418176 56.716740, 22.283351 56.716740, 22.283351 54.945559))",
"POLYGON ((22.731485 56.716749, 24.500982 56.716749, 24.500982 56.981611, 22.731485 56.981611, 22.731485 56.716749))",
"POLYGON ((22.478826 56.981716, 24.591530 56.981716, 24.591530 60.147077, 22.478826 60.147077, 22.478826 56.981716))",
"POLYGON ((22.304502 60.147085, 24.581725 60.147085, 24.581725 61.397910, 22.304502 61.397910, 22.304502 60.147085))",
"POLYGON ((22.296091 61.397910, 24.518263 61.397910, 24.518263 69.942376, 22.296091 69.942376, 22.296091 61.397910))",
"POLYGON ((25.300752 -34.024683, 27.377035 -34.024683, 27.377035 -29.825265, 25.300752 -29.825265, 25.300752 -34.024683))",
"POLYGON ((25.416706 -29.825260, 27.276386 -29.825260, 27.276386 -24.644642, 25.416706 -24.644642, 25.416706 -29.825260))",
"POLYGON ((24.722692 -24.644625, 26.262391 -24.644625, 26.262391 35.161304, 24.722692 35.161304, 24.722692 -24.644625))",
"POLYGON ((24.670946 35.161426, 26.276171 35.161426, 26.276171 35.270432, 24.670946 35.270432, 24.670946 35.161426))",
"POLYGON ((25.009023 35.270437, 25.739550 35.270437, 25.739550 35.298235, 25.009023 35.298235, 25.009023 35.270437))",
"POLYGON ((24.628972 35.298240, 25.738850 35.298240, 25.738850 35.336975, 24.628972 35.336975, 24.628972 35.298240))",
"POLYGON ((24.669604 35.337026, 27.375544 35.337026, 27.375544 36.889503, 24.669604 36.889503, 24.669604 35.337026))",
"POLYGON ((25.079036 36.889518, 27.421877 36.889518, 27.421877 37.530282, 25.079036 37.530282, 25.079036 36.889518))",
"POLYGON ((24.759495 37.530331, 27.351380 37.530331, 27.351380 37.934610, 24.759495 37.934610, 24.759495 37.530331))",
"POLYGON ((27.192410 37.934626, 27.372511 37.934626, 27.372511 37.993315, 27.192410 37.993315, 27.192410 37.934626))",
"POLYGON ((26.022460 37.993343, 27.427245 37.993343, 27.427245 38.933953, 26.022460 38.933953, 26.022460 37.993343))",
"POLYGON ((26.017814 38.933980, 27.305785 38.933980, 27.305785 39.721836, 26.017814 39.721836, 26.017814 38.933980))",
"POLYGON ((25.062371 39.721929, 26.280404 39.721929, 26.280404 39.893265, 25.062371 39.893265, 25.062371 39.721929))",
"POLYGON ((25.061775 39.893322, 26.404534 39.893322, 26.404534 40.146132, 25.061775 40.146132, 25.061775 39.893322))",
"POLYGON ((24.669736 40.146166, 27.325156 40.146166, 27.325156 40.782066, 24.669736 40.782066, 24.669736 40.146166))",
"POLYGON ((24.621079 40.782066, 27.426388 40.782066, 27.426388 41.352743, 24.621079 41.352743, 24.621079 40.782066))",
"POLYGON ((24.611257 41.352789, 27.426983 41.352789, 27.426983 41.986774, 24.611257 41.986774, 24.611257 41.352789))",
"POLYGON ((24.614793 41.986800, 25.502948 41.986800, 25.502948 42.100797, 24.614793 42.100797, 24.614793 41.986800))",
"POLYGON ((24.601767 42.100808, 25.041465 42.100808, 25.041465 42.168728, 24.601767 42.168728, 24.601767 42.100808))",
"POLYGON ((24.676744 42.168728, 25.069808 42.168728, 25.069808 42.187267, 24.676744 42.187267, 24.676744 42.168728))",
"POLYGON ((24.604319 42.187270, 25.351233 42.187270, 25.351233 42.227588, 24.604319 42.227588, 24.604319 42.187270))",
"POLYGON ((24.621498 42.227591, 25.326441 42.227591, 25.326441 42.272555, 24.621498 42.272555, 24.621498 42.227591))",
"POLYGON ((24.628845 42.272573, 25.945077 42.272573, 25.945077 42.305613, 24.628845 42.305613, 24.628845 42.272573))",
"POLYGON ((24.608356 42.305613, 27.281704 42.305613, 27.281704 42.447891, 24.608356 42.447891, 24.608356 42.305613))",
"POLYGON ((24.601626 42.447945, 26.505864 42.447945, 26.505864 42.620856, 24.601626 42.620856, 24.601626 42.447945))",
"POLYGON ((24.732427 42.620868, 27.212223 42.620868, 27.212223 42.686867, 24.732427 42.686867, 24.732427 42.620868))",
"POLYGON ((25.258080 42.686870, 26.336250 42.686870, 26.336250 42.699481, 25.258080 42.699481, 25.258080 42.686870))",
"POLYGON ((24.672449 42.699493, 25.902029 42.699493, 25.902029 42.985377, 24.672449 42.985377, 24.672449 42.699493))",
"POLYGON ((24.673492 42.985774, 27.102370 42.985774, 27.102370 43.147125, 24.673492 43.147125, 24.673492 42.985774))",
"POLYGON ((24.629149 43.147135, 27.407243 43.147135, 27.407243 43.398402, 24.629149 43.398402, 24.629149 43.147135))",
"POLYGON ((24.891135 43.398468, 27.150121 43.398468, 27.150121 43.738529, 24.891135 43.738529, 24.891135 43.398468))",
"POLYGON ((25.327921 43.738537, 27.140792 43.738537, 27.140792 44.046957, 25.327921 44.046957, 25.327921 43.738537))",
"POLYGON ((24.638234 44.046967, 27.359084 44.046967, 27.359084 45.169381, 24.638234 45.169381, 24.638234 44.046967))",
"POLYGON ((24.634621 45.169418, 27.201182 45.169418, 27.201182 45.826832, 24.634621 45.826832, 24.634621 45.169418))",
"POLYGON ((24.786172 45.826845, 27.423784 45.826845, 27.423784 46.215916, 24.786172 46.215916, 24.786172 45.826845))",
"POLYGON ((24.786191 46.215917, 24.807478 46.215917, 24.807478 46.217762, 24.786191 46.217762, 24.786191 46.215917))",
"POLYGON ((24.782160 46.217766, 24.799790 46.217766, 24.799790 46.219056, 24.782160 46.219056, 24.782160 46.217766))",
"POLYGON ((24.779890 46.219056, 24.799390 46.219056, 24.799390 46.219764, 24.779890 46.219764, 24.779890 46.219056))",
"POLYGON ((24.782163 46.219769, 24.799629 46.219769, 24.799629 46.220616, 24.782163 46.220616, 24.782163 46.219769))",
"POLYGON ((24.608806 46.220620, 26.912868 46.220620, 26.912868 46.860934, 24.608806 46.860934, 24.608806 46.220620))",
"POLYGON ((24.624946 46.860938, 27.283318 46.860938, 27.283318 48.521151, 24.624946 48.521151, 24.624946 46.860938))",
"POLYGON ((25.041021 48.521192, 26.985510 48.521192, 26.985510 48.731354, 25.041021 48.731354, 25.041021 48.521192))",
"POLYGON ((24.730271 48.731451, 27.072737 48.731451, 27.072737 49.252561, 24.730271 49.252561, 24.730271 48.731451))",
"POLYGON ((24.602816 49.252561, 27.413574 49.252561, 27.413574 49.802607, 24.602816 49.802607, 24.602816 49.252561))",
"POLYGON ((24.897813 49.802747, 26.820361 49.802747, 26.820361 50.125235, 24.897813 50.125235, 24.897813 49.802747))",
"POLYGON ((24.627083 50.125242, 27.071489 50.125242, 27.071489 50.746331, 24.627083 50.746331, 24.627083 50.125242))",
"POLYGON ((24.704783 50.746351, 27.382950 50.746351, 27.382950 53.962540, 24.704783 53.962540, 24.704783 50.746351))",
"POLYGON ((24.983129 53.962778, 27.426763 53.962778, 27.426763 54.619852, 24.983129 54.619852, 24.983129 53.962778))",
"POLYGON ((24.829704 54.619882, 27.372235 54.619882, 27.372235 55.847067, 24.829704 55.847067, 24.829704 54.619882))",
"POLYGON ((24.767701 55.847086, 27.412803 55.847086, 27.412803 56.638011, 24.767701 56.638011, 24.767701 55.847086))",
"POLYGON ((24.680107 56.638191, 26.761488 56.638191, 26.761488 57.313786, 24.680107 57.313786, 24.680107 56.638191))",
"POLYGON ((24.671162 57.313800, 27.130204 57.313800, 27.130204 58.889222, 24.671162 58.889222, 24.671162 57.313800))",
"POLYGON ((24.601794 58.889222, 27.234985 58.889222, 27.234985 59.451415, 24.601794 59.451415, 24.601794 58.889222))",
"POLYGON ((24.686691 59.451427, 26.788327 59.451427, 26.788327 60.143228, 24.686691 60.143228, 24.686691 59.451427))",
"POLYGON ((24.886367 60.143234, 25.108266 60.143234, 25.108266 60.181286, 24.886367 60.181286, 24.886367 60.143234))",
"POLYGON ((24.884461 60.181299, 25.179431 60.181299, 25.179431 60.199882, 24.884461 60.199882, 24.884461 60.181299))",
"POLYGON ((24.696657 60.199882, 25.611915 60.199882, 25.611915 60.217775, 24.696657 60.217775, 24.696657 60.199882))",
"POLYGON ((24.819605 60.217776, 25.611617 60.217776, 25.611617 60.250902, 24.819605 60.250902, 24.819605 60.217776))",
"POLYGON ((24.835583 60.250902, 26.300431 60.250902, 26.300431 60.431600, 24.835583 60.431600, 24.835583 60.250902))",
"POLYGON ((24.848990 60.431620, 27.250802 60.431620, 27.250802 60.667628, 24.848990 60.667628, 24.848990 60.431620))",
"POLYGON ((25.918017 60.667628, 27.005938 60.667628, 27.005938 60.873151, 25.918017 60.873151, 25.918017 60.667628))",
"POLYGON ((26.198926 60.873157, 27.105673 60.873157, 27.105673 61.062546, 26.198926 61.062546, 26.198926 60.873157))",
"POLYGON ((24.710821 61.062553, 27.272476 61.062553, 27.272476 70.790187, 24.710821 70.790187, 24.710821 61.062553))",
"POLYGON ((27.431326 -30.524443, 28.692902 -30.524443, 28.692902 -30.116751, 27.431326 -30.116751, 27.431326 -30.524443))",
"POLYGON ((27.689996 -30.116750, 28.779254 -30.116750, 28.779254 -29.935453, 27.689996 -29.935453, 27.689996 -30.116750))",
"POLYGON ((28.170028 -29.935452, 28.212667 -29.935452, 28.212667 -29.926985, 28.170028 -29.926985, 28.170028 -29.935452))",
"POLYGON ((27.520711 -29.926984, 28.846604 -29.926984, 28.846604 -29.571309, 27.520711 -29.571309, 27.520711 -29.926984))",
"POLYGON ((28.384831 -29.571308, 29.154102 -29.571308, 29.154102 -29.511724, 28.384831 -29.511724, 28.384831 -29.571308))",
"POLYGON ((27.493391 -29.511719, 29.034483 -29.511719, 29.034483 -29.445752, 27.493391 -29.445752, 27.493391 -29.511719))",
"POLYGON ((27.447004 -29.445749, 29.337394 -29.445749, 29.337394 -29.378436, 27.447004 -29.378436, 27.447004 -29.445749))",
"POLYGON ((27.447242 -29.378433, 29.515284 -29.378433, 29.515284 -29.300359, 27.447242 -29.300359, 27.447242 -29.378433))",
"POLYGON ((27.480710 -29.300359, 29.139486 -29.300359, 29.139486 -29.173762, 27.480710 -29.173762, 27.480710 -29.300359))",
"POLYGON ((27.732203 -29.173753, 28.116098 -29.173753, 28.116098 -29.115937, 27.732203 -29.115937, 27.732203 -29.173753))",
"POLYGON ((27.804043 -29.115936, 28.345865 -29.115936, 28.345865 -28.967156, 27.804043 -28.967156, 27.804043 -29.115936))",
"POLYGON ((27.994521 -28.966875, 28.989551 -28.966875, 28.989551 -28.949198, 27.994521 -28.949198, 27.994521 -28.966875))",
"POLYGON ((27.898663 -28.949130, 29.002098 -28.949130, 29.002098 -28.939015, 27.898663 -28.939015, 27.898663 -28.949130))",
"POLYGON ((27.896343 -28.938983, 28.627364 -28.938983, 28.627364 -28.928249, 27.896343 -28.928249, 27.896343 -28.938983))",
"POLYGON ((27.883286 -28.928247, 28.630494 -28.928247, 28.630494 -28.900498, 27.883286 -28.900498, 27.883286 -28.928247))",
"POLYGON ((27.890806 -28.900496, 28.806686 -28.900496, 28.806686 -28.869899, 27.890806 -28.869899, 27.890806 -28.900496))",
"POLYGON ((28.039735 -28.869896, 28.466800 -28.869896, 28.466800 -28.800103, 28.039735 -28.800103, 28.039735 -28.869896))",
"POLYGON ((28.151849 -28.800103, 28.443791 -28.800103, 28.443791 -28.773841, 28.151849 -28.773841, 28.151849 -28.800103))",
"POLYGON ((28.103038 -28.773838, 28.538605 -28.773838, 28.538605 -28.756290, 28.103038 -28.756290, 28.103038 -28.773838))",
"POLYGON ((28.155289 -28.756284, 28.523349 -28.756284, 28.523349 -28.729399, 28.155289 -28.729399, 28.155289 -28.756284))",
"POLYGON ((28.164440 -28.729396, 28.441179 -28.729396, 28.441179 -28.703450, 28.164440 -28.703450, 28.164440 -28.729396))",
"POLYGON ((28.335758 -28.703372, 28.578906 -28.703372, 28.578906 -28.611581, 28.335758 -28.611581, 28.335758 -28.703372))",
"POLYGON ((28.478001 -28.611580, 28.681731 -28.611580, 28.681731 -28.585122, 28.478001 -28.585122, 28.478001 -28.611580))",
"POLYGON ((27.749567 -28.585122, 30.343323 -28.585122, 30.343323 -25.626090, 27.749567 -25.626090, 27.749567 -28.585122))",
"POLYGON ((27.442064 -25.626067, 30.343953 -25.626067, 30.343953 -2.883431, 27.442064 -2.883431, 27.442064 -25.626067))",
"POLYGON ((27.596374 -2.883420, 30.332597 -2.883420, 30.332597 30.995208, 27.596374 30.995208, 27.596374 -2.883420))",
"POLYGON ((27.725715 30.996098, 29.917464 30.996098, 29.917464 36.200025, 27.725715 36.200025, 27.725715 30.996098))",
"POLYGON ((27.808712 36.200067, 30.268430 36.200067, 30.268430 36.424135, 27.808712 36.424135, 27.808712 36.200067))",
"POLYGON ((28.210113 36.425000, 28.233171 36.425000, 28.233171 36.443008, 28.210113 36.443008, 28.210113 36.425000))",
"POLYGON ((28.213001 36.443010, 28.233036 36.443010, 28.233036 36.446364, 28.213001 36.446364, 28.213001 36.443010))",
"POLYGON ((28.013855 36.446369, 30.061224 36.446369, 30.061224 36.745338, 28.013855 36.745338, 28.013855 36.446369))",
"POLYGON ((27.540464 36.745463, 30.059977 36.745463, 30.059977 37.355603, 27.540464 37.355603, 27.540464 36.745463))",
"POLYGON ((27.556471 37.355621, 30.167691 37.355621, 30.167691 37.734086, 27.556471 37.734086, 27.556471 37.355621))",
"POLYGON ((27.524490 37.734404, 29.666175 37.734404, 29.666175 37.921849, 27.524490 37.921849, 27.524490 37.734404))",
"POLYGON ((27.471928 37.921849, 30.170033 37.921849, 30.170033 38.359228, 27.471928 38.359228, 27.471928 37.921849))",
"POLYGON ((27.996524 38.359247, 30.225152 38.359247, 30.225152 38.557186, 27.996524 38.557186, 27.996524 38.359247))",
"POLYGON ((27.982010 38.557208, 29.964717 38.557208, 29.964717 38.584194, 27.982010 38.584194, 27.982010 38.557208))",
"POLYGON ((27.434156 38.584222, 30.321137 38.584222, 30.321137 40.112994, 27.434156 40.112994, 27.434156 38.584222))",
"POLYGON ((27.855157 40.113020, 30.161242 40.113020, 30.161242 40.997153, 27.855157 40.997153, 27.855157 40.113020))",
"POLYGON ((27.686260 40.997155, 28.982698 40.997155, 28.982698 41.030165, 27.686260 41.030165, 27.686260 40.997155))",
"POLYGON ((27.446167 41.030170, 29.067305 41.030170, 29.067305 43.173793, 27.446167 43.173793, 27.446167 41.030170))",
"POLYGON ((27.446794 43.174165, 28.467081 43.174165, 28.467081 43.426925, 27.446794 43.426925, 27.446794 43.174165))",
"POLYGON ((27.591410 43.426927, 28.547735 43.426927, 28.547735 43.883769, 27.591410 43.883769, 27.591410 43.426927))",
"POLYGON ((27.805640 43.883782, 28.776164 43.883782, 28.776164 44.547306, 27.805640 44.547306, 27.805640 43.883782))",
"POLYGON ((27.764726 44.547307, 30.349888 44.547307, 30.349888 46.832926, 27.764726 46.832926, 27.764726 44.547307))",
"POLYGON ((27.565009 46.832966, 29.609395 46.832966, 29.609395 47.308116, 27.565009 47.308116, 27.565009 46.832966))",
"POLYGON ((27.575508 47.308148, 29.992029 47.308148, 29.992029 49.396718, 27.575508 49.396718, 27.575508 47.308148))",
"POLYGON ((27.501064 49.396771, 29.946407 49.396771, 29.946407 50.179234, 27.501064 50.179234, 27.501064 49.396771))",
"POLYGON ((27.463893 50.179235, 30.350366 50.179235, 30.350366 52.887856, 27.463893 52.887856, 27.463893 50.179235))",
"POLYGON ((27.484034 52.887926, 30.259621 52.887926, 30.259621 53.853649, 27.484034 53.853649, 27.484034 52.887926))",
"POLYGON ((27.427922 53.853684, 30.315181 53.853684, 30.315181 55.045028, 27.427922 55.045028, 27.427922 53.853684))",
"POLYGON ((27.467799 55.045036, 30.252571 55.045036, 30.252571 56.199947, 27.467799 56.199947, 27.467799 55.045036))",
"POLYGON ((27.859707 56.200077, 30.194674 56.200077, 30.194674 57.709567, 27.859707 57.709567, 27.859707 56.200077))",
"POLYGON ((27.612480 57.709569, 29.566505 57.709569, 29.566505 57.814074, 27.612480 57.814074, 27.612480 57.709569))",
"POLYGON ((28.189891 57.814076, 30.248228 57.814076, 30.248228 58.507317, 28.189891 58.507317, 28.189891 57.814076))",
"POLYGON ((27.532882 58.507329, 30.338429 58.507329, 30.338429 59.700156, 27.532882 59.700156, 27.532882 58.507329))",
"POLYGON ((29.615373 59.700163, 30.352090 59.700163, 30.352090 59.949302, 29.615373 59.949302, 29.615373 59.700163))",
"POLYGON ((27.852950 59.949505, 30.340344 59.949505, 30.340344 60.020859, 27.852950 60.020859, 27.852950 59.949505))",
"POLYGON ((29.256713 60.020859, 30.289945 60.020859, 30.289945 60.175061, 29.256713 60.175061, 29.256713 60.020859))",
"POLYGON ((27.461209 60.175126, 30.300523 60.175126, 30.300523 60.509326, 27.461209 60.509326, 27.461209 60.175126))",
"POLYGON ((27.683509 60.509333, 30.300557 60.509333, 30.300557 60.697810, 27.683509 60.697810, 27.683509 60.509333))",
"POLYGON ((27.680094 60.698431, 28.815418 60.698431, 28.815418 60.786788, 27.680094 60.786788, 27.680094 60.698431))",
"POLYGON ((27.556005 60.802897, 30.299923 60.802897, 30.299923 69.715851, 27.556005 69.715851, 27.556005 60.802897))",
"POLYGON ((30.433578 -27.524253, 35.173812 -27.524253, 35.173812 -16.506949, 30.433578 -16.506949, 30.433578 -27.524253))",
"POLYGON ((34.903758 -16.506903, 35.161487 -16.506903, 35.161487 -16.283241, 34.903758 -16.283241, 34.903758 -16.506903))",
"POLYGON ((30.505891 -16.283239, 35.043644 -16.283239, 35.043644 18.409492, 30.505891 18.409492, 30.505891 -16.283239))",
"POLYGON ((31.820570 18.528196, 32.518300 18.528196, 32.518300 19.094550, 31.820570 19.094550, 31.820570 18.528196))",
"POLYGON ((30.407729 19.095050, 33.808569 19.095050, 33.808569 21.869534, 30.407729 21.869534, 30.407729 19.095050))",
"POLYGON ((30.676124 21.869574, 34.962532 21.869574, 34.962532 24.979294, 30.676124 24.979294, 30.676124 21.869574))",
"POLYGON ((30.506963 24.979294, 34.078065 24.979294, 34.078065 25.721393, 30.506963 25.721393, 30.506963 24.979294))",
"POLYGON ((30.421742 25.721462, 32.662544 25.721462, 32.662544 25.737950, 30.421742 25.737950, 30.421742 25.721462))",
"POLYGON ((30.615712 25.737950, 33.395120 25.737950, 33.395120 25.929639, 30.615712 25.929639, 30.615712 25.737950))",
"POLYGON ((30.887391 25.929644, 34.316564 25.929644, 34.316564 27.848711, 30.887391 27.848711, 30.887391 25.929644))",
"POLYGON ((30.417938 27.848726, 35.002351 27.848726, 35.002351 29.893992, 30.417938 29.893992, 30.417938 27.848726))",
"POLYGON ((31.126585 29.893992, 31.204004 29.893992, 31.204004 29.975634, 31.126585 29.975634, 31.126585 29.893992))",
"POLYGON ((31.123533 29.975637, 31.140567 29.975637, 31.140567 29.978676, 31.123533 29.978676, 31.123533 29.975637))",
"POLYGON ((31.125120 29.978681, 31.139310 29.978681, 31.139310 29.980112, 31.125120 29.980112, 31.125120 29.978681))",
"POLYGON ((31.073738 29.980118, 34.977719 29.980118, 34.977719 30.045755, 31.073738 30.045755, 31.073738 29.980118))",
"POLYGON ((30.361422 30.045837, 35.117302 30.045837, 35.117302 31.043183, 30.361422 31.043183, 30.361422 30.045837))",
"POLYGON ((30.389013 31.043357, 35.217517 31.043357, 35.217517 31.773874, 30.389013 31.773874, 30.389013 31.043357))",
"POLYGON ((34.621323 31.773905, 35.221671 31.773905, 35.221671 32.218335, 34.621323 32.218335, 34.621323 31.773905))",
"POLYGON ((32.608664 32.218690, 35.215685 32.218690, 35.215685 34.685646, 32.608664 34.685646, 32.608664 32.218690))",
"POLYGON ((32.320286 34.685649, 33.637925 34.685649, 33.637925 34.910515, 32.320286 34.910515, 32.320286 34.685649))",
"POLYGON ((32.784038 34.910556, 34.007344 34.910556, 34.007344 35.172687, 32.784038 35.172687, 32.784038 34.910556))",
"POLYGON ((32.800976 35.173802, 34.225416 35.173802, 34.225416 36.144277, 32.800976 36.144277, 32.800976 35.173802))",
"POLYGON ((30.470566 36.144293, 34.241436 36.144293, 34.241436 36.579988, 30.470566 36.579988, 30.470566 36.144293))",
"POLYGON ((30.441383 36.579988, 34.663467 36.579988, 34.663467 36.826952, 30.441383 36.826952, 30.441383 36.579988))",
"POLYGON ((30.703535 36.833922, 34.897266 36.833922, 34.897266 36.967338, 30.703535 36.967338, 30.703535 36.833922))",
"POLYGON ((30.457303 36.967364, 34.780845 36.967364, 34.780845 37.438465, 30.457303 37.438465, 30.457303 36.967364))",
"POLYGON ((30.511210 37.438492, 34.673564 37.438492, 34.673564 37.678712, 30.511210 37.678712, 30.511210 37.438492))",
"POLYGON ((30.471528 37.678712, 35.042133 37.678712, 35.042133 38.187346, 30.471528 38.187346, 30.471528 37.678712))",
"POLYGON ((30.503566 38.187429, 35.211845 38.187429, 35.211845 38.355824, 30.503566 38.355824, 30.503566 38.187429))",
"POLYGON ((30.539727 38.370309, 35.087070 38.370309, 35.087070 38.538218, 30.539727 38.538218, 30.539727 38.370309))",
"POLYGON ((30.531144 38.538286, 35.194482 38.538286, 35.194482 38.781831, 30.531144 38.781831, 30.531144 38.538286))",
"POLYGON ((30.572518 38.781831, 35.154832 38.781831, 35.154832 39.006466, 30.572518 39.006466, 30.572518 38.781831))",
"POLYGON ((30.391732 39.006495, 35.206292 39.006495, 35.206292 39.199152, 30.391732 39.199152, 30.391732 39.006495))",
"POLYGON ((30.786000 39.199180, 35.225217 39.199180, 35.225217 39.510937, 30.786000 39.510937, 30.786000 39.199180))",
"POLYGON ((30.394157 39.511230, 35.208596 39.511230, 35.208596 39.709804, 30.394157 39.709804, 30.394157 39.511230))",
"POLYGON ((30.396272 39.709894, 35.139044 39.709894, 35.139044 39.917912, 30.396272 39.917912, 30.396272 39.709894))",
"POLYGON ((30.806999 39.918161, 35.105978 39.918161, 35.105978 40.513017, 30.806999 40.513017, 30.806999 39.918161))",
"POLYGON ((31.147634 40.513045, 35.225294 40.513045, 35.225294 41.025861, 31.147634 41.025861, 31.147634 40.513045))",
"POLYGON ((31.420271 41.025884, 35.150952 41.025884, 35.150952 42.026152, 31.420271 42.026152, 31.420271 41.025884))",
"POLYGON ((33.553272 42.026168, 35.150851 42.026168, 35.150851 44.503891, 33.553272 44.503891, 33.553272 42.026168))",
"POLYGON ((33.404210 44.503891, 34.368889 44.503891, 34.368889 44.609757, 33.404210 44.609757, 33.404210 44.503891))",
"POLYGON ((33.485132 44.609780, 34.962330 44.609780, 34.962330 44.843154, 33.485132 44.843154, 33.485132 44.609780))",
"POLYGON ((30.377782 44.843158, 35.148841 44.843158, 35.148841 46.147000, 30.377782 46.147000, 30.377782 44.843158))",
"POLYGON ((30.355657 46.147045, 33.871020 46.147045, 33.871020 46.968208, 30.355657 46.968208, 30.355657 46.147045))",
"POLYGON ((31.924967 46.968243, 35.131037 46.968243, 35.131037 47.635696, 31.924967 47.635696, 31.924967 46.968243))",
"POLYGON ((32.477797 47.635781, 34.960776 47.635781, 34.960776 47.777375, 32.477797 47.777375, 32.477797 47.635781))",
"POLYGON ((31.110699 47.777375, 35.113939 47.777375, 35.113939 47.857155, 31.110699 47.857155, 31.110699 47.777375))",
"POLYGON ((31.084172 47.857155, 34.863330 47.857155, 34.863330 47.988848, 31.084172 47.988848, 31.084172 47.857155))",
"POLYGON ((30.857878 47.992443, 35.186830 47.992443, 35.186830 48.238879, 30.857878 48.238879, 30.857878 47.992443))",
"POLYGON ((30.463142 48.238898, 35.188072 48.238898, 35.188072 48.430112, 30.463142 48.430112, 30.463142 48.238898))",
"POLYGON ((31.095975 48.430182, 35.171034 48.430182, 35.171034 48.500046, 31.095975 48.500046, 31.095975 48.430182))",
"POLYGON ((32.135444 48.500057, 35.225590 48.500057, 35.225590 48.651863, 32.135444 48.651863, 32.135444 48.500057))",
"POLYGON ((30.353449 48.651863, 35.159942 48.651863, 35.159942 49.067626, 30.353449 49.067626, 30.353449 48.651863))",
"POLYGON ((30.831608 49.067684, 35.171482 49.067684, 35.171482 49.656482, 30.831608 49.656482, 30.831608 49.067684))",
"POLYGON ((30.396823 49.656482, 35.225543 49.656482, 35.225543 50.447143, 30.396823 50.447143, 30.396823 49.656482))",
"POLYGON ((30.353891 50.447868, 34.969836 50.447868, 34.969836 51.343016, 30.353891 51.343016, 30.353891 50.447868))",
"POLYGON ((30.398185 51.343030, 34.944677 51.343030, 34.944677 53.244805, 30.398185 53.244805, 30.398185 51.343030))",
"POLYGON ((30.388372 53.244817, 34.449556 53.244817, 34.449556 54.432899, 30.388372 54.432899, 30.388372 53.244817))",
"POLYGON ((30.418251 54.432956, 35.153961 54.432956, 35.153961 58.385684, 30.418251 58.385684, 30.418251 54.432956))",
"POLYGON ((30.363181 58.385703, 35.224670 58.385703, 35.224670 59.685763, 30.363181 59.685763, 30.363181 58.385703))",
"POLYGON ((30.352200 59.685763, 32.898352 59.685763, 32.898352 59.953880, 30.352200 59.953880, 30.352200 59.685763))",
"POLYGON ((30.357420 59.953891, 35.173244 59.953891, 35.173244 61.781703, 30.357420 61.781703, 30.357420 59.953891))",
"POLYGON ((30.467749 61.781757, 34.796270 61.781757, 34.796270 69.767851, 30.467749 69.767851, 30.467749 61.781757))",
"POLYGON ((35.726610 -16.097995, 39.189924 -16.097995, 39.189924 12.609398, 35.726610 12.609398, 35.726610 -16.097995))",
"POLYGON ((35.301578 12.609400, 39.218831 12.609400, 39.218831 24.853158, 35.301578 24.853158, 35.301578 12.609400))",
"POLYGON ((35.339911 24.853186, 39.127746 24.853186, 39.127746 30.530891, 35.339911 30.530891, 35.339911 24.853186))",
"POLYGON ((35.326413 30.530924, 36.811149 30.530924, 36.811149 31.321261, 35.326413 31.321261, 35.326413 30.530924))",
"POLYGON ((35.225701 31.322526, 36.463048 31.322526, 36.463048 31.781384, 35.225701 31.781384, 35.225701 31.322526))",
"POLYGON ((35.227172 31.781391, 37.596277 31.781391, 37.596277 32.282923, 35.227172 32.282923, 35.227172 31.781391))",
"POLYGON ((35.235993 32.283222, 37.595642 32.283222, 37.595642 32.751374, 35.235993 32.751374, 35.235993 32.283222))",
"POLYGON ((35.225683 32.751434, 36.448576 32.751434, 36.448576 33.045595, 35.225683 33.045595, 35.225683 32.751434))",
"POLYGON ((35.484759 33.045595, 36.306969 33.045595, 36.306969 33.512661, 35.484759 33.512661, 35.484759 33.045595))",
"POLYGON ((35.370573 33.512681, 38.274917 33.512681, 38.274917 34.547480, 35.370573 34.547480, 35.370573 33.512681))",
"POLYGON ((35.901002 34.547486, 39.073245 34.547486, 39.073245 35.190302, 35.901002 35.190302, 35.901002 34.547486))",
"POLYGON ((35.934202 35.190416, 37.159763 35.190416, 37.159763 35.442692, 35.934202 35.442692, 35.934202 35.190416))",
"POLYGON ((35.731466 35.442797, 37.498070 35.442797, 37.498070 35.775921, 35.731466 35.775921, 35.731466 35.442797))",
"POLYGON ((35.992824 35.776132, 39.095634 35.776132, 39.095634 36.268679, 35.992824 36.268679, 35.992824 35.776132))",
"POLYGON ((35.346510 36.268731, 37.528786 36.268731, 37.528786 36.564798, 35.346510 36.564798, 35.346510 36.268731))",
"POLYGON ((35.796978 36.564798, 39.139250 36.564798, 39.139250 36.781435, 35.796978 36.781435, 35.796978 36.564798))",
"POLYGON ((36.155687 36.781449, 39.220313 36.781449, 39.220313 36.853869, 36.155687 36.853869, 36.155687 36.781449))",
"POLYGON ((35.623299 36.853891, 39.125956 36.853891, 39.125956 36.963927, 35.623299 36.963927, 35.623299 36.853891))",
"POLYGON ((35.327537 36.964021, 39.122851 36.964021, 39.122851 37.102678, 35.327537 37.102678, 35.327537 36.964021))",
"POLYGON ((35.272593 37.102820, 38.923706 37.102820, 38.923706 37.231002, 35.272593 37.231002, 35.272593 37.102820))",
"POLYGON ((35.272685 37.231454, 38.789781 37.231454, 38.789781 37.538077, 35.272685 37.538077, 35.272685 37.231454))",
"POLYGON ((35.864912 37.538083, 39.183945 37.538083, 39.183945 38.025937, 35.864912 38.025937, 35.864912 37.538083))",
"POLYGON ((35.361618 38.026003, 38.319775 38.026003, 38.319775 38.221070, 35.361618 38.221070, 35.361618 38.026003))",
"POLYGON ((35.381178 38.221309, 38.816950 38.221309, 38.816950 38.452167, 35.381178 38.452167, 35.381178 38.221309))",
"POLYGON ((35.279118 38.452247, 39.113087 38.452247, 39.113087 38.720872, 35.279118 38.720872, 35.279118 38.452247))",
"POLYGON ((35.320060 38.720919, 39.078337 38.720919, 39.078337 38.914400, 35.320060 38.914400, 35.320060 38.720919))",
"POLYGON ((35.361693 38.914547, 39.170152 38.914547, 39.170152 39.074564, 35.361693 39.074564, 35.361693 38.914547))",
"POLYGON ((35.360340 39.074783, 39.221027 39.074783, 39.221027 39.264463, 35.360340 39.264463, 35.360340 39.074783))",
"POLYGON ((35.434490 39.264724, 39.211771 39.264724, 39.211771 39.439028, 35.434490 39.439028, 35.434490 39.264724))",
"POLYGON ((35.258776 39.439227, 38.866282 39.439227, 38.866282 39.605197, 35.258776 39.605197, 35.258776 39.439227))",
"POLYGON ((35.258765 39.605364, 38.145728 39.605364, 38.145728 39.730238, 35.258765 39.730238, 35.258765 39.605364))",
"POLYGON ((35.891597 39.730278, 38.246381 39.730278, 38.246381 39.811996, 35.891597 39.811996, 35.891597 39.730278))",
"POLYGON ((35.230303 39.812094, 38.985331 39.812094, 38.985331 40.148928, 35.230303 40.148928, 35.230303 39.812094))",
"POLYGON ((35.234779 40.148939, 39.070390 40.148939, 39.070390 40.464886, 35.234779 40.464886, 35.234779 40.148939))",
"POLYGON ((35.225877 40.465190, 39.185800 40.465190, 39.185800 45.021711, 35.225877 45.021711, 35.225877 40.465190))",
"POLYGON ((35.278955 45.021750, 39.190610 45.021750, 39.190610 45.312960, 35.278955 45.312960, 35.278955 45.021750))",
"POLYGON ((35.369916 45.313031, 39.173990 45.313031, 39.173990 46.941165, 35.369916 46.941165, 35.369916 45.313031))",
"POLYGON ((35.251237 46.941165, 39.076393 46.941165, 39.076393 47.784528, 35.251237 47.784528, 35.251237 46.941165))",
"POLYGON ((35.364102 47.784528, 38.911900 47.784528, 38.911900 48.138081, 35.364102 48.138081, 35.364102 47.784528))",
"POLYGON ((35.251124 48.138153, 38.926114 48.138153, 38.926114 48.638793, 35.251124 48.638793, 35.251124 48.138153))",
"POLYGON ((35.317351 48.638799, 38.576558 48.638799, 38.576558 49.441606, 35.317351 49.441606, 35.317351 48.638799))",
"POLYGON ((35.331781 49.441698, 37.612580 49.441698, 37.612580 50.044203, 35.331781 50.044203, 35.331781 49.441698))",
"POLYGON ((35.225871 50.044205, 39.131424 50.044205, 39.131424 51.358530, 35.225871 51.358530, 35.225871 50.044205))",
"POLYGON ((35.258063 51.358567, 39.214729 51.358567, 39.214729 51.657859, 35.258063 51.657859, 35.258063 51.358567))",
"POLYGON ((36.065662 51.657859, 39.215749 51.657859, 39.215749 51.663397, 36.065662 51.663397, 36.065662 51.657859))",
"POLYGON ((35.287568 51.663398, 39.215581 51.663398, 39.215581 51.669944, 35.287568 51.669944, 35.287568 51.663398))",
"POLYGON ((38.935367 51.669951, 39.222109 51.669951, 39.222109 51.674029, 38.935367 51.674029, 38.935367 51.669951))",
"POLYGON ((38.933566 51.674043, 39.224454 51.674043, 39.224454 51.680736, 38.933566 51.680736, 38.933566 51.674043))",
"POLYGON ((35.293052 51.680832, 39.224500 51.680832, 39.224500 51.844218, 35.293052 51.844218, 35.293052 51.680832))",
"POLYGON ((35.226129 51.844222, 39.214623 51.844222, 39.214623 54.075719, 35.226129 54.075719, 35.226129 51.844222))",
"POLYGON ((36.033932 54.075881, 39.194178 54.075881, 39.194178 54.506887, 36.033932 54.506887, 36.033932 54.075881))",
"POLYGON ((36.236311 54.506895, 36.263291 54.506895, 36.263291 54.511869, 36.236311 54.511869, 36.236311 54.506895))",
"POLYGON ((35.308884 54.511872, 39.125095 54.511872, 39.125095 54.742362, 35.308884 54.742362, 35.308884 54.511872))",
"POLYGON ((35.232106 54.742696, 39.036916 54.742696, 39.036916 55.360674, 35.232106 55.360674, 35.232106 54.742696))",
"POLYGON ((35.512061 55.360693, 39.109601 55.360693, 39.109601 55.597700, 35.512061 55.597700, 35.512061 55.360693))",
"POLYGON ((36.206804 55.597713, 38.958621 55.597713, 38.958621 55.710684, 36.206804 55.710684, 36.206804 55.597713))",
"POLYGON ((35.775301 55.710693, 38.957143 55.710693, 38.957143 55.753069, 35.775301 55.753069, 35.775301 55.710693))",
"POLYGON ((37.551593 55.753111, 38.932683 55.753111, 38.932683 55.795795, 37.551593 55.795795, 37.551593 55.753111))",
"POLYGON ((36.133399 55.795801, 39.091515 55.795801, 39.091515 55.906702, 36.133399 55.906702, 36.133399 55.795801))",
"POLYGON ((35.571504 55.906887, 39.212385 55.906887, 39.212385 56.129890, 35.571504 56.129890, 35.571504 55.906887))",
"POLYGON ((35.824316 56.132470, 39.199301 56.132470, 39.199301 56.455241, 35.824316 56.455241, 35.824316 56.132470))",
"POLYGON ((35.437110 56.455245, 39.136091 56.455245, 39.136091 58.163668, 35.437110 58.163668, 35.437110 56.455245))",
"POLYGON ((35.230942 58.163668, 39.204753 58.163668, 39.204753 65.117789, 35.230942 65.117789, 35.230942 58.163668))",
"POLYGON ((39.380588 -24.218878, 46.306104 -24.218878, 46.306104 15.421135, 39.380588 15.421135, 39.380588 -24.218878))",
"POLYGON ((39.339894 15.421166, 45.338457 15.421166, 45.338457 22.156817, 39.339894 22.156817, 39.339894 15.421166))",
"POLYGON ((39.353455 22.156818, 46.772824 22.156818, 46.772824 25.868853, 39.353455 25.868853, 39.353455 22.156818))",
"POLYGON ((43.601282 25.868885, 46.190239 25.868885, 46.190239 33.309719, 43.601282 33.309719, 43.601282 25.868885))",
"POLYGON ((40.886556 33.310060, 46.784065 33.310060, 46.784065 34.547919, 40.886556 34.547919, 40.886556 33.310060))",
"POLYGON ((39.817983 34.547919, 46.627961 34.547919, 46.627961 36.083315, 39.817983 36.083315, 39.817983 34.547919))",
"POLYGON ((39.557056 36.083367, 46.772880 36.083367, 46.772880 36.747499, 39.557056 36.747499, 39.557056 36.083367))",
"POLYGON ((39.289814 36.747628, 41.589480 36.747628, 41.589480 36.939861, 39.289814 36.939861, 39.289814 36.747628))",
"POLYGON ((39.337090 36.939962, 45.461179 36.939962, 45.461179 37.133306, 39.337090 37.133306, 39.337090 36.939962))",
"POLYGON ((39.470089 37.133398, 44.793921 37.133398, 44.793921 37.229552, 39.470089 37.229552, 39.470089 37.133398))",
"POLYGON ((39.655576 37.229616, 46.047620 37.229616, 46.047620 37.352112, 39.655576 37.352112, 39.655576 37.229616))",
"POLYGON ((39.245234 37.352406, 44.399516 37.352406, 44.399516 37.447420, 39.245234 37.447420, 39.245234 37.352406))",
"POLYGON ((39.435774 37.447561, 43.383601 37.447561, 43.383601 37.514963, 39.435774 37.514963, 39.435774 37.447561))",
"POLYGON ((39.269526 37.514979, 45.076575 37.514979, 45.076575 37.608872, 39.269526 37.608872, 39.269526 37.514979))",
"POLYGON ((39.299969 37.609153, 43.857840 37.609153, 43.857840 37.721976, 39.299969 37.721976, 39.299969 37.609153))",
"POLYGON ((39.315525 37.722026, 46.151791 37.722026, 46.151791 37.777772, 39.315525 37.777772, 39.315525 37.722026))",
"POLYGON ((39.314207 37.777832, 46.231642 37.777832, 46.231642 37.839397, 39.314207 37.839397, 39.314207 37.777832))",
"POLYGON ((39.314517 37.839423, 46.583097 37.839423, 46.583097 37.970083, 39.314517 37.970083, 39.314517 37.839423))",
"POLYGON ((39.224958 37.970083, 46.583862 37.970083, 46.583862 38.176391, 39.224958 38.176391, 39.224958 37.970083))",
"POLYGON ((39.269574 38.176427, 45.835462 38.176427, 45.835462 38.344784, 39.269574 38.344784, 39.269574 38.176427))",
"POLYGON ((39.428181 38.344813, 44.168818 38.344813, 44.168818 38.502925, 39.428181 38.502925, 39.428181 38.344813))",
"POLYGON ((39.247063 38.503022, 46.080709 38.503022, 46.080709 38.791511, 39.247063 38.791511, 39.247063 38.503022))",
"POLYGON ((39.258486 38.791551, 46.245849 38.791551, 46.245849 39.006125, 39.258486 39.006125, 39.258486 38.791551))",
"POLYGON ((39.231027 39.006394, 46.264590 39.006394, 46.264590 39.209208, 39.231027 39.209208, 39.231027 39.006394))",
"POLYGON ((39.230927 39.209251, 43.973171 39.209251, 43.973171 39.347352, 39.230927 39.347352, 39.230927 39.209251))",
"POLYGON ((39.384639 39.347651, 44.286925 39.347651, 44.286925 39.522230, 39.384639 39.522230, 39.384639 39.347651))",
"POLYGON ((39.409891 39.522239, 45.636912 39.522239, 45.636912 39.697708, 39.409891 39.697708, 39.409891 39.522239))",
"POLYGON ((39.603075 39.697723, 46.759440 39.697723, 46.759440 39.769725, 39.603075 39.769725, 39.603075 39.697723))",
"POLYGON ((39.627581 39.769732, 46.763848 39.769732, 46.763848 39.901821, 39.627581 39.901821, 39.627581 39.769732))",
"POLYGON ((39.890360 39.901855, 46.832193 39.901855, 46.832193 39.960275, 39.890360 39.960275, 39.890360 39.901855))",
"POLYGON ((39.617794 39.960311, 46.835478 39.960311, 46.835478 40.017732, 39.617794 40.017732, 39.617794 39.960311))",
"POLYGON ((39.455102 40.017935, 46.804654 40.017935, 46.804654 40.120725, 39.455102 40.120725, 39.455102 40.017935))",
"POLYGON ((39.815630 40.120831, 46.668517 40.120831, 46.668517 40.353134, 39.815630 40.353134, 39.815630 40.120831))",
"POLYGON ((39.454511 40.353346, 45.486082 40.353346, 45.486082 40.502741, 39.454511 40.502741, 39.454511 40.353346))",
"POLYGON ((40.727356 40.502875, 46.327598 40.502875, 46.327598 40.580766, 40.727356 40.580766, 40.727356 40.502875))",
"POLYGON ((40.748063 40.580927, 46.432806 40.580927, 46.432806 40.918920, 40.748063 40.918920, 40.748063 40.580927))",
"POLYGON ((40.509786 40.918920, 44.764380 40.918920, 44.764380 41.247039, 40.509786 41.247039, 40.509786 40.918920))",
"POLYGON ((41.571538 41.247228, 45.379331 41.247228, 45.379331 41.539556, 41.571538 41.539556, 41.571538 41.247228))",
"POLYGON ((41.572091 41.539630, 45.022798 41.539630, 45.022798 41.691863, 41.572091 41.691863, 41.572091 41.539630))",
"POLYGON ((41.730572 41.691864, 45.382504 41.691864, 45.382504 41.937658, 41.730572 41.937658, 41.730572 41.691864))",
"POLYGON ((41.670053 41.937673, 45.769829 41.937673, 45.769829 42.833563, 41.670053 42.833563, 41.670053 41.937673))",
"POLYGON ((39.954026 42.833579, 45.754699 42.833579, 45.754699 43.433198, 39.954026 43.433198, 39.954026 42.833579))",
"POLYGON ((39.600467 43.433198, 46.707820 43.433198, 46.707820 44.103870, 39.600467 44.103870, 39.600467 43.433198))",
"POLYGON ((39.291941 44.103910, 43.887949 44.103910, 43.887949 44.988830, 39.291941 44.988830, 39.291941 44.103910))",
"POLYGON ((39.285904 44.988843, 44.469162 44.988843, 44.469162 47.115916, 39.285904 47.115916, 39.285904 44.988843))",
"POLYGON ((39.331847 47.115945, 40.429941 47.115945, 40.429941 47.420772, 39.331847 47.420772, 39.331847 47.115945))",
"POLYGON ((39.306740 47.420772, 45.894969 47.420772, 45.894969 48.802995, 39.306740 48.802995, 39.306740 47.420772))",
"POLYGON ((39.281531 48.803282, 45.586355 48.803282, 45.586355 50.962773, 39.281531 50.962773, 39.281531 48.803282))",
"POLYGON ((39.280766 50.962780, 45.977040 50.962780, 45.977040 51.325178, 39.280766 51.325178, 39.280766 50.962780))",
"POLYGON ((39.300616 51.325198, 46.748683 51.325198, 46.748683 51.542471, 39.300616 51.542471, 39.300616 51.325198))",
"POLYGON ((39.452465 51.542472, 46.057989 51.542472, 46.057989 51.596062, 39.452465 51.596062, 39.452465 51.542472))",
"POLYGON ((39.224509 51.596089, 46.087722 51.596089, 46.087722 52.131658, 39.224509 52.131658, 39.224509 51.596089))",
"POLYGON ((39.579877 52.135280, 46.662209 52.135280, 46.662209 52.643456, 39.579877 52.643456, 39.579877 52.135280))",
"POLYGON ((39.229485 52.643466, 46.688169 52.643466, 46.688169 54.054172, 39.229485 54.054172, 39.229485 52.643466))",
"POLYGON ((39.280652 54.112748, 46.145922 54.112748, 46.145922 54.403584, 39.280652 54.403584, 39.280652 54.112748))",
"POLYGON ((39.334573 54.403652, 46.590369 54.403652, 46.590369 54.989459, 39.334573 54.989459, 39.334573 54.403652))",
"POLYGON ((39.249015 54.989459, 46.159864 54.989459, 46.159864 55.932345, 39.249015 55.932345, 39.249015 54.989459))",
"POLYGON ((39.474060 55.932409, 46.558521 55.932409, 46.558521 56.297432, 39.474060 56.297432, 39.474060 55.932409))",
"POLYGON ((39.809520 56.297476, 44.065343 56.297476, 44.065343 56.440697, 39.809520 56.440697, 39.809520 56.297476))",
"POLYGON ((39.388690 56.440698, 45.092455 56.440698, 45.092455 57.143264, 39.388690 57.143264, 39.388690 56.440698))",
"POLYGON ((39.413927 57.143404, 46.544666 57.143404, 46.544666 57.460997, 39.413927 57.460997, 39.413927 57.143404))",
"POLYGON ((39.481236 57.461005, 45.893227 57.461005, 45.893227 59.229120, 39.481236 59.229120, 39.481236 57.461005))",
"POLYGON ((39.503739 59.229128, 46.626632 59.229128, 46.626632 68.073086, 39.503739 68.073086, 39.503739 59.229128))",
"POLYGON ((46.998602 -49.552309, 73.537436 -49.552309, 73.537436 17.039782, 46.998602 17.039782, 46.998602 -49.552309))",
"POLYGON ((52.743194 17.039803, 73.494938 17.039803, 73.494938 18.722621, 52.743194 18.722621, 52.743194 17.039803))",
"POLYGON ((72.823330 18.722759, 73.496897 18.722759, 73.496897 18.935312, 72.823330 18.935312, 72.823330 18.722759))",
"POLYGON ((72.822048 18.935458, 73.232440 18.935458, 73.232440 19.009245, 72.822048 19.009245, 72.822048 18.935458))",
"POLYGON ((72.731488 19.009256, 73.179264 19.009256, 73.179264 19.465715, 72.731488 19.465715, 72.731488 19.009256))",
"POLYGON ((57.248694 19.465743, 73.518550 19.465743, 73.518550 22.924578, 57.248694 22.924578, 57.248694 19.465743))",
"POLYGON ((56.505284 22.924616, 72.633268 22.924616, 72.633268 23.391368, 56.505284 23.391368, 56.505284 22.924616))",
"POLYGON ((55.684054 23.391372, 72.979311 23.391372, 72.979311 24.244879, 55.684054 24.244879, 55.684054 23.391372))",
"POLYGON ((47.717228 24.244886, 73.590747 24.244886, 73.590747 25.142895, 47.717228 25.142895, 47.717228 24.244886))",
"POLYGON ((73.577778 25.142925, 73.591482 25.142925, 73.591482 25.157999, 73.577778 25.157999, 73.577778 25.142925))",
"POLYGON ((49.576487 25.158000, 73.591353 25.158000, 73.591353 25.709754, 49.576487 25.709754, 49.576487 25.158000))",
"POLYGON ((50.483744 25.709904, 73.024222 25.709904, 73.024222 26.331616, 50.483744 26.331616, 50.483744 25.709904))",
"POLYGON ((66.173500 26.331686, 73.319457 26.331686, 73.319457 28.020572, 66.173500 28.020572, 66.173500 26.331686))",
"POLYGON ((48.088939 28.020575, 73.333778 28.020575, 73.333778 29.614435, 48.088939 29.614435, 48.088939 28.020575))",
"POLYGON ((52.543802 29.614467, 73.099717 29.614467, 73.099717 30.666490, 52.543802 30.666490, 52.543802 29.614467))",
"POLYGON ((48.352707 30.666617, 73.408960 30.666617, 73.408960 33.484186, 48.352707 33.484186, 48.352707 30.666617))",
"POLYGON ((47.044692 33.484192, 73.218757 33.484192, 73.218757 34.534976, 47.044692 34.534976, 47.044692 33.484192))",
"POLYGON ((46.912810 34.534996, 72.029580 34.534996, 72.029580 35.812761, 46.912810 35.812761, 46.912810 34.534996))",
"POLYGON ((47.491783 35.812999, 71.792888 35.812999, 71.792888 36.527041, 47.491783 36.527041, 47.491783 35.812999))",
"POLYGON ((47.232997 36.527041, 72.261395 36.527041, 72.261395 37.024200, 47.232997 37.024200, 47.232997 36.527041))",
"POLYGON ((47.158264 37.024474, 72.871618 37.024474, 72.871618 37.785049, 47.158264 37.785049, 47.158264 37.024474))",
"POLYGON ((57.834201 37.785127, 72.871773 37.785127, 72.871773 37.942392, 57.834201 37.942392, 57.834201 37.785127))",
"POLYGON ((46.979514 37.942405, 71.554241 37.942405, 71.554241 39.072507, 46.979514 39.072507, 46.979514 37.942405))",
"POLYGON ((46.936782 39.072526, 70.176654 39.072526, 70.176654 39.678851, 46.936782 39.678851, 46.936782 39.072526))",
"POLYGON ((46.885687 39.679084, 70.563597 39.679084, 70.563597 39.942828, 46.885687 39.942828, 46.885687 39.679084))",
"POLYGON ((46.852698 39.942849, 46.979679 39.942849, 46.979679 39.954877, 46.852698 39.954877, 46.852698 39.942849))",
"POLYGON ((46.854392 39.954881, 47.028304 39.954881, 47.028304 39.959549, 46.854392 39.959549, 46.854392 39.954881))",
"POLYGON ((46.855645 39.959557, 47.027487 39.959557, 47.027487 39.969470, 46.855645 39.969470, 46.855645 39.959557))",
"POLYGON ((46.857484 39.969490, 47.016436 39.969490, 47.016436 39.976093, 46.857484 39.976093, 46.857484 39.969490))",
"POLYGON ((46.861853 39.976093, 47.015823 39.976093, 47.015823 39.982064, 46.861853 39.982064, 46.861853 39.976093))",
"POLYGON ((46.835714 39.982070, 47.015508 39.982070, 47.015508 39.996722, 46.835714 39.996722, 46.835714 39.982070))",
"POLYGON ((46.835757 39.996835, 46.993375 39.996835, 46.993375 40.011157, 46.835757 40.011157, 46.835757 39.996835))",
"POLYGON ((46.887170 40.011184, 46.999099 40.011184, 46.999099 40.021332, 46.887170 40.021332, 46.887170 40.011184))",
"POLYGON ((46.865819 40.021346, 49.389046 40.021346, 49.389046 40.050458, 46.865819 40.050458, 46.865819 40.021346))",
"POLYGON ((46.874183 40.050491, 49.389643 40.050491, 49.389643 40.073880, 46.874183 40.073880, 46.874183 40.050491))",
"POLYGON ((46.891119 40.073890, 70.811960 40.073890, 70.811960 40.092321, 46.891119 40.092321, 46.891119 40.073890))",
"POLYGON ((46.887107 40.092330, 67.850396 40.092330, 67.850396 40.138621, 46.887107 40.138621, 46.887107 40.092330))",
"POLYGON ((46.838171 40.138630, 70.571342 40.138630, 70.571342 40.345496, 46.838171 40.345496, 46.838171 40.138630))",
"POLYGON ((46.994746 40.345504, 72.805513 40.345504, 72.805513 40.772640, 46.994746 40.772640, 46.994746 40.345504))",
"POLYGON ((47.017064 40.772646, 72.940460 40.772646, 72.940460 41.296984, 47.017064 41.296984, 47.017064 40.772646))",
"POLYGON ((60.356137 41.296984, 69.314704 41.296984, 69.314704 41.377398, 60.356137 41.377398, 60.356137 41.296984))",
"POLYGON ((47.050273 41.377398, 69.273772 41.377398, 69.273772 41.929916, 47.050273 41.929916, 47.050273 41.377398))",
"POLYGON ((47.281192 41.929921, 73.251069 41.929921, 73.251069 42.889588, 47.281192 42.889588, 47.281192 41.929921))",
"POLYGON ((46.845015 42.889597, 69.341509 42.889597, 69.341509 45.739720, 46.845015 45.739720, 46.845015 42.889597))",
"POLYGON ((46.846796 45.741511, 71.389937 45.741511, 71.389937 49.176207, 46.846796 49.176207, 46.846796 45.741511))",
"POLYGON ((46.840908 49.176764, 73.139407 49.176764, 73.139407 52.014087, 46.840908 52.014087, 46.840908 49.176764))",
"POLYGON ((47.443953 52.014298, 69.154289 52.014298, 69.154289 53.124863, 47.443953 53.124863, 47.443953 52.014298))",
"POLYGON ((47.390465 53.124868, 69.266956 53.124868, 69.266956 53.515338, 47.390465 53.515338, 47.390465 53.124868))",
"POLYGON ((48.102919 53.515374, 61.483001 53.515374, 61.483001 54.166146, 48.102919 54.166146, 48.102919 53.515374))",
"POLYGON ((47.689535 54.166437, 72.965367 54.166437, 72.965367 54.290642, 47.689535 54.290642, 47.689535 54.166437))",
"POLYGON ((48.231866 54.290649, 63.578546 54.290649, 63.578546 54.301697, 48.231866 54.301697, 48.231866 54.290649))",
"POLYGON ((47.440405 54.301707, 61.270104 54.301707, 61.270104 54.657354, 47.440405 54.657354, 47.440405 54.301707))",
"POLYGON ((48.843950 54.657473, 73.452044 54.657473, 73.452044 54.982788, 48.843950 54.982788, 48.843950 54.657473))",
"POLYGON ((48.935719 54.982844, 73.500184 54.982844, 73.500184 55.152923, 48.935719 55.152923, 48.935719 54.982844))",
"POLYGON ((47.473879 55.152923, 73.561546 55.152923, 73.561546 55.750985, 47.473879 55.750985, 47.473879 55.152923))",
"POLYGON ((47.241861 55.751024, 70.442841 55.751024, 70.442841 56.151126, 47.241861 56.151126, 47.241861 55.751024))",
"POLYGON ((47.252131 56.151128, 64.630915 56.151128, 64.630915 56.833720, 47.252131 56.833720, 47.252131 56.151128))",
"POLYGON ((48.872146 56.833726, 64.070779 56.833726, 64.070779 57.041723, 48.872146 57.041723, 48.872146 56.833726))",
"POLYGON ((48.047371 57.041741, 67.075013 57.041741, 67.075013 57.674701, 48.047371 57.674701, 48.047371 57.041741))",
"POLYGON ((48.342466 57.674712, 68.271957 57.674712, 68.271957 58.346174, 48.342466 58.346174, 48.342466 57.674712))",
"POLYGON ((48.322521 58.346281, 61.862342 58.346281, 61.862342 59.413439, 48.322521 59.413439, 48.322521 58.346281))",
"POLYGON ((51.255794 59.413444, 56.785935 59.413444, 56.785935 60.155586, 51.255794 60.155586, 51.255794 59.413444))",
"POLYGON ((46.858835 60.155601, 73.396363 60.155601, 73.396363 76.955234, 46.858835 76.955234, 46.858835 60.155601))",
"POLYGON ((98.836391 -3.017962, 104.736189 -3.017962, 104.736189 2.678916, 98.836391 2.678916, 98.836391 -3.017962))",
"POLYGON ((80.217257 2.678934, 101.720188 2.678934, 101.720188 6.123661, 80.217257 6.123661, 80.217257 2.678934))",
"POLYGON ((79.830306 6.123662, 100.507038 6.123662, 100.507038 7.958768, 79.830306 7.958768, 79.830306 6.123662))",
"POLYGON ((76.226054 7.958768, 99.984170 7.958768, 99.984170 10.145240, 76.226054 10.145240, 76.226054 7.958768))",
"POLYGON ((76.205320 10.145249, 104.939056 10.145249, 104.939056 11.562134, 76.205320 11.562134, 76.205320 10.145249))",
"POLYGON ((75.031352 11.562134, 105.439913 11.562134, 105.439913 12.392274, 75.031352 12.392274, 75.031352 11.562134))",
"POLYGON ((74.820811 12.392284, 105.046189 12.392284, 105.046189 12.974266, 74.820811 12.974266, 74.820811 12.392284))",
"POLYGON ((74.995675 12.974266, 104.339674 12.974266, 104.339674 13.211694, 74.995675 13.211694, 74.995675 12.974266))",
"POLYGON ((75.990852 13.211715, 104.749722 13.211715, 104.749722 13.443345, 75.990852 13.443345, 75.990852 13.211715))",
"POLYGON ((74.754903 13.443351, 104.554612 13.443351, 104.554612 13.739115, 74.754903 13.739115, 74.754903 13.443351))",
"POLYGON ((76.912566 13.739124, 104.559289 13.739124, 104.559289 13.773886, 76.912566 13.773886, 76.912566 13.739124))",
"POLYGON ((75.033416 13.773894, 104.557872 13.773894, 104.557872 14.357224, 75.033416 14.357224, 75.033416 13.773894))",
"POLYGON ((75.247205 14.357239, 105.459124 14.357239, 105.459124 15.315127, 75.247205 15.315127, 75.247205 14.357239))",
"POLYGON ((73.734622 15.315148, 78.048955 15.315148, 78.048955 15.949374, 73.734622 15.949374, 73.734622 15.315148))",
"POLYGON ((73.841663 15.949374, 104.768387 15.949374, 104.768387 16.789645, 73.841663 16.789645, 73.841663 15.949374))",
"POLYGON ((74.097555 16.789645, 100.278673 16.789645, 100.278673 16.837706, 74.097555 16.837706, 74.097555 16.789645))",
"POLYGON ((74.564590 16.837713, 100.261802 16.837713, 100.261802 17.018513, 74.564590 17.018513, 74.564590 16.837713))",
"POLYGON ((76.829436 17.018533, 99.841330 17.018533, 99.841330 17.339545, 76.829436 17.339545, 76.829436 17.018533))",
"POLYGON ((76.828666 17.339550, 76.833486 17.339550, 76.833486 17.343161, 76.828666 17.343161, 76.828666 17.339550))",
"POLYGON ((76.828821 17.343162, 99.789173 17.343162, 99.789173 17.468868, 76.828821 17.468868, 76.828821 17.343162))",
"POLYGON ((73.819465 17.468870, 102.772613 17.468870, 102.772613 18.122888, 73.819465 18.122888, 73.819465 17.468870))",
"POLYGON ((73.620755 18.122929, 83.412724 18.122929, 83.412724 18.364831, 73.620755 18.364831, 73.620755 18.122929))",
"POLYGON ((73.750641 18.364852, 100.771803 18.364852, 100.771803 18.780848, 73.750641 18.780848, 73.750641 18.364852))",
"POLYGON ((73.702875 18.780886, 103.208133 18.780886, 103.208133 20.023961, 73.702875 20.023961, 73.702875 18.780886))",
"POLYGON ((74.795785 20.023994, 105.610156 20.023994, 105.610156 21.159953, 74.795785 21.159953, 74.795785 20.023994))",
"POLYGON ((94.854804 21.159953, 94.906479 21.159953, 94.906479 21.171972, 94.854804 21.171972, 94.854804 21.159953))",
"POLYGON ((75.390504 21.172133, 103.842941 21.172133, 103.842941 22.356560, 75.390504 22.356560, 75.390504 21.172133))",
"POLYGON ((75.046457 22.356617, 98.092973 22.356617, 98.092973 23.481214, 75.046457 23.481214, 75.046457 22.356617))",
"POLYGON ((73.639018 23.607865, 102.829808 23.607865, 102.829808 24.627582, 73.639018 24.627582, 73.639018 23.607865))",
"POLYGON ((76.185214 24.627608, 102.706565 24.627608, 102.706565 25.137227, 76.185214 25.137227, 76.185214 24.627608))",
"POLYGON ((73.591484 25.137293, 85.444496 25.137293, 85.444496 25.270926, 73.591484 25.270926, 73.591484 25.137293))",
"POLYGON ((73.824687 25.270937, 104.253328 25.270937, 104.253328 26.021599, 73.824687 26.021599, 73.824687 25.270937))",
"POLYGON ((76.447465 26.021736, 101.713981 26.021736, 101.713981 26.861015, 76.447465 26.861015, 76.447465 26.021736))",
"POLYGON ((74.857210 26.861023, 94.750771 26.861023, 94.750771 27.154235, 74.857210 27.154235, 74.857210 26.861023))",
"POLYGON ((74.856675 27.154239, 78.043693 27.154239, 78.043693 27.217862, 74.856675 27.217862, 74.856675 27.154239))",
"POLYGON ((77.326918 27.217872, 89.637697 27.217872, 89.637697 27.471907, 77.326918 27.471907, 77.326918 27.217872))",
"POLYGON ((77.327912 27.471917, 89.323052 27.471917, 89.323052 27.694849, 77.327912 27.694849, 77.327912 27.471917))",
"POLYGON ((84.434687 27.695029, 86.018094 27.695029, 86.018094 27.774589, 84.434687 27.774589, 84.434687 27.695029))",
"POLYGON ((75.147972 27.774590, 99.707885 27.774590, 99.707885 28.517063, 75.147972 28.517063, 75.147972 27.774590))",
"POLYGON ((77.134395 28.517125, 86.712370 28.517125, 86.712370 28.591093, 77.134395 28.591093, 77.134395 28.517125))",
"POLYGON ((75.619046 28.591097, 91.687101 28.591097, 91.687101 29.401203, 75.619046 29.401203, 75.619046 28.591097))",
"POLYGON ((74.125624 29.401203, 83.073912 29.401203, 83.073912 29.587708, 74.125624 29.587708, 74.125624 29.401203))",
"POLYGON ((73.951591 29.587713, 91.135397 29.587713, 91.135397 31.102662, 73.951591 31.102662, 73.951591 29.587713))",
"POLYGON ((73.640838 31.102670, 100.453592 31.102670, 100.453592 34.062984, 73.640838 34.062984, 73.640838 31.102670))",
"POLYGON ((74.976650 34.071087, 104.701523 34.071087, 104.701523 37.133331, 74.976650 37.133331, 74.976650 34.071087))",
"POLYGON ((99.863002 37.133331, 105.569675 37.133331, 105.569675 37.575309, 99.863002 37.575309, 99.863002 37.133331))",
"POLYGON ((101.231502 37.575309, 105.781807 37.575309, 105.781807 37.794115, 101.231502 37.794115, 101.231502 37.575309))",
"POLYGON ((77.542528 37.794115, 105.781394 37.794115, 105.781394 38.208902, 77.542528 38.208902, 77.542528 37.794115))",
"POLYGON ((100.800181 38.209003, 105.780547 38.209003, 105.780547 38.346028, 100.800181 38.346028, 100.800181 38.209003))",
"POLYGON ((73.922172 38.347439, 105.734562 38.347439, 105.734562 39.747646, 73.922172 39.747646, 73.922172 38.347439))",
"POLYGON ((99.272693 39.747702, 99.531283 39.747702, 99.531283 39.780271, 99.272693 39.780271, 99.272693 39.747702))",
"POLYGON ((99.130926 39.780373, 99.497263 39.780373, 99.497263 39.796779, 99.130926 39.796779, 99.130926 39.780373))",
"POLYGON ((98.214614 39.796792, 99.477293 39.796792, 99.477293 39.845960, 98.214614 39.845960, 98.214614 39.796792))",
"POLYGON ((75.677365 39.846224, 101.146695 39.846224, 101.146695 41.885897, 75.677365 41.885897, 75.677365 39.846224))",
"POLYGON ((74.118480 41.885962, 103.851035 41.885962, 103.851035 47.410991, 74.118480 47.410991, 74.118480 41.885962))",
"POLYGON ((83.435103 47.411238, 101.357525 47.411238, 101.357525 47.909373, 83.435103 47.909373, 83.435103 47.411238))",
"POLYGON ((75.898422 47.909387, 102.841660 47.909387, 102.841660 51.510058, 75.898422 51.510058, 75.898422 47.909387))",
"POLYGON ((78.923330 51.510073, 104.665687 51.510073, 104.665687 53.025250, 78.923330 53.025250, 78.923330 51.510073))",
"POLYGON ((73.865466 53.025253, 88.083947 53.025253, 88.083947 53.785043, 73.865466 53.785043, 73.865466 53.025253))",
"POLYGON ((74.085498 53.785128, 99.029923 53.785128, 99.029923 55.159309, 74.085498 55.159309, 74.085498 53.785128))",
"POLYGON ((83.746339 55.159416, 100.705139 55.159416, 100.705139 55.260163, 83.746339 55.260163, 83.746339 55.159416))",
"POLYGON ((74.882136 55.260174, 101.654569 55.260174, 101.654569 56.291878, 74.882136 56.291878, 74.882136 55.260174))",
"POLYGON ((75.595529 56.291885, 104.135658 56.291885, 104.135658 75.735783, 75.595529 75.735783, 75.595529 56.291885))",
"POLYGON ((110.008800 -8.261979, 112.527438 -8.261979, 112.527438 -7.571796, 110.008800 -7.571796, 110.008800 -8.261979))",
"POLYGON ((106.714643 -7.571576, 112.403884 -7.571576, 112.403884 -6.135538, 106.714643 -6.135538, 106.714643 -7.571576))",
"POLYGON ((106.021634 -6.135535, 110.346135 -6.135535, 110.346135 14.660995, 106.021634 14.660995, 106.021634 -6.135535))",
"POLYGON ((105.814332 14.660999, 111.308499 14.660999, 111.308499 26.459893, 105.814332 26.459893, 105.814332 14.660999))",
"POLYGON ((106.566710 26.460056, 112.227732 26.460056, 112.227732 34.452064, 106.566710 34.452064, 106.566710 26.460056))",
"POLYGON ((106.226715 34.452066, 112.420622 34.452066, 112.420622 37.273592, 106.226715 37.273592, 106.226715 34.452066))",
"POLYGON ((106.211540 37.273725, 108.659268 37.273725, 108.659268 37.307887, 106.211540 37.307887, 106.211540 37.273725))",
"POLYGON ((108.338993 37.307939, 108.655756 37.307939, 108.655756 37.339618, 108.338993 37.339618, 108.338993 37.307939))",
"POLYGON ((108.259709 37.339884, 109.013442 37.339884, 109.013442 37.378374, 108.259709 37.378374, 108.259709 37.339884))",
"POLYGON ((108.177595 37.378377, 108.945910 37.378377, 108.945910 37.418044, 108.177595 37.418044, 108.177595 37.378377))",
"POLYGON ((107.935289 37.418438, 108.176803 37.418438, 108.176803 37.524061, 107.935289 37.524061, 107.935289 37.418438))",
"POLYGON ((107.794804 37.524062, 108.976519 37.524062, 108.976519 37.531011, 107.794804 37.531011, 107.794804 37.524062))",
"POLYGON ((107.601073 37.531072, 108.044961 37.531072, 108.044961 37.540675, 107.601073 37.540675, 107.601073 37.531072))",
"POLYGON ((107.555932 37.540699, 109.148242 37.540699, 109.148242 37.662856, 107.555932 37.662856, 107.555932 37.540699))",
"POLYGON ((105.781825 37.662879, 107.599834 37.662879, 107.599834 37.828952, 105.781825 37.828952, 105.781825 37.662879))",
"POLYGON ((105.781998 37.829411, 107.507606 37.829411, 107.507606 37.913734, 105.781998 37.913734, 105.781998 37.829411))",
"POLYGON ((105.792033 37.913841, 107.373250 37.913841, 107.373250 37.976314, 105.792033 37.976314, 105.792033 37.913841))",
"POLYGON ((105.787155 37.976420, 107.261177 37.976420, 107.261177 38.067007, 105.787155 38.067007, 105.787155 37.976420))",
"POLYGON ((105.782026 38.067521, 107.133683 38.067521, 107.133683 38.142772, 105.782026 38.142772, 105.782026 38.067521))",
"POLYGON ((106.774304 38.142807, 106.903806 38.142807, 106.903806 38.168754, 106.774304 38.168754, 106.774304 38.142807))",
"POLYGON ((105.782543 38.168790, 106.805073 38.168790, 106.805073 38.222367, 105.782543 38.222367, 105.782543 38.168790))",
"POLYGON ((105.783502 38.222381, 106.663354 38.222381, 106.663354 38.256266, 105.783502 38.256266, 105.783502 38.222381))",
"POLYGON ((105.786057 38.256297, 106.566548 38.256297, 106.566548 38.298251, 105.786057 38.298251, 105.786057 38.256297))",
"POLYGON ((105.829126 38.298283, 106.506878 38.298283, 106.506878 38.323629, 105.829126 38.323629, 105.829126 38.298283))",
"POLYGON ((105.792821 38.323656, 106.478044 38.323656, 106.478044 38.362858, 105.792821 38.362858, 105.792821 38.323656))",
"POLYGON ((105.787307 38.362880, 112.440613 38.362880, 112.440613 38.946494, 105.787307 38.946494, 105.787307 38.362880))",
"POLYGON ((111.000034 38.946539, 112.542519 38.946539, 112.542519 39.053078, 111.000034 39.053078, 111.000034 38.946539))",
"POLYGON ((112.388681 39.053127, 112.509386 39.053127, 112.509386 39.070531, 112.388681 39.070531, 112.388681 39.053127))",
"POLYGON ((112.289539 39.070597, 112.550637 39.070597, 112.550637 39.098614, 112.289539 39.098614, 112.289539 39.070597))",
"POLYGON ((111.166514 39.098614, 112.347913 39.098614, 112.347913 39.127246, 111.166514 39.127246, 111.166514 39.098614))",
"POLYGON ((112.233667 39.127330, 112.262401 39.127330, 112.262401 39.180338, 112.233667 39.180338, 112.233667 39.127330))",
"POLYGON ((111.203272 39.190798, 112.226700 39.190798, 112.226700 39.264295, 111.203272 39.264295, 111.203272 39.190798))",
"POLYGON ((111.085737 39.264327, 112.176211 39.264327, 112.176211 39.294914, 111.085737 39.294914, 111.085737 39.264327))",
"POLYGON ((111.241083 39.295050, 112.128292 39.295050, 112.128292 39.310520, 111.241083 39.310520, 111.241083 39.295050))",
"POLYGON ((111.189458 39.310522, 112.092006 39.310522, 112.092006 39.344983, 111.189458 39.344983, 111.189458 39.310522))",
"POLYGON ((109.776026 39.345088, 112.036374 39.345088, 112.036374 39.373111, 109.776026 39.373111, 109.776026 39.345088))",
"POLYGON ((111.149842 39.373192, 112.005052 39.373192, 112.005052 39.413866, 111.149842 39.413866, 111.149842 39.373192))",
"POLYGON ((111.192736 39.414049, 111.948538 39.414049, 111.948538 39.435830, 111.192736 39.435830, 111.192736 39.414049))",
"POLYGON ((111.095861 39.435831, 111.917230 39.435831, 111.917230 39.471367, 111.095861 39.471367, 111.095861 39.435831))",
"POLYGON ((111.363815 39.471540, 111.909447 39.471540, 111.909447 39.504184, 111.363815 39.504184, 111.363815 39.471540))",
"POLYGON ((111.421071 39.504574, 111.875487 39.504574, 111.875487 39.543726, 111.421071 39.543726, 111.421071 39.504574))",
"POLYGON ((111.438684 39.543743, 111.871542 39.543743, 111.871542 39.586525, 111.438684 39.586525, 111.438684 39.543743))",
"POLYGON ((111.745953 39.586543, 111.884311 39.586543, 111.884311 39.594606, 111.745953 39.594606, 111.745953 39.586543))",
"POLYGON ((111.699409 39.594634, 111.883584 39.594634, 111.883584 39.603333, 111.699409 39.603333, 111.699409 39.594634))",
"POLYGON ((111.698380 39.603381, 111.877808 39.603381, 111.877808 39.615957, 111.698380 39.615957, 111.698380 39.603381))",
"POLYGON ((111.435772 39.615982, 111.874758 39.615982, 111.874758 39.627850, 111.435772 39.627850, 111.435772 39.615982))",
"POLYGON ((111.600198 39.627900, 111.866646 39.627900, 111.866646 39.634427, 111.600198 39.634427, 111.600198 39.627900))",
"POLYGON ((111.436025 39.634427, 111.870303 39.634427, 111.870303 39.638937, 111.436025 39.638937, 111.436025 39.634427))",
"POLYGON ((111.440770 39.638937, 111.871051 39.638937, 111.871051 39.644250, 111.440770 39.644250, 111.440770 39.638937))",
"POLYGON ((111.446835 39.644263, 111.881505 39.644263, 111.881505 39.651166, 111.446835 39.651166, 111.446835 39.644263))",
"POLYGON ((111.482514 39.651168, 111.916745 39.651168, 111.916745 39.668137, 111.482514 39.668137, 111.482514 39.651168))",
"POLYGON ((106.816891 39.668194, 111.965224 39.668194, 111.965224 39.757556, 106.816891 39.757556, 106.816891 39.668194))",
"POLYGON ((111.960965 39.758135, 112.020948 39.758135, 112.020948 39.842177, 111.960965 39.842177, 111.960965 39.758135))",
"POLYGON ((112.018461 39.842319, 112.065406 39.842319, 112.065406 39.912869, 112.018461 39.912869, 112.018461 39.842319))",
"POLYGON ((112.065424 39.912927, 112.115759 39.912927, 112.115759 39.988773, 112.065424 39.988773, 112.065424 39.912927))",
"POLYGON ((112.116018 39.989002, 112.194499 39.989002, 112.194499 40.099931, 112.116018 40.099931, 112.116018 39.989002))",
"POLYGON ((112.176634 40.100039, 112.225472 40.100039, 112.225472 40.139640, 112.176634 40.139640, 112.176634 40.100039))",
"POLYGON ((112.178085 40.139794, 112.236004 40.139794, 112.236004 40.174883, 112.178085 40.174883, 112.178085 40.139794))",
"POLYGON ((112.182465 40.174964, 112.284690 40.174964, 112.284690 40.204099, 112.182465 40.204099, 112.182465 40.174964))",
"POLYGON ((112.217900 40.204101, 112.295717 40.204101, 112.295717 40.235065, 112.217900 40.235065, 112.217900 40.204101))",
"POLYGON ((112.261631 40.235390, 112.368397 40.235390, 112.368397 40.267863, 112.261631 40.267863, 112.261631 40.235390))",
"POLYGON ((112.297342 40.267872, 112.393248 40.267872, 112.393248 40.324744, 112.297342 40.324744, 112.297342 40.267872))",
"POLYGON ((105.950139 40.324746, 112.393838 40.324746, 112.393838 71.933657, 105.950139 71.933657, 105.950139 40.324746))",
"POLYGON ((114.310274 -34.375006, 116.280901 -34.375006, 116.280901 -8.465571, 114.310274 -8.465571, 114.310274 -34.375006))",
"POLYGON ((112.560662 -8.465504, 116.260261 -8.465504, 116.260261 22.197449, 112.560662 22.197449, 112.560662 -8.465504))",
"POLYGON ((113.136066 22.197449, 114.361904 22.197449, 114.361904 27.835249, 113.136066 27.835249, 113.136066 22.197449))",
"POLYGON ((112.552869 27.835315, 116.090099 27.835315, 116.090099 39.032322, 112.552869 39.032322, 112.552869 27.835315))",
"POLYGON ((112.550989 39.032721, 112.658368 39.032721, 112.658368 39.096132, 112.550989 39.096132, 112.550989 39.032721))",
"POLYGON ((112.646333 39.096134, 115.167229 39.096134, 115.167229 39.199552, 112.646333 39.199552, 112.646333 39.096134))",
"POLYGON ((112.786495 39.199608, 113.182688 39.199608, 113.182688 39.255821, 112.786495 39.255821, 112.786495 39.199608))",
"POLYGON ((112.761665 39.255821, 116.437734 39.255821, 116.437734 39.948319, 112.761665 39.948319, 112.761665 39.255821))",
"POLYGON ((112.774062 39.948319, 116.373886 39.948319, 116.373886 40.190140, 112.774062 40.190140, 112.774062 39.948319))",
"POLYGON ((112.813601 40.190273, 115.954620 40.190273, 115.954620 40.223308, 112.813601 40.223308, 112.813601 40.190273))",
"POLYGON ((112.855613 40.223516, 115.947642 40.223516, 115.947642 40.245319, 112.855613 40.245319, 112.855613 40.223516))",
"POLYGON ((112.865367 40.245322, 115.936557 40.245322, 115.936557 40.247914, 112.865367 40.247914, 112.865367 40.245322))",
"POLYGON ((112.866355 40.247915, 115.936540 40.247915, 115.936540 40.250897, 112.866355 40.250897, 112.866355 40.247915))",
"POLYGON ((112.868009 40.250912, 115.936657 40.250912, 115.936657 40.256577, 112.868009 40.256577, 112.868009 40.250912))",
"POLYGON ((112.869506 40.256651, 116.072019 40.256651, 116.072019 40.289883, 112.869506 40.289883, 112.869506 40.256651))",
"POLYGON ((112.884050 40.289929, 116.071690 40.289929, 116.071690 40.312593, 112.884050 40.312593, 112.884050 40.289929))",
"POLYGON ((112.890539 40.312630, 115.965877 40.312630, 115.965877 40.316143, 112.890539 40.316143, 112.890539 40.312630))",
"POLYGON ((112.891027 40.316143, 115.966903 40.316143, 115.966903 40.319000, 112.891027 40.319000, 112.891027 40.316143))",
"POLYGON ((112.852630 40.319001, 115.967238 40.319001, 115.967238 40.326322, 112.852630 40.326322, 112.852630 40.319001))",
"POLYGON ((112.890642 40.326326, 115.983699 40.326326, 115.983699 40.332706, 112.890642 40.332706, 112.890642 40.326326))",
"POLYGON ((112.908691 40.332733, 116.044984 40.332733, 116.044984 40.336188, 112.908691 40.336188, 112.908691 40.332733))",
"POLYGON ((112.921477 40.336214, 116.038038 40.336214, 116.038038 40.343628, 112.921477 40.343628, 112.921477 40.336214))",
"POLYGON ((112.947583 40.343630, 116.025370 40.343630, 116.025370 40.351132, 112.947583 40.351132, 112.947583 40.343630))",
"POLYGON ((112.971120 40.351147, 116.017846 40.351147, 116.017846 40.357760, 112.971120 40.357760, 112.971120 40.351147))",
"POLYGON ((113.002611 40.357776, 116.019119 40.357776, 116.019119 40.365814, 113.002611 40.365814, 113.002611 40.357776))",
"POLYGON ((113.037533 40.365837, 116.025346 40.365837, 116.025346 40.373979, 113.037533 40.373979, 113.037533 40.365837))",
"POLYGON ((113.095214 40.373982, 116.244166 40.373982, 116.244166 40.395739, 113.095214 40.395739, 113.095214 40.373982))",
"POLYGON ((113.192857 40.395741, 116.261067 40.395741, 116.261067 40.405930, 113.192857 40.405930, 113.192857 40.395741))",
"POLYGON ((113.233134 40.406015, 116.328419 40.406015, 116.328419 40.411541, 113.233134 40.411541, 113.233134 40.406015))",
"POLYGON ((113.246002 40.411545, 116.352308 40.411545, 116.352308 40.417798, 113.246002 40.417798, 113.246002 40.411545))",
"POLYGON ((113.256684 40.417804, 116.373856 40.417804, 116.373856 40.423568, 113.256684 40.423568, 113.256684 40.417804))",
"POLYGON ((113.264587 40.423568, 116.378075 40.423568, 116.378075 40.432060, 113.264587 40.432060, 113.264587 40.423568))",
"POLYGON ((113.285403 40.432128, 116.425989 40.432128, 116.425989 40.439192, 113.285403 40.439192, 113.285403 40.432128))",
"POLYGON ((113.307424 40.439203, 116.485335 40.439203, 116.485335 40.447971, 113.307424 40.447971, 113.307424 40.439203))",
"POLYGON ((113.338067 40.447981, 116.482273 40.447981, 116.482273 40.454938, 113.338067 40.454938, 113.338067 40.447981))",
"POLYGON ((113.361163 40.454958, 116.474285 40.454958, 116.474285 40.458513, 113.361163 40.458513, 113.361163 40.454958))",
"POLYGON ((113.396011 40.458522, 116.485360 40.458522, 116.485360 40.461683, 113.396011 40.461683, 113.396011 40.458522))",
"POLYGON ((113.387878 40.461684, 116.451122 40.461684, 116.451122 40.468320, 113.387878 40.468320, 113.387878 40.461684))",
"POLYGON ((113.421211 40.468324, 116.477901 40.468324, 116.477901 40.477043, 113.421211 40.477043, 113.421211 40.468324))",
"POLYGON ((113.460441 40.477054, 116.485323 40.477054, 116.485323 40.480869, 113.460441 40.480869, 113.460441 40.477054))",
"POLYGON ((113.468695 40.480880, 116.478748 40.480880, 116.478748 40.489281, 113.468695 40.489281, 113.468695 40.480880))",
"POLYGON ((113.987430 40.489302, 116.438376 40.489302, 116.438376 40.495051, 113.987430 40.495051, 113.987430 40.489302))",
"POLYGON ((113.984531 40.495055, 116.470400 40.495055, 116.470400 40.512497, 113.984531 40.512497, 113.984531 40.495055))",
"POLYGON ((113.004314 40.512503, 116.485353 40.512503, 116.485353 40.532597, 113.004314 40.532597, 113.004314 40.512503))",
"POLYGON ((113.005171 40.532675, 115.411944 40.532675, 115.411944 40.558626, 113.005171 40.558626, 113.005171 40.532675))",
"POLYGON ((112.972530 40.558632, 115.411353 40.558632, 115.411353 40.574153, 112.972530 40.574153, 112.972530 40.558632))",
"POLYGON ((113.099430 40.574213, 114.071868 40.574213, 114.071868 40.587169, 113.099430 40.587169, 113.099430 40.574213))",
"POLYGON ((113.143052 40.587232, 116.239798 40.587232, 116.239798 40.594087, 113.143052 40.594087, 113.143052 40.587232))",
"POLYGON ((113.152768 40.594158, 116.256181 40.594158, 116.256181 40.604734, 113.152768 40.604734, 113.152768 40.594158))",
"POLYGON ((114.019204 40.604737, 116.205111 40.604737, 116.205111 40.626260, 114.019204 40.626260, 114.019204 40.604737))",
"POLYGON ((114.048281 40.626293, 116.206316 40.626293, 116.206316 40.658957, 114.048281 40.658957, 114.048281 40.626293))",
"POLYGON ((114.060786 40.658974, 116.193815 40.658974, 116.193815 40.676367, 114.060786 40.676367, 114.060786 40.658974))",
"POLYGON ((114.063639 40.676429, 116.167362 40.676429, 116.167362 40.690723, 114.063639 40.690723, 114.063639 40.676429))",
"POLYGON ((114.053644 40.690746, 116.172064 40.690746, 116.172064 40.705834, 114.053644 40.705834, 114.053644 40.690746))",
"POLYGON ((114.053742 40.705897, 116.173368 40.705897, 116.173368 40.726027, 114.053742 40.726027, 114.053742 40.705897))",
"POLYGON ((114.064283 40.726035, 115.998870 40.726035, 115.998870 40.736505, 114.064283 40.736505, 114.064283 40.726035))",
"POLYGON ((114.082113 40.736511, 116.165240 40.736511, 116.165240 40.748104, 114.082113 40.748104, 114.082113 40.736511))",
"POLYGON ((113.064002 40.748146, 116.161309 40.748146, 116.161309 40.762383, 113.064002 40.762383, 113.064002 40.748146))",
"POLYGON ((113.078277 40.762417, 116.155739 40.762417, 116.155739 40.781429, 113.078277 40.781429, 113.078277 40.762417))",
"POLYGON ((115.433453 40.781451, 116.153907 40.781451, 116.153907 40.810259, 115.433453 40.810259, 115.433453 40.781451))",
"POLYGON ((114.453976 40.810278, 116.115352 40.810278, 116.115352 40.872269, 114.453976 40.872269, 114.453976 40.810278))",
"POLYGON ((114.792837 40.872325, 116.104670 40.872325, 116.104670 40.983732, 114.792837 40.983732, 114.792837 40.872325))",
"POLYGON ((113.162812 40.983785, 115.936974 40.983785, 115.936974 62.534010, 113.162812 62.534010, 113.162812 40.983785))",
"POLYGON ((117.188520 -31.456450, 132.328189 -31.456450, 132.328189 -9.636561, 117.188520 -9.636561, 117.188520 -31.456450))",
"POLYGON ((116.539860 -9.636561, 125.205362 -9.636561, 125.205362 -5.133767, 116.539860 -5.133767, 116.539860 -9.636561))",
"POLYGON ((116.821694 -5.133751, 131.246343 -5.133751, 131.246343 11.109617, 116.821694 11.109617, 116.821694 -5.133751))",
"POLYGON ((120.566510 11.109631, 125.483596 11.109631, 125.483596 14.381782, 120.566510 14.381782, 120.566510 11.109631))",
"POLYGON ((120.566000 14.381899, 121.079589 14.381899, 121.079589 14.605148, 120.566000 14.605148, 120.566000 14.381899))",
"POLYGON ((119.933912 14.605157, 121.437995 14.605157, 121.437995 22.630194, 119.933912 22.630194, 119.933912 14.605157))",
"POLYGON ((120.155944 22.630421, 121.120186 22.630421, 121.120186 23.002831, 120.155944 23.002831, 120.155944 22.630421))",
"POLYGON ((116.997698 23.002840, 121.613205 23.002840, 121.613205 24.078548, 116.997698 24.078548, 116.997698 23.002840))",
"POLYGON ((118.318231 24.078704, 121.942677 24.078704, 121.942677 25.003935, 118.318231 25.003935, 118.318231 24.078704))",
"POLYGON ((121.420960 25.003937, 121.932811 25.003937, 121.932811 25.042512, 121.420960 25.042512, 121.420960 25.003937))",
"POLYGON ((121.426496 25.042513, 121.874984 25.042513, 121.874984 25.165725, 121.426496 25.165725, 121.426496 25.042513))",
"POLYGON ((119.755754 25.165742, 127.879809 25.165742, 127.879809 26.408884, 119.755754 26.408884, 119.755754 25.165742))",
"POLYGON ((117.031539 26.408903, 131.095321 26.408903, 131.095321 32.025592, 117.031539 32.025592, 117.031539 26.408903))",
"POLYGON ((116.779330 32.025751, 131.390822 32.025751, 131.390822 32.628884, 116.779330 32.628884, 116.779330 32.025751))",
"POLYGON ((117.216625 32.628931, 130.739755 32.628931, 130.739755 33.160097, 117.216625 33.160097, 117.216625 32.628931))",
"POLYGON ((129.891647 33.160097, 130.415614 33.160097, 130.415614 33.190700, 129.891647 33.190700, 129.891647 33.160097))",
"POLYGON ((126.277951 33.190714, 131.739822 33.190714, 131.739822 33.513827, 126.277951 33.513827, 126.277951 33.190714))",
"POLYGON ((126.521296 33.513827, 132.341432 33.513827, 132.341432 34.296284, 126.521296 34.296284, 126.521296 33.513827))",
"POLYGON ((116.577861 34.296645, 132.453467 34.296645, 132.453467 35.842112, 116.577861 35.842112, 116.577861 34.296645))",
"POLYGON ((117.027400 35.842150, 129.252237 35.842150, 129.252237 37.466094, 117.027400 37.466094, 117.027400 35.842150))",
"POLYGON ((122.121664 37.466103, 127.120740 37.466103, 127.120740 37.553822, 122.121664 37.553822, 122.121664 37.466103))",
"POLYGON ((126.735119 37.553822, 127.104604 37.553822, 127.104604 37.588034, 126.735119 37.588034, 126.735119 37.553822))",
"POLYGON ((117.169650 37.588063, 128.606370 37.588063, 128.606370 39.974817, 117.169650 39.974817, 117.169650 37.588063))",
"POLYGON ((116.506951 39.976529, 124.615118 39.976529, 124.615118 40.231976, 116.506951 40.231976, 116.506951 39.976529))",
"POLYGON ((117.245336 40.231978, 117.408681 40.231978, 117.408681 40.243211, 117.245336 40.243211, 117.245336 40.231978))",
"POLYGON ((117.179203 40.243214, 117.811546 40.243214, 117.811546 40.257929, 117.179203 40.257929, 117.179203 40.243214))",
"POLYGON ((117.177423 40.258004, 117.825623 40.258004, 117.825623 40.277460, 117.177423 40.277460, 117.177423 40.258004))",
"POLYGON ((117.209812 40.277496, 128.749036 40.277496, 128.749036 40.385637, 117.209812 40.385637, 117.209812 40.277496))",
"POLYGON ((116.623104 40.385689, 118.321048 40.385689, 118.321048 40.421168, 116.623104 40.421168, 116.623104 40.385689))",
"POLYGON ((116.567159 40.421176, 118.334500 40.421176, 118.334500 40.425338, 116.567159 40.425338, 116.567159 40.421176))",
"POLYGON ((116.564872 40.425347, 118.335180 40.425347, 118.335180 40.430684, 116.564872 40.430684, 116.564872 40.425347))",
"POLYGON ((116.561836 40.430702, 118.332707 40.430702, 118.332707 40.435859, 116.561836 40.435859, 116.561836 40.430702))",
"POLYGON ((116.560573 40.435867, 118.268432 40.435867, 118.268432 40.439985, 116.560573 40.439985, 116.560573 40.435867))",
"POLYGON ((116.554523 40.439986, 117.260330 40.439986, 117.260330 40.445382, 116.554523 40.445382, 116.554523 40.439986))",
"POLYGON ((116.485423 40.445388, 117.246132 40.445388, 117.246132 40.451498, 116.485423 40.451498, 116.485423 40.445388))",
"POLYGON ((116.492990 40.451502, 117.239858 40.451502, 117.239858 40.454504, 116.492990 40.454504, 116.492990 40.451502))",
"POLYGON ((116.498569 40.454506, 117.235745 40.454506, 117.235745 40.456406, 116.498569 40.456406, 116.498569 40.454506))",
"POLYGON ((116.485422 40.456407, 117.228690 40.456407, 117.228690 40.462866, 116.485422 40.462866, 116.485422 40.456407))",
"POLYGON ((116.485422 40.462886, 117.231331 40.462886, 117.231331 40.481487, 116.485422 40.481487, 116.485422 40.462886))",
"POLYGON ((116.486926 40.481507, 117.218255 40.481507, 117.218255 40.516431, 116.486926 40.516431, 116.486926 40.481507))",
"POLYGON ((116.485495 40.516433, 117.257124 40.516433, 117.257124 40.555067, 116.485495 40.555067, 116.485495 40.516433))",
"POLYGON ((116.794885 40.555120, 117.338840 40.555120, 117.338840 40.587088, 116.794885 40.587088, 116.794885 40.555120))",
"POLYGON ((116.818717 40.587221, 120.713628 40.587221, 120.713628 40.624631, 116.818717 40.624631, 116.818717 40.587221))",
"POLYGON ((116.819093 40.624655, 120.711233 40.624655, 120.711233 40.641733, 116.819093 40.641733, 116.819093 40.624655))",
"POLYGON ((116.819013 40.641737, 116.858749 40.641737, 116.858749 40.653516, 116.819013 40.653516, 116.819013 40.641737))",
"POLYGON ((116.853552 40.653541, 117.330114 40.653541, 117.330114 40.661840, 116.853552 40.661840, 116.853552 40.653541))",
"POLYGON ((116.865857 40.661840, 117.352646 40.661840, 117.352646 40.673638, 116.865857 40.673638, 116.865857 40.661840))",
"POLYGON ((116.875109 40.673645, 129.199171 40.673645, 129.199171 40.680561, 116.875109 40.680561, 116.875109 40.673645))",
"POLYGON ((116.878666 40.680563, 117.425843 40.680563, 117.425843 40.682784, 116.878666 40.682784, 116.878666 40.680563))",
"POLYGON ((116.880848 40.682797, 117.420808 40.682797, 117.420808 40.685728, 116.880848 40.685728, 116.880848 40.682797))",
"POLYGON ((116.884326 40.685759, 117.402700 40.685759, 117.402700 40.691433, 116.884326 40.691433, 116.884326 40.685759))",
"POLYGON ((116.887081 40.691443, 117.192517 40.691443, 117.192517 40.694766, 116.887081 40.694766, 116.887081 40.691443))",
"POLYGON ((116.889915 40.694773, 117.186846 40.694773, 117.186846 40.696920, 116.889915 40.696920, 116.889915 40.694773))",
"POLYGON ((116.889384 40.696926, 117.163265 40.696926, 117.163265 40.700913, 116.889384 40.700913, 116.889384 40.696926))",
"POLYGON ((116.890966 40.700921, 117.157217 40.700921, 117.157217 40.707864, 116.890966 40.707864, 116.890966 40.700921))",
"POLYGON ((116.894670 40.707876, 126.214262 40.707876, 126.214262 41.153987, 116.894670 41.153987, 116.894670 40.707876))",
"POLYGON ((121.014805 41.154057, 126.226520 41.154057, 126.226520 41.252561, 121.014805 41.252561, 121.014805 41.154057))",
"POLYGON ((121.035328 41.252782, 121.070928 41.252782, 121.070928 41.393828, 121.035328 41.393828, 121.035328 41.252782))",
"POLYGON ((121.027082 41.393877, 121.168857 41.393877, 121.168857 41.672246, 121.027082 41.672246, 121.027082 41.393877))",
"POLYGON ((121.148695 41.672430, 131.829935 41.672430, 131.829935 42.935000, 121.148695 42.935000, 121.148695 41.672430))",
"POLYGON ((131.779367 42.935018, 132.410842 42.935018, 132.410842 43.116595, 131.779367 43.116595, 131.779367 42.935018))",
"POLYGON ((117.593156 43.116595, 132.373085 43.116595, 132.373085 47.075160, 117.593156 47.075160, 117.593156 43.116595))",
"POLYGON ((117.982092 47.075163, 132.440083 47.075163, 132.440083 71.746754, 117.982092 71.746754, 117.982092 47.075163))",
"POLYGON ((145.445867 -85.003658, 179.995979 -85.003658, 179.995979 -41.568141, 145.445867 -41.568141, 145.445867 -85.003658))",
"POLYGON ((142.911190 -41.568137, 176.919008 -41.568137, 176.919008 -37.949425, 142.911190 -37.949425, 142.911190 -41.568137))",
"POLYGON ((145.426095 -37.949422, 146.450792 -37.949422, 146.450792 -37.929138, 145.426095 -37.929138, 145.426095 -37.949422))",
"POLYGON ((145.233434 -37.929134, 145.491548 -37.929134, 145.491548 -37.914658, 145.233434 -37.914658, 145.233434 -37.929134))",
"POLYGON ((140.783648 -37.914628, 175.480528 -37.914628, 175.480528 -37.804305, 140.783648 -37.804305, 140.783648 -37.914628))",
"POLYGON ((143.552640 -37.804301, 176.186967 -37.804301, 176.186967 -36.900222, 143.552640 -36.900222, 143.552640 -37.804301))",
"POLYGON ((141.985772 -36.900206, 174.857313 -36.900206, 174.857313 -35.305873, 141.985772 -35.305873, 141.985772 -36.900206))",
"POLYGON ((136.891661 -35.305872, 174.121633 -35.305872, 174.121633 -35.158646, 136.891661 -35.158646, 136.891661 -35.305872))",
"POLYGON ((135.557069 -35.158506, 151.250346 -35.158506, 151.250346 -33.992363, 135.557069 -33.992363, 135.557069 -35.158506))",
"POLYGON ((135.269833 -33.992334, 151.273818 -33.992334, 151.273818 -33.845842, 135.269833 -33.845842, 135.269833 -33.992334))",
"POLYGON ((149.577801 -33.845827, 151.629371 -33.845827, 151.629371 -33.136580, 149.577801 -33.136580, 149.577801 -33.845827))",
"POLYGON ((136.463407 -33.019107, 167.971529 -33.019107, 167.971529 -27.472837, 136.463407 -27.472837, 136.463407 -33.019107))",
"POLYGON ((133.887000 -27.472837, 166.948325 -27.472837, 166.948325 -22.155216, 133.887000 -22.155216, 133.887000 -27.472837))",
"POLYGON ((133.889043 -22.155210, 166.466821 -22.155210, 166.466821 -20.774039, 133.889043 -20.774039, 133.889043 -22.155210))",
"POLYGON ((132.689343 -20.774022, 179.194324 -20.774022, 179.194324 6.962270, 132.689343 6.962270, 132.689343 -20.774022))",
"POLYGON ((132.453467 6.962271, 167.683297 6.962271, 167.683297 34.457359, 132.453467 34.457359, 132.453467 6.962271))",
"POLYGON ((135.370609 34.457362, 136.637037 34.457362, 136.637037 34.547356, 135.370609 34.547356, 135.370609 34.457362))",
"POLYGON ((135.475439 34.547384, 135.850591 34.547384, 135.850591 34.558001, 135.475439 34.558001, 135.475439 34.547384))",
"POLYGON ((135.481747 34.558007, 135.851788 34.558007, 135.851788 34.568209, 135.481747 34.568209, 135.481747 34.558007))",
"POLYGON ((133.768122 34.568218, 135.851778 34.568218, 135.851778 34.624788, 133.768122 34.624788, 133.768122 34.568218))",
"POLYGON ((134.971917 34.624788, 135.846587 34.624788, 135.846587 34.677044, 134.971917 34.677044, 134.971917 34.624788))",
"POLYGON ((135.185560 34.677048, 139.441070 34.677048, 139.441070 34.692430, 135.185560 34.692430, 135.185560 34.677048))",
"POLYGON ((133.762085 34.692441, 138.015865 34.692441, 138.015865 34.798909, 133.762085 34.798909, 133.762085 34.692441))",
"POLYGON ((133.620562 34.799050, 137.527418 34.799050, 137.527418 34.913788, 133.620562 34.913788, 133.620562 34.799050))",
"POLYGON ((134.845573 34.913790, 137.529327 34.913790, 137.529327 34.953595, 134.845573 34.953595, 134.845573 34.913790))",
"POLYGON ((135.605212 34.953605, 138.411223 34.953605, 138.411223 35.012041, 135.605212 35.012041, 135.605212 34.953605))",
"POLYGON ((135.673419 35.012042, 136.205888 35.012042, 136.205888 35.038940, 135.673419 35.038940, 135.673419 35.012042))",
"POLYGON ((135.213993 35.038941, 137.534837 35.038941, 137.534837 35.072658, 135.213993 35.072658, 135.213993 35.038941))",
"POLYGON ((135.213870 35.072658, 140.223610 35.072658, 140.223610 35.157595, 135.213870 35.157595, 135.213870 35.072658))",
"POLYGON ((134.199352 35.157611, 139.674501 35.157611, 139.674501 35.420129, 134.199352 35.420129, 134.199352 35.157611))",
"POLYGON ((133.048398 35.420139, 139.656702 35.420139, 139.656702 35.529153, 133.048398 35.529153, 133.048398 35.420139))",
"POLYGON ((138.577568 35.529153, 140.167167 35.529153, 140.167167 35.652705, 138.577568 35.652705, 138.577568 35.529153))",
"POLYGON ((136.074326 35.652811, 139.968007 35.652811, 139.968007 35.702975, 136.074326 35.702975, 136.074326 35.652811))",
"POLYGON ((139.216524 35.703028, 140.499094 35.703028, 140.499094 35.925722, 139.216524 35.925722, 139.216524 35.703028))",
"POLYGON ((136.183778 35.925799, 140.368693 35.925799, 140.368693 36.341718, 136.183778 36.341718, 136.183778 35.925799))",
"POLYGON ((136.443436 36.341820, 140.644957 36.341820, 140.644957 36.549585, 136.443436 36.549585, 136.443436 36.341820))",
"POLYGON ((136.658048 36.561789, 141.013791 36.561789, 141.013791 37.592266, 136.658048 37.592266, 136.658048 36.561789))",
"POLYGON ((138.424974 37.592270, 141.446412 37.592270, 141.446412 38.727930, 138.424974 38.727930, 138.424974 37.592270))",
"POLYGON ((132.520338 38.727963, 146.857864 38.727963, 146.857864 44.150149, 132.520338 44.150149, 132.520338 38.727963))",
"POLYGON ((132.903381 44.150156, 148.330184 44.150156, 148.330184 49.037764, 132.903381 49.037764, 132.903381 44.150156))",
"POLYGON ((132.600513 49.037770, 179.446980 49.037770, 179.446980 65.733972, 132.600513 65.733972, 132.600513 49.037770))",
"POLYGON ((144.492247 65.733974, 172.236747 65.733974, 172.236747 68.994402, 144.492247 68.994402, 144.492247 65.733974))",
];
